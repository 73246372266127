import { useMediaQuery } from '@mui/material';
import { ConnectKitButton } from '@rehold-io/connectkit';
import { track, PredefinedEvents } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { HotjarEvents, trackHotjar } from 'features/HotJar';
import { TOUR_CLASSES } from 'features/OnboardingTour';

import { useGetDomain } from 'entities/Domain';

import { MenuIcon, WalletIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { useIsMobile } from 'shared/hooks/useResponsive';
import { shortenIfAddress } from 'shared/lib';
import { Box, Text, Button } from 'shared/ui';
import { Menu } from 'shared/ui/Menu';

import { MainMenu as MainMenuNew, Step, WalletMenu as WalletMenuNew } from '../../Menu';

export const Account: React.FC = () => {
  const { address } = useAccount();

  if (address) {
    return <AccountConnected />;
  }

  return <AccountConnect />;
};

export const AccountConnect: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const isWideMobile = useMediaQuery('(max-width: 1024px)', { noSsr: true });

  return (
    <ConnectKitButton.Custom>
      {({ show }) => {
        const onPress = () => {
          trackHotjar(HotjarEvents.WALLET_CONNECT_CLICK);
          track(PredefinedEvents.Wallet.Connect.Click({ from: location.pathname }));
          show?.();
        };
        return (
          <Button
            variant="primary"
            size="small"
            onClick={onPress}
            borderRadius={32}
            ml={8}
            className={TOUR_CLASSES.HEADER_CONNECT.class}
            data-id="header-connect"
          >
            {t(`modals.connectWallet.${isWideMobile ? 'shortTitle' : 'title'}`)}
          </Button>
        );
      }}
    </ConnectKitButton.Custom>
  );
};

export const AccountConnected = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <Main />;
  }

  return <Wallet />;
};

export const Main = () => {
  const [isOpen, toggle] = useState<boolean>(false);
  const [step, setStep] = useState<Step>('root');

  const handleToggle = useCallback(() => {
    toggle((isOpen) => !isOpen);
  }, [toggle]);

  useEffect(() => {
    if (!isOpen) {
      setStep('root');
    }
  }, [isOpen]);

  const menuTrigger = (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      minWidth={40}
      height={40}
      px={{ default: 0, tablet: 10 }}
      ml={8}
      borderRadius={40}
      bg={isOpen ? 'secondary-02' : undefined}
      borderColor="secondary-02"
      sx={{ borderWidth: 1 }}
      onClick={handleToggle}
      color="secondary-03"
    >
      <MenuIcon width={24} height={24} />
    </Box>
  );

  return (
    <Menu trigger={menuTrigger} isOpen={isOpen} toggle={toggle} blockBackgroundScroll={step !== 'root'}>
      <MainMenuNew toggle={toggle} step={step} onChangeStep={setStep} />
    </Menu>
  );
};

export const Wallet = observer(() => {
  const { address } = useAccount();

  const isWideMobile = useMediaQuery('(max-width: 1024px)', { noSsr: true });
  const isMobile = useIsMobile();

  const [isOpen, toggle] = useState<boolean>(false);

  const { name: domain } = useGetDomain();
  const slice = isMobile ? 4 : 20;

  const viewAddress = domain || address || '';

  const handleToggle = useCallback(() => {
    toggle((isOpen) => !isOpen);
  }, [toggle]);

  const menuTrigger = (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      minWidth={40}
      height={40}
      px={{ default: 0, phone: 10 }}
      ml={8}
      borderRadius={40}
      bg="background-01"
      borderColor="secondary-02"
      sx={{ borderWidth: 1 }}
      onClick={handleToggle}
      color="white-01"
    >
      <WalletIcon width={24} height={24} />
      {!isWideMobile && <Text ml={8}>{shortenIfAddress(viewAddress, domain ? slice : 4)}</Text>}
    </Box>
  );

  return (
    <Menu trigger={menuTrigger} isOpen={isOpen} toggle={toggle} blockBackgroundScroll>
      <WalletMenuNew />
    </Menu>
  );
});
