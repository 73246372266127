import { Skeleton } from '@mui/material';
import { formatAmount } from '@rehold-io/formatters';
import { useTranslation } from 'react-i18next';

import { PendingDualType } from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

import { Box, Text, CurrencyPair } from 'shared/ui';

interface DualPendingInputsProps {
  dual: PendingDualType;
  pairBg?: React.ComponentProps<typeof CurrencyPair>['borderColor'];
  showPeriod?: boolean;
}

export const PendingDualInputs: React.FC<DualPendingInputsProps> = ({ dual, pairBg, showPeriod = true }) => {
  const { t } = useTranslation();
  const { baseTicker, inputAmount, inputTicker, quoteTicker, stakingPeriod } = dual;
  const eqTicker = inputTicker === baseTicker ? quoteTicker : baseTicker;

  const { tokens } = useGetDualTokensQuery();

  return (
    <Box flexDirection="row" alignItems="center">
      <Box mr={8}>
        <CurrencyPair borderColor={pairBg} major={baseTicker} minor={quoteTicker} renderAsTokens />
      </Box>
      <Box alignSelf="center">
        <Box mb={2} display="flex" flexDirection="row" gap="4px">
          <Text>{formatAmount({ symbol: inputTicker, value: inputAmount })}</Text>
          <Text>{tokens[inputTicker]?.symbol}</Text>
        </Box>
        <Box>
          <Text text="app-12-medium" display="flex" gap="4px" alignItems="center">
            <Skeleton
              width={48}
              height={15}
              sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'none' }}
              animation="wave"
            />
            <Text opacity={0.4}>{tokens[eqTicker]?.symbol}</Text>
            {showPeriod && (
              <Text color="secondary-03" opacity={0.6} ml="4px">
                {' '}
                {t('common.hoursShort', { value: stakingPeriod })}
              </Text>
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
