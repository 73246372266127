import { requester } from 'shared/api';

import {
  ClaimReferralArgs,
  ReferralClaim,
  ReferralHash,
  ReferralOverviewResponse,
  ReferralSettings,
} from '../model/types';

export const getReferralOverview = (address: string) =>
  requester.get<ReferralOverviewResponse>(`/api/v2/referrals/${address}`).then((res) => res.data);

export const getReferralClaims = (address: string) =>
  requester.get<ReferralClaim[]>(`/api/v2/referrals/${address}/claims`).then((res) => res.data);

export const getReferralSettings = (chainId: number) =>
  requester.get<ReferralSettings>(`/api/v2/referrals/${chainId}/settings`).then((res) => res.data);

export const claimReferral = ({ chainId, signature }: ClaimReferralArgs) =>
  requester.post<ReferralHash>(
    '/api/v2/referrals/claim',
    { chainId },
    {
      headers: {
        Authorization: signature,
      },
    },
  );
