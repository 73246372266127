import { useMediaQuery } from '@mui/material';

import { PerpetualFormProvider, PerpetualTariffsProvider } from 'entities/Perpetual/lib/providers';

import { Box } from 'shared/ui';

import './index.scss';
import { PerpetualsChart } from './ui/PerpetualsChart';
import { PerpetualsForm } from './ui/PerpetualsForm';
import { PerpetualsInfo } from './ui/PerpetualsInfo';
import { PerpetualsLists } from './ui/PerpetualsLists';

export const PerpetualsWidget = () => {
  const isMediumSizedDesktop = useMediaQuery('(max-width: 1280px)');
  const isSmallSizedDesktop = useMediaQuery('(max-width: 767px)');

  return (
    <PerpetualTariffsProvider>
      <PerpetualFormProvider>
        <Box className="perpetuals-container">
          <Box className="perpetuals-primary-container">
            <PerpetualsInfo />
            <PerpetualsChart />
            <Box className="perpetuals-secondary-container">
              {isSmallSizedDesktop && (
                <Box className="perpetuals-form-container">
                  <PerpetualsForm />
                </Box>
              )}
              <PerpetualsLists />
              {isMediumSizedDesktop && !isSmallSizedDesktop && (
                <Box className="perpetuals-form-container">
                  <PerpetualsForm />
                </Box>
              )}
            </Box>
          </Box>
          {!isMediumSizedDesktop && (
            <Box className="perpetuals-form-container">
              <PerpetualsForm />
            </Box>
          )}
        </Box>
      </PerpetualFormProvider>
    </PerpetualTariffsProvider>
  );
};
