import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';

import { useSignAuthMessage } from 'features/Auth/lib/useSignAuthMessage';

import { DEFAULT_ERROR } from 'shared/lib';
import { notifyError } from 'shared/lib/notifications';

import { useCreateWithdrawalMutation } from '../model';

type Props = {
  amount: string;
  chainId: number;
  ticker: string;
};

export const useCreateWithdrawal = ({ amount, chainId, ticker }: Props) => {
  const { mutateAsync, ...rest } = useCreateWithdrawalMutation();
  const { getSignature } = useSignAuthMessage();

  const createWithdrawal = useCallback(async () => {
    try {
      const signature = await getSignature();
      if (!signature) {
        return;
      }

      const result = await mutateAsync({
        signature,
        chainId,
        amount,
        ticker,
      });

      return result;
    } catch (e: any) {
      if (e instanceof AxiosError && e.response?.data?._error?.message) {
        notifyError({ text: e.response?.data?._error?.message });
        return;
      }

      notifyError({ text: DEFAULT_ERROR });
    }
  }, [mutateAsync, amount, chainId, ticker]);

  return useMemo(
    () => ({
      createWithdrawal,
      ...rest,
    }),
    [createWithdrawal, rest],
  );
};
