import { observer } from 'mobx-react-lite';
import React, { useMemo, CFC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetDualAssetsQuery } from 'entities/Dual';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';

import { CHAIN_INFO } from 'shared/config/chains';
import { useNetwork } from 'shared/hooks/network';
import { Box, Button, Text, Spinner, PageContent } from 'shared/ui';

import { DualsList } from './ui';

export const DualCreateFeature: CFC = observer(() => {
  const { t } = useTranslation();
  const { network } = useNetwork();
  const assetsState = useGetDualAssetsQuery();
  const tokensState = useGetDualTokensAllQuery();

  const clientHeight = document.getElementById('root')!.clientHeight;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [clientHeight]);

  const preparationView = useMemo(() => {
    const { isError: isTokensFailed, isLoading: isTokensLoading, refetch: refetchTokens } = tokensState;
    const { isError: isAssetsError, isLoading: isAssetsLoading, refetch: refetchAssets } = assetsState;

    const isLoading = isAssetsLoading || isTokensLoading;

    const isComingSoon = !CHAIN_INFO[network?.id];

    const handleRefetch = () => {
      if (isTokensFailed) {
        refetchTokens();
      }
      if (isAssetsError) {
        refetchAssets();
      }
    };

    if (isComingSoon) {
      return (
        <Box alignItems="center" justifyContent="center" height={544}>
          <Text text="app-22-medium" color="primary-01">
            {t('common.soonOn')} {network?.name}
          </Text>
        </Box>
      );
    }

    if (isLoading) {
      return (
        <Box alignItems="center" justifyContent="center" height={544}>
          <Spinner />
        </Box>
      );
    }

    if (isTokensFailed || isAssetsError) {
      return (
        <>
          <Box padding={16} height={544} justifyContent="center" alignItems="center">
            <Text text="app-18-medium" mb={16}>
              {t('common.wrong')}
            </Text>
            <Button onClick={handleRefetch} data-id="retry-dual-create">
              {t('common.fetchRetry')}
            </Button>
          </Box>
        </>
      );
    }
  }, [tokensState, network, t, assetsState]);

  return <PageContent pt={{ desktop: 20, phone: 0, tablet: 16 }}>{preparationView || <DualsList />}</PageContent>;
});
