/* eslint-disable no-nested-ternary */
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { CFC } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoReplayField, AutoReplayProvider } from 'features/AutoReplay';

import {
  DualChartPreview,
  DualInputs,
  DualTariff,
  useInputTickerLimits,
  useCalculateOutput,
  DualType,
  getTariffFromDual,
  formatApr,
} from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

import { InfoIcon } from 'shared/assets';
import { useIsSmallMobile } from 'shared/hooks/useResponsive';
import { Box, Text, PageContent, Badge, Tooltip } from 'shared/ui';

import { ReplayButton } from '../Button';

import styles from './Modal.module.scss';

type Props = {
  handleReplay: (value: boolean) => Promise<void>;
  isAprChanged: boolean;
  isEnabledAutoReplay?: boolean;
  isOpen: boolean;
  isPeriodChanged: boolean;
  isReplayDisabled: boolean;
  isReplayLoading: boolean;
  isShowChanged: boolean;
  newDual: DualType;
  oldDual: DualType;
  tariff: DualTariff | null;
  textError?: string;
};

export const ModalContent: CFC<Props> = observer(
  ({
    handleReplay,
    isAprChanged,
    isEnabledAutoReplay,
    isOpen,
    isPeriodChanged,
    isReplayDisabled,
    isReplayLoading,
    isShowChanged,
    newDual,
    oldDual,
    tariff,
    textError,
  }) => {
    const { tokens } = useGetDualTokensQuery();
    const isMobile = useIsSmallMobile();
    const { t } = useTranslation();

    const { output } = useCalculateOutput({
      currentTariff: getTariffFromDual(newDual),
      inputAmount: newDual.inputAmount,
      inputTicker: newDual.inputTicker,
      skip: !isOpen,
    });

    const { limit: limitDownOutput } = useInputTickerLimits(
      output?.down?.outputTicker || '',
      getTariffFromDual(newDual),
    );
    const { limit: limitUpOutput } = useInputTickerLimits(output?.up?.outputTicker || '', getTariffFromDual(newDual));

    const isDisabledLimitAmountLess = output
      ? BigNumber(output.down.outputAmount).lt(BigNumber(limitDownOutput.min)) ||
        BigNumber(output.up.outputAmount).lt(BigNumber(limitUpOutput.min))
      : false;
    const isDisabledLimitAmountMore = output
      ? BigNumber(output.down.outputAmount).gt(BigNumber(limitDownOutput.max)) ||
        BigNumber(output.up.outputAmount).gt(BigNumber(limitUpOutput.max))
      : false;

    const error = isDisabledLimitAmountLess
      ? t('dual.autoReplay.validation.amountMore', {
          downAmount: `${limitDownOutput.min} ${tokens[output?.down?.outputTicker || '']?.symbol}`,
          upAmount: `${limitUpOutput.min} ${tokens[output?.up?.outputTicker || '']?.symbol}`,
        })
      : isDisabledLimitAmountMore
      ? t('dual.autoReplay.validation.amountLess', {
          downAmount: `${limitDownOutput.max} ${tokens[output?.down?.outputTicker || '']?.symbol}`,
          upAmount: `${limitUpOutput.max} ${tokens[output?.up?.outputTicker || '']?.symbol}`,
        })
      : undefined;

    return (
      <PageContent maxHeight="100%" height="100%" px={isMobile ? 12 : 24} pb={16} mt={20} className={styles.container}>
        {tariff && (
          <Box flexDirection="row" justifyContent="space-between">
            <DualInputs dual={newDual} pairBg="black-01" />
            <Box>
              <Badge>{formatApr(tariff.apr)}</Badge>
            </Box>
          </Box>
        )}

        {tariff && (
          <DualChartPreview tariff={tariff} inputAmount={newDual.inputAmount} inputTicker={newDual.inputTicker} />
        )}
        {isShowChanged && tariff && (
          <Box bg="secondary-02" px={24} py={24} borderRadius={16}>
            <Box gap="16px" alignItems="flex-start" flexDirection="row">
              <Text color="secondary-03">
                <InfoIcon width={24} height={24} />
              </Text>
              <Box gap="4px" flexDirection="column">
                <Text color="secondary-03">{t('dual.hasChanged')}</Text>
                <Box flexDirection="column">
                  {isAprChanged && (
                    <Text color="secondary-03" text="app-12-medium">
                      APR: {formatApr(oldDual.apr, '')} →&nbsp;
                      {formatApr(tariff.apr, '')}
                    </Text>
                  )}
                  {isPeriodChanged && (
                    <Text color="secondary-03" text="app-12-medium">
                      {t('dual.period')}: {t('common.hoursShort', { value: oldDual.stakingPeriod })} →&nbsp;
                      {t('common.hoursShort', { value: tariff.stakingPeriod })}
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <AutoReplayProvider defaultValue={isEnabledAutoReplay}>
          <Box>
            <AutoReplayField
              inputAmount={BigNumber(newDual.inputAmount).toNumber()}
              inputTicker={newDual.inputTicker}
              error={error}
              isDisabledValidation
            />
          </Box>
          <Tooltip
            title={textError}
            disabled={!textError}
            PopperProps={{
              disablePortal: true,
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 12,
                  },
                },
              ],
            }}
          >
            <ReplayButton onClick={handleReplay} loading={isReplayLoading} disabled={isReplayDisabled} />
          </Tooltip>
        </AutoReplayProvider>
      </PageContent>
    );
  },
);
