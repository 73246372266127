import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetReferralClaimsQuery } from 'entities/Referral';

import { Box, Button, Text, Spinner } from 'shared/ui';

import { ReferralProgramReferralsClaimsList } from './ClaimsList';

export const ReferralProgramReferralsClaims: FC = () => {
  const { t } = useTranslation();
  const { data: items, isError, isFetching, isLoading, refetch } = useGetReferralClaimsQuery();

  if (isLoading) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Button onClick={() => refetch({})} data-id="refetch-claim-referral">
          {t('common.fetchRetry')}
        </Button>
      </Box>
    );
  }

  if (!items?.length) {
    return null;
  }

  return (
    <Box>
      <Box mb={8} alignItems="center" flexDirection="row">
        <Text text="app-22-medium">{t('referralProgram.referralsPage.claims')}</Text>
        {isFetching && (
          <Box ml={8}>
            <Spinner size={24} />
          </Box>
        )}
      </Box>
      <ReferralProgramReferralsClaimsList items={items} />
    </Box>
  );
};
