import { getAmountPrecisionBySymbol } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, formatUnits } from 'viem';

import { SwapDataResponse } from 'entities/Swap';
import { useSwapTokenByAddress } from 'entities/Swap/lib';
import { SwapTokenIcon } from 'entities/Token';

import { BackIcon } from 'shared/assets';
import theme from 'shared/theme';
import { Box, Modal, PageContent, Spinner, Text } from 'shared/ui';

import styles from './SwapStateModal.module.scss';

interface Props {
  data?: SwapDataResponse;
  onClose: () => void;
  open: boolean;
}

export const SwapStateModalConfirm: FC<Props> = ({ data, open, onClose }) => {
  const { t } = useTranslation();

  const { data: fromTokenData } = useSwapTokenByAddress(data?.fromToken as Address);
  const { data: toTokenData } = useSwapTokenByAddress(data?.toToken as Address);

  return (
    <Modal className={styles.modal} isOpen={open && !!data} onClose={onClose} closeIcon>
      <PageContent height="100%" maxHeight="100%" flexShrink={2} px={22} pb={56} pt={34}>
        {data && (
          <Box flexDirection="column" alignItems="center" justifyContent="center" gap={16}>
            <Spinner size={60} />
            <Text text="app-22-medium">{t('modals.swapState.confirm.title')}</Text>
            <Box flexDirection="row" alignItems="center" justifyContent="center" gap={10}>
              {fromTokenData && (
                <>
                  <SwapTokenIcon size={20} url={fromTokenData.logoURI} address={data.fromToken as Address} />
                  <Text>{`${BigNumber(formatUnits(BigInt(data.fromAmount), fromTokenData.decimals)).toFixed(
                    getAmountPrecisionBySymbol(fromTokenData.symbol.toLowerCase()),
                  )} ${fromTokenData.symbol}`}</Text>
                </>
              )}
              <BackIcon
                width={20}
                height={20}
                style={{ transform: 'rotate(180deg)' }}
                color={theme.colors['gray-01']}
              />
              {toTokenData && (
                <>
                  <SwapTokenIcon size={20} url={toTokenData.logoURI} address={data.toToken as Address} />
                  <Text>{`${BigNumber(formatUnits(BigInt(data.toAmount), toTokenData.decimals)).toFixed(
                    getAmountPrecisionBySymbol(toTokenData.symbol.toLowerCase()),
                  )} ${toTokenData.symbol}`}</Text>
                </>
              )}
            </Box>
            <Text text="app-14-medium" color="gray-01">
              {t('modals.swapState.confirm.proceed')}
            </Text>
          </Box>
        )}
      </PageContent>
    </Modal>
  );
};
