import { useMediaQuery } from '@mui/material';
import { track, PredefinedEvents } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { storageSignature } from 'features/Auth';

import { useUserMutation } from 'entities/User';

import { useAccount } from 'shared/hooks';
import { removeQueryParam } from 'shared/lib';
import { SUPPORTED_LANGUAGES } from 'shared/locales';
import { Box } from 'shared/ui/Box';

import { MenuHeader } from '../Header';
import { MenuProps } from '../types';

import { LanguageMenuItem } from './Item';

export const LANGUAGES = Object.keys(SUPPORTED_LANGUAGES);

export const LanguageMenu: FC<MenuProps> = observer(({ onPressBack, toggle }) => {
  const {
    i18n: { changeLanguage, language },
    t,
  } = useTranslation();
  const { address } = useAccount();

  const { mutateAsync } = useUserMutation();

  const handleChangeLanguage = useCallback(
    (language: string) => {
      track(PredefinedEvents.Language.Change.Success({ language }));
      changeLanguage(language);
      removeQueryParam('language');

      if (!address) return;
      const signature = storageSignature.get(address);
      if (signature) mutateAsync({ language, signature });
    },
    [changeLanguage, address, mutateAsync],
  );

  const handleMap = useCallback(
    (lang: string) => (
      <LanguageMenuItem
        key={lang}
        lang={lang}
        title={SUPPORTED_LANGUAGES[lang]}
        active={language === lang}
        changeLanguage={handleChangeLanguage}
        toggle={toggle}
      />
    ),
    [language, handleChangeLanguage, toggle],
  );

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  return (
    <Box height={isWideMobile ? 'calc(100dvh - 115px)' : undefined}>
      {onPressBack && <MenuHeader onPressBack={onPressBack}>{t('common.language')}</MenuHeader>}
      <Box>{LANGUAGES.map(handleMap)}</Box>
    </Box>
  );
});
