import React, { FC } from 'react';

import { ArrowIcon } from 'shared/assets';

import { StyledButton, StyledCircle, StyledContainer, StyledCircles } from './styled';

type Props = {
  items?: number[];
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
};

export const Pagination: FC<Props> = ({ items, page, setPage, totalPages }) => {
  const circles = items || new Array(totalPages).fill('').map((_, index) => index);

  const currentIndex = items?.findIndex((item) => item === page)!;
  const prevNumber = items ? items[currentIndex - 1] : page - 1;
  const nextNumber = items ? items[currentIndex + 1] : page + 1;

  return (
    <StyledContainer>
      <StyledButton $type="prev" $hide={page === circles[0]} onClick={() => setPage(prevNumber)}>
        <ArrowIcon width={24} height={24} />
      </StyledButton>
      <StyledCircles>
        {circles.map((number) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledCircle onClick={() => setPage(number)} key={number} $isActive={number === page} />
        ))}
      </StyledCircles>
      <StyledButton $type="next" $hide={page === circles[circles.length - 1]} onClick={() => setPage(nextNumber)}>
        <ArrowIcon width={24} height={24} />
      </StyledButton>
    </StyledContainer>
  );
};
