import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { HintIcon } from 'shared/assets';
import { getShortName } from 'shared/lib';
import { Box, Text, Tooltip, ButtonIcon } from 'shared/ui';

type HashInfoRowProps = {
  hash?: string;
  onClick: () => void;
  textTooltip: string;
  title: string;
};

export const HashInfoRow: FC<HashInfoRowProps> = observer(({ hash, onClick, textTooltip, title }) => (
  <Box
    gap="8px"
    alignItems="center"
    flexDirection="row"
    justifyContent="space-between"
    display="grid"
    gridTemplateColumns="max-content max-content 1fr max-content"
  >
    <Text text="app-12-regular">{title}</Text>
    <Tooltip title={textTooltip}>
      <Box>
        <ButtonIcon isOutlined sx={{ padding: '2px' }} data-id="hint-auto-replay">
          <HintIcon width={16} height={16} />
        </ButtonIcon>
      </Box>
    </Tooltip>
    <Box width="100%" paddingBottom={3} height="100%" justifyContent="flex-end">
      <Box sx={{ borderBottomWidth: 1, borderStyle: 'dashed' }} borderColor="secondary-04" />
    </Box>
    {hash ? (
      <Text text="app-12-regular" color="primary-01" onClick={onClick} textDecoration="underline">
        {getShortName(hash, 8, 4)}
      </Text>
    ) : (
      <Skeleton
        width={108}
        height={15}
        sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'none' }}
        animation="wave"
      />
    )}
  </Box>
));
