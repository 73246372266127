import { formatAmount } from '@rehold-io/formatters';
import BN, { BigNumber } from 'bignumber.js';
import { formatUnits } from 'viem';

import { UserToken } from 'entities/Token/model/types';

import { roundToNearest } from 'shared/lib';

export const getFormattedEthers = (token: UserToken) => {
  if (!token.balance || BigNumber(token.balance).isZero()) return '0';

  const balanceEth = formatUnits(BigInt(token.balance!), token.decimals);

  const formattedBalance = formatAmount({
    roundingMode: BN.ROUND_DOWN,
    symbol: token.symbol.toLowerCase(),
    value: formatUnits(BigInt(token.balance!), token.decimals),
  });

  return !BigNumber(formattedBalance).isZero()
    ? formattedBalance
    : roundToNearest(BigNumber(balanceEth)).toFixed(20).replace(/0*$/, '');
};
