import { LOCAL_STORAGE_CHART_SETTINGS } from 'shared/lib';
import { logger } from 'shared/lib/logger';

export class SettingsAdapter implements TradingView.ISettingsAdapter {
  initialSettings?: TradingView.InitialSettingsMap | undefined;

  constructor() {
    this.initialSettings = {};

    const storedSettings = localStorage.getItem(LOCAL_STORAGE_CHART_SETTINGS);
    if (storedSettings) {
      try {
        this.initialSettings = JSON.parse(storedSettings);
      } catch (e) {
        this.initialSettings = {};
        logger.error('Failed to parse settings from localStorage', e);
      }
    }
  }

  setValue(key: string, value: string): void {
    this.setNestedValue(this.initialSettings, key, value);
    this.saveSettings();
  }

  removeValue(key: string): void {
    this.removeNestedValue(this.initialSettings, key);
    this.saveSettings();
  }

  private setNestedValue(obj: any, key: string, value: string): void {
    const keys = key.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
      if (!(keys[i] in current)) {
        current[keys[i]] = {};
      }
      current = current[keys[i]];
    }

    try {
      current[keys[keys.length - 1]] = JSON.parse(value);
    } catch (e: any) {
      current[keys[keys.length - 1]] = value;
    }
  }

  private removeNestedValue(obj: any, key: string): void {
    const keys = key.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
      if (!(keys[i] in current)) {
        return;
      }
      current = current[keys[i]];
    }

    delete current[keys[keys.length - 1]];
  }

  private saveSettings(): void {
    try {
      const serializedSettings = JSON.stringify(this.initialSettings);
      localStorage.setItem(LOCAL_STORAGE_CHART_SETTINGS, serializedSettings);
    } catch (e) {
      logger.error('Failed to save settings to localStorage', e);
    }
  }
}
