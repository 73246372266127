import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PathPatterns, capitalizeFirstLetter } from 'shared/lib';
import { Box, Button, Text } from 'shared/ui';

import { TypeTab } from '../../model/types';

type Props = {
  type: TypeTab;
};

type LocalesTab = 'Active' | 'History' | 'Limit';

export const Empty: FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box borderColor="secondary-02" borderRadius={16} p={24} sx={{ borderWidth: 1 }}>
      <Box mb={24} alignItems="center">
        <Text>{t(`dual.no${capitalizeFirstLetter(type) as LocalesTab}`)}</Text>
      </Box>
      <Button onClick={() => navigate(PathPatterns.DualsDashboard)}>{t('dual.createDual')}</Button>
    </Box>
  );
};
