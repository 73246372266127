import { FC, PropsWithChildren } from 'react';

import { BackIcon } from 'shared/assets';
import { Box, BoxProps, Text } from 'shared/ui';

export interface MenuHeaderProps {
  onPressBack?: BoxProps['onClick'];
}

export const MenuHeader: FC<PropsWithChildren<MenuHeaderProps>> = ({ children, onPressBack }) => (
  <Box
    flexDirection="row"
    alignItems="center"
    height={56}
    px={{ default: 12, desktop: 16, tablet: 16 }}
    onClick={onPressBack}
  >
    {onPressBack && (
      <Box mr={8} onClick={onPressBack} color="white-01">
        <BackIcon width={24} height={24} />
      </Box>
    )}
    <Text>{children}</Text>
  </Box>
);
