import { useMediaQuery } from '@mui/material';
import cx from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';

import { PerpetualsFormInput, usePerpetualForm } from 'entities/Perpetual';

import { useAccount } from 'shared/hooks';
import theme from 'shared/theme';
import { Box, Text } from 'shared/ui';
import { StyledText } from 'shared/ui/Error/styled';

import './index.scss';

const handleStyle = {
  border: `4px solid ${theme.colors['primary-01']}`,
  opacity: 1,
  marginTop: -6,
  height: 16,
  width: 16,
  backgroundColor: theme.colors['background-01'],
  boxShadow: 'none',
};
const trackStyle = { height: 4, backgroundColor: theme.colors['primary-01'] };
const railStyle = { height: 4, backgroundColor: '#232331' };

export const PerpetualsFormLeverage = () => {
  const { t } = useTranslation();

  const { isConnected } = useAccount();

  const { state, form, changeLeverage } = usePerpetualForm();

  const minLeverage = +(state.tariff?.minLeverage || 0);
  const maxLeverage = +(state.tariff?.maxLeverage || 500);

  const isSmallMobile = useMediaQuery('(max-width: 534px)', { noSsr: false });

  return (
    <Box className="perpetuals-form-leverage">
      <Text text="app-16-medium"> {t('perpetuals.form.leverage')}</Text>
      <Box className="perpetuals-form-leverage-input">
        <Box
          className={cx('perpetuals-form-leverage-input-field', {
            error: state.tariff && form.formState.errors.leverage,
          })}
        >
          {state.tariff && (
            <PerpetualsFormInput
              value={state.leverage}
              onChange={(e) => changeLeverage(e.value === '.' ? '0.' : e.value)}
              max={maxLeverage}
              ticker="usdt"
            />
          )}
          {!state.tariff && <Text text="app-16-medium">—</Text>}
        </Box>
        <Box
          className="perpetuals-form-leverage-input-button"
          onClick={() => changeLeverage((maxLeverage * 0.1).toString())}
        >
          {state.tariff && <Text text="app-16-medium">x{(maxLeverage * 0.1).toString()}</Text>}
          {!state.tariff && <Text text="app-16-medium">—</Text>}
        </Box>
        <Box
          className="perpetuals-form-leverage-input-button"
          onClick={() => changeLeverage((maxLeverage * 0.5).toString())}
        >
          {state.tariff && <Text text="app-16-medium">x{(maxLeverage * 0.5).toString()}</Text>}
          {!state.tariff && <Text text="app-16-medium">—</Text>}
        </Box>
        <Box className="perpetuals-form-leverage-input-button" onClick={() => changeLeverage(maxLeverage.toString())}>
          {state.tariff && <Text text="app-16-medium">x{maxLeverage.toString()}</Text>}
          {!state.tariff && <Text text="app-16-medium">—</Text>}
        </Box>
        {isConnected && state.tariff && form.formState.errors.leverage?.message && (
          <StyledText
            $align="left"
            className="triangle-up"
            position="absolute"
            bottom={0}
            zIndex={10000000000}
            borderRadius="8px"
            px={8}
            py={4}
            bgColor="red-01"
            text="app-12-regular"
            left={0}
            right="unset"
            maxWidth={isSmallMobile ? '100%' : '534px'}
          >
            {form.formState.errors.leverage.message}
          </StyledText>
        )}
      </Box>
      <Box className="perpetuals-form-leverage-slider">
        <Slider
          value={+state.leverage}
          onChange={(value) => changeLeverage(value.toString())}
          min={minLeverage}
          max={maxLeverage}
          step={1}
          styles={{ track: trackStyle, rail: railStyle, handle: handleStyle }}
        />
      </Box>
    </Box>
  );
};
