import { formatAmount } from '@rehold-io/formatters';
import { UseQueryResult } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { CFC, useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PerpetualBalance,
  PerpetualBalanceResponse,
  PerpetualFormValues,
  PerpetualTariff,
} from 'entities/Perpetual/model';
import { useGetPerpetualBalanceQuery } from 'entities/Perpetual/model/hooks';
import { useRateInterval } from 'entities/Rates';
import { RateProduct } from 'entities/Rates/model/types';

import { useAccount } from 'shared/hooks';
import { formatWithCommas } from 'shared/lib';

import { usePerpetualTariffs } from '../hooks/usePerpetualTariffs';

const SUPPORTED_INPUT_TICKERS = ['usdt', 'usdc'];

const DEFAULT_AMOUNT = '0';
const DEFAULT_TICKER = SUPPORTED_INPUT_TICKERS[0];
const DEFAULT_LEVERAGE = '2';

const ZERO_INPUT_TICKERS = SUPPORTED_INPUT_TICKERS.map((ticker) => ({ amount: DEFAULT_AMOUNT, ticker }));

type State = PerpetualFormValues & {
  balance: PerpetualBalance | undefined;
  tariff: PerpetualTariff | undefined;
};

type Props = {
  balances: PerpetualBalance[] | undefined;
  balancesQuery: UseQueryResult<PerpetualBalanceResponse, {}>;
  changeAmount: (amount: string) => void;
  changeInput: (inputTicker: string) => void;
  changeLeverage: (leverage: string) => void;
  changeTariff: (tariffId: string) => void;
  form: UseFormReturn<PerpetualFormValues, any, undefined>;
  state: State;
};

export const PerpetualFormContext = React.createContext({} as Props);

export const PerpetualFormProvider: CFC = observer(({ children }) => {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();

  const { tariffs } = usePerpetualTariffs();
  const balancesQuery = useGetPerpetualBalanceQuery({ address: address || '' }, { enabled: isConnected });
  const balances = isConnected ? balancesQuery.data : ZERO_INPUT_TICKERS;

  const [state, setState] = useState<State>({
    tariffId: tariffs[0]?.id,
    tariff: tariffs.find((tariff) => tariff.id === tariffs[0]?.id) as PerpetualTariff | undefined,
    balance: balances?.find((balance) => balance.ticker === DEFAULT_TICKER) as PerpetualBalance | undefined,
    inputAmount: DEFAULT_AMOUNT,
    inputTicker: DEFAULT_TICKER,
    isShort: tariffs[0]?.isShort || false,
    leverage: tariffs[0]?.minLeverage || DEFAULT_LEVERAGE,
  });
  const [prevTariff, setPrevTariff] = useState<PerpetualTariff | undefined>(state.tariff);

  const perpetualForm = useForm<PerpetualFormValues>({
    defaultValues: {
      tariffId: state.tariffId,
      inputAmount: state.inputAmount,
      inputTicker: state.inputTicker,
      isShort: state.isShort,
      leverage: state.leverage,
    },
    mode: 'onSubmit',
  });

  const { symbol: priceSymbol, price: inputUsdPrice } = useRateInterval({
    from: state.inputTicker,
    to: 'usd',
    product: RateProduct.DEFAULT,
  });

  useEffect(() => {
    perpetualForm.setValue('tariffId', state.tariffId);
    perpetualForm.setValue('inputAmount', state.inputAmount);
    perpetualForm.setValue('inputTicker', state.inputTicker);
    perpetualForm.setValue('isShort', state.isShort);
    perpetualForm.setValue('leverage', state.leverage);
  }, [state]);

  useEffect(() => {
    const updates = { ...state };

    updates.balance = balances?.find((balance) => balance.ticker === state.inputTicker);

    makeUpdates(updates, prevTariff);
    setState(updates);
  }, [balances]);

  useEffect(() => {
    const updates = { ...state };

    updates.tariff = tariffs.find((tariff) => tariff.id === updates.tariffId) || tariffs[0];
    updates.tariffId = updates.tariff?.id;

    const isSamePair =
      state.tariff &&
      updates.tariff &&
      state.tariff?.baseTicker === updates.tariff?.baseTicker &&
      state.tariff?.quoteTicker === updates.tariff?.quoteTicker;

    if (updates.tariff && !isSamePair)
      updates.leverage = BigNumber(updates.tariff.maxLeverage).div(2).toFixed(0, BigNumber.ROUND_CEIL);

    makeUpdates(updates, state.tariff);
    setState(updates);
  }, [tariffs]);

  useEffect(() => {
    if (priceSymbol.split('/')[0] === state.inputTicker) makeUpdates(state);
  }, [state.inputTicker, priceSymbol]);

  const makeUpdates = (updates: State, prevTariff?: PerpetualTariff) => {
    updates.tariff = tariffs.find((tariff) => tariff.id === updates.tariffId);
    updates.balance = balances?.find((balance) => balance.ticker === updates.inputTicker);

    const isSamePair =
      updates.tariff &&
      prevTariff?.baseTicker === updates.tariff?.baseTicker &&
      prevTariff?.quoteTicker === updates.tariff?.quoteTicker;

    if (updates.tariff) {
      if (!isSamePair) updates.leverage = BigNumber(updates.tariff.maxLeverage).div(2).toFixed(0, BigNumber.ROUND_CEIL);
      updates.isShort = updates.tariff.isShort;
      if (!updates.balance && !isSamePair) updates.inputAmount = '0';
    }

    if (updates.balance && updates.tariff && !isSamePair && priceSymbol.split('/')[0] === updates.inputTicker) {
      const limit = BigNumber(updates.tariff.maxAmountUSD).div(inputUsdPrice);
      const input = BigNumber(updates.balance.amount).times(BigNumber(updates.tariff.maxLeverage).div(2)).gt(limit)
        ? limit.div(BigNumber(updates.tariff.maxLeverage).div(2))
        : BigNumber(updates.balance.amount);
      updates.inputAmount = formatAmount({
        value: input.toString(),
        symbol: updates.inputTicker || state.inputTicker,
        roundingMode: BigNumber.ROUND_DOWN,
      });
    }

    updateSize(updates);

    setPrevTariff(undefined);
  };

  const changeInput = (inputTicker: string) => {
    const updates = { ...state };

    updates.inputTicker = inputTicker;
    updates.balance = balances?.find((balance) => balance.ticker === updates.inputTicker);

    makeUpdates(updates, prevTariff);
    setState(updates);
  };

  const changeTariff = (tariffId: string) => {
    setPrevTariff(state.tariff);

    const updates = { ...state };

    updates.tariffId = tariffId;
    updates.tariff = tariffs.find((tariff) => tariff.id === updates.tariffId);

    const isSamePair =
      state.tariff &&
      updates.tariff &&
      state.tariff?.baseTicker === updates.tariff?.baseTicker &&
      state.tariff?.quoteTicker === updates.tariff?.quoteTicker;

    if (updates.tariff && !isSamePair)
      updates.leverage = BigNumber(updates.tariff.maxLeverage).div(2).toFixed(0, BigNumber.ROUND_CEIL);

    makeUpdates(updates, state.tariff);
    setState(updates);
  };

  const changeAmount = (inputAmount: string) => {
    const updates = { ...state };

    updates.inputAmount = inputAmount;

    const balanceAmount = BigNumber(
      formatAmount({
        value: updates.balance?.amount || 0,
        symbol: updates.balance?.ticker || updates.inputTicker,
        roundingMode: BigNumber.ROUND_DOWN,
      }),
    );

    const isError =
      isConnected &&
      updates.balance &&
      (balanceAmount.lt(updates.inputAmount) || BigNumber(updates.inputAmount).eq(0) || !updates.inputAmount);

    if (isError) perpetualForm.setError('inputAmount', {});
    if (!isError) perpetualForm.clearErrors('inputAmount');

    updateSize(updates);
    setState(updates);
  };

  const changeLeverage = (leverage: string) => {
    const updates = { ...state };

    updates.leverage = leverage;

    const isMoreError = updates.tariff && BigNumber(updates.tariff.maxLeverage).lt(updates.leverage);
    const isLessError = updates.tariff && BigNumber(updates.tariff.minLeverage).gt(updates.leverage);
    const isZeroLeverage = updates.tariff && !updates.leverage;
    const isError = isMoreError || isLessError || isZeroLeverage;

    if (isMoreError)
      perpetualForm.setError('leverage', {
        message: t('perpetuals.form.errors.maxLeverage', { leverage: updates.tariff!.maxLeverage }),
      });
    if (isLessError)
      perpetualForm.setError('leverage', {
        message: t('perpetuals.form.errors.minLeverage', { leverage: updates.tariff!.minLeverage }),
      });
    if (isZeroLeverage) perpetualForm.setError('leverage', {});

    if (!isError) perpetualForm.clearErrors('leverage');

    updateSize(updates);
    setState(updates);
  };

  const updateSize = (updates: State) => {
    const openingFeeAmount = updates.tariff
      ? BigNumber(updates.inputAmount)
          .multipliedBy(BigNumber(updates.leverage))
          .multipliedBy(updates.tariff.openingFee ?? 0)
      : null;
    const size = BigNumber(updates.inputAmount || 0)
      .multipliedBy(updates.leverage || 0)
      .minus(openingFeeAmount || 0);

    const maxAmount = BigNumber(updates.tariff?.maxAmountUSD ?? 0).div(inputUsdPrice);
    const minAmount = BigNumber(updates.tariff?.minAmountUSD ?? 0).div(inputUsdPrice);

    const isMoreError = updates.tariff && BigNumber(updates.inputAmount).gt(0) && maxAmount.lt(size);
    const isLessError = updates.tariff && BigNumber(updates.inputAmount).gt(0) && minAmount.gt(size);
    const isError = isMoreError || isLessError;

    if (isError) {
      const message = isMoreError
        ? t('perpetuals.form.errors.maxSize', {
            volume: formatWithCommas(
              formatAmount({
                value: maxAmount,
                symbol: updates.inputTicker,
                roundingMode: BigNumber.ROUND_CEIL,
              }),
            ),
            ticker: updates.inputTicker.toUpperCase(),
          })
        : t('perpetuals.form.errors.minSize', {
            volume: formatWithCommas(
              formatAmount({
                value: minAmount,
                symbol: updates.inputTicker,
                roundingMode: BigNumber.ROUND_CEIL,
              }),
            ),
            ticker: updates.inputTicker.toUpperCase(),
          });

      perpetualForm.setError('root', { message });
    }
    if (!isError) perpetualForm.clearErrors('root');
  };

  const values: Props = useMemo(
    () => ({
      state,
      form: perpetualForm,
      balances,
      balancesQuery,
      changeTariff,
      changeInput,
      changeAmount,
      changeLeverage,
    }),
    [perpetualForm, balancesQuery, state, changeTariff, changeInput, changeAmount, changeLeverage],
  );

  return (
    <PerpetualFormContext.Provider value={values as Props}>
      <FormProvider {...values.form}>{children}</FormProvider>
    </PerpetualFormContext.Provider>
  );
});
