import { Address, etherUnits, parseUnits } from 'viem';

import { GAS_FOR_CHAINS, Network } from 'entities/Chain';

import { useNetwork } from 'shared/hooks/network';

import { useWETHContractPrepare } from './useWETHContractPrepare';

type EstimateWETHGasArgs = {
  enabled: boolean;
  fromAmount?: string;
  fromToken?: Address | null;
  toToken?: Address | null;
};

export const useEstimateWETHGas = ({ enabled, fromAmount, fromToken, toToken }: EstimateWETHGasArgs) => {
  const { network } = useNetwork();

  const prepare = useWETHContractPrepare({
    fromAmount: fromAmount || '0',
    fromToken: fromToken!,
    toToken: toToken!,
    enabled,
  });

  const fallbackGas = parseUnits(
    GAS_FOR_CHAINS[(network?.network || 'ethereum') as Network]?.weth?.toString() as `${number}`,
    etherUnits.gwei,
  );

  return {
    data: prepare.gas || fallbackGas,
    isLoading: prepare.isLoading || prepare.isFetching || prepare.isRefetching,
  };
};
