import { formatAmount } from '@rehold-io/formatters';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReferralClaim } from 'entities/Referral/model/types';

import { ClockIcon, HappyIcon } from 'shared/assets';
import { getChainBlockExplorerLink } from 'shared/config';
import { formatDate } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import { StyledLink } from './styles';

export interface ReferralProgramReferralsClaimsListProps {
  items: ReferralClaim[];
}

export const ReferralProgramReferralsClaimsList: FC<ReferralProgramReferralsClaimsListProps> = ({ items }) => (
  <Box>
    {items.map((item) => (
      <ReferralProgramReferralsClaimsListItem key={item.txHash} {...item} />
    ))}
  </Box>
);

export const ReferralProgramReferralsClaimsListItem: FC<ReferralClaim> = ({
  amount,
  chainId,
  createdAt,
  status,
  ticker,
  txHash,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const isPending = status === 'PENDING';
  const link = getChainBlockExplorerLink('tx', txHash, chainId);

  const children = (
    <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={48}>
      <Box flexDirection="row" alignItems="center">
        <Box mr={8} color={isPending ? 'white-01' : 'primary-01'}>
          <HappyIcon width={24} height={24} />
        </Box>
        <Text text="app-12-medium" color={isPending ? 'white-01' : 'primary-01'}>
          {formatAmount({ symbol: ticker, value: amount })} <Text sx={{ opacity: 0.6 }}>{ticker.toUpperCase()}</Text>
        </Text>
      </Box>

      {isPending ? (
        <Box color="orange-01" gap="6px" flexDirection="row">
          <Text text="app-12-medium" color="orange-01">
            {t('referralProgram.referralsPage.pending')}
          </Text>
          <ClockIcon width={16} height={16} />
        </Box>
      ) : (
        <Text text="app-12-medium" color="white-02">
          {formatDate(createdAt, language)}
        </Text>
      )}
    </Box>
  );

  return link?.url ? (
    <StyledLink href={link?.url} target="_blank" rel="noreferrer">
      {children}
    </StyledLink>
  ) : (
    children
  );
};
