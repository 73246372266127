import { useState } from 'react';
import { Address } from 'viem';
import { usePrepareContractWrite, usePublicClient } from 'wagmi';

import { useIsWETH } from 'entities/Swap/lib/hooks/useIsWETH';
import { useWETHAddress } from 'entities/Swap/lib/hooks/useWETHAddress';

import { NATIVE_TOKEN_ADDRESS, wethAbi } from 'shared/config';
import { useNetwork } from 'shared/hooks/network';
import { useAccount } from 'shared/hooks/useAccount';
import { calculateGasMargin } from 'shared/lib';
import { logger } from 'shared/lib/logger';

interface WETHContractPrepareArgs {
  enabled: boolean;
  fromAmount: string;
  fromToken: Address;
  toToken: Address;
}

export const useWETHContractPrepare = ({ fromToken, fromAmount, toToken, enabled }: WETHContractPrepareArgs) => {
  const weth = useWETHAddress();
  const publicClient = usePublicClient();

  const { address: user } = useAccount();
  const { networkReady, selectedChainId } = useNetwork();

  const [gas, setGas] = useState<bigint | undefined>();

  const isWETH = useIsWETH(fromToken, toToken);

  const depositPrepareArgs = {
    account: user!,
    address: weth as Address,
    abi: wethAbi,
    functionName: 'deposit',
    value: BigInt(fromAmount),
    cacheTime: 0,
  } as const;

  const withdrawPrepareArgs = {
    account: user!,
    address: weth,
    abi: wethAbi,
    functionName: 'withdraw',
    args: [BigInt(fromAmount)],
    cacheTime: 0,
  } as const;

  const prepareEnabled =
    isWETH && !!fromAmount && fromAmount !== '' && !!fromToken && !!toToken && networkReady && !!user && enabled;

  const prepare = usePrepareContractWrite({
    ...(fromToken === NATIVE_TOKEN_ADDRESS ? depositPrepareArgs : withdrawPrepareArgs),
    chainId: selectedChainId,
    enabled: prepareEnabled,
    scopeKey: `${fromToken === NATIVE_TOKEN_ADDRESS ? 'wrap' : 'unwrap'}::${selectedChainId}`,

    onSuccess: async (data) => {
      if (data.request.gas) {
        return setGas(calculateGasMargin(data.request.gas));
      }
      try {
        const gasLimit = await publicClient.estimateGas(
          fromToken === NATIVE_TOKEN_ADDRESS ? depositPrepareArgs : withdrawPrepareArgs,
        );
        const gas = calculateGasMargin(gasLimit);
        setGas(gas);
      } catch (e) {
        logger.error(e);
      }
    },
  });

  const config = prepare.config.request
    ? { ...prepare.config, request: { ...prepare.config.request, gas } }
    : prepare.config;

  return { ...prepare, config, gas };
};
