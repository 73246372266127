import classNames from 'classnames';

import { AppChain } from 'shared/config/chains/types';
import { useDetectMobileDevice } from 'shared/hooks';
import { Box } from 'shared/ui';

type Props = {
  chain: AppChain<any>;
  onSelect: (chainId?: number) => void;
  selected: number | undefined;
};

export const FilterChain: React.FC<Props> = ({ selected, onSelect, chain }) => {
  const isMobileDevice = useDetectMobileDevice();

  return (
    <Box height="40px" width="32px" alignItems="center" justifyContent="center" onClick={() => onSelect(chain.id)}>
      <Box
        className={classNames('animated', {
          hoverable: !isMobileDevice,
          'hoverable-active': chain.id === selected,
        })}
        height="32px"
        width="32px"
        alignItems="center"
        justifyContent="center"
        border={chain.id === selected ? '1px solid' : 'none'}
        borderColor={chain.id === selected ? 'secondary-03' : undefined}
        backgroundColor={chain.id === selected ? 'secondary-03' : undefined}
        borderRadius="32px"
      >
        {chain.renderLogo(22)}
      </Box>
    </Box>
  );
};
