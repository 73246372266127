import { useMediaQuery } from '@mui/material';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { formatSuffixNumber, useGetPointsStatsQuery } from 'entities/Points';

import { useAccount } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { Box, CurrencyIcon, Spinner, Text } from 'shared/ui';

export const Points: FC = observer(() => {
  const { address } = useAccount();

  const navigate = useNavigate();
  const location = useLocation();

  const handlePress = useCallback(() => {
    track(PredefinedEvents.Menu.Point.Click({ from: location.pathname }));
    if (location.pathname !== PathPatterns.Points) {
      navigate(PathPatterns.Points);
    }
  }, [navigate, location]);

  const { data } = useGetPointsStatsQuery();

  const isSmallDesktop = useMediaQuery('(max-width: 900px)', { noSsr: true });
  const isTableLayout = useMediaQuery('(max-width: 767px)', { noSsr: true });
  const isWideMobile = useMediaQuery('(max-width: 475px)', { noSsr: true });

  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      height={40}
      pr={isWideMobile || (isSmallDesktop && !isTableLayout) ? 0 : 12}
      ml={8}
      borderRadius={40}
      bg="background-01"
      borderColor="secondary-02"
      onClick={handlePress}
      data-id="nav-duals"
      sx={{ borderWidth: 1 }}
    >
      <CurrencyIcon size={isWideMobile ? 40 : 36} ticker="redy" />
      {!(isWideMobile || (isSmallDesktop && !isTableLayout)) && <Text mx={8}>REDY</Text>}
      {!(isWideMobile || (isSmallDesktop && !isTableLayout)) && (
        <>
          {data && address && (
            <Box
              justifyContent="center"
              alignItems="center"
              minWidth={20}
              height={20}
              bg="primary-01"
              borderRadius={20}
              px={5}
            >
              <Text color="black-01">{formatSuffixNumber(data?.balance || 0)}</Text>
            </Box>
          )}
          {!data && address && <Spinner size={20} />}
        </>
      )}
    </Box>
  );
});
