import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getDual } from 'entities/Dual/api/queries';

import { useNetwork } from 'shared/hooks/network';

import { DualType, GetDualRequestArgs } from '../dual';

export const useGetDualQuery = (id: string, options?: UseQueryOptions<DualType, GetDualRequestArgs>) => {
  const { chainId } = useNetwork();

  return useQuery<DualType, GetDualRequestArgs>([`dual-${chainId}-${id}`], () => getDual({ chainId: chainId!, id }), {
    ...options,
    enabled: !!chainId && !!id && (options?.enabled ?? true),
  });
};
