import BigNumber from 'bignumber.js';
import { useCallback } from 'react';
import { etherUnits, formatUnits, parseUnits } from 'viem';

import { useEstimateWETHGas } from 'features/SwapTokens/lib/useEstimateWETHGas';

import { useRate } from 'entities/Rates';
import { SwapFormValues, useIsWETH, useSwapQuote, useSwapTokenByAddress } from 'entities/Swap';

import { DEFAULT_SLIPPAGE_VALUE, NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

export function useTrackParamsLazy(values: SwapFormValues) {
  const { address: user } = useAccount();
  const { selectedChainId: chainId } = useNetwork();

  const { data: quote } = useSwapQuote();
  const { data: inputToken } = useSwapTokenByAddress(values.fromToken!);
  const { data: outputToken } = useSwapTokenByAddress(values.toToken!);

  const isWETH = useIsWETH(values.fromToken, values.toToken);

  // Fetch native token price or it's wrapped equivalent
  const { data: nativeToken } = useSwapTokenByAddress(NATIVE_TOKEN_ADDRESS);
  const { price } = useRate({ from: nativeToken?.symbol, to: 'usd' });

  const wrappingValueUSD = inputToken && isWETH ? BigNumber(values.fromAmount).multipliedBy(price).toNumber() : 0;

  const { data: gas, isLoading: isGasLoading } = useEstimateWETHGas({
    fromAmount: inputToken ? parseUnits(values.fromAmount as `${number}`, inputToken.decimals).toString() : '',
    fromToken: values.fromToken,
    toToken: values.toToken,
    enabled: isWETH && price !== 1,
  });

  // Calculating gas for wrapping/unwrapping
  const formattedGas =
    isWETH && price !== 1 && gas && !isGasLoading
      ? BigNumber(formatUnits(gas, etherUnits.gwei).toString()).multipliedBy(price).toNumber()
      : '0';

  const getTrackParams = useCallback(
    (formValues: SwapFormValues) => ({
      address: user!,
      chainId,
      fromAmount: formValues.fromAmount,
      fromAmountUSD: quote ? quote.fromAmountUSD : wrappingValueUSD,
      fromTicker: inputToken!.symbol.toLowerCase(),
      toAmount: quote ? formatUnits(BigInt(quote.toAmount), outputToken!.decimals) : formValues.fromAmount,
      toAmountUSD: quote ? quote.toAmountUSD : wrappingValueUSD,
      toTicker: outputToken!.symbol.toLowerCase(),
      gasAmountUSD: quote ? quote.gasAmountUSD : formattedGas,
      slippage: formValues.slippage || DEFAULT_SLIPPAGE_VALUE.toString(),
    }),
    [user, quote, wrappingValueUSD, inputToken, outputToken, formattedGas, values],
  );

  return getTrackParams;
}
