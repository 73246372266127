import classnames from 'classnames';
import React, { FC, useState } from 'react';

import { useSwapForm } from 'entities/Swap/lib';

import { BackIcon } from 'shared/assets';
import { useDetectMobileDevice } from 'shared/hooks';
import theme from 'shared/theme';
import { Box } from 'shared/ui';

import styles from './SwapReversable.module.scss';

export const SwapReversable: FC = () => {
  const [hovered, setHovered] = useState(false);

  const { input, output, reverse } = useSwapForm();

  const isMobileDevice = useDetectMobileDevice();

  const disabled = !input.address || !output.address;

  return (
    <Box
      className={classnames(styles['reverse-button'], styles['round-button'])}
      zIndex={1}
      height={20}
      width="100%"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        style={disabled ? { opacity: 0.7 } : {}}
        className={classnames(styles.arrow)}
        width={52}
        height={52}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        borderRadius={48}
        border="4px"
        borderColor="background-01"
        backgroundColor="secondary-02"
        onClick={
          !disabled
            ? () => {
                if (isMobileDevice) {
                  setHovered(true);
                  setTimeout(() => setHovered(false), 300);
                }
                reverse();
              }
            : undefined
        }
        onMouseEnter={!isMobileDevice ? () => setHovered(true) : undefined}
        onMouseLeave={!isMobileDevice ? () => setHovered(false) : undefined}
        role="none"
      >
        <BackIcon
          width={24}
          height={24}
          color={theme.colors['secondary-01']}
          style={{ transform: `rotate(${hovered && !disabled ? '90deg' : '-90deg'})`, transition: 'transform 0.3s' }}
        />
      </Box>
    </Box>
  );
};
