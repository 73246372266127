import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const polygonMumbai: AppChain<'polygon-mumbai'> = {
  blockExplorers: {
    default: {
      name: 'PolygonScan',
      url: 'https://mumbai.polygonscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  disabled: false,
  domain: {
    address: '0x2a93C52E7B6E7054870758e15A1446E769EdfB93',
  },
  id: 80001,
  logo: <CurrencyIcon ticker="matic" size={24} />,
  name: 'Polygon Mumbai',
  nativeCurrency: {
    decimals: 18,
    name: 'MATIC',
    symbol: 'MATIC',
  },
  network: 'polygon-mumbai',
  tokenStandard: 'ERC-20',
  renderLogo: (size) => <CurrencyIcon ticker="matic" size={size} />,
  router: {
    abi: routerAbi,
    address: '0x1d131ebd06e511e1b6d0ad40edd05b277347a9fa',
  },
  rpcUrls: {
    default: {
      http: ['https://matic-mumbai.chainstacklabs.com'],
    },
    public: {
      http: ['https://matic-mumbai.chainstacklabs.com'],
    },
  },
  testnet: true,
  vault: {
    address: '0x24312b0405032e51e6708cd592af91ee8049f9f2',
  },
};
