import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReplayDual } from 'features/ReplayDual';

import { useDualModal, useGetDualQuery } from 'entities/Dual';

import { Box, PageHeader, Spinner, Modal } from 'shared/ui';

import { ClaimedDual } from './ui';
import { ActiveDual } from './ui/ActiveDual';

type Dual = 'claimed' | 'default' | 'replay';

export const DualModal = observer(() => {
  const { t } = useTranslation();

  const { dualParams, setDualParams } = useDualModal();
  const { data: dual, isFetching, isLoading } = useGetDualQuery(dualParams?.id!, { enabled: !!dualParams?.id });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (dualParams) {
      setIsOpen(true);
    }
  }, [dualParams]);

  const isLoaded = dual && dual?.id === dualParams?.id;

  const isClaimed = dual?.l1Claimed || dual?.l2AutoReplayed || dual?.l2Replayed;

  let type: Dual = 'default';

  if (dualParams?.type) {
    type = dualParams?.type;
  } else if (isClaimed) {
    type = 'claimed';
  }

  const onDismiss = useCallback(() => {
    if (isClaimed) {
      track(PredefinedEvents.Dual.Claimed.Close({ ...dual }));
    }
    setIsOpen(false);
  }, [dual, isClaimed]);

  const id = dual?.version === 1 ? dual.id : (dual?.id || '').substring(2, 10);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onDismiss}
      closeCallback={() => setDualParams(null)}
      closeIcon
      header={<PageHeader showLeftButton={false}>{isLoaded ? t('modals.claimedDual.title', { id }) : null}</PageHeader>}
    >
      {(isLoading || (isFetching && !isLoaded)) && (
        <Box height={400} justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      )}
      {isLoaded && type === 'claimed' && <ClaimedDual dual={dual} />}
      {isLoaded && type === 'default' && <ActiveDual dual={dual} onClose={onDismiss} />}
      {isLoaded && type === 'replay' && (
        <ReplayDual
          dual={dual}
          type="modal"
          onClose={onDismiss}
          isEnabledAutoReplay={dualParams.enabledAutoReplay}
          fromLocation="notification"
        />
      )}
    </Modal>
  );
});
