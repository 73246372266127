import { Log, decodeAbiParameters } from 'viem';

import { dualAbiV1 } from 'shared/config';

const TOPICS = {
  DUAL_CREATED: '0xc320489c908913918e759f48a3c3b765ed041c49a5340ea0178ef1de6a6094f5',
};

export const getCreatedDualInfo = (logs: Log<BigInt, number>[]) => {
  let id = null;
  logs.forEach((log: any) => {
    const [topic] = log.topics;
    //@ts-ignore
    const inputs = dualAbiV1.find((item) => item.name === 'DualCreated')!.inputs;

    if (topic === TOPICS.DUAL_CREATED) {
      const data = decodeAbiParameters(inputs as any, log.data);

      id = BigInt(data[0] as number).toString();
    }
  });

  return id as null | string;
};
