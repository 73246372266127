import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from 'shared/assets';
import { useIsSmallMobile } from 'shared/hooks/useResponsive';
import { Box, Button, Modal, PageContent, PageHeader, Text } from 'shared/ui';

type Props = {
  isOpen: boolean;
  onApprove: () => void;
  setIsOpen: (value: boolean) => void;
};

export const DoubleApproveModal: FC<Props> = observer(({ isOpen, setIsOpen, onApprove }) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallMobile();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      closeIcon
      header={
        <PageHeader showLeftButton={false} px={{ phone: 12, tablet: 16 }}>
          {t('doubleApprove.title')}
        </PageHeader>
      }
    >
      <PageContent maxHeight="100%" height="100%" px={isMobile ? 12 : 24} pb={16} mt={20}>
        <Box flexDirection="column" gap="16px">
          <Box bg="secondary-02" px={24} py={24} borderRadius={16}>
            <Box gap="16px" alignItems="flex-start" flexDirection="row">
              <Text color="secondary-03">
                <InfoIcon width={24} height={24} />
              </Text>

              <Text color="secondary-03">{t('doubleApprove.info')}</Text>
            </Box>
          </Box>
          <Text color="gray-01">{t('doubleApprove.description')}</Text>
          <Button mt={8} onClick={onApprove}>
            {t('doubleApprove.button')}
          </Button>
        </Box>
      </PageContent>
    </Modal>
  );
});
