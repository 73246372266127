import { formatAmount, getAmountPrecisionBySymbol } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PerpetualsFormInput, usePerpetualForm } from 'entities/Perpetual';

import theme from 'shared/theme';
import { Box, Text } from 'shared/ui';

import './index.scss';

const handleStyle = {
  border: `4px solid ${theme.colors['primary-01']}`,
  opacity: 1,
  marginTop: -6,
  height: 16,
  width: 16,
  backgroundColor: theme.colors['background-01'],
  boxShadow: 'none',
};
const trackStyle = { height: 4, backgroundColor: theme.colors['primary-01'] };
const railStyle = { height: 4, backgroundColor: '#232331' };

export const PerpetualsFormAmount = () => {
  const { t } = useTranslation();

  const { state, form, changeAmount } = usePerpetualForm();

  const [touched, setTouched] = useState(false);

  const amount = BigNumber(
    formatAmount({
      value: state.balance?.amount || 0,
      symbol: state.balance?.ticker || state.inputTicker,
      roundingMode: BigNumber.ROUND_DOWN,
    }),
  );

  return (
    <Box className="perpetuals-form-amount">
      <Text text="app-16-medium">{t('perpetuals.form.amount')}</Text>
      <Box className="perpetuals-form-amount-input">
        <Box
          className={cx('perpetuals-form-amount-input-field', {
            error: touched && state.balance && form.formState.errors.inputAmount,
          })}
        >
          <PerpetualsFormInput
            value={state.inputAmount}
            onChange={(e) => {
              changeAmount(e.value === '.' ? '0.' : e.value);
              setTouched(true);
            }}
            max={amount.toNumber()}
            ticker={state.inputTicker}
          />
        </Box>
        <Box
          className="perpetuals-form-amount-input-button"
          onClick={() =>
            changeAmount(
              formatAmount({
                value: amount.multipliedBy(0.2).toString(),
                symbol: state.inputTicker,
                roundingMode: BigNumber.ROUND_DOWN,
              }),
            )
          }
        >
          <Text text="app-16-medium">20%</Text>
        </Box>
        <Box
          className="perpetuals-form-amount-input-button"
          onClick={() =>
            changeAmount(
              formatAmount({
                value: amount.multipliedBy(0.5).toString(),
                symbol: state.inputTicker,
                roundingMode: BigNumber.ROUND_DOWN,
              }),
            )
          }
        >
          <Text text="app-16-medium">50%</Text>
        </Box>
        <Box
          className="perpetuals-form-amount-input-button"
          onClick={() =>
            changeAmount(
              formatAmount({
                value: amount.toString(),
                symbol: state.inputTicker,
                roundingMode: BigNumber.ROUND_DOWN,
              }),
            )
          }
        >
          <Text text="app-16-medium">100%</Text>
        </Box>
      </Box>
      <Box className="perpetuals-form-amount-slider">
        <Slider
          value={+state.inputAmount}
          onChange={(value) =>
            changeAmount(
              formatAmount({
                value: value.toString(),
                symbol: state.inputTicker,
                roundingMode: BigNumber.ROUND_DOWN,
              }),
            )
          }
          min={0}
          max={amount.toNumber()}
          step={1 * getAmountPrecisionBySymbol(state.inputTicker) ** -10}
          styles={{ track: trackStyle, rail: railStyle, handle: handleStyle }}
        />
      </Box>
    </Box>
  );
};
