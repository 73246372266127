import { formatAmount } from '@rehold-io/formatters';
import { useTranslation } from 'react-i18next';

import { DualType } from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

import { Box, Text, CurrencyPair } from 'shared/ui';

interface DualInputsProps {
  dual: Pick<
    DualType,
    | 'baseTicker'
    | 'inputAmount'
    | 'inputBaseAmount'
    | 'inputQuoteAmount'
    | 'inputTicker'
    | 'quoteTicker'
    | 'stakingPeriod'
  >;
  pairBg?: React.ComponentProps<typeof CurrencyPair>['borderColor'];
  showPeriod?: boolean;
}

export const DualInputs: React.FC<DualInputsProps> = ({ dual, pairBg, showPeriod = true }) => {
  const { t } = useTranslation();

  const { baseTicker, inputBaseAmount, inputQuoteAmount, inputTicker, quoteTicker } = dual;

  const eqTicker = inputTicker === baseTicker ? quoteTicker : baseTicker;
  const eqAmount = inputTicker === baseTicker ? inputQuoteAmount : inputBaseAmount;

  const { tokens } = useGetDualTokensQuery();

  return (
    <Box flexDirection="row" alignItems="center">
      <Box mr={8}>
        <CurrencyPair borderColor={pairBg} major={dual.baseTicker} minor={dual.quoteTicker} renderAsTokens />
      </Box>
      <Box alignSelf="center">
        <Box mb={2} display="flex" flexDirection="row" gap="4px">
          <Text>{formatAmount({ symbol: dual.inputTicker, value: dual.inputAmount })}</Text>
          <Text>{tokens[dual.inputTicker]?.symbol}</Text>
        </Box>
        <Box>
          <Text text="app-12-medium" gap="4px" display="flex">
            <Text opacity={0.4}>{formatAmount({ symbol: eqTicker, value: eqAmount })}</Text>
            <Text opacity={0.4}>{tokens[eqTicker]?.symbol}</Text>
            {showPeriod && (
              <Text color="secondary-03" opacity={0.6} ml="4px">
                {' '}
                {t('common.hoursShort', { value: dual.stakingPeriod })}
              </Text>
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
