import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { useTranslation } from 'react-i18next';
import { useWalletClient } from 'wagmi';

import { useGetDualTokensQuery } from 'entities/Token';
import { waitForUserAction } from 'entities/User';

import { useNetwork } from 'shared/hooks/network';
import { logger } from 'shared/lib/logger';

export const useAddTokenToWallet = () => {
  const { t } = useTranslation();
  const { chainId } = useNetwork();
  const { data } = useWalletClient();
  const { tokens } = useGetDualTokensQuery();

  const addToken = async (ticker: string) => {
    const token = tokens[ticker];
    if (!token) {
      logger.log('Token not found');
      return;
    }
    track(PredefinedEvents.Token.AddToWallet.Click({ address: token.address!, chainId: chainId! }));

    try {
      track(PredefinedEvents.Token.AddToWallet.Attempt({ address: token.address!, chainId: chainId! }));

      waitForUserAction(
        data?.watchAsset({
          options: {
            address: token.address as string,
            decimals: token.decimals,
            image: token.logoURI,
            symbol: token.symbol,
          },
          type: 'ERC20',
        }),
        { description: t('modals.signToken.description', { token: token.symbol }), title: t('modals.signToken.title') },
      );

      track(PredefinedEvents.Token.AddToWallet.Success({ address: token.address!, chainId: chainId! }));
    } catch (error: any) {
      track(
        PredefinedEvents.Token.AddToWallet.Fail({
          address: token.address!,
          chainId: chainId!,
          error: error?.message ?? 'Unknown error',
        }),
      );
      logger.error(error);
    }
  };

  return { addToken };
};
