import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSignAuthMessage } from 'features/Auth';

import { useUserMutation, useUserStore } from 'entities/User';

import { useAccount } from 'shared/hooks';
import { logger } from 'shared/lib/logger';
import { checkEmail } from 'shared/lib/validation';
import { i18n } from 'shared/locales';
import { Box, Button, Input } from 'shared/ui';

type SubscribeFormValues = {
  email: string;
};

const validateEmail = {
  validate: (value: string) => {
    if (!checkEmail(value)) {
      return i18n.t('errors.invalidAddress');
    }

    return true;
  },
};

type SubscribeFormProps = {
  fromLocation: 'duals' | 'notification';
  isEdit?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
};

export const SubscribeForm: FC<SubscribeFormProps> = observer(({ fromLocation, isEdit, onCancel, onSuccess }) => {
  const { address } = useAccount();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { mutateAsync } = useUserMutation();
  const { getSignature } = useSignAuthMessage();

  const { userInfo } = useUserStore();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    setFocus,
  } = useForm<SubscribeFormValues>({
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (isEdit) setFocus('email');
  }, [isEdit, setFocus]);

  const handleCancel = () => {
    onCancel?.();

    const { email } = getValues();
    track(
      PredefinedEvents.Notification.Email.Cancel({
        address: address!,
        email: email || userInfo?.email || '',
        from: fromLocation,
      }),
    );
  };

  useEffect(() => {
    const handler = (key: KeyboardEvent) => {
      if (key.key === 'Enter' && isDirty && !isLoading) {
        handleSubmit(onSubmit)();
      }
    };

    window.addEventListener('keypress', handler);

    return () => {
      window.removeEventListener('keypress', handler);
    };
  }, [isDirty, isLoading]);

  const onSubmit = async (values: SubscribeFormValues) => {
    setIsLoading(true);

    const signature = await getSignature();
    if (!signature) return setIsLoading(false);
    try {
      await mutateAsync({ email: values.email, language, signature });
      onSuccess?.();

      track(PredefinedEvents.Notification.Email.Save({ address: address!, email: values.email, from: fromLocation }));
    } catch (error) {
      logger.error(error);
    }

    setIsLoading(false);
  };

  return (
    <Box flexDirection={isEdit ? 'column' : 'row'} gap={isEdit ? '12px' : '8px'}>
      <Controller
        name="email"
        rules={validateEmail}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input placeholder={t('notifications.enterEmail')} size="tiny" error={error?.message} {...field} />
        )}
      />

      <Box flexDirection="row" gap="12px">
        {isEdit && (
          <Button width="max-content" minWidth="75px" size="tiny" onClick={handleCancel} variant="red">
            {t('common.cancel')}
          </Button>
        )}
        <Button
          minWidth="75px"
          width="max-content"
          size="tiny"
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty}
          loading={isLoading}
        >
          {t(`common.${isEdit ? 'save' : 'subscribe'}`)}
        </Button>
      </Box>
    </Box>
  );
});
