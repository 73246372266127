import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ForwardIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { Box } from 'shared/ui/Box';
import { Text } from 'shared/ui/Text';

export interface MainMenuNetworkProps {
  onPress: () => void;
}

export const MainMenuNetwork: FC<MainMenuNetworkProps> = observer(({ onPress }) => {
  const { network } = useNetwork();
  const { t } = useTranslation();

  return (
    <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={48} p={24} onClick={onPress}>
      <Box flexDirection="row" alignItems="center">
        <Box width={24} height={24} mr={8}>
          {network?.renderLogo(24)}
        </Box>
        <Text>{t('common.network')}</Text>
      </Box>
      <Box flexDirection="row" alignItems="center" height={32} ml={8} onClick={onPress}>
        <Text text="app-12-regular" color="secondary-03">
          {network?.name}
        </Text>
        <Box width={16} height={16} ml={4} color="secondary-03">
          <ForwardIcon width="100%" height="100%" />
        </Box>
      </Box>
    </Box>
  );
});
