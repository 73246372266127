import { hexDataLength } from '@ethersproject/bytes';
import BigNumber from 'bignumber.js';
import { arbitrum, optimism } from 'viem/chains';
import { fetchFeeData, readContract, readContracts } from 'wagmi/actions';

import { optimismAbi } from 'shared/config';
import { arbitrumAbi, arbitrumAddressContract } from 'shared/config/chains/abi/arbitrumAbi';
import { optimismAddressContract } from 'shared/config/chains/abi/optimismAbi';

import { ethPublicClient } from './providers/wagmi/config';

const INPUT_DATA_CLAIM =
  '0xc8336952000000000000000000000000d53bb409d5c445ffde59f9335b9e1e41ed3f3261000000000000000000000000d53bb409d5c445ffde59f9335b9e1e41ed3f326100000000000000000000000055d398326f99059ff775485246999027b319795500000000000000000000000000000000000000000000007282b37b3a89be680016f6708024612788eed443055e5cce5b5539a793b1e0a4387ea9dfe2202aee6200000000000000000000000000000000000000000000000000000000000000c00000000000000000000000000000000000000000000000000000000000000041dd274e57b0655f794bd830881a84153e41b98bdff291f1288fe148d480e49da93b33373ed6ebbf2121acae080f8e36e21bee83990c6d507e6de3ed5ab2daa14c1c00000000000000000000000000000000000000000000000000000000000000';

const estimateGasOptimismClaim = async () => {
  const { gasPrice } = await fetchFeeData();

  const l1GasPrice = await ethPublicClient.getGasPrice();
  const GAS_LIMIT_V1 = BigInt(75000);

  const [resultGasUsed, resultScalar] = await readContracts({
    allowFailure: true,
    contracts: [
      {
        abi: optimismAbi,
        address: optimismAddressContract,
        functionName: 'getL1GasUsed',
        args: [INPUT_DATA_CLAIM],
      },
      {
        abi: optimismAbi,
        address: optimismAddressContract,
        functionName: 'scalar',
      },
    ],
  });

  const l1GasUsed = resultGasUsed.result || BigInt(0);
  const l1Scalar = resultScalar.result || BigInt(0);

  const l2 = (gasPrice || BigInt(0)) * GAS_LIMIT_V1;
  const l1 =
    l1GasUsed *
    BigInt(
      BigNumber(l1Scalar.toString())
        .shiftedBy(-6)
        .times(Number(l1GasPrice) || 0)
        .toFixed(0),
    );

  return (l1 + l2).toString();
};

const estimateGasArbitrumClaim = async () => {
  const { gasPrice } = await fetchFeeData();

  const result = await readContract({
    abi: arbitrumAbi,
    address: arbitrumAddressContract,
    functionName: 'getPricesInWei',
  });

  const l2GasPrice = BigInt(gasPrice || 0);
  const l2GasUsed = BigInt(25000);

  const l1P = BigInt(result[1]);
  const l1S = BigInt(140 + hexDataLength(INPUT_DATA_CLAIM));

  const txFees = l2GasPrice * (l2GasUsed + (l1P * l1S) / l2GasPrice);

  return txFees.toString();
};

const CLAIM_GAS_LIMIT = 95387;

export const estimateGasDefaultClaim = async () => {
  const { gasPrice } = await fetchFeeData();
  const gas = (gasPrice || BigInt(0)) * BigInt(CLAIM_GAS_LIMIT);

  return gas.toString();
};

export const estimateGasClaim = async (chainId: number) => {
  if (chainId === optimism.id) {
    return estimateGasOptimismClaim();
  }
  if (chainId === arbitrum.id) {
    return estimateGasArbitrumClaim();
  }

  return estimateGasDefaultClaim();
};
