import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'shared/hooks';
import { Box, Button, Spinner, Text } from 'shared/ui';

import { usePoints } from './lib';
import {
  ConnectAccountButton,
  PointsTotalEarned,
  PointsInviteFriends,
  HowItWorks,
  PointsDailyQuest,
  MissingReferral,
} from './ui';
import { PointsLeaderboard } from './ui/Leaderboard';
import styles from './ui/Points.module.scss';

export const PointsWidget: React.FC = observer(() => {
  const { isConnected } = useAccount();
  const { t } = useTranslation();

  const { isSettingsLoading, isSettingsError, settingsRefetch, isStatsLoading, isStatsError, statsRefetch } =
    usePoints();

  const isLoading = isConnected ? isStatsLoading || isSettingsLoading : isSettingsLoading;

  if (isLoading) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  if (isSettingsError || isStatsError) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Text text="app-18-medium" mb={16}>
          {t('common.wrong')}
        </Text>
        <Button
          onClick={() => {
            settingsRefetch();
            statsRefetch();
          }}
        >
          {t('common.fetchRetry')}
        </Button>
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <PointsTotalEarned />
      <PointsDailyQuest />
      {!isConnected && <ConnectAccountButton />}
      <PointsInviteFriends />
      <MissingReferral />
      <Box>
        <HowItWorks />
      </Box>
      <PointsLeaderboard />
    </Box>
  );
});
