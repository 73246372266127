import { useCallback, useEffect, useState } from 'react';

import { useAccount } from 'shared/hooks';

export const useLiveChatSettings = () => {
  const { address } = useAccount();
  const [ready, setReady] = useState(false);

  const liveChatWidget = document.getElementById('chat-widget-container');

  const onVisibilityChanged = useCallback(
    (data: any) => {
      switch (data.visibility) {
        case 'maximized':
          if (liveChatWidget) liveChatWidget.style.zIndex = `${Number.MAX_SAFE_INTEGER}`;
          break;
        default:
          if (liveChatWidget) liveChatWidget.style.zIndex = `4`;
          break;
      }
    },
    [liveChatWidget],
  );

  useEffect(() => {
    window.LiveChatWidget?.init();
    window.LiveChatWidget?.once('ready', () => setReady(true));
    window.LiveChatWidget?.on('visibility_changed', onVisibilityChanged);

    return () => {
      window.LiveChatWidget?.off('visibility_changed', onVisibilityChanged);
    };
  }, [onVisibilityChanged]);

  useEffect(() => {
    if (liveChatWidget) liveChatWidget.style.zIndex = '4';
  }, [ready, liveChatWidget]);

  useEffect(() => {
    if (window.LiveChatWidget && ready) {
      window.LiveChatWidget?.call('set_customer_name', address || null);
    }
  }, [address, ready, window.LiveChatWidget]);
};
