import BN from 'bignumber.js';
import { useMemo } from 'react';

import { GAS_FOR_CHAINS, Network } from 'entities/Chain';

import { useNetwork } from 'shared/hooks/network';

const GAS_MARGIN = 3;

export const useGetMaxAmountForCreate = (limit: number, balance: number, ticker: string) => {
  const { network } = useNetwork();
  const maxAmount = useMemo(() => {
    const chain = network?.network as Network;

    if (!chain || GAS_FOR_CHAINS[chain]?.ticker !== ticker) return limit;
    if (!balance) return balance;

    const gas = BN(GAS_FOR_CHAINS[chain]?.dual || 0).times(GAS_MARGIN);

    if (BN(balance).lt(gas.plus(BN(limit)))) {
      return BN(balance).minus(gas).toFixed(6);
    }

    return limit;
  }, [limit, balance, ticker, network?.network]);

  return maxAmount;
};
