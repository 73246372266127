import React, { useState, useEffect } from 'react';

import { Text, TextProps } from '../Text';

import './ThreeDots.module.scss';

export const ThreeDots: React.FC<TextProps> = (props) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length >= 3 ? '.' : `${prevDots}.`));
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Text className="loading-dots" style={{ display: 'flex', width: '30px' }} {...props}>
      {dots}
    </Text>
  );
};
