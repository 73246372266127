import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Text } from 'shared/ui';

import { useReferral } from '../../lib';

export const ReferralProgramInviteFriendsText: React.FC = observer(() => {
  const { t } = useTranslation();
  const { revShareFee, revShareFeeError, revShareFeeFetching, revShareFeeRefetch, revShareType } = useReferral();

  if (!revShareFee) {
    return null;
  }

  return (
    <Box pb={24}>
      {revShareFeeError ? (
        <Box justifyContent="center">
          <Button onClick={revShareFeeRefetch} loading={revShareFeeFetching} data-id="refetch-revshare">
            {t('common.fetchRetry')}
          </Button>
        </Box>
      ) : (
        <>
          <Text fontSize={32} lineHeight={42} color="white-01">
            <Trans
              t={t}
              i18nKey="referralProgram.mainPage.inviteFriends"
              components={{
                Text: <Text color="primary-01" fontSize={32} lineHeight={42} />,
              }}
              values={{
                revShareFee,
                revShareFeeTypeProfit:
                  revShareType === 'COMPANY_PROFIT'
                    ? t('referralProgram.mainPage.protocolProfit')
                    : t('referralProgram.mainPage.theirProfit'),
              }}
            />
          </Text>
        </>
      )}
    </Box>
  );
});
