import { useEffect } from 'react';

import { useNetwork } from 'shared/hooks/network';

export const useSyncNetwork = () => {
  const { store, chainId } = useNetwork();

  //sync store if change chain in wallet
  useEffect(() => {
    if (chainId && store.selectedChainId !== chainId) {
      store.selectNetwork(chainId);
    }
  }, [store, store.selectedChainId, chainId]);
};
