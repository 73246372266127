import React from 'react';
import { useTranslation } from 'react-i18next';

import { SwapAssetField } from 'entities/Swap';
import { useSwapForm, useSwapQuote } from 'entities/Swap/lib';

export const SwapOutputField = () => {
  const { t } = useTranslation();

  const { output, update } = useSwapForm();

  const { isFetching, isInitialLoading, isError } = useSwapQuote();

  return (
    <SwapAssetField
      title={t('common.youGet')}
      value={output}
      isFetching={isFetching && isInitialLoading && !isError}
      onChange={(value) => update({ output: value })}
      disabled
      fiatHidden
    />
  );
};
