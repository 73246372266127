import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { hideStep } from 'features/OnboardingTour';

import { CheckIcon } from 'shared/assets';
import { AppChain } from 'shared/config/chains/types';
import { Box, Text, Spinner } from 'shared/ui';

interface NetworkListItemProps {
  isNew?: boolean;
  network: AppChain<any>;
  onNetworkChange: (nextNetworkId: number) => void;
  selected?: boolean;
  switching?: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NetworkListItem: React.FC<NetworkListItemProps> = React.memo(
  ({ network, onNetworkChange, selected, switching, toggle }) => {
    const { disabled, id, name, status } = network;
    const { t } = useTranslation();

    const handlePress = useCallback(() => {
      onNetworkChange(id);
      toggle(false);
      hideStep();
    }, [id, onNetworkChange, toggle]);

    const disabledStyles = useMemo(() => {
      if (disabled) {
        return {
          opacity: 0.4,
        };
      }

      return {};
    }, [disabled]);

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={48}
        px={24}
        onClick={handlePress}
        data-id="newtwork-switch"
        disabled={disabled}
      >
        <Box flexDirection="row" alignItems="center">
          <Box position="relative" mr={12} {...disabledStyles}>
            {network.renderLogo(24)}
            {switching && (
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                justifyContent="center"
                alignItems="center"
              >
                <Spinner size={24} />
              </Box>
            )}
          </Box>
          <Text {...disabledStyles}>{name}</Text>
          {status && (
            <Text
              text="app-12-regular"
              color="black-01"
              sx={{ opacity: 1 }}
              ml={14}
              bgColor="primary-01"
              px={6}
              borderRadius={20}
            >
              {t(`common.${status}`)}
            </Text>
          )}
          {disabled && (
            <Text
              text="app-12-regular"
              color="black-01"
              sx={{ opacity: 1 }}
              ml={14}
              bgColor="primary-01"
              px={6}
              borderRadius={20}
            >
              {t('common.soon')}
            </Text>
          )}
        </Box>
        {selected && (
          <Box color="secondary-03">
            <CheckIcon width={24} height={24} />
          </Box>
        )}
      </Box>
    );
  },
);
