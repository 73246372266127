import Tour from 'shepherd.js/src/types/tour';

import { TOUR_CLASSES } from 'features/OnboardingTour';

interface Props {
  isApprove?: boolean;
  isConnected?: boolean;
  isInsufficientFunds?: boolean;
  isSwitch?: boolean;
  isUnavalable?: boolean;
  tour: Tour | null;
}

export const handleOnboarding = ({
  tour,
  isConnected,
  isUnavalable,
  isApprove,
  isInsufficientFunds,
  isSwitch,
}: Props) => {
  const step = tour?.getCurrentStep()?.id;
  if (
    !tour?.isActive() ||
    (step !== `step-${TOUR_CLASSES.FORM_CONNECT.id}` &&
      step !== `step-${TOUR_CLASSES.UNAVAILABLE_CREATION.id}` &&
      step !== `step-${TOUR_CLASSES.SWITCH_CHAIN.id}` &&
      step !== `step-${TOUR_CLASSES.INSUFFICIENT_FUNDS.id}` &&
      step !== `step-${TOUR_CLASSES.APROVE.id}` &&
      step !== `step-${TOUR_CLASSES.START.id}`)
  )
    return;

  if (!isConnected) {
    tour.hide();
    tour.show(`step-${TOUR_CLASSES.FORM_CONNECT.id}`, true);
    return;
  }

  if (isUnavalable) {
    tour.hide();
    tour.show(`step-${TOUR_CLASSES.UNAVAILABLE_CREATION.id}`, true);
    return;
  }

  if (isSwitch) {
    tour.hide();
    tour.show(`step-${TOUR_CLASSES.SWITCH_CHAIN.id}`, true);
    return;
  }

  if (isInsufficientFunds) {
    tour.hide();
    tour.show(`step-${TOUR_CLASSES.INSUFFICIENT_FUNDS.id}`, true);
    return;
  }

  if (isApprove) {
    tour.hide();
    tour.show(`step-${TOUR_CLASSES.APROVE.id}`, true);
    return;
  }

  tour.hide();
  tour.show(`step-${TOUR_CLASSES.START.id}`, true);
};
