import classNames from 'classnames';
import React from 'react';

import { OnrampReversable } from 'entities/Onramp';
import { SwapTabs } from 'entities/Swap';

import { useWidget } from 'shared/hooks';
import { Box } from 'shared/ui';

import styles from '../../Onramp.module.scss';
import { OnrampInputField } from '../OnrampInputField';
import { OnrampOutputField } from '../OnrampOutputField';
import { OnrampSubmitButton } from '../OnrampSubmitButton';

export const OnrampContent: React.FC<{
  onChange: (result: string | null | undefined) => void;
  onTabChange: (tab: string) => void;
  tab: string;
}> = ({ tab, onTabChange, onChange }) => {
  const isWidget = useWidget();

  return (
    <>
      <Box ml={4} mr={4} mb={16} mt={4} className={classNames(styles.root, 'hide-scroll')} gap={20}>
        <Box gap={20} pt={3.5}>
          {!isWidget && <SwapTabs value={tab} onChange={onTabChange} />}
          <Box pt={3.5}>
            <OnrampInputField />
            <OnrampReversable />
            <OnrampOutputField />
          </Box>
        </Box>
      </Box>
      {!isWidget && (
        <OnrampSubmitButton
          onSubmit={() => onChange(null)}
          onSubmitFail={() => onChange(undefined)}
          onSubmitSuccess={(url) => onChange(url)}
        />
      )}
    </>
  );
};
