import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useGetDualTokenByTickerAndChain } from 'entities/Token/model/useGetDualTokenByTickerAndChain';

import { CHAIN_INFO } from 'shared/config/chains/index';
import { useWidget } from 'shared/hooks';
import { Box, CurrencyPair, Text } from 'shared/ui';
import { BackButton } from 'shared/ui/PageHeader/BackButton';

export const DualHeader = () => {
  const isWidget = useWidget();
  const { control } = useFormContext();
  const [assetId, chainId] = useWatch({ control, name: ['assetId', 'chainId'] });

  const [params] = useSearchParams();
  const isBackButtonEnabled = useMemo(() => params.get('backBtn'), [params]);

  const [baseTicker, quoteTicker] = assetId.split('/');
  const baseToken = useGetDualTokenByTickerAndChain(baseTicker, chainId);
  const quoteToken = useGetDualTokenByTickerAndChain(quoteTicker, chainId);

  const network = CHAIN_INFO[chainId];

  return (
    <Box height="40px" width="100%" flexDirection="row" justifyContent="center" alignItems="center">
      {(!isWidget || (isWidget && isBackButtonEnabled)) && (
        <Box position="absolute" left="-12px" top="0">
          <BackButton hoverable />
        </Box>
      )}
      <Box flexDirection="row" alignItems="center" paddingY="10px">
        <CurrencyPair major={assetId.split('/')[0]} minor={assetId.split('/')[1]} />
        <Box ml={8}>
          <Text text="app-14-medium" color="white-01">
            {baseToken?.symbol}&#183;{quoteToken?.symbol}
          </Text>

          <Box flexDirection="row" gap="4px" alignItems="center">
            {network?.renderLogo(10)}
            <Text text="app-12-regular" opacity="0.6">
              {network?.name}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
