import { useTranslation } from 'react-i18next';

import { useOnrampForm, useOnrampQuote } from 'entities/Onramp';

import { ArrowIcon, ForwardIcon } from 'shared/assets';
import theme from 'shared/theme';
import { Box, IconByUrl, Logo, Text, ThreeDots } from 'shared/ui';

export const OnrampConnecting = () => {
  const { t } = useTranslation();

  const { data } = useOnrampQuote();
  const { input, output } = useOnrampForm();

  return (
    <>
      <Box
        width="100%"
        height="100%"
        maxHeight="580px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={16}
      >
        <Box flexDirection="row" gap={16} alignItems="center" justifyContent="center">
          <Logo variant="gradient" size={60} />
          <Box flexDirection="row" rowGap={2}>
            <ForwardIcon
              opacity={0.25}
              color={theme.colors['primary-01']}
              width={20}
              height={60}
              style={{
                strokeWidth: 1.5,
                transform: 'scale(1.7)',
                marginLeft: '-4px',
                marginRight: '-4px',
                transition: 'opacity 0.1s ease-out',
              }}
            />
            <ForwardIcon
              opacity={0.5}
              color={theme.colors['primary-01']}
              width={20}
              height={60}
              style={{
                strokeWidth: 1.5,
                transform: 'scale(1.7)',
                marginLeft: '-4px',
                marginRight: '-4px',
                transition: 'opacity 0.1s ease-out',
              }}
            />
            <ForwardIcon
              opacity={1}
              color={theme.colors['primary-01']}
              width={20}
              height={60}
              style={{
                strokeWidth: 1.5,
                transform: 'scale(1.7)',
                marginLeft: '-4px',
                marginRight: '-4px',
                transition: 'opacity 0.1s ease-out',
              }}
            />
          </Box>
          <IconByUrl url="/static/onramps/moonpay.svg" size={60} loading="eager" />
        </Box>
        <Box flexDirection="row" gap={2} alignItems="baseline">
          <Text text="app-18-medium" textAlign="center">
            {t('onramp.connecting', { provider: 'MoonPay' }).replace('...', '')}
          </Text>
          <ThreeDots />
        </Box>
        {data && input.currency && output.currency && (
          <Box flexDirection="row" alignItems="center" justifyContent="center" gap={10}>
            <Text color="secondary-03" text="app-14-medium">{`${
              data.fromAmount
            } ${input.currency.ticker.toUpperCase()}`}</Text>
            <ArrowIcon color={theme.colors['secondary-03']} width={20} height={20} />
            <Text color="secondary-03" text="app-14-medium">{`${
              data.toAmount
            } ${output.currency.ticker.toUpperCase()}`}</Text>
          </Box>
        )}
      </Box>
    </>
  );
};
