import { useState } from 'react';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';

import { Box } from 'shared/ui';

import TVChartContainer from './TVChartContainer';
import './index.scss';

export const PerpetualsChart = () => {
  const [period, setPeriod] = useState('1m');

  const { state } = usePerpetualForm();

  return (
    <Box className="perpetuals-chart">
      <TVChartContainer
        chartLines={[]}
        savedShouldShowPositionLines
        isLoading={!state.tariff}
        isShort={state.isShort}
        symbol={state.tariff?.baseTicker || 'btc'}
        onSelectToken={() => {}}
        period={period}
        setPeriod={setPeriod}
      />
    </Box>
  );
};
