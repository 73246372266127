export const updateQueryParam = (parameter: string, value?: string | null) => {
  const queryParams = new URL(window.location.href);

  if (value) {
    queryParams.searchParams.set(parameter, value);
    window.history.replaceState(window.history.state, '', queryParams.href);
    return;
  }

  if (queryParams.searchParams.has(parameter) && value === null) {
    queryParams.searchParams.set(parameter, 'null');
    window.history.replaceState(window.history.state, '', queryParams.href);
    return;
  }

  if (queryParams.searchParams.has(parameter) && !value) {
    queryParams.searchParams.delete(parameter);
    window.history.replaceState(window.history.state, '', queryParams.href);
  }
};
