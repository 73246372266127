import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DualsUserWidget } from 'widgets/Duals';
import { DualCreateFeature } from 'widgets/DualsDashboard';
import { LayoutContent } from 'widgets/Layout/Content';

import { DualTariffsProvider } from 'entities/Dual';
import { DualTabs } from 'entities/Dual/ui/DualTabs';

import { PUBLIC_URL } from 'shared/config';
import { PathPatterns } from 'shared/lib';

export const Duals = React.memo(() => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const pathRoot = `/${pathname.split('/')[1]}`;

  return (
    <>
      <Helmet title={t('main.metaTitle')}>
        <link rel="canonical" href={`${PUBLIC_URL}${pathRoot}`} />
      </Helmet>

      <DualTariffsProvider>
        <DualTabs />
        <LayoutContent mt={16}>
          {pathRoot === PathPatterns.DualsDashboard && <DualCreateFeature />}
          {pathRoot === PathPatterns.Duals && <DualsUserWidget />}
        </LayoutContent>
      </DualTariffsProvider>
    </>
  );
});
