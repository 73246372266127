import React, { FC } from 'react';

import { SwapDataResponse } from 'entities/Swap';

import { SwapStateModalConfirm } from './SwapStateModalConfirm';
import { SwapStateModalFail } from './SwapStateModalFail';
import { SwapStateModalSubmitted } from './SwapStateModalSubmitted';
import { SwapStateModalSuccess } from './SwapStateModalSuccess';

interface Props {
  confirm: boolean;
  data?: SwapDataResponse;
  error?: any;
  fail: boolean;
  hash?: string;
  onClose: () => void;
  submitted: boolean;
  success: boolean;
}

export const SwapStateModal: FC<Props> = ({ data, hash, error, confirm, fail, submitted, success, onClose }) => (
  <>
    <SwapStateModalConfirm data={data} open={confirm} onClose={onClose} />
    <SwapStateModalSubmitted data={data} hash={hash!} open={submitted} onClose={onClose} />
    <SwapStateModalSuccess hash={hash!} open={success} onClose={onClose} />
    <SwapStateModalFail hash={hash} open={fail} onClose={onClose} error={error} />
  </>
);
