import { formatAmount } from '@rehold-io/formatters';
import { useQueryClient } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { useDeferredValue } from 'react';

import { useSignAuthMessage } from 'features/Auth';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';
import { useCreatePerpetualPositionMutation } from 'entities/Perpetual/model/hooks';

import { useAccount } from 'shared/hooks';
import { DEFAULT_ERROR } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyError, notifyPosition } from 'shared/lib/notifications';

export const useSubmit = () => {
  const queryClient = useQueryClient();

  const { address } = useAccount();

  const { state } = usePerpetualForm();

  const { getSignature } = useSignAuthMessage();

  const openingFeeAmount = state.tariff
    ? BigNumber(state.inputAmount || 0)
        .multipliedBy(BigNumber(state.leverage || 0))
        .multipliedBy(state.tariff.openingFee ?? 0)
    : null;
  const defferedInputAmount = useDeferredValue(state.inputAmount);

  const { mutateAsync, isLoading, isError } = useCreatePerpetualPositionMutation();

  const submit = async () => {
    if (!state.tariff) {
      logger.error(new Error('Perpetual tariff was not selected or does not exist'));
      notifyError({
        text: DEFAULT_ERROR,
      });
      return;
    }

    try {
      const signature = await getSignature();

      if (!signature) return;

      await mutateAsync({
        baseTicker: state.tariff.baseTicker,
        quoteTicker: state.tariff.quoteTicker,
        inputAmount: defferedInputAmount,
        inputTicker: state.inputTicker,
        isShort: state.tariff.isShort,
        leverage: +state.leverage,
        signature,
      });

      queryClient.invalidateQueries({
        queryKey: [`perpetual-closed-positions-${address}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`perpetual-opened-positions-${address}`],
      });

      notifyPosition({
        status: 'success',
        inputAmount: formatAmount({
          value: BigNumber(defferedInputAmount).minus(openingFeeAmount ?? 0),
          symbol: state.inputTicker,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        inputTicker: state.inputTicker,
        isShort: state.isShort,
        leverage: state.leverage,
        type: 'open',
      });
    } catch (error: any) {
      const errorMessage: string = error?.response?.data?._error?.message;

      if (errorMessage) {
        notifyError({
          text: errorMessage,
        });
        return;
      }

      notifyError({
        text: DEFAULT_ERROR,
      });
      logger.error(error);
    }
  };

  return {
    isDisabled: false,
    isError,
    isLoading,
    onSubmit: submit,
  };
};
