import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash.omit';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';

import { ClaimDualButton } from 'features/ClaimDual';
import { useHappyMoments } from 'features/HappyMoments';
import { ReplayDual } from 'features/ReplayDual';

import { DualInputs, DualType, DualChartWithProfit, useDualModal, formatApr } from 'entities/Dual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, BoxProps, Badge } from 'shared/ui';

import { ClosedDualInfo } from './ClosedDualInfo';
import { StyledBox } from './styled';

interface DualProps extends BoxProps {
  dual: DualType;
}

export const ImplDual = forwardRef<HTMLDivElement, DualProps>(({ dual, ...rest }) => {
  const containerRef = useRef(null);
  const queryClient = useQueryClient();
  const { setDualParams } = useDualModal();
  const [isLoading, setIsLoading] = useState(false);
  const { chainId } = useNetwork();
  const { address } = useAccount();

  const { setValues } = useHappyMoments();

  const trackedDual = useMemo(() => omit(dual, ['chartData', 'closeCalculate']), [dual]);

  const onSuccessClaim = async () => {
    queryClient.invalidateQueries({
      queryKey: [`claimed-duals-${chainId}-${address}`],
    });
    queryClient.invalidateQueries({
      queryKey: [`closed-duals-${chainId}-${address}`],
    });
    queryClient.invalidateQueries({
      queryKey: [`count-duals-${chainId}-${address}`],
    });
    setIsLoading(false);

    setValues({ amount: dual.outputAmount, ticker: dual.outputTicker, type: 'dual-claim' });
  };

  const onStart = useCallback(() => {
    setIsLoading(true);
  }, []);
  const onError = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onOpen = () => {
    setDualParams({ id: dual.id });
  };

  return (
    <StyledBox borderRadius={24} bg="secondary-02" p={16} pt={20} {...rest} ref={containerRef} onClick={onOpen}>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" mb={19}>
        <DualInputs dual={dual} pairBg="secondary-02" />
        <Box>
          <Badge>{formatApr(dual.apr)}</Badge>
        </Box>
      </Box>
      <DualChartWithProfit dual={dual} chartMargin={-16} />
      {!dual.closedPrice && dual.finishAt && <ClosedDualInfo dual={dual} trackedDual={trackedDual} />}
      {!!dual.closedPrice && (
        <Box mt={24} zIndex={1}>
          <ReplayDual dual={dual} isDisabled={isLoading} fromLocation="duals" />
          <ClaimDualButton dual={dual} onSuccess={onSuccessClaim} onStart={onStart} onError={onError} />
        </Box>
      )}
    </StyledBox>
  );
});

export const Dual = observer(ImplDual);
