import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetReferralOverviewQuery } from 'entities/Referral';
import { DualTokenIcon } from 'entities/Token';

import { PeopleIcon } from 'shared/assets';
import { Box, Text, Spinner } from 'shared/ui';

import { useReferralData, useReferral } from '../../lib';

export const ReferralProgramReferralsTotalEarned: FC = () => {
  const { t } = useTranslation();
  const { balanceError, balanceFetching, balanceRefetch, claimedBalance: amount, ticker } = useReferral();
  const { isError, isLoading, refetch } = useGetReferralOverviewQuery();

  const { duals, referrals, swaps } = useReferralData();

  const isSmallMobile = useMediaQuery(`(max-width: 430px)`, { noSsr: true });
  const isTinyMobile = useMediaQuery(`(max-width: 340px)`, { noSsr: true });

  return (
    <Box flexDirection="row" justifyContent="space-between" mb={24}>
      <Box>
        <Text mb={8}>{t('referralProgram.referralsPage.totalEarned')}</Text>
        <Box flexDirection="row" alignItems="center">
          <Box mr={8}>{ticker && <DualTokenIcon ticker={ticker} size={24} />}</Box>
          <Text>
            {amount} {ticker.toUpperCase()}
          </Text>
          {balanceFetching && (
            <Box ml={8}>
              <Spinner size={24} />
            </Box>
          )}
          {balanceError && (
            <Box flexDirection="row" ml={8}>
              <Box onClick={balanceRefetch}>
                <Text color="red-01" fontWeight="bold">
                  {t('common.fetchRetry')}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {isError && (
        <Box alignSelf="center">
          <Box onClick={() => refetch({})}>
            <Text color="red-01" fontWeight="bold">
              {t('common.fetchRetry')}
            </Text>
          </Box>
        </Box>
      )}

      {isLoading && (
        <Box alignSelf="center">
          <Spinner size={24} />
        </Box>
      )}

      {!isError && !isLoading && (
        <>
          <Box flexDirection="row" justifyContent="space-between">
            {!isTinyMobile && (
              <Box alignItems="flex-end" ml={24}>
                <Text color="secondary-03" mb={8}>
                  {t('referralProgram.referralsPage.duals')}
                </Text>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  minWidth={20}
                  height={20}
                  px={6}
                  borderRadius={10}
                  bg="secondary-02"
                >
                  <Text color="secondary-03">{duals}</Text>
                </Box>
              </Box>
            )}
            {!isSmallMobile && (
              <Box alignItems="flex-end" ml={24}>
                <Text color="secondary-03" mb={8}>
                  {t('referralProgram.referralsPage.swaps')}
                </Text>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  minWidth={20}
                  height={20}
                  px={6}
                  borderRadius={10}
                  bg="secondary-02"
                >
                  <Text color="secondary-03">{swaps}</Text>
                </Box>
              </Box>
            )}
            <Box alignItems="flex-end" ml={24}>
              <Text color="secondary-03" mb={8}>
                {t('referralProgram.referralsPage.referrals')}
              </Text>
              <Box flexDirection="row" alignItems="center">
                <Box mr={8} style={{ stroke: 'var(--secondary-03)', strokeWidth: 0 }}>
                  <PeopleIcon width={16} height={16} />
                </Box>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  minWidth={20}
                  height={20}
                  px={6}
                  borderRadius={10}
                  bg="secondary-02"
                >
                  <Text color="secondary-03">{referrals}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
