import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSwapRouterAddress } from 'features/SwapTokens';

import { SwapCollapsible, SwapPriceImpactWarning, SwapReversable, SwapTabs } from 'entities/Swap';
import { SwapFormProvider, SwapQuoteProvider } from 'entities/Swap/lib';
import { SwapTokensProvider } from 'entities/Swap/lib/providers/swapTokensProvider';

import { useWidget } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, Text } from 'shared/ui';

import styles from '../../Swap.module.scss';
import { SwapInputField } from '../SwapInputField';
import { SwapOutputField } from '../SwapOutputField';
import { SwapSettings } from '../SwapSettings';
import { SwapSubmitButton } from '../SwapSubmitButton';

export const SwapTab: React.FC<{ onTabChange: (tab: string) => void; tab: string }> = observer(
  ({ onTabChange, tab }) => {
    const { network } = useNetwork();
    const { t } = useTranslation();

    const isWidget = useWidget();

    const routerAddress = useSwapRouterAddress();

    if (!routerAddress) {
      return (
        <Box px={{ default: 12, desktop: 16, tablet: 16 }} py={{ desktop: 20, phone: 0, tablet: 16 }}>
          <Box alignItems="center" justifyContent="center" height={544}>
            <Text text="app-22-medium" color="primary-01">
              {t('common.soonOn')} {network?.name}
            </Text>
          </Box>
        </Box>
      );
    }

    return (
      <Box px={{ default: 12, desktop: 16, tablet: 16 }} py={{ desktop: 20, phone: 0, tablet: 16 }}>
        <SwapTokensProvider>
          <SwapFormProvider>
            <SwapQuoteProvider>
              <Box ml={4} mr={4} mb={16} mt={4} className={classNames(styles.root, 'hide-scroll')} gap={20}>
                <Box gap={20}>
                  {!isWidget && (
                    <Box flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
                      <SwapTabs value={tab} onChange={onTabChange} />
                      <SwapSettings />
                    </Box>
                  )}
                  <Box>
                    <SwapInputField />
                    <SwapReversable />
                    <SwapOutputField />
                  </Box>
                </Box>
                <SwapCollapsible />
                <SwapPriceImpactWarning />
              </Box>
              {!isWidget && <SwapSubmitButton />}
            </SwapQuoteProvider>
          </SwapFormProvider>
        </SwapTokensProvider>
      </Box>
    );
  },
);
