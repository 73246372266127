import React from 'react';

import styles from './ChartSpinner.module.scss';
import { ReactComponent as Circle } from './images/circle.svg';

interface ChartSpinnerProps {
  size?: number;
}

export const ChartSpinner: React.FC<ChartSpinnerProps> = ({ size = 80 }) => (
  <Circle className={styles.spinner} height={size} width={size} />
);
