import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { useSwitchNetwork as useSwitchNetworkWagmi } from 'wagmi';

import { logger } from 'shared/lib/logger';

import { useStore } from '../store';

export const useSwitchNetwork = () => {
  const { switchNetworkAsync } = useSwitchNetworkWagmi();
  const { networkStore } = useStore();

  const handleSwitch = (id: number) => {
    track(PredefinedEvents.Network.Change.Attempt({ chainId: id }));
    if (!switchNetworkAsync) {
      // we are not connected
      networkStore.selectNetwork(id);
      track(PredefinedEvents.Network.Change.Success({ chainId: id }));
      return;
    }

    networkStore.switchTo(id);
    return switchNetworkAsync(id)
      .then((r) => {
        networkStore.selectNetwork(id);
        track(PredefinedEvents.Network.Change.Success({ chainId: id }));
        return r;
      })
      .catch((error) => {
        track(PredefinedEvents.Network.Change.Fail({ chainId: id }));
        logger.error(error);
      })
      .finally(() => {
        networkStore.switchReset();
      });
  };

  return {
    switchNetwork: handleSwitch,
  };
};
