import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TypeTab } from 'widgets/Duals/model';

import { useGetOpenedLimitDualsQuery } from 'features/LimitsDual';

import { Box, Button, Text, Spinner } from 'shared/ui';

import { Empty } from '../../Empty';
import { LimitDual } from '../LimitDual';

export const OpenedLimitDuals = observer(() => {
  const { t } = useTranslation();
  const { data, error, isError, isFetching, isInitialLoading, isLoading, refetch } = useGetOpenedLimitDualsQuery();
  const showSpinner = isInitialLoading && !error;

  if (showSpinner) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  if (!isError && !isInitialLoading && !data?.length) {
    return (
      <Box>
        <Empty type={TypeTab.LIMIT} />
      </Box>
    );
  }

  return (
    <Box gap="20px">
      <Box mb={-8} flexDirection="row">
        <Text text="app-18-medium">{t('dual.limit')}</Text>
        {data?.length && isFetching && (
          <Box ml={8}>
            <Spinner size={24} />
          </Box>
        )}
      </Box>

      {(isInitialLoading || isError || isLoading) && (
        <Box height={150} justifyContent="center" alignItems="center">
          {(isInitialLoading || isLoading) && <Spinner />}
          {isError && <Button onClick={() => refetch()}>{t('common.refetch')}</Button>}
        </Box>
      )}

      {data?.map((dual) => (
        <LimitDual key={dual.id} dual={dual} />
      ))}
    </Box>
  );
});
