import { formatAmount } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import { Toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { CheckIcon, ErrorIcon } from 'shared/assets';
import { Box } from 'shared/ui/Box';
import { Spinner } from 'shared/ui/Spinner';
import { Text } from 'shared/ui/Text';

import { BasicToast } from '../BasicToast';

export interface PositionToastProps {
  inputAmount: string;
  inputTicker: string;
  isShort: boolean;
  leverage: string | number;
  onClose?: VoidFunction;
  status?: 'error' | 'success' | 'waiting';
  toast?: Toast;
  type: 'open' | 'close';
}

export const PositionToast: React.FC<PositionToastProps> = ({
  onClose,
  status = 'waiting',
  toast,
  inputAmount,
  inputTicker,
  leverage,
  isShort,
  type,
}) => {
  const { t } = useTranslation();

  const toastTitle = t(`modals.position.${type}.title.${status}`, {
    amount: formatAmount({
      value: inputAmount,
      symbol: inputTicker,
      roundingMode: BigNumber.ROUND_DOWN,
    }).replace('.00', ''),
    ticker: inputTicker.toUpperCase(),
    type: isShort ? 'Short' : 'Long',
    leverage,
  });

  const textColor = status === 'error' ? 'red-01' : status === 'success' ? 'primary-01' : 'white-01';

  return (
    <BasicToast onClose={onClose} toastProps={toast}>
      <Box flexDirection="row" alignItems="center">
        <Box width={24} height={24} justifyContent="center" alignItems="center" mr={8}>
          {(status === 'error' || status === 'success') && (
            <Text color={textColor}>
              {status === 'error' && <ErrorIcon width={24} height={24} />}
              {status === 'success' && <CheckIcon width={24} height={24} />}
            </Text>
          )}
          {status === 'waiting' && <Spinner size={24} />}
        </Box>
        <Box width="100%">
          <Text color={textColor}>{toastTitle}</Text>
        </Box>
      </Box>
    </BasicToast>
  );
};
