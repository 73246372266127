import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useReferral } from 'features/ReferralProgram/lib';

import { PathPatterns } from 'shared/lib';
import { Box, Button, Text } from 'shared/ui';

export const MissingReferral: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { unclaimedBalance } = useReferral();

  if (!unclaimedBalance || unclaimedBalance === '0') return null;

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="secondary-02"
      borderRadius={16}
      padding={8}
      mb={16}
      gap={24}
    >
      <Text text="app-16-medium" ml={16}>
        {t('points.missingReferral.title')}
      </Text>
      <Button variant="secondary" width="fit-content" onClick={() => navigate(PathPatterns.ReferralProgramMain)}>
        {t('points.missingReferral.button')}
      </Button>
    </Box>
  );
};
