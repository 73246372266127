import { styled } from '@mui/material';

import { Box } from 'shared/ui';

export const StyledBox = styled(Box)(({ theme }) => ({
  '&:hover': {
    '--currency-pair-color': theme.colors['secondary-04'],
    background: theme.colors['secondary-04'],
    border: `1px solid ${theme.colors['secondary-03']}`,
  },
  border: `1px solid ${theme.colors['secondary-02']}`,
  boxSizing: 'border-box',
  cursor: 'pointer',

  transition: 'all 0.2s ease',
}));
