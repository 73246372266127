import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { useAccount } from 'shared/hooks';

import { getTransfers } from '../api/queries';

import { GetTransfersResponse, TransferStatus } from './types';

export const useGetTransfersQuery = (
  status: TransferStatus,
  options: UseQueryOptions<GetTransfersResponse | null, unknown, GetTransfersResponse | null, string[]>,
) => {
  const { address } = useAccount();

  return useQuery(
    [`transfers-${address}-${status}`],
    () => (address ? getTransfers({ address: address!, status }) : null),
    {
      enabled: !!address,
      ...options,
    },
  );
};
