import { configureScope } from '@sentry/react';
import { useEffect } from 'react';

import { useNetwork } from './network';
import { useAccount } from './useAccount';

export const useSentryScope = () => {
  const { address, connector } = useAccount();
  const { wagmi } = useNetwork();

  const chainId = wagmi.chain?.id;
  useEffect(() => {
    if (address && chainId && connector) {
      configureScope((scope) => {
        scope.setUser({ id: address });
        scope.setTag('chainId', chainId);
        scope.setTag('connector', connector?.id);
      });
    }
  }, [address, chainId, connector]);
};
