import { useMemo } from 'react';
import { Address } from 'wagmi';

import { isEqualTariff, useStakingPlans } from 'entities/Dual';
import { useRate } from 'entities/Rates';

import { useAccount } from 'shared/hooks';

import { DashboardFormValues } from '../model/form';

export interface TrackParams {
  address: Address;
  apr: number;
  baseTicker: string;
  initialPrice: string;
  inputAmount: number;
  inputTicker: string;
  quoteTicker: string;
  stakingPeriod: number;
}

export function useTrackParams(values: DashboardFormValues): TrackParams {
  const { address } = useAccount();
  const plans = useStakingPlans({ assetId: values.assetId, chainId: values.chainId });
  const selectedPlan = useMemo(
    () => plans.find((tariff) => isEqualTariff(tariff, values.tariff)),
    [plans, values.tariff],
  );
  const rate = useRate({
    from: selectedPlan?.baseTicker,
    to: selectedPlan?.quoteTicker,
    inputTicker: values.inputTicker,
  });

  const trackParams: TrackParams = useMemo(
    () => ({
      address: address!,
      apr: selectedPlan?.apr!,
      baseTicker: selectedPlan?.baseTicker!,
      initialPrice: rate?.formatted?.price!,
      inputAmount: Number(values.inputAmount),
      inputTicker: values.inputTicker,
      quoteTicker: selectedPlan?.quoteTicker!,
      stakingPeriod: selectedPlan?.stakingPeriod!,
    }),
    [address, rate, selectedPlan, values.inputAmount, values.inputTicker],
  );

  return trackParams;
}
