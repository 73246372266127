import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { usePoints } from 'widgets/Points/lib';

import { formatSuffixNumber } from 'entities/Points';

import { PeopleIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { formatCurrency } from 'shared/lib';
import { Box, Text, CurrencyIcon, Spinner, Tooltip } from 'shared/ui';

export const PointsTotalEarned: FC = () => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();

  const { stats, isStatsLoading } = usePoints();

  const isTinyMobile = useMediaQuery('(max-width: 360px)', { noSsr: false });
  const isSmallMobile = useMediaQuery('(max-width: 430px)', { noSsr: false });
  const isMediumMobile = useMediaQuery('(max-width: 530px)', { noSsr: false });
  const isMobile = useMediaQuery('(max-width: 768px)', { noSsr: false });

  if (isConnected && isStatsLoading) {
    return (
      <Box height={250} alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box flexDirection="row" justifyContent="space-between" mb={24}>
      <Box>
        <Text mb={8}>{t('points.totalEarned')}</Text>
        <Box flexDirection="row" alignItems="center">
          <Box mr={8}>
            <CurrencyIcon ticker="redy" size={24} />
          </Box>
          {isMobile && (
            <Box flexDirection="row" gap={8}>
              {isConnected && (
                <Tooltip title={formatCurrency(stats?.balance || 0).replace('$', '')}>
                  <Text>{formatSuffixNumber(stats?.balance || 0)}</Text>
                </Tooltip>
              )}
              {!isConnected && <Text>{formatSuffixNumber(stats?.balance || 0)}</Text>}
              REDY
            </Box>
          )}
          {!isMobile && <Text>{formatCurrency(stats?.balance || 0).replace('$', '')} REDY</Text>}
        </Box>
      </Box>
      <Box flexDirection="row" justifyContent="space-between">
        {!isTinyMobile && (
          <Box alignItems="flex-end" ml={24}>
            <Text color="secondary-03" mb={8}>
              {t('points.perpetuals')}
            </Text>
            <Box
              justifyContent="center"
              alignItems="center"
              minWidth={20}
              height={20}
              px={6}
              borderRadius={10}
              bg="secondary-02"
            >
              <Text color="secondary-03">{stats?.perpetuals || 0}</Text>
            </Box>
          </Box>
        )}
        {(!isSmallMobile || isTinyMobile) && (
          <Box alignItems="flex-end" ml={24}>
            <Text color="secondary-03" mb={8}>
              {t('points.duals')}
            </Text>
            <Box
              justifyContent="center"
              alignItems="center"
              minWidth={20}
              height={20}
              px={6}
              borderRadius={10}
              bg="secondary-02"
            >
              <Text color="secondary-03">{stats?.duals || 0}</Text>
            </Box>
          </Box>
        )}
        {!isMediumMobile && (
          <Box alignItems="flex-end" ml={24}>
            <Text color="secondary-03" mb={8}>
              {t('points.swaps')}
            </Text>
            <Box
              justifyContent="center"
              alignItems="center"
              minWidth={20}
              height={20}
              px={6}
              borderRadius={10}
              bg="secondary-02"
            >
              <Text color="secondary-03">{stats?.swaps || 0}</Text>
            </Box>
          </Box>
        )}
        <Box alignItems="flex-end" ml={24}>
          <Text color="secondary-03" mb={8}>
            {t('points.referrals')}
          </Text>
          <Box flexDirection="row" alignItems="center">
            <Box mr={8} style={{ stroke: 'var(--secondary-03)', strokeWidth: 0 }}>
              <PeopleIcon width={16} height={16} />
            </Box>
            <Box
              justifyContent="center"
              alignItems="center"
              minWidth={20}
              height={20}
              px={6}
              borderRadius={10}
              bg="secondary-02"
            >
              <Text color="secondary-03">{stats?.referrals || 0}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
