import classnames from 'classnames';
import React from 'react';

import { SwitchIcon } from 'shared/assets';

import { useGetDualTokenByTickerAndChain } from '../../model/useGetDualTokenByTickerAndChain';
import { DualTokenIcon } from '../DualTokenIcon';

import styles from './TokenSelect.module.scss';

interface TokenSelectProps {
  chainId: number;
  onChange?: (value: string) => void;
  selected: string;
  size?: 'md' | 'sm';
  tickers: string[];
}

export const TokenSelect: React.FC<TokenSelectProps> = ({
  onChange = (): void => {},
  selected,
  size = 'md',
  tickers = [],
  chainId,
}) => {
  const selectedIndex = tickers.findIndex((value) => value === selected);

  const selectedTicker = useGetDualTokenByTickerAndChain(tickers[selectedIndex], chainId);

  if (tickers.length === 0) {
    return null;
  }

  const handleSelect = (delta: number) => () => {
    let nextIndex = selectedIndex + delta;

    if (nextIndex === tickers.length) {
      nextIndex = 0;
    }

    onChange(tickers[nextIndex]);
  };

  const isSmall = size === 'sm';

  const className = classnames(styles.select, {
    [styles.small]: isSmall,
  });

  const tickerIcon = isSmall ? null : <DualTokenIcon ticker={selectedTicker?.symbol!} size={24} />;

  const switchIconSize = isSmall ? 12 : 16;
  return (
    <div className={className} role="listbox" onClick={handleSelect(1)} data-id="token-select">
      {tickerIcon}
      <div className={styles.label}>{selectedTicker?.symbol}</div>
      <div className={styles.icon}>
        <SwitchIcon width={switchIconSize} height={switchIconSize} />
      </div>
    </div>
  );
};
