import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { Address, formatUnits } from 'viem';
import { usePrepareSendTransaction, usePublicClient, useSendTransaction } from 'wagmi';

import { useGetSwapDataQuery, useSwapTokenByAddress } from 'entities/Swap';

import { NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { useNetwork } from 'shared/hooks/network';
import { calculateGasMargin } from 'shared/lib';
import { logger } from 'shared/lib/logger';

import { useAllowance } from './useAllowance';
import { useSwapRouterAddress } from './useSwapRouterAddress';

type SwapArgs = {
  address: Address;
  fromAmount: string;
  fromToken: Address;
  slippage: string;
  toToken: Address;
};

export const useSwap = ({ address, fromAmount, fromToken, slippage, toToken }: SwapArgs) => {
  const routerAddress = useSwapRouterAddress();
  const publicClient = usePublicClient();
  const { networkReady, selectedChainId } = useNetwork();

  const { data: fromTokenData } = useSwapTokenByAddress(fromToken);

  const {
    data: swapData,
    isFetching: isSwapDataLoading,
    isInitialLoading: isSwapDataInitial,
    isError: isSwapDataError,
  } = useGetSwapDataQuery(
    {
      address,
      fromAmount,
      fromToken,
      slippage,
      toToken,
      chainId: selectedChainId,
    },
    { retry: false, refetchInterval: 1000 * 10 },
  );

  const { needApprove, isError: isAllowanceError } = useAllowance({
    amount: fromTokenData ? formatUnits(BigInt(fromAmount), fromTokenData.decimals).toString() : '',
    address: fromToken,
  });

  const [gasForSwap, setGasForSwap] = useState<bigint | undefined>();

  const swapPrepareArgs = {
    account: address,
    to: routerAddress,
    data: swapData?.data as `0x${string}`,
    value: fromToken === NATIVE_TOKEN_ADDRESS ? BigInt(fromAmount) : undefined,
    cacheTime: 0,
  } as const;

  const prepareEnabled = !!fromAmount && fromAmount !== '' && networkReady && !!address && !needApprove;

  const swapPrepare = usePrepareSendTransaction({
    ...swapPrepareArgs,
    chainId: selectedChainId,
    enabled: !!swapData && prepareEnabled,
    scopeKey: `swap::${selectedChainId}`,

    onSuccess: async (data) => {
      if (data.gas) {
        return setGasForSwap(calculateGasMargin(data.gas));
      }
      try {
        const gasLimit = await publicClient.estimateGas(swapPrepareArgs);
        const gas = calculateGasMargin(gasLimit);
        setGasForSwap(gas);
      } catch (e) {
        setGasForSwap(undefined);
        logger.error(e);
      }
    },
  });

  const { data: swapConfig } = swapPrepare;

  const swapWrite = useSendTransaction(
    swapConfig ? { ...swapPrepareArgs, ...swapConfig, gas: gasForSwap } : swapPrepareArgs,
  );

  if (!address || !networkReady || !fromAmount || !BigNumber(fromAmount).gt(0) || isSwapDataError || isAllowanceError) {
    return {
      isPreparing: false,
      isError: true,
      write: null,
      data: null,
    };
  }

  if ((isSwapDataLoading && isSwapDataInitial) || needApprove) {
    return {
      isPreparing: true,
      isError: false,
      write: null,
      data: null,
    };
  }

  return {
    isPreparing: false,
    isError: false,
    write: swapWrite,
    data: swapData,
  };
};
