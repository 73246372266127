import { useEffect, useState } from 'react';

import { useStorageOnboarding } from 'features/OnboardingTour/hooks/useStorageOnboarding';

import { useGetCountDualsQuery } from 'entities/Dual';

import { useStorageHelpStart } from './useStorageHelpStart';

export const useShowHelpTooltip = () => {
  const [isShow, setIsShow] = useState(false);
  const { data } = useGetCountDualsQuery();
  const { get: getStorageStart, set } = useStorageHelpStart();

  const { get: getStorageOnboarding } = useStorageOnboarding();

  const onClose = () => {
    setIsShow(false);
    set();
  };

  useEffect(() => {
    const isShown = getStorageOnboarding();
    const isShownStart = getStorageStart();

    if (!data || !!data.total || !isShown || isShownStart) return;

    setIsShow(true);
  }, [data]);

  return { isShow, onClose };
};
