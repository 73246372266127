import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getPerpetualClosedPositions } from 'entities/Perpetual/api';

import { PerpetualClosedPositionsRequestArgs, PerpetualClosedPositionsResponse } from '../data';

export const useGetPerpetualClosedPositionsQuery = (
  args: PerpetualClosedPositionsRequestArgs,
  options?: UseQueryOptions<PerpetualClosedPositionsResponse | null, {}>,
) =>
  useQuery<PerpetualClosedPositionsResponse | null, {}>(
    [`perpetual-closed-positions-${args.address}`],
    () => (!options || options.enabled === undefined || options.enabled ? getPerpetualClosedPositions(args) : null),
    {
      ...options,
      enabled: options?.enabled !== undefined ? options.enabled : true,
      cacheTime: 1000 * 60,
      refetchInterval: 1000 * 60,
    },
  );
