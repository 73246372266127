import React from 'react';

import { LimitDual } from 'features/LimitsDual';

import { useRate } from 'entities/Rates';

export const useLimitDualChartData = (dual: LimitDual) => {
  const chartData = dual.chartData.map((d) => ({ createdAt: Date.parse(d.createdAt as any), price: +d.price }));
  const dualInitialPrice = +dual.targetPrice;
  const dualStartedAt = Date.parse(dual.createdAt as any);
  const dualFinished = !!dual.closedPrice;

  const rate = useRate({
    from: dual.baseTicker,
    skip: dualFinished,
    to: dual.quoteTicker,
    inputTicker: dual.inputTicker,
  });

  const lastPoint = React.useMemo(
    () =>
      dualFinished || !rate?.price
        ? chartData[chartData.length - 1]
        : {
            createdAt: rate?.createdAt || Date.now(),
            price: rate?.price,
          },
    [dualFinished, rate, chartData],
  );

  const chartDataWithLastPoint = React.useMemo(() => {
    if (lastPoint) {
      return [...chartData, lastPoint];
    }

    return chartData;
  }, [chartData, lastPoint]);

  return { chartData, chartDataWithLastPoint, dualFinished, dualInitialPrice, dualStartedAt, lastPoint };
};
