import useResizeObserver from '@react-hook/resize-observer';
import { formatPrice } from '@rehold-io/formatters';
import BN from 'bignumber.js';
import * as d3 from 'd3';
import { observer } from 'mobx-react-lite';
import React, { useLayoutEffect, useRef, FC, useState, useMemo, useEffect } from 'react';

import { PendingDualType } from 'entities/Dual';
import { ChartRateItems } from 'entities/Rates/model/types';

import { Box, Text } from 'shared/ui';

import { usePendingDualChartData } from '../../lib/usePendingDualChartData';

interface DualChartProps {
  chartData: ChartRateItems;
  dual: PendingDualType;
}

export const PendingDualChart: FC<DualChartProps> = observer(({ chartData: initialChartData, dual }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);

  const [svgSize, setSvgSize] = useState<{ height: number; width: number } | null>(null);

  useLayoutEffect(() => {
    if (boxRef.current) {
      const { height, width } = boxRef.current.getBoundingClientRect();
      setSvgSize({ height, width });
    }
  }, []);

  useResizeObserver(boxRef, ({ borderBoxSize }) => {
    const { blockSize: height, inlineSize: width } = borderBoxSize[0];
    setSvgSize({ height, width });
  });

  const BADGE_HEIGHT = 16;
  const PROFIT_BLOCK_WIDTH = 130;
  const TICKER_ICON_WIDTH = 24;
  const PARENT_PADDING = 24;

  const marginXLeft = 3;
  const marginXRight = PROFIT_BLOCK_WIDTH - TICKER_ICON_WIDTH / 2 + PARENT_PADDING; //
  const marginY = BADGE_HEIGHT / 2;

  const viewboxHeight = svgSize?.height || 1;
  const viewboxWidth = svgSize?.width || 1;

  const { chartData, chartDataWithLastPoint, dualFinishAt, dualInitialPrice, dualStartedAt, lastPoint } =
    usePendingDualChartData(dual, initialChartData);

  const [priceMin = 0, priceMax = 0] = useMemo(
    () => d3.extent(chartDataWithLastPoint, (d) => d.price),
    [chartDataWithLastPoint],
  );

  let priceMinDomainPrev: BN | null = null;
  let priceMaxDomainPrev: BN | null = null;

  const yScale = useMemo(() => {
    const diffWithMax = BN(priceMax).minus(dualInitialPrice).abs();
    const diffWithMin = BN(priceMin).minus(dualInitialPrice).abs();

    const diffMax = diffWithMax.gt(diffWithMin) ? diffWithMax : diffWithMin;
    const priceMinDomain = BN(dualInitialPrice).minus(diffMax);
    const priceMaxDomain = BN(dualInitialPrice).plus(diffMax);

    if (
      !priceMinDomainPrev ||
      !priceMaxDomainPrev ||
      priceMinDomainPrev.gt(priceMinDomain) ||
      priceMinDomainPrev.lt(priceMinDomain)
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      priceMinDomainPrev = priceMinDomain.minus(diffMax.times(0.1));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      priceMaxDomainPrev = priceMaxDomain.plus(diffMax.times(0.1));
    }

    return d3
      .scaleLinear()
      .domain([priceMinDomainPrev.toNumber(), priceMaxDomainPrev.toNumber()])
      .range([viewboxHeight - marginY, marginY]);
  }, [dualInitialPrice, priceMax, priceMin, marginY, viewboxHeight]);

  const [currentPricePosition, setCurrentPricePosition] = useState<null | number>(null);
  const [initialPricePosition, setInitialPricePosition] = useState<null | number>(null);

  const currentPriceDiff = useMemo(() => {
    if (!currentPricePosition) {
      return null;
    }

    return `${(currentPricePosition / viewboxHeight) * 100}%`;
  }, [currentPricePosition, viewboxHeight]);

  const initialPriceDiff = useMemo(() => {
    if (!initialPricePosition) {
      return null;
    }

    return `${(initialPricePosition / viewboxHeight) * 100}%`;
  }, [initialPricePosition, viewboxHeight]);

  const [minDate = 0, maxDate = 0] = useMemo(
    () => d3.extent(chartDataWithLastPoint, (d) => d.createdAt),
    [chartDataWithLastPoint],
  );

  const xScale = useMemo(() => {
    const domainMax = Math.max(maxDate, dualFinishAt);

    return d3
      .scaleLinear()
      .domain([minDate, domainMax])
      .range([marginXLeft, viewboxWidth - marginXRight]);
  }, [minDate, maxDate, dualFinishAt, viewboxWidth, marginXRight]);

  useEffect(() => {
    setInitialPricePosition(yScale(+dualInitialPrice));
    setCurrentPricePosition(yScale(+lastPoint.price));
  }, [dualInitialPrice, lastPoint.price, yScale]);

  const lineTransformer = useMemo(
    () =>
      d3
        .line<(typeof chartData)[0]>()
        .x((d) => xScale(d.createdAt))
        .y((d) => yScale(d.price)),
    [xScale, yScale],
  );

  const historyLineD = useMemo(
    () => lineTransformer(chartData.filter((d) => d.createdAt <= dualStartedAt)),
    [chartData, lineTransformer, dualStartedAt],
  );

  const dualLineD = useMemo(
    () => lineTransformer(chartData.filter((d) => d.createdAt >= dualStartedAt)),
    [chartData, lineTransformer, dualStartedAt],
  );

  const lastPointLineD = useMemo(() => {
    const localData = chartData.length ? [chartData[chartData.length - 1], lastPoint] : [lastPoint];
    return lineTransformer(localData);
  }, [chartData, lastPoint, lineTransformer]);

  const initialPriceLineD = useMemo(() => {
    const localData = [
      { price: +dualInitialPrice, x: 0 },
      { price: +dualInitialPrice, x: viewboxWidth - PARENT_PADDING },
    ];

    const lineTransformer = d3
      .line<(typeof localData)[0]>()
      .x((d) => d.x)
      .y((d) => yScale(d.price));

    return lineTransformer(localData);
  }, [viewboxWidth, dualInitialPrice, yScale]);

  const currentPriceLineD = useMemo(() => {
    const localData = [
      { price: +lastPoint.price, x: xScale(lastPoint.createdAt) },
      { price: +lastPoint.price, x: viewboxWidth - PARENT_PADDING },
    ];

    const lineTransformer = d3
      .line<(typeof localData)[0]>()
      .x((d) => d.x)
      .y((d) => yScale(d.price));

    return lineTransformer(localData);
  }, [viewboxWidth, yScale, xScale, lastPoint]);

  const leftDashedLineD = useMemo(() => {
    const localData = [
      { x: xScale(dualStartedAt), y: 0 },
      { x: xScale(dualStartedAt), y: viewboxHeight },
    ];

    const lineTransformer = d3
      .line<(typeof localData)[0]>()
      .x((d) => d.x)
      .y((d) => d.y);

    return lineTransformer(localData);
  }, [viewboxHeight, dualStartedAt, xScale]);

  const rightDashedLineD = useMemo(() => {
    const localData = [
      { x: xScale(dualFinishAt), y: 0 },
      { x: xScale(dualFinishAt), y: viewboxHeight },
    ];

    const lineTransformer = d3
      .line<(typeof localData)[0]>()
      .x((d) => d.x)
      .y((d) => d.y);

    return lineTransformer(localData);
  }, [viewboxHeight, dualFinishAt, xScale]);

  return (
    <Box height={136} position="relative" ref={boxRef} mt={2}>
      <svg ref={svgRef} height="100%" width="100%">
        {historyLineD && <path fill="none" stroke="#632BDC" strokeWidth={1} strokeLinecap="round" d={historyLineD} />}
        {dualLineD && <path fill="none" stroke="#05F283" strokeWidth={1} strokeLinecap="round" d={dualLineD} />}
        {leftDashedLineD && (
          <path
            fill="none"
            strokeDasharray="2 3"
            stroke="url(#paint0_linear_3233_5429)"
            strokeWidth={1}
            strokeLinecap="round"
            d={leftDashedLineD}
          />
        )}

        {rightDashedLineD && (
          <path
            fill="none"
            strokeDasharray="2 3"
            stroke="url(#paint0_linear_3233_5429)"
            strokeWidth={1}
            strokeLinecap="round"
            d={rightDashedLineD}
          />
        )}
        {lastPointLineD && (
          <path fill="none" stroke="#05F283" strokeWidth={1} strokeLinecap="round" d={lastPointLineD} />
        )}

        {/* start white point */}
        <circle fill="white" cx={xScale(dualStartedAt)} cy={yScale(+dual.initialPrice)} r={3} />
        {/* finish white point */}
        <circle fill="white" cx={xScale(dualFinishAt)} cy={yScale(+dual.initialPrice)} r={3} />
        {/* current price point */}
        <circle fill="#05F283" cx={xScale(lastPoint.createdAt)} cy={yScale(lastPoint.price)} r={3} />
        {/* white line */}
        {initialPriceLineD && (
          <path fill="none" stroke="white" strokeWidth={0.5} strokeLinecap="round" d={initialPriceLineD} />
        )}

        {/* current price animation */}

        <circle fill="#05F283" cx={xScale(lastPoint.createdAt)} cy={yScale(lastPoint.price)} r={3}>
          {/* <circle cx={chartStart} cy={middleY} fill="none" r={3} stroke="var(--color-white-01)" strokeWidth={2}> */}
          <animate attributeName="r" from={3} to={6} dur="1.5s" begin="0s" repeatCount="indefinite" />
          <animate attributeName="opacity" from={0.6} to={0} dur="1.5s" begin="0s" repeatCount="indefinite" />
          {/* </circle> */}
        </circle>

        {/* green line */}
        {currentPriceLineD && (
          <path fill="none" stroke="#05F283" strokeWidth={0.5} strokeLinecap="round" d={currentPriceLineD} />
        )}

        <linearGradient id="paint0_linear_3233_20278" gradientTransform="rotate(90)">
          <stop stopColor="#05F283" />
          <stop offset="1" stopColor="#05F283" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint0_linear_451_5495" gradientTransform="rotate(90)">
          <stop stopColor="#05F283" stopOpacity="0" />
          <stop offset="1" stopColor="#05F283" />
        </linearGradient>

        <linearGradient id="paint0_linear_3233_5429" x1="0.5" y1="138" x2="0.5" y2="-2" gradientUnits="userSpaceOnUse">
          <stop stopColor="#632BDC" stopOpacity="0" />
          <stop offset="0.5" stopColor="#632BDC" />
          <stop offset="1" stopColor="#632BDC" stopOpacity="0" />
        </linearGradient>
      </svg>
      {initialPriceDiff && (
        <Box position="absolute" right={PARENT_PADDING} top={initialPriceDiff}>
          <Box position="relative" top={-BADGE_HEIGHT / 2}>
            <Box height={16} borderRadius={20} px={6} justifyContent="center" bg="white-01">
              <Text text="app-12-medium" color="background-01">
                {formatPrice({ from: dual.baseTicker, to: dual.quoteTicker, value: dual.initialPrice })}
              </Text>
            </Box>
          </Box>
        </Box>
      )}

      {currentPriceDiff && (
        <Box position="absolute" right={PARENT_PADDING} style={{ top: currentPriceDiff }}>
          <Box position="relative" top={-BADGE_HEIGHT / 2}>
            <Box height={16} borderRadius={20} px={6} justifyContent="center" bg="primary-01">
              <Text text="app-12-medium" color="background-01">
                {formatPrice({
                  from: dual.baseTicker,
                  to: dual.quoteTicker,
                  value: chartDataWithLastPoint[chartDataWithLastPoint.length - 1].price,
                })}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});
