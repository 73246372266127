import { splitSignature } from '@ethersproject/bytes';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { useSignTypedData, useContractReads } from 'wagmi';

import { waitForUserAction } from 'entities/User';

import { permitByChainAndToken } from 'shared/config';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { MAX_INT } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { PermitDomain, PermitMessage } from 'shared/types';

import { useTokenPermits } from './useTokenPermits';

const PERMIT_EXPIRATION_SECONDS = 60 * 60 * 24;

const PERMIT_TYPE = {
  Permit: [
    {
      name: 'owner',
      type: 'address',
    },
    {
      name: 'spender',
      type: 'address',
    },
    {
      name: 'value',
      type: 'uint256',
    },
    {
      name: 'nonce',
      type: 'uint256',
    },
    {
      name: 'deadline',
      type: 'uint256',
    },
  ],
} as const;

const abi = [
  {
    inputs: [],
    name: 'name',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
    ],
    name: 'nonces',
    outputs: [
      {
        internalType: 'uint256',
        name: 'address',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const;

export const usePermit = (token: `0x${string}`) => {
  const { address: owner } = useAccount();
  const { chainId, network } = useNetwork();
  const { updatePermits } = useTokenPermits();

  const { data } = useContractReads({
    contracts: [
      {
        abi,
        address: token,
        functionName: 'name',
      },
      {
        abi,
        address: token,
        args: [owner!],
        functionName: 'nonces',
      },
    ],
  });

  const value = BigInt(MAX_INT);
  const deadline = BigInt(Math.floor(Date.now() / 1000) + PERMIT_EXPIRATION_SECONDS);

  const message = {
    deadline,
    nonce: data?.[1]?.result,
    owner: owner!,
    spender: network?.vault?.address!,
    value,
  } as PermitMessage;

  const domain = {
    chainId: chainId!,
    name: data?.[0]?.result!,
    verifyingContract: token,
    version: permitByChainAndToken?.[chainId]?.[token]?.toString(),
  } as PermitDomain;

  const { isLoading, signTypedDataAsync } = useSignTypedData({
    domain,
    message,
    primaryType: 'Permit',
    types: PERMIT_TYPE,
  });

  const requestPermit = async (trackParams: Arguments<typeof PredefinedEvents.Dual.Permit.Attempt>) => {
    try {
      track(PredefinedEvents.Dual.Permit.Attempt(trackParams));
      const signature = await waitForUserAction(signTypedDataAsync(), 'message');
      track(PredefinedEvents.Dual.Permit.Success(trackParams));

      const { v, r, s } = splitSignature(signature);

      updatePermits(
        {
          amount: value,
          deadline,
          v,
          r: r as `0x${string}`,
          s: s as `0x${string}`,
        },
        chainId!,
        token,
      );
    } catch (error: any) {
      logger.error(error);

      track(
        PredefinedEvents.Dual.Permit.Fail({
          ...trackParams,
          error: error?.message ?? 'Unknown error',
        }),
      );
    }
  };

  return { isLoading, requestPermit };
};
