import { ConnectKitButton } from '@rehold-io/connectkit';
import { track, PredefinedEvents } from '@rehold-io/data-layer-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { HotjarEvents, trackHotjar } from 'features/HotJar';

import { ButtonProps, Button } from 'shared/ui';

export interface ConnectAccountButtonProps extends Omit<ButtonProps, 'onPress'> {}

export const ConnectAccountButton: FC<ConnectAccountButtonProps> = (props) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <ConnectKitButton.Custom>
      {({ show }) => {
        const onPress = () => {
          trackHotjar(HotjarEvents.WALLET_CONNECT_CLICK);
          track(PredefinedEvents.Wallet.Connect.Click({ from: location.pathname }));
          show?.();
        };
        return (
          <Button data-id="connect-from-points" variant="primary" onClick={onPress} mb={16} {...props}>
            {t('modals.connectWallet.title')}
          </Button>
        );
      }}
    </ConnectKitButton.Custom>
  );
};
