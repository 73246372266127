import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteLimitDual } from '../api/queries';

export const useDeleteLimitDualMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(['delete-limit-dual'], deleteLimitDual, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`opened-limit-${variables.address.toLowerCase()}-${variables.chainId}`],
      });
    },
  });
};
