import classNames from 'classnames';
import React, { CFC } from 'react';

import { Box } from 'shared/ui/Box';
import { Text } from 'shared/ui/Text';

import styles from './Card.module.scss';
import { CardProps } from './types';

export const Card: CFC<CardProps> = (props) => {
  const { children, label, onClick } = props;

  const { className, isOutlined, variant = 'secondary' } = props;

  const CardProps = {
    primary: {
      backgroundColor: isOutlined ? undefined : 'primary-01',
      borderColor: 'primary-01',
      color: 'black-01',
    },
    secondary: {
      backgroundColor: isOutlined ? undefined : 'secondary-02',
      borderColor: 'secondary-02',
      color: 'secondary-03',
    },
  } as const;

  const LabelProps = {
    primary: {
      bgColor: isOutlined ? 'background-01' : 'primary-01',
      color: 'black-01',
    },
    secondary: {
      bgColor: isOutlined ? 'background-01' : 'secondary-02',
      color: 'secondary-03',
    },
  } as const;

  const labelContent = label ? (
    <Text className={styles.label} variant="label" {...LabelProps[variant]}>
      {label}
    </Text>
  ) : null;

  return (
    <Box
      className={classNames(className, styles.card)}
      onClick={onClick}
      {...CardProps[variant]}
      data-id={props['data-id']}
    >
      {labelContent}
      {children}
    </Box>
  );
};
