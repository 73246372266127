import { formatAmount } from '@rehold-io/formatters';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DualChartWithProfit, DualInputs, DualType, formatApr } from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

import { formatDate } from 'shared/lib';
import { Box, PageContent, Text, Badge } from 'shared/ui';

import { Details } from '../Details';

type Props = {
  dual: DualType;
};

export const ClaimedDual: FC<Props> = observer(({ dual }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { tokens } = useGetDualTokensQuery();

  const isReplayed = dual.l2Replayed || dual.l2AutoReplayed;

  return (
    <PageContent className="hide-horizontal-scroll" px={24} pb={24} mt={20}>
      <Box flexDirection="row" justifyContent="space-between" mb={19} alignItems="center">
        <DualInputs dual={dual} pairBg="background-01" />
        <Box>
          <Badge>{formatApr(dual.apr)}</Badge>
        </Box>
      </Box>
      <DualChartWithProfit dual={dual} />
      <Details dual={dual} />
      <Box height="1px" my="30px" bg="secondary-02" mx="-30px" mt={24} />
      <Box>
        <Box>
          <Text textAlign="center" text="app-18-medium">
            {t(`modals.claimedDual.${isReplayed ? 'replayed' : 'claimed'}`)}:{' '}
            {formatAmount({ symbol: dual.outputTicker, value: dual.outputAmount })} {tokens[dual.outputTicker]?.symbol}
          </Text>
        </Box>
        {dual.finishAt && (
          <Box mt={16} sx={{ opacity: 0.2 }}>
            <Text textAlign="center">{formatDate(dual.finishAt, language)}</Text>
          </Box>
        )}
      </Box>
    </PageContent>
  );
});
