import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import classNames from 'classnames';
import React, { CFC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from 'shared/assets';
import { useDebounce, useDetectMobileDevice } from 'shared/hooks';
import { Box } from 'shared/ui';

import styles from './OnrampAssetSelect.module.scss';

type Props = {
  onClose: () => void;
  onSearch: React.Dispatch<React.SetStateAction<string>>;
  searchText: string;
  setDebouncedText: React.Dispatch<React.SetStateAction<string>>;
};

export const OnrampAssetSelectModalFilter: CFC<Props> = ({
  onClose,
  onSearch,
  searchText,
  setDebouncedText,
  children,
}) => {
  const { t } = useTranslation();

  const [eventSent, setEventSent] = useState(false);

  const isMobileDevice = useDetectMobileDevice();

  const refInput = useRef<HTMLInputElement | null>(null);

  const debouncedText = useDebounce(searchText, 300);

  useEffect(() => {
    if (!isMobileDevice) handleFocus();
  }, []);

  useEffect(() => {
    setDebouncedText(debouncedText);
  }, [debouncedText]);

  const handleFocus = () => {
    refInput?.current?.focus();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const text = value.trimStart();
    onSearch(text);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Escape') {
      onClose();
      e.stopPropagation();
    }

    if (e.currentTarget.value.length + e.key.length === 1 && !eventSent) {
      track(PredefinedEvents.Onramp.Tokens.Search());
      setEventSent(true);
    }
  };

  return (
    <Box width="100%" position="relative" py={16}>
      <Box className={styles.inputContainer}>
        <Box
          justifyContent="center"
          alignItems="center"
          width={40}
          height={40}
          position="absolute"
          top="2px"
          left="0"
          onClick={handleFocus}
          zIndex={1}
          color="white-01"
          data-id="filter-asset"
        >
          <SearchIcon width={24} height={24} />
        </Box>
        <input
          placeholder={t('modals.onramp.assetSelection.search.placeholder')}
          className={classNames(styles.input, styles._active)}
          ref={refInput}
          onChange={handleSearch}
          value={searchText}
          onKeyDown={handleKeyPress}
          onBlur={() => setEventSent(false)}
          autoComplete="off"
        />
      </Box>
      <Box width="100%">{children}</Box>
    </Box>
  );
};
