import { requester } from 'shared/api';

import {
  PerpetualBalanceRequestArgs,
  PerpetualBalanceResponse,
  PerpetualClosePositionRequestArgs,
  PerpetualClosePositionResponse,
  PerpetualClosedPositionsRequestArgs,
  PerpetualClosedPositionsResponse,
  PerpetualCreatePositionRequestArgs,
  PerpetualCreatePositionResponse,
  PerpetualGetChartCandlesRequestArgs,
  PerpetualGetChartCandlesResponse,
  PerpetualOpenedPositionsRequestArgs,
  PerpetualOpenedPositionsResponse,
  PerpetualTariffsResponse,
} from '../model';

export const getPerpetualTariffs = () =>
  requester.get<PerpetualTariffsResponse>(`/api/v1/perpetuals/tariffs`).then((res) => res.data);

export const getPerpetualBalance = ({ address }: PerpetualBalanceRequestArgs) =>
  requester.get<PerpetualBalanceResponse>(`/api/v1/balances/${address}`).then((res) => res.data);

export const getPerpetualOpenedPositions = ({ address }: PerpetualOpenedPositionsRequestArgs) =>
  requester.get<PerpetualOpenedPositionsResponse>(`/api/v1/perpetuals/${address}/opened`).then((res) => res.data);

export const getPerpetualClosedPositions = ({ address }: PerpetualClosedPositionsRequestArgs) =>
  requester.get<PerpetualClosedPositionsResponse>(`/api/v1/perpetuals/${address}/closed`).then((res) => res.data);

export const createPerpetualPosition = ({ signature, ...body }: PerpetualCreatePositionRequestArgs) =>
  requester
    .post<PerpetualCreatePositionResponse>(`/api/v1/perpetuals/`, body, {
      headers: {
        Authorization: signature,
      },
    })
    .then((res) => res.data);

export const closePerpetualPosition = ({ signature, id }: PerpetualClosePositionRequestArgs) =>
  requester
    .post<PerpetualClosePositionResponse>(`/api/v1/perpetuals/${id}/close`, undefined, {
      headers: {
        Authorization: signature,
      },
    })
    .then((res) => res.data);

export const getChartCandles = ({ symbol, resolution, fromDate, toDate }: PerpetualGetChartCandlesRequestArgs) =>
  requester
    .get<PerpetualGetChartCandlesResponse>(`/api/v1/candles`, {
      params: {
        symbol: `${symbol}/usdt`,
        interval: resolution,
        fromDate,
        toDate,
      },
    })
    .then((res) => res.data);
