import { ratesStore } from 'entities/Rates';
import { RateProduct } from 'entities/Rates/model/types';

import { StreamingInput, SubscriptionItem } from './types';
import { getNextBarTime } from './utils';

const channelToSubscription = new Map<string, SubscriptionItem>();

function handleStreamingData(data: { id: string; p: number; r: string; t: number }) {
  const { id, p, t, r } = data;

  const tradePrice = p;
  const tradeTime = t;

  const channelString = id;
  const subscriptionItem = channelToSubscription.get(channelString);

  if (!subscriptionItem || !subscriptionItem.lastDailyBar) {
    return;
  }

  const prevDailyBar = subscriptionItem.prevDailyBar;
  const lastDailyBar = subscriptionItem.lastDailyBar;
  const nextDailyBarTime = getNextBarTime(lastDailyBar.time, r);

  let bar: any;
  if (tradeTime >= nextDailyBarTime) {
    subscriptionItem.prevDailyBar = lastDailyBar;
    bar = {
      time: nextDailyBarTime,
      open: lastDailyBar.close,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
    };
  } else {
    bar = {
      ...lastDailyBar,
      high: Math.max(lastDailyBar.high, tradePrice),
      low: Math.min(lastDailyBar.low, tradePrice),
      close: tradePrice,
    };

    if (prevDailyBar?.close !== bar.open) {
      bar.open = prevDailyBar.close;
    }
  }

  subscriptionItem.lastDailyBar = { ...bar };

  subscriptionItem.handlers.forEach((handler: any) => handler.callback(bar));
  channelToSubscription.set(channelString, subscriptionItem);
}

export function subscribeOnStream(input: StreamingInput) {
  const { symbolInfo, lastDailyBar, prevDailyBar, onRealtimeCallback, resolution, subscriberUID } = input;

  const rateSymbol = `${symbolInfo.ticker!.toLowerCase()}/usdt`;

  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };
  const key = `${subscriberUID}-${resolution}`;

  let subscriptionItem = channelToSubscription.get(key);

  subscriptionItem = {
    subscriberUID,
    resolution,
    lastDailyBar,
    prevDailyBar,
    rateSymbol,
    handlers: [handler],
  };
  channelToSubscription.set(key, subscriptionItem);

  ratesStore.rateSubscribe(rateSymbol, RateProduct.PERPETUAL);

  ratesStore.registerCallback(RateProduct.PERPETUAL, subscriberUID, (event) => {
    const [symbol, mid] = event;

    if (symbol === rateSymbol) {
      handleStreamingData({ id: key, p: mid, t: Date.now(), r: resolution });
    }
  });
}

export function unsubscribeFromStream(subscriberUID: any) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem?.handlers.findIndex((handler: { id: any }) => handler.id === subscriberUID);

    if (handlerIndex !== -1) {
      channelToSubscription.delete(channelString);

      ratesStore.rateUnsubscribe(subscriptionItem?.rateSymbol || '', RateProduct.PERPETUAL);
      ratesStore.deregisterCallback(RateProduct.PERPETUAL, subscriberUID);
      break;
    }
  }
}
