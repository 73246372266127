import { useState } from 'react';

import { PendingDualType } from 'entities/Dual';
import { useRate } from 'entities/Rates';
import { ChartRateItems } from 'entities/Rates/model/types';

export const usePendingDualChartData = (dual: PendingDualType, initialChartData: ChartRateItems) => {
  const [chartData] = useState<ChartRateItems>(initialChartData);
  const dualStartedAt = dual.startedAt;
  const dualFinishAt = dual.finishAt;
  const dualInitialPrice = dual.initialPrice;

  const rate = useRate({
    from: dual.baseTicker,
    to: dual.quoteTicker,
    inputTicker: dual.inputTicker,
  });

  const lastPoint = rate?.price
    ? {
        createdAt: rate?.createdAt || Date.now(),
        price: rate.price || 0,
      }
    : chartData[chartData.length - 1];

  return {
    chartData,
    chartDataWithLastPoint: [...chartData, lastPoint],
    dualFinishAt: Date.parse(dualFinishAt),
    dualInitialPrice,
    dualStartedAt: Date.parse(dualStartedAt),
    lastPoint,
  };
};
