import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getDualAssets } from 'entities/Dual/api/queries';

let isPolling = false;
const POLLING_INTERVAL = 30_000;

export const useGetDualAssetsQuery = () => {
  const result = useQuery([`dual-assets`], () => getDualAssets());

  const { refetch } = result;

  useEffect(() => {
    if (isPolling) return;
    isPolling = true;
    const intervalId = setInterval(() => {
      refetch();
    }, POLLING_INTERVAL);

    return () => {
      isPolling = false;
      clearInterval(intervalId);
    };
  }, [refetch]);

  return result;
};
