import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { CFC, useEffect } from 'react';

import { useAccount } from 'shared/hooks';
import { PermitData } from 'shared/types';

type TokenPermitsContextValue = {
  clearPermits: () => void;
  permits: Record<number, Record<string, PermitData>>;
  updatePermits: (permit: PermitData, chainId: number, tokenAddress: string) => void;
};

export const TokenPermitsContext = React.createContext({} as TokenPermitsContextValue);

export const TokenPermitsProvider: CFC = observer(({ children }) => {
  const { address } = useAccount();
  const state = useLocalObservable<TokenPermitsContextValue>(() => ({
    clearPermits() {
      this.permits = {};
    },

    permits: {},

    updatePermits(permit: PermitData, chainId: number, token: string) {
      this.permits = {
        ...this.permits,
        [chainId]: {
          ...this.permits?.[chainId],
          [token]: permit,
        },
      };
    },
  }));

  useEffect(() => {
    state.clearPermits();
  }, [address, state]);

  return <TokenPermitsContext.Provider value={state}>{children}</TokenPermitsContext.Provider>;
});
