import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTour } from 'features/OnboardingTour';

import { PathPatterns } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import styles from './NavTabs.module.scss';

export const NavTabs: FC = () => {
  const { t } = useTranslation();
  const { tour } = useTour();

  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isSmallMobileScreen = useMediaQuery('(max-width: 400px)');

  return (
    <>
      {isMobileScreen ? (
        ReactDOM.createPortal(
          <Box className={styles['nav-tabs-mobile']}>
            <Box
              flexDirection="row"
              alignItems="center"
              width="100%"
              backgroundColor="secondary-02"
              borderRadius={32}
              padding={8}
              gap={8}
              boxShadow="0 0 32px 0 rgba(0,0,0,0.6)"
            >
              <Box flexDirection="row" alignItems="center" gap={8} width="100%" overflow="auto" borderRadius={32}>
                <NavTab name={t('navigation.perpetual')} index path={PathPatterns.Perpetuals} />
                <NavTab name={t('navigation.dual')} path={PathPatterns.DualsDashboard} />
                <NavTab
                  name={t('navigation.swap')}
                  path={PathPatterns.Swap}
                  onClick={() => {
                    tour?.cancel();
                  }}
                />
                {!isSmallMobileScreen && (
                  <NavTab
                    name={t('navigation.buy')}
                    path={PathPatterns.Buy}
                    onClick={() => {
                      tour?.cancel();
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>,
          document.body,
        )
      ) : (
        <Box className={styles['nav-tabs']}>
          <NavTab name={t('navigation.perpetual')} index path={PathPatterns.Perpetuals} />
          <NavTab name={t('navigation.dual')} path={PathPatterns.DualsDashboard} />
          <NavTab
            name={t('navigation.swap')}
            path={PathPatterns.Swap}
            onClick={() => {
              tour?.cancel();
            }}
          />
          <NavTab
            name={t('navigation.buy')}
            path={PathPatterns.Buy}
            onClick={() => {
              tour?.cancel();
            }}
          />
        </Box>
      )}
    </>
  );
};

interface NavTabProps {
  index?: boolean;
  name: string;
  onClick?: () => void;
  path: string;
}

const NavTab: FC<NavTabProps> = ({ name, path, index = false, onClick }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isMobileScreen = useMediaQuery('(max-width: 767px)');

  return (
    <Box
      className={styles['nav-tab-item']}
      backgroundColor={isMobileScreen ? 'background-01' : undefined}
      borderRadius={40}
      onClick={() => {
        if (onClick) onClick();
        navigate(path);
      }}
    >
      <Text
        text={!isMobileScreen ? 'app-16-medium' : 'app-14-medium'}
        color={
          (index && pathname === path) || (!index && pathname.includes(path))
            ? 'primary-01'
            : isMobileScreen
            ? 'secondary-03'
            : 'gray-01'
        }
      >
        {name}
      </Text>
    </Box>
  );
};
