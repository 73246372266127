import { TokensState } from 'entities/Token/model/types';

import { CHAIN_INFO } from 'shared/config/chains/index';

import { formatAsset } from '../lib/providers';

import { DualAssetType, DualAssetsResponse } from './dual';

type Args = { assets: DualAssetsResponse; tokens: Record<string, TokensState> };

export const mapResponseAssetsToAssets = ({ assets, tokens }: Args) =>
  assets.reduce((acc, { apr, baseToken, quoteToken, chainId }) => {
    const baseTicker = tokens[chainId]?.[baseToken]?.symbol?.toLowerCase() || '';
    const quoteTicker = tokens[chainId]?.[quoteToken]?.symbol?.toLowerCase() || '';

    if (!baseTicker || !quoteTicker) return acc;

    const asset = {
      apr: Number(apr),
      assetId: `${baseTicker}/${quoteTicker}`,
      baseTicker,
      quoteTicker,
      chainId,
    };

    const newAsset = formatAsset(asset, CHAIN_INFO[chainId]);

    if (newAsset) {
      acc.push(newAsset);
    }

    acc.push(asset);

    return acc;
  }, [] as DualAssetType[]);
