import useResizeObserver from '@react-hook/resize-observer';
import * as d3 from 'd3';
import { path } from 'd3-path';
import { FC, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from 'shared/ui';

import { Tag } from './Tag';

const PRICE_BOX_WIDTH_MIN = 80;
const PRICE_BOX_HEIGHT = 16;
const PERIOD_BOX_WIDTH = 120;

export enum InfographicDirection {
  DOWN = 'DOWN',
  UP = 'UP',
}

export interface InfographicProps {
  direction: InfographicDirection;
  period: number;
}

export const Infographic: FC<InfographicProps> = ({ direction, period }) => {
  const { t } = useTranslation();

  const boxRef = useRef(null);

  const [{ height: svgSizeHeight, width: svgSizeWidth }, setSvgSize] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, 300])
        .range([0, svgSizeWidth - PRICE_BOX_WIDTH_MIN]),
    [svgSizeWidth],
  );
  const yScale = useMemo(() => d3.scaleLinear().domain([0, 136]).range([0, svgSizeHeight]), [svgSizeHeight]);

  const delimeterLeftLine = useMemo(() => {
    const p = path();

    p.moveTo(xScale(64), yScale(138));
    p.lineTo(xScale(64), yScale(-2));

    return p.toString();
  }, [xScale, yScale]);
  const delimeterRightLine = useMemo(() => {
    const p = path();

    p.moveTo(xScale(254), yScale(138));
    p.lineTo(xScale(254), yScale(-2));

    return p.toString();
  }, [xScale, yScale]);

  const dualLine = useMemo(() => {
    const p = path();

    if (direction === InfographicDirection.UP) {
      p.moveTo(xScale(254), yScale(32));
      p.bezierCurveTo(
        xScale(213.29),
        yScale(27.518),
        xScale(202.187),
        yScale(54.4167),
        xScale(182.579),
        yScale(70.0877),
      );
      p.bezierCurveTo(
        xScale(162.971),
        yScale(85.7587),
        xScale(146.166),
        yScale(88.4292),
        xScale(126.5),
        yScale(80.9998),
      );
      p.bezierCurveTo(xScale(108.5), yScale(74.1996), xScale(105), yScale(62.5002), xScale(87.5), yScale(61.5));
      p.bezierCurveTo(xScale(62.5407), yScale(60.0735), xScale(55), yScale(80.9998), xScale(42), yScale(80.9998));
      p.bezierCurveTo(xScale(23.5), yScale(80.9998), xScale(13.5), yScale(52.4998), xScale(-4), yScale(52.4998));
    } else {
      p.moveTo(xScale(254), yScale(104.468));
      p.bezierCurveTo(
        xScale(213.29),
        yScale(108.95),
        xScale(202.187),
        yScale(82.0511),
        xScale(182.579),
        yScale(66.3801),
      );
      p.bezierCurveTo(
        xScale(162.971),
        yScale(50.7091),
        xScale(146.166),
        yScale(48.0385),
        xScale(126.5),
        yScale(55.468),
      );
      p.bezierCurveTo(xScale(108.5), yScale(62.2682), xScale(105), yScale(73.9676), xScale(87.5), yScale(74.9678));
      p.bezierCurveTo(xScale(62.5407), yScale(76.3943), xScale(55), yScale(55.468), xScale(42), yScale(55.468));
      p.bezierCurveTo(xScale(23.5), yScale(55.468), xScale(13.5), yScale(83.968), xScale(-4), yScale(83.968));
    }

    return p.toString();
  }, [direction, xScale, yScale]);
  const dualLineFuture = useMemo(() => {
    const p = path();

    if (direction === InfographicDirection.UP) {
      p.moveTo(xScale(254), yScale(31.5));
      p.lineTo(xScale(300), yScale(31.5));
      p.lineTo(xScale(300), yScale(32));
      p.lineTo(xScale(254), yScale(32));
      p.lineTo(xScale(254), yScale(31.5));
    } else {
      p.moveTo(xScale(254), yScale(103.5));
      p.lineTo(xScale(300), yScale(103.5));
      p.lineTo(xScale(300), yScale(104));
      p.lineTo(xScale(254), yScale(104));
      p.lineTo(xScale(254), yScale(103.5));
    }

    p.closePath();

    return p.toString();
  }, [direction, xScale, yScale]);

  const initialPriceLinePast = useMemo(() => {
    const p = path();

    p.moveTo(xScale(0), yScale(67.5));
    p.lineTo(xScale(64), yScale(67.5));
    p.lineTo(xScale(64), yScale(68));
    p.lineTo(xScale(0), yScale(68));
    p.lineTo(xScale(0), yScale(67.5));
    p.closePath();

    return p.toString();
  }, [xScale, yScale]);
  const initialPriceLine = useMemo(() => {
    const p = path();

    p.moveTo(xScale(64), yScale(67.5));
    p.lineTo(xScale(254), yScale(67.5));
    p.lineTo(xScale(254), yScale(68));
    p.lineTo(xScale(64), yScale(68));
    p.lineTo(xScale(64), yScale(67.5));
    p.closePath();

    return p.toString();
  }, [xScale, yScale]);
  const initialPriceLineFuture = useMemo(() => {
    const p = path();

    p.moveTo(xScale(254), yScale(67.5));
    p.lineTo(xScale(300), yScale(67.5));
    p.lineTo(xScale(300), yScale(68));
    p.lineTo(xScale(254), yScale(68));
    p.lineTo(xScale(254), yScale(67.5));
    p.closePath();

    return p.toString();
  }, [xScale, yScale]);

  useResizeObserver(boxRef, ({ contentRect }) => {
    const { height, width } = contentRect;
    setSvgSize({ height, width });
  });

  return (
    <Box ref={boxRef} width="100%" height={136} position="relative">
      <svg
        width={svgSizeWidth}
        height={svgSizeHeight}
        viewBox={`0 0 ${svgSizeWidth} ${svgSizeHeight}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_522_7240)">
          <path
            d={delimeterLeftLine}
            stroke="url(#paint0_linear_522_7240)"
            strokeLinecap="round"
            strokeDasharray="2 3"
          />
          <path
            d={delimeterRightLine}
            stroke="url(#paint1_linear_522_7240)"
            strokeLinecap="round"
            strokeDasharray="2 3"
          />
          <g>
            <path d={dualLine} stroke="#05F283" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <g clipPath={`url(#clip1_522_7240-${direction})`}>
            <path d={dualLineFuture} fill="#05F283" />
          </g>
          <g clipPath="url(#clip2_522_7240)">
            <path opacity="0.4" d={initialPriceLinePast} fill="#864DF7" />
          </g>
          <g clipPath="url(#clip3_522_7240)">
            <path d={initialPriceLine} fill="white" />
          </g>
          <g clipPath="url(#clip4_522_7240)">
            <path d={initialPriceLineFuture} fill="white" />
          </g>
          <circle cx={xScale(254)} cy={yScale(direction === InfographicDirection.UP ? 32 : 104)} r="3" fill="#05F283" />
          <circle cx={xScale(64)} cy={yScale(68)} r="3" fill="white" />
          <circle cx={xScale(254)} cy={yScale(68)} r="3" fill="white" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_522_7240"
            x1={xScale(63.5)}
            y1={yScale(138)}
            x2={xScale(63.5)}
            y2={yScale(-2)}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#632BDC" stopOpacity="0" />
            <stop offset="0.5" stopColor="#632BDC" />
            <stop offset="1" stopColor="#632BDC" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_522_7240"
            x1={xScale(253.5)}
            y1={yScale(138)}
            x2={xScale(253.5)}
            y2={yScale(-2)}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#632BDC" stopOpacity="0" />
            <stop offset="0.5" stopColor="#632BDC" />
            <stop offset="1" stopColor="#632BDC" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip1_522_7240-up">
            <rect
              width={Math.abs(xScale(136))}
              height={yScale(1)}
              fill="white"
              transform={`translate(${xScale(254)} ${yScale(31.5)})`}
            />
          </clipPath>
          <clipPath id="clip1_522_7240-down">
            <rect
              width={Math.abs(xScale(136))}
              height={yScale(1)}
              fill="white"
              transform={`translate(${xScale(254)} ${yScale(103.5)})`}
            />
          </clipPath>
          <clipPath id="clip2_522_7240">
            <rect
              width={Math.abs(xScale(64))}
              height={yScale(1)}
              fill="white"
              transform={`translate(${0} ${yScale(67.5)})`}
            />
          </clipPath>
          <clipPath id="clip3_522_7240">
            <rect
              width={Math.abs(xScale(190))}
              height={yScale(1)}
              fill="white"
              transform={`translate(${xScale(64)} ${yScale(67.5)})`}
            />
          </clipPath>
          <clipPath id="clip4_522_7240">
            <rect
              width={Math.abs(xScale(136))}
              height={yScale(1)}
              fill="white"
              transform={`translate(${xScale(254)} ${yScale(67.5)})`}
            />
          </clipPath>
        </defs>
      </svg>
      <Box position="absolute" top={yScale(67.5 - PRICE_BOX_HEIGHT / 2)} right={0}>
        <Tag>{t('charts.entryPrice')}</Tag>
      </Box>
      <Box
        position="absolute"
        top={yScale((direction === InfographicDirection.UP ? 31.5 : 103.5) - PRICE_BOX_HEIGHT / 2)}
        right={0}
      >
        <Tag variant="primary">{t('charts.closePrice')}</Tag>
      </Box>
      <Box
        alignItems="center"
        position="absolute"
        width={PERIOD_BOX_WIDTH}
        bottom={yScale(8)}
        left={xScale(159 - PERIOD_BOX_WIDTH / 2)}
      >
        <Text text="app-12-medium" color="secondary-03">
          {t('charts.period')}: {t('common.hoursWithCount', { count: period })}
        </Text>
      </Box>
    </Box>
  );
};
