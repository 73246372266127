import { Tooltip } from '@mui/material';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import React from 'react';

import styles from './index.module.css';

interface TooltipProps {
  content: string;
  isPermit: boolean;
}

export const ApproveTooltip: React.FC<TooltipProps> = React.memo(({ content, isPermit }) => {
  const onOpenChange = () => {
    if (isPermit) track(PredefinedEvents.Dual.Permit.Tooltip());
    else track(PredefinedEvents.Dual.Approve.Tooltip());
  };

  return (
    <Tooltip title={content} arrow placement="top" enterTouchDelay={0} onOpen={onOpenChange}>
      <div className={styles.trigger} onClick={(event) => event.stopPropagation()}>
        ?
      </div>
    </Tooltip>
  );
});
