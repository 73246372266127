import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { PerpetualsWidget } from 'widgets/Perpetuals';

import { PUBLIC_URL } from 'shared/config';
import { PathPatterns } from 'shared/lib';

export const Perpetuals = React.memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('perpetuals.metaTitle')}>
        <link rel="canonical" href={`${PUBLIC_URL}${PathPatterns.Perpetuals}`} />
      </Helmet>

      <PerpetualsWidget />
    </>
  );
});
