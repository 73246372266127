import { useMemo } from 'react';

import { useSwapForm } from 'entities/Swap/lib';

import { DEFAULT_SLIPPAGE_VALUE, HIGH_SLIPPAGE_BOUND } from 'shared/config';

export const useSwapSlippage = () => {
  const { form, formValues } = useSwapForm();

  const isValidSlippage =
    Number.isFinite(parseFloat(formValues.slippage)) && !Number.isNaN(parseFloat(formValues.slippage));

  const slippageDecimal = isValidSlippage ? parseFloat(formValues.slippage!) : DEFAULT_SLIPPAGE_VALUE;
  const slippagePercent = (slippageDecimal * 100).toFixed(2);

  const isCustomSlippage = formValues.slippage !== '';
  const isHighSlippage = slippageDecimal > HIGH_SLIPPAGE_BOUND;

  const setSlippage = (decimal: string) => form.setValue('slippage', decimal);

  const result = useMemo(
    () => ({
      decimal: slippageDecimal,
      percent: slippagePercent,
      isCustomSlippage,
      isHighSlippage,
      isValidSlippage,
      setSlippage,
    }),
    [slippageDecimal, slippagePercent, isCustomSlippage, isHighSlippage, isValidSlippage, setSlippage],
  );

  return result;
};
