import { requester } from 'shared/api';

import type {
  UpdateLimitArgs,
  CreateLimitArgs,
  LimitDual,
  DeleteLimitArgs,
  LimitRequestArgs,
  LimitDualsResponse,
} from '../model/types';

export const createLimitDual = ({ signature, chainId, ...body }: CreateLimitArgs) =>
  requester.post<LimitDual>(`/api/v2/duals/${chainId}/limits`, body, {
    headers: {
      Authorization: signature,
    },
  });

export const updateLimitDual = ({ chainId, id, signature, ...body }: UpdateLimitArgs) =>
  requester.put<LimitDual>(`/api/v2/duals/${chainId}/limits/${id}`, body, {
    headers: {
      Authorization: signature,
    },
  });

export const deleteLimitDual = ({ chainId, id, signature }: DeleteLimitArgs) =>
  requester.delete<{}>(`/api/v2/duals/${chainId}/limits/${id}`, {
    headers: {
      Authorization: signature,
    },
  });

export const getOpenedLimitDuals = ({ address, chainId }: LimitRequestArgs) =>
  requester.get<LimitDualsResponse>(`/api/v2/duals/${chainId}/limits/${address}/opened`).then((res) => res.data);

export const getClosedLimitDuals = ({ address, chainId }: LimitRequestArgs) =>
  requester.get<LimitDualsResponse>(`/api/v2/duals/${chainId}/limits/${address}/closed`).then((res) => res.data);
