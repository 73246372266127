import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAccount } from 'shared/hooks';

import { authCheck } from '../api/queries';

export const useAuthCheckQuery = (options: UseQueryOptions<undefined, AxiosError, undefined, string[]>) => {
  const { address } = useAccount();

  return useQuery<undefined, AxiosError, undefined, string[]>(['auth-me'], () => authCheck(), {
    enabled: !!address,
    ...options,
  });
};
