import { useAccount } from 'wagmi';

import { useSignAuthMessage } from 'features/Auth';

import { CHAIN_INFO } from 'shared/config/chains';

import { CreateLimitArgs } from '../model/types';
import { useCreateLimitDualMutation } from '../model/useCreateLimitDualMutation';

export const useCreateLimitDual = () => {
  const { mutateAsync: create, ...rest } = useCreateLimitDualMutation();

  const { getSignature } = useSignAuthMessage();

  const { address } = useAccount();
  const createLimit = async (args: Omit<CreateLimitArgs, 'authSignature' | 'signature'>) => {
    const { chainId } = args;

    const network = CHAIN_INFO[chainId];

    if (!address) {
      return Promise.reject(new Error('not address'));
    }
    if (!network?.router?.address) {
      return Promise.reject(new Error('not supported limit dual'));
    }

    const signature = await getSignature();

    if (!signature) {
      return Promise.reject(new Error('Empty signature'));
    }

    return create({
      signature,
      ...args,
    });
  };

  return {
    createLimit,
    ...rest,
  };
};
