import React, { useCallback, FC } from 'react';

import { Box } from 'shared/ui/Box';

import { LanguageMenu } from '../LanguageMenu';
import { NetworkMenu } from '../NetworkMenu';

import { MainMenuAccount } from './MainMenuAccount';
import { MainMenuLanguage } from './MainMenuLanguage';
import { MainMenuNetwork } from './MainMenuNetwork';

export type Step = 'language' | 'network' | 'root';

interface Props {
  onChangeStep: (step: Step) => void;
  step: Step;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainMenu: React.FC<Props> = ({ onChangeStep, step, toggle }) => {
  if (step === 'network') {
    return <NetworkMenu onPressBack={() => onChangeStep('root')} toggle={toggle} />;
  }

  if (step === 'language') {
    return <LanguageMenu onPressBack={() => onChangeStep('root')} toggle={toggle} />;
  }

  return <MainMenuRoot onChangeStep={onChangeStep} />;
};

interface MainMenuRootProps {
  onChangeStep: (step: Step) => void;
}

const MainMenuRoot: FC<MainMenuRootProps> = ({ onChangeStep }) => {
  const handlePressNetwork = useCallback(() => {
    onChangeStep('network');
  }, [onChangeStep]);

  const handlePressLanguage = useCallback(() => {
    onChangeStep('language');
  }, [onChangeStep]);

  return (
    <Box>
      <MainMenuAccount />
      <MainMenuNetwork onPress={handlePressNetwork} />
      <MainMenuLanguage onPress={handlePressLanguage} />
    </Box>
  );
};
