import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { checkSupportedChain } from 'shared/config/chains';
import { useNetwork } from 'shared/hooks/network';

import { getDualTokenList } from '../api/queries';

export const usePrefetchTokens = () => {
  const queryClient = useQueryClient();

  const { switchingTo, wagmi } = useNetwork();

  const chainId = wagmi.chain?.id;

  useEffect(() => {
    const isSupportedChain = checkSupportedChain(Number(switchingTo));
    if (switchingTo && switchingTo !== chainId && isSupportedChain) {
      queryClient.prefetchQuery([`tokens-${switchingTo}`], () => getDualTokenList(switchingTo!));
    }
  }, [switchingTo, queryClient, chainId]);
};
