import { useState } from 'react';
import { parseUnits } from 'viem';
import { useContractWrite, usePrepareContractWrite, usePublicClient } from 'wagmi';

import { DualTariff, getTariffId } from 'entities/Dual';
import { useGetDualTariffQuery } from 'entities/Dual/model/hooks/useGetDualTariffQuery';
import { useGetDualTokenById } from 'entities/Token';
import { useGetDualTokenAddress } from 'entities/Token/model/useGetDualTokenAddress';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { calculateGasMargin } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { ContractTariff } from 'shared/types';

import { useAllowance } from './useAllowance';

type CreateArgs = {
  chainId: number;
  enabled?: boolean;
  inputAmount: number | string;
  inputTicker: string;
  isDisabledPermit?: boolean;
  tariff: DualTariff;
};

export const useCreate = ({
  enabled = true,
  inputAmount = 0,
  inputTicker = '',
  tariff: dualTariff,
  isDisabledPermit,
  chainId,
}: CreateArgs) => {
  const publicClient = usePublicClient();

  const realTariffId = getTariffId(dualTariff?.id || '');

  const baseToken = useGetDualTokenAddress(dualTariff?.baseTicker, chainId)!;
  const quoteToken = useGetDualTokenAddress(dualTariff?.quoteTicker, chainId)!;

  const tariff = useGetDualTariffQuery({ baseToken, quoteToken, chainId })?.data?.find((t) => t.id === realTariffId);

  const { address } = useAccount();
  const { network: currentNetwork, networkReady } = useNetwork();
  const inputToken = useGetDualTokenById(inputTicker);

  const { isSupportPermit, needApprove } = useAllowance({ inputAmount, inputTicker, isDisabledPermit });

  const isCreateWithPermit = isSupportPermit && needApprove;
  const prepareEnabled =
    enabled &&
    !!inputAmount &&
    networkReady &&
    chainId === currentNetwork?.id &&
    !!address &&
    !needApprove &&
    !!tariff &&
    !isCreateWithPermit;

  const tariffForContract = {
    chainId: BigInt(currentNetwork?.id || 1),
    ...(tariff && {
      baseToken: tariff.baseToken,
      expireAt: BigInt(tariff.expireAt),
      maxBaseAmount: BigInt(tariff.maxBaseAmount),
      maxQuoteAmount: BigInt(tariff.maxQuoteAmount),
      minBaseAmount: BigInt(tariff.minBaseAmount),
      minQuoteAmount: BigInt(tariff.minQuoteAmount),
      quoteToken: tariff.quoteToken,
      stakingPeriod: BigInt(tariff.stakingPeriod),
      thresholdBaseAmount: BigInt(tariff.thresholdBaseAmount),
      thresholdQuoteAmount: BigInt(tariff.thresholdQuoteAmount),
      user: tariff.user,
      yield: BigInt(tariff.yield),
    }),
  } as ContractTariff;

  const [gasForCreate, setGasForCreate] = useState<bigint | undefined>();

  const tokenPrepareArgs = {
    abi: currentNetwork?.router?.abi!,
    address: currentNetwork?.router?.address!,
    account: address!,
    args: [
      tariffForContract,
      {
        amount: parseUnits(inputAmount as `${number}`, inputToken?.decimals || 18),
        token: inputToken?.address!,
        user: address!,
      },
      tariff?.signature!,
    ],
    functionName: 'create',
    cacheTime: 0,
  } as const;

  const tokenPrepare = usePrepareContractWrite({
    ...tokenPrepareArgs,
    chainId: currentNetwork?.id,
    enabled: currentNetwork?.id === chainId && !!inputToken?.address && prepareEnabled,
    scopeKey: `create::${currentNetwork?.id}`,

    onSuccess: async (data) => {
      if (data.request.gas) {
        return setGasForCreate(calculateGasMargin(data.request.gas));
      }
      try {
        const gasLimit = await publicClient.estimateContractGas(tokenPrepareArgs);
        const gas = calculateGasMargin(gasLimit);
        setGasForCreate(gas);
      } catch (e) {
        setGasForCreate(undefined);
        logger.error(e);
      }
    },
  });

  const [gasForNativeCreate, setGasForNativeCreate] = useState<bigint | undefined>();

  const nativeTokenPrepareArgs = {
    account: address!,
    abi: currentNetwork?.router?.abi!,
    address: currentNetwork?.router?.address!,
    args: [tariffForContract, tariff?.signature!],
    functionName: 'createETH',
    value: parseUnits(inputAmount as `${number}`, currentNetwork?.nativeCurrency?.decimals || 18),
    cacheTime: 0,
  } as const;

  const nativePrepare = usePrepareContractWrite({
    ...nativeTokenPrepareArgs,
    chainId: currentNetwork?.id,
    enabled: currentNetwork?.id === chainId && !inputToken?.address && prepareEnabled,
    scopeKey: `createETH::${currentNetwork?.id}`,

    onSuccess: async (data) => {
      if (data.request.gas) {
        return setGasForCreate(calculateGasMargin(data.request.gas));
      }
      try {
        const gasLimit = await publicClient.estimateContractGas(nativeTokenPrepareArgs);
        const gas = calculateGasMargin(gasLimit);
        setGasForNativeCreate(gas);
      } catch (e) {
        setGasForCreate(undefined);
        logger.error(e);
      }
    },
  });

  const { config: createConfig } = tokenPrepare;
  const tokenWrite = useContractWrite(
    createConfig.request ? { ...createConfig, request: { ...createConfig.request, gas: gasForCreate } } : createConfig,
  );

  const { config: createNativeConfig } = nativePrepare;
  const nativeWrite = useContractWrite(
    createNativeConfig.request
      ? { ...createNativeConfig, request: { ...createNativeConfig.request, gas: gasForNativeCreate } }
      : createNativeConfig,
  );

  if (inputToken?.address) {
    return {
      isPreparing: tokenPrepare.isFetching,
      prepare: { ...tokenPrepare, error: needApprove ? null : tokenPrepare.error },
      write: tokenWrite,
    };
  }

  return {
    isPreparing: nativePrepare.isFetching,
    prepare: { ...nativePrepare, error: needApprove ? null : nativePrepare.error },
    write: nativeWrite,
  };
};
