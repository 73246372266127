import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSignAuthMessage } from 'features/Auth';

import { useClosePerpetualPositionMutation } from 'entities/Perpetual/model/hooks';

import { DEFAULT_ERROR } from 'shared/lib';
import { notifyError } from 'shared/lib/notifications';

const ERROR_POSITION_NOT_FOUND = 'Position not found';

export const usePerpetualClosePosition = ({ id, onSuccess }: { id: string; onSuccess: () => void }) => {
  const { t } = useTranslation();

  const { mutateAsync, ...rest } = useClosePerpetualPositionMutation();

  const { getSignature } = useSignAuthMessage();

  const close = useCallback(async () => {
    try {
      const signature = await getSignature();

      if (!signature) return;

      await mutateAsync({
        id,
        signature,
      });

      onSuccess();
    } catch (e: any) {
      if (e?.response?.data?._error?.message === ERROR_POSITION_NOT_FOUND) {
        notifyError({ text: 'Position not found' });
        return;
      }

      notifyError({ text: DEFAULT_ERROR });
    }
  }, [mutateAsync, getSignature]);

  return { close, ...rest };
};
