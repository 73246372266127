import { useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSwapSlippage } from 'widgets/Swap/lib/useSwapSlippage';

import { SettingsIcon } from 'shared/assets';
import { useIsSmallMobile } from 'shared/hooks';
import { Box, Text } from 'shared/ui';

interface Props {
  onClick?: () => void;
  opened: boolean;
}

export const SwapSettingsButton: FC<Props> = ({ opened, onClick }) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);

  const { percent: slippagePercent, isCustomSlippage, isHighSlippage, isValidSlippage } = useSwapSlippage();

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  const isSmallMobile = useIsSmallMobile();

  return (
    <Box
      style={{ cursor: hovered ? 'pointer' : 'default' }}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap="8px"
      padding={8}
      borderRadius={40}
      bg={isHighSlippage ? 'overlay-01' : 'background-01'}
      borderColor={
        !isSmallMobile && isCustomSlippage && isValidSlippage && !isHighSlippage
          ? 'secondary-02'
          : isHighSlippage
          ? 'overlay-01'
          : 'background-01'
      }
      sx={{ borderWidth: 1 }}
      color={hovered ? 'secondary-01' : isHighSlippage ? 'white-01' : opened ? 'secondary-01' : 'white-01'}
      onClick={onClick || (() => {})}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!isSmallMobile && isCustomSlippage && isValidSlippage && (
        <Text color={isHighSlippage ? 'orange-01' : 'white-01'} style={{ opacity: isHighSlippage ? 1 : 0.4 }}>
          {isWideMobile
            ? `${slippagePercent}%`
            : t('swap.settings.maxSlippage.customValue', { slippage: slippagePercent })}
        </Text>
      )}
      <SettingsIcon width={24} height={24} />
    </Box>
  );
};
