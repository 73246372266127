import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { arbitrum } from 'viem/chains';

import { linea } from 'shared/config/chains/linea';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

import { getNomisScore } from '../api/queries';

import { NomisResponse } from './types';

export const useNomisScore = (options: UseQueryOptions<NomisResponse, AxiosError, NomisResponse, string[]>) => {
  const { address } = useAccount();
  const { selectedChainId, network } = useNetwork();

  return useQuery<NomisResponse, AxiosError, NomisResponse, string[]>(
    [`nomis-score-${selectedChainId}-${address}`],
    () => getNomisScore({ chainId: selectedChainId, address: address! }),
    {
      enabled:
        !!address && !!selectedChainId && (network?.network === linea.network || network?.network === arbitrum.network),
      ...options,
    },
  );
};
