import { TabsContextValue, TabUnstyledProps, useTabContext } from '@mui/base';
import React, { FC, memo } from 'react';

import { Text } from '../Text';

import { StyledTab } from './styled';

type Props = TabUnstyledProps & {
  children?: React.ReactNode;
  label: string;
};

export const Tab: FC<Props> = memo(({ children, label, value: tabValue, ...props }) => {
  const { value } = useTabContext() as TabsContextValue;
  return (
    <StyledTab {...props} value={tabValue}>
      <Text color={value === tabValue ? 'white-01' : 'secondary-03'} className="tab-text">
        {label}
      </Text>
      {children}
    </StyledTab>
  );
});
