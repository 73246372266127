import { useTheme } from '@mui/material';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isAddress } from 'viem';

import { HintIcon, LimitOrder } from 'shared/assets';
import { i18n } from 'shared/locales';
import { Box, FormControlSwitch, Text, Tooltip, ButtonIcon, Input } from 'shared/ui';

import { StyledCard, StyledInputRoot } from './styled';

type ReceiveFieldProps = {
  enabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const validateAddress = {
  validate: async (value: string) => {
    if (!isAddress(value)) {
      return i18n.t('errors.invalidAddress');
    }

    return true;
  },
};

export const ReceiveField: FC<ReceiveFieldProps> = ({ enabled, onChange }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { control } = useFormContext<{ receiver: string }>();

  return (
    <StyledCard variant="secondary" isOutlined>
      <Box display="flex" justifyContent="center" overflow="hidden">
        <FormControlSwitch
          checked={enabled}
          onChange={onChange}
          label={
            <Box gap="8px" alignItems="center" flexDirection="row" flexShrink={1}>
              <LimitOrder width={24} height={20} color={enabled ? colors['primary-01'] : colors['secondary-03']} />
              <Text text="app-14-medium" color={enabled ? 'primary-01' : 'secondary-03'}>
                {t('receive.receiveToAnotherWallet')}
              </Text>
              <Tooltip title={t('receive.receiveToAnotherWalletTooltip')}>
                <Box>
                  <ButtonIcon isOutlined sx={{ padding: '2px' }}>
                    <HintIcon width={16} height={16} />
                  </ButtonIcon>
                </Box>
              </Tooltip>
            </Box>
          }
          justifyContent="space-between"
        />
      </Box>
      <StyledInputRoot $isHide={!enabled}>
        <Controller
          name="receiver"
          rules={enabled ? validateAddress : { validate: undefined }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              label={t('receive.receiver')}
              placeholder={t('enterWalletAddress')}
              isOutline
              error={error?.message}
              {...field}
              onChange={(e) => field.onChange(e.target.value.trim())}
            />
          )}
        />
      </StyledInputRoot>
    </StyledCard>
  );
};
