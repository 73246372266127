import React, { useCallback } from 'react';

import { CheckIcon } from 'shared/assets';
import { Box, Text } from 'shared/ui';

export interface LanguageMenuItemProps {
  active: boolean;
  changeLanguage: (lang: string) => void;
  lang: string;
  title: string;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LanguageMenuItem: React.FC<LanguageMenuItemProps> = ({ active, changeLanguage, lang, title, toggle }) => {
  const handlePress = useCallback(() => {
    changeLanguage(lang);
    toggle(false);
  }, [changeLanguage, lang, toggle]);

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      height={48}
      px={24}
      onClick={handlePress}
      color="secondary-03"
    >
      <Text color="white-01">{title}</Text>
      {active && <CheckIcon width={24} height={24} />}
    </Box>
  );
};
