/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query';

import { getRatesExtended } from '../api';

type RateArgs = {
  product?: 'perpetual';
  symbol?: `${string}/${string}` | null;
};

export const useRateExtended = ({ symbol, product }: RateArgs) => {
  const result = useQuery(
    [`rates-extended-${symbol}${product ? `-${product}` : ''}`],
    () => getRatesExtended({ symbols: symbol ? [symbol] : [], product }),
    {
      refetchInterval: 1000,
      enabled: !!symbol,
    },
  );

  return result;
};
