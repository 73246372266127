import { observer } from 'mobx-react-lite';

import { useGetClaimedDualsQuery } from 'entities/Dual';

import { Box, Spinner } from 'shared/ui';

import { Claimed } from '../Claimed';

export const HistoryDuals = observer(() => {
  const { error: error3, isLoading: isClaimedLoading } = useGetClaimedDualsQuery();
  const showSpinner = isClaimedLoading && !error3;

  if (showSpinner) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  return <Claimed />;
});
