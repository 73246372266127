import { useQueryClient } from '@tanstack/react-query';
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { STATUS_LIMIT, type LimitDual as LimitDualType } from 'features/LimitsDual';

import { DualChartCalculationRow, DualInputs, formatApr } from 'entities/Dual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, Text, Badge } from 'shared/ui';

import { CancelButton } from '../CancelButton';
import { LimitChart } from '../LimitChart';

type Props = {
  dual: LimitDualType;
};

export const LimitDual: FC<Props> = memo(({ dual }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { chainId } = useNetwork();
  const { address } = useAccount();

  const updateDuals = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [`opened-limit-${address}-${chainId}`],
    });
    queryClient.invalidateQueries({
      queryKey: [`count-duals-${chainId}-${address}`],
    });
  }, [address, chainId, queryClient]);

  return (
    <Box borderRadius={24} bg="secondary-02" p={16} pt={20}>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" mb={19}>
        <DualInputs dual={dual} pairBg="secondary-02" />
        <Box>
          <Badge>{formatApr(dual.apr)}</Badge>
        </Box>
      </Box>
      <DualChartCalculationRow row={dual.closeCalculate.up} active />
      <Box mx={-16} my={10}>
        <LimitChart dual={dual} cb={updateDuals} />
      </Box>
      <DualChartCalculationRow row={dual.closeCalculate.down} active />

      {dual.status === STATUS_LIMIT.OPEN && (
        <Box gap="16px" pt={24}>
          {/* <EditButton dual={dual} /> */}
          <CancelButton dual={dual} />
        </Box>
      )}

      {dual.status !== STATUS_LIMIT.OPEN && (
        <>
          <Box height="1px" mt="24px" bg="background-01" mx="-30px" />
          <Box p={8} mt={16}>
            <Text textAlign="center" color="secondary-01" text="app-14-medium">
              {dual.status === STATUS_LIMIT.CANCELED && t('dual.canceled')}
              {dual.status === STATUS_LIMIT.CLOSED && t('dual.closed')}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
});
