import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { PageNotFound } from 'pages/PageNotFound';

import { DualWidget } from 'widgets/Dual';

import { PUBLIC_URL } from 'shared/config';
import { PathPatterns } from 'shared/lib';

export const Dual = React.memo(() => {
  const { t } = useTranslation();

  const [isNotFound, setNotFound] = useState(false);

  return (
    <>
      {isNotFound ? (
        <PageNotFound />
      ) : (
        <>
          <Helmet title={t('dual.metaTitle')}>
            <link rel="canonical" href={`${PUBLIC_URL}${PathPatterns.Dual}`} />
          </Helmet>

          <DualWidget onNotFound={setNotFound} />
        </>
      )}
    </>
  );
});
