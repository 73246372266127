import React, { useState } from 'react';
import { Address } from 'viem';

import { useSwapTokenByAddress } from 'entities/Swap/lib/hooks';

import { Box, IconByUrl, Spinner, Text } from 'shared/ui';
import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

interface Props extends Omit<React.ComponentProps<typeof CurrencyIcon>, 'ticker'> {
  address: Address;
}

export const SwapTokenIcon: React.FC<Props> = (props) => {
  const [isError, setIsError] = useState(false);

  return (
    <>
      {props.url && !isError && <IconByUrl {...props} url={props.url} onError={() => setIsError(true)} />}
      {(!props.url || isError) && <SymbolIcon {...props} />}
    </>
  );
};

const SymbolIcon: React.FC<Props> = (props) => {
  const { address } = props;

  const { data } = useSwapTokenByAddress(address);

  return (
    <>
      {data && data.symbol && (
        <Box
          height={props.size}
          width={props.size}
          backgroundColor="secondary-01"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          borderRadius={40}
          {...props}
        >
          <Text style={{ fontSize: (props.size as number) / 2.8 }}>
            {data.symbol.replace(/[a-z]/g, '').substring(0, 3)}
          </Text>
        </Box>
      )}
      {!data && <Spinner size={props.size} />}
    </>
  );
};
