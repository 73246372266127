import { observer } from 'mobx-react-lite';
import React from 'react';

import { PendingDual, storagePendingDuals } from 'features/PendingDual';

import { DualType } from 'entities/Dual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

type Props = {
  data: DualType[] | undefined;
};

export const PendingDuals: React.FC<Props> = observer(({ data }) => {
  const { address } = useAccount();
  const { chainId } = useNetwork();

  const { get, setDuals } = storagePendingDuals;

  const duals = get(address!, chainId!);

  if (data === undefined) return null;

  const pendingDuals = duals.filter(
    (dual) =>
      !data?.find(({ id, l1TxHash }) => {
        if (id && dual.id) return id === dual.id;
        if (l1TxHash && dual.l1TxHash) return l1TxHash === dual.l1TxHash;
        return new Date().toISOString() > dual.finishAt;
      }),
  );

  if (address) setDuals(address, pendingDuals, chainId!);

  return (
    <>
      {pendingDuals.map((dual, index) => (
        <PendingDual dual={dual} key={dual.l1TxHash} mb={index === duals.length - 1 && !data.length ? 0 : 24} />
      ))}
    </>
  );
});
