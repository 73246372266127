import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import classNames from 'classnames';
import React, { memo, startTransition, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon, BackIcon } from 'shared/assets';
import { useDetectMobileDevice } from 'shared/hooks';
import { Box } from 'shared/ui';

import styles from './FilterAsset.module.scss';

type Props = {
  isActive: boolean;
  onSearch: React.Dispatch<React.SetStateAction<string>>;
  searchText: string;
  setDebouncedText: React.Dispatch<React.SetStateAction<string>>;
  setIsShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FilterAsset: React.CFC<Props> = memo(
  ({ children, isActive, onSearch, searchText, setDebouncedText, setIsShowSearch }) => {
    const { t } = useTranslation();
    const isMobileDevice = useDetectMobileDevice();

    const refInput = useRef<HTMLInputElement | null>(null);

    const handleToggle = () => {
      setIsShowSearch((p) => {
        const nextValue = !p;

        if (nextValue) {
          refInput?.current?.focus();
          track(PredefinedEvents.Dual.Asset.Search());
        } else {
          onSearch('');
          setDebouncedText('');
        }

        return nextValue;
      });
    };
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value.trimStart();
      onSearch(text);

      startTransition(() => {
        setDebouncedText(text);
      });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isActive) return;
      if (e.code === 'Escape') {
        handleToggle();
        e.stopPropagation();
      }
    };

    return (
      <Box className={styles.container}>
        <Box className={styles.inputContainer}>
          <Box
            className={classNames('animated', styles['icon-button'], {
              hoverable: !isMobileDevice,
              [styles._active]: isActive,
            })}
            onClick={handleToggle}
            zIndex={isActive ? 1 : 0}
            data-id="filter-asset"
          >
            {isActive ? <BackIcon width={24} height={24} /> : <SearchIcon width={24} height={24} />}
          </Box>
          <input
            className={classNames(styles.input, { [styles._active]: isActive })}
            ref={refInput}
            onChange={handleSearch}
            value={searchText}
            onKeyDown={handleKeyPress}
            placeholder={t('main.search.placeholder')}
            autoComplete="off"
          />
        </Box>
        <Box
          width="fit-content"
          alignItems="flex-end"
          height={40}
          zIndex={isActive ? -1 : 2}
          className={classNames({ [styles.hidden]: isActive })}
        >
          {children}
        </Box>
      </Box>
    );
  },
);
