import { useQuery } from '@tanstack/react-query';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

import { getReferralClaims } from '../api/queries';

export const useGetReferralClaimsQuery = () => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  return useQuery([`referral-claims-${address}`], () => getReferralClaims(address!), {
    enabled: !!address && !!chainId,
    staleTime: 30000,
  });
};
