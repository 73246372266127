import { Address } from 'viem';

import { ROUTER_ADDRESS } from 'shared/config';
import { useNetwork } from 'shared/hooks/network';

export const useSwapRouterAddress = () => {
  const { selectedChainId } = useNetwork();

  return ROUTER_ADDRESS[selectedChainId] as Address;
};
