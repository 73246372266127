import React from 'react';

import { Box } from 'shared/ui';

import { ReactComponent as Circle } from './assets/loader.svg';
import styles from './index.module.scss';

interface ChartSpinnerProps {
  size?: number;
}

export const Spinner: React.FC<ChartSpinnerProps> = () => (
  <Box width={80} height={80} padding={10}>
    <Circle className={styles.spinner} height={60} width={60} />
  </Box>
);
