import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { Address } from 'viem';

import { getSwapData } from 'entities/Swap/api';
import { useIsWETH } from 'entities/Swap/lib/hooks/useIsWETH';

import { SwapDataRequestArgs, SwapDataResponse } from '../data';

export const useGetSwapDataQuery = (
  args: SwapDataRequestArgs,
  options?: UseQueryOptions<SwapDataResponse, SwapDataRequestArgs>,
) => {
  const isWETH = useIsWETH(args.fromToken as Address, args.toToken as Address);

  const isAllowedToFetch = !!(
    args &&
    args.chainId &&
    args.address &&
    args.fromAmount &&
    BigNumber(args.fromAmount).isGreaterThan(0) &&
    args.fromToken &&
    args.toToken &&
    !isWETH
  );

  return useQuery<SwapDataResponse, SwapDataRequestArgs>(
    [`swap-data-${args.chainId}-${args.address}-${args.slippage}-${args.fromAmount}-${args.fromToken}-${args.toToken}`],
    () =>
      getSwapData({
        chainId: args.chainId,
        address: args.address,
        slippage: args.slippage,
        fromAmount: args.fromAmount,
        fromToken: args.fromToken,
        toToken: args.toToken,
      }),
    {
      ...options,
      enabled: typeof options?.enabled === 'undefined' ? !!isAllowedToFetch : options.enabled && !!isAllowedToFetch,
      cacheTime: 1000,
    },
  );
};
