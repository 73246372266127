import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { useDeferredValue } from 'react';
import { Address, TransactionReceipt, parseUnits } from 'viem';
import { waitForTransaction as waitForTransactionWagmi } from 'wagmi/actions';

import { useWETHContract } from 'features/SwapTokens';
import { useSwap } from 'features/SwapTokens/lib/useSwap';

import { SwapDataResponse, SwapFormValues } from 'entities/Swap';
import { useSwapQuote, useSwapTokenByAddress } from 'entities/Swap/lib/hooks';

import { DEFAULT_SLIPPAGE_VALUE } from 'shared/config';
import { useAccount } from 'shared/hooks';
import { DEFAULT_ERROR } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyError } from 'shared/lib/notifications';

import { useTrackParamsLazy } from './useTrackParamsLazy';

export const useSubmit = (
  data: SwapFormValues,
  {
    onSwapSubmit,
    onSwapSuccess,
    onSwapFail,
    onSwapStart,
  }: {
    onSwapFail: (id: string, hash?: string, error?: any) => void;
    onSwapStart: (id: string, data: SwapDataResponse) => void;
    onSwapSubmit: (id: string, hash: string, waitTx: Promise<TransactionReceipt>) => void;
    onSwapSuccess: (id: string, hash: string) => void;
  },
) => {
  const { address } = useAccount();
  const { fromAmount, fromToken, toToken, slippage } = data;

  const getTrackParams = useTrackParamsLazy(data);

  const { data: quote } = useSwapQuote();

  const { data: inputToken } = useSwapTokenByAddress(fromToken!);
  const { data: outputToken } = useSwapTokenByAddress(toToken!);

  const defferedInputAmount = useDeferredValue(
    fromAmount && inputToken ? parseUnits(fromAmount as `${number}`, inputToken.decimals).toString() : '',
  );

  const {
    isPreparing: isSwapPreparing,
    isError: isSwapPreparingError,
    write: swapWrite,
    data: swapData,
  } = useSwap({
    address: address!,
    fromAmount: defferedInputAmount,
    fromToken: fromToken!,
    toToken: toToken!,
    slippage: slippage || DEFAULT_SLIPPAGE_VALUE.toString(),
  });

  const {
    isWETH,
    isError: isWrapPreparingError,
    isPreparing: isWrapPreparing,
    write: wrapWrite,
  } = useWETHContract({
    address: address!,
    fromAmount: defferedInputAmount,
    fromToken: fromToken!,
    toToken: toToken!,
  });

  const swap = async (values: SwapFormValues) => {
    if (!swapData || !quote || !inputToken || !outputToken || !address) {
      notifyError({
        text: DEFAULT_ERROR,
      });
      const error = new Error(DEFAULT_ERROR);
      logger.error(error);

      return;
    }

    const trackParams = getTrackParams(values);

    track(PredefinedEvents.Swap.Start.Click(trackParams));

    if (!swapWrite?.sendTransactionAsync) {
      notifyError({
        text: DEFAULT_ERROR,
      });
      const error = new Error(DEFAULT_ERROR);
      logger.error(error);

      return;
    }

    const localId = crypto.randomUUID();
    let hash: Address | undefined;

    try {
      onSwapStart(localId, swapData);

      hash = (await swapWrite.sendTransactionAsync()).hash;

      track(PredefinedEvents.Swap.Start.Attempt(trackParams));

      const waitTx = waitForTransactionWagmi({ hash });

      onSwapSubmit(localId, hash, waitTx);

      await waitTx;

      track(PredefinedEvents.Swap.Start.Success(trackParams));
      onSwapSuccess(localId, hash);
    } catch (error: any) {
      onSwapFail(localId, hash, error);

      swapWrite.reset();
      track(PredefinedEvents.Swap.Start.Fail({ ...trackParams, error: error?.message ?? 'Unknown error' }));
      logger.error(error);
    }
  };

  const wrap = async (values: SwapFormValues) => {
    if (!inputToken || !outputToken || !address) {
      notifyError({
        text: DEFAULT_ERROR,
      });
      const error = new Error(DEFAULT_ERROR);
      logger.error(error);

      return;
    }

    const trackParams = getTrackParams(values);

    track(PredefinedEvents.Swap.Start.Click(trackParams));

    if (!wrapWrite?.writeAsync) {
      notifyError({
        text: DEFAULT_ERROR,
      });
      const error = new Error(DEFAULT_ERROR);
      logger.error(error);

      return;
    }

    const localId = crypto.randomUUID();
    let hash: Address | undefined;

    try {
      onSwapStart(localId, {
        data: '',
        fromAmount: defferedInputAmount,
        toAmount: defferedInputAmount,
        fromToken: fromToken as Address,
        toToken: toToken as Address,
        fromAmountUSD: '',
        toAmountUSD: '',
      });

      hash = (await wrapWrite.writeAsync()).hash;

      track(PredefinedEvents.Swap.Start.Attempt(trackParams));

      const waitTx = waitForTransactionWagmi({ hash });

      onSwapSubmit(localId, hash, waitTx);

      await waitTx;

      track(PredefinedEvents.Swap.Start.Success(trackParams));
      onSwapSuccess(localId, hash);
    } catch (error: any) {
      onSwapFail(localId, hash, error);

      wrapWrite.reset();
      track(PredefinedEvents.Swap.Start.Fail({ ...trackParams, error: error?.message ?? 'Unknown error' }));
      logger.error(error);
    }
  };

  return {
    isDisabled: false,
    isSubmitPreparingError: isWETH ? isWrapPreparingError : isSwapPreparingError,
    isSubmitPreparing: isWETH ? isWrapPreparing : isSwapPreparing,
    onSubmit: isWETH ? wrap : swap,
  };
};
