import { Skeleton } from '@mui/material';
import { formatAmount, formatPrice } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { PerpetualsNetValueTooltip } from 'entities/Perpetual';
import { usePerpetualClosedPositions } from 'entities/Perpetual/lib/hooks';
import { PerpetualClosedPosition } from 'entities/Perpetual/model';

import { DownIcon, UpIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { formatCurrency, formatPercent, formatWithCommas } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import './index.scss';

export const PerpetualsListsHistory = () => {
  const { t } = useTranslation();

  const { isConnected } = useAccount();

  const { data, isLoading } = usePerpetualClosedPositions();

  return (
    <Box className="perpetuals-lists-history">
      <Box className="perpetuals-lists-history-header">
        <Box className="perpetuals-lists-history-row">
          <Cell width="13%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.pair')}
            </Text>
          </Cell>
          <Cell width="18%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.amount')}
            </Text>
          </Cell>
          <Cell width="18%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.size')}
            </Text>
          </Cell>
          <Cell width="25%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.pnl')}
            </Text>
          </Cell>
          <Cell width="13%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.openPrice')}
            </Text>
          </Cell>
          <Cell width="13%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.closePrice')}
            </Text>
          </Cell>
          <Cell width="72px" />
        </Box>
      </Box>
      <Box className="perpetuals-lists-history-body">
        {isConnected && isLoading && (
          <Skeleton
            width={128}
            height={24}
            sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)', marginTop: 20 }}
            animation="wave"
          />
        )}
        {isConnected && !isLoading && data?.map((position) => <Item position={position} />)}
        {(!isConnected || (!isLoading && !data?.length)) && (
          <Box paddingTop={20}>
            <Text whiteSpace="nowrap">{t('perpetuals.lists.noHistory')}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const Item: React.FC<{ position: PerpetualClosedPosition }> = ({ position }) => {
  const size = BigNumber(position.inputAmount)
    .multipliedBy(position.leverage)
    .minus(position.openingFeeAmount ?? 0);

  const amount = BigNumber(position.inputAmount).minus(position.openingFeeAmount ?? 0);

  const diff = BigNumber(position.outputAmount).minus(amount);
  const diffPercent = diff.div(amount);

  const isPositive = diff.gt(0);
  const isNegative = diff.lt(0);

  return (
    <Box className="perpetuals-lists-history-row">
      <Cell>
        <Box className="perpetuals-lists-history-cell-token">
          <img alt="token" src={`/static/tickers/${position.baseTicker}.svg`} />
          <Text text="app-14-regular">
            {position.baseTicker.toUpperCase()}/{position.quoteTicker.toUpperCase()}
          </Text>
        </Box>
      </Cell>
      <Cell>
        <Box className="perpetuals-lists-history-cell-token">
          <Text text="app-14-regular">
            {formatWithCommas(
              formatAmount({ value: amount, symbol: position.inputTicker, roundingMode: BigNumber.ROUND_DOWN }),
            )}{' '}
            {position.inputTicker.toUpperCase()}
          </Text>
          <Box className="perpetuals-lists-history-cell-leverage">
            {!position.isShort && <UpIcon width={16} height={16} />}
            {position.isShort && <DownIcon width={16} height={16} />}
            <Text text="app-12-regular" color="gray-01">
              x{position.leverage}
            </Text>
          </Box>
        </Box>
      </Cell>
      <Cell>
        <Text text="app-14-regular">
          {formatWithCommas(
            formatAmount({ value: size, symbol: position.inputTicker, roundingMode: BigNumber.ROUND_DOWN }),
          )}{' '}
          {position.inputTicker.toUpperCase()}
        </Text>
      </Cell>
      <Cell>
        <Box className="perpetuals-lists-history-cell-pnl">
          <PerpetualsNetValueTooltip position={position}>
            <Text
              text="app-14-regular"
              whiteSpace="nowrap"
              className={cx({
                'perpetuals-lists-history-positive': isPositive,
                'perpetuals-lists-history-negative': isNegative,
              })}
            >{`${isPositive ? '+' : ''}${formatCurrency(diff.toNumber(), undefined, 2, 4).replace(
              '$',
              '',
            )} ${position.inputTicker.toUpperCase()} (${isPositive ? '+' : ''}${formatPercent({
              value: diffPercent,
              decimals: 2,
            })}%)`}</Text>
          </PerpetualsNetValueTooltip>
        </Box>
      </Cell>
      <Cell>
        <Text text="app-14-regular">
          {formatWithCommas(
            formatPrice({ value: position.initialPrice, from: position.baseTicker, to: position.quoteTicker }),
          )}
        </Text>
      </Cell>
      <Cell>
        <Text text="app-14-regular">
          {formatWithCommas(
            formatPrice({ value: position.closedPrice, from: position.baseTicker, to: position.quoteTicker }),
          )}
        </Text>
      </Cell>
    </Box>
  );
};

const Cell: React.CFC<{ className?: string; width?: string | number }> = ({ className, children, width }) => (
  <Box className="perpetuals-lists-history-cell" width={width}>
    <Box className={className}>{children}</Box>
  </Box>
);
