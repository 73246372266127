import { useQuery } from '@tanstack/react-query';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

import { getOpenedLimitDuals } from '../api/queries';
import { selectLimitDual } from '../lib/selectLimitDual';

export const useGetOpenedLimitDualsQuery = () => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  return useQuery(
    [`opened-limit-${address}-${chainId}`],
    () => getOpenedLimitDuals({ address: address!, chainId: chainId! }),
    {
      enabled: !!address && !!chainId,
      cacheTime: 60000,
      staleTime: 60000,
      refetchInterval: 5 * 60 * 1000,
      select: selectLimitDual,
    },
  );
};
