import { requester } from 'shared/api';

import { ClaimPointsArgs, PointsStats, PointsSettings, PointsLeaderboard } from '../model/types';

export const getPointsStats = (address: string) =>
  requester.get<PointsStats>(`/api/v1/points/${address}`).then((res) => res.data);

export const getPointsSettings = () => requester.get<PointsSettings>(`/api/v1/points/settings`).then((res) => res.data);

export const claimPoints = ({ signature, token, recaptcha, turnstile }: ClaimPointsArgs) =>
  requester.post(
    '/api/v1/points/claim',
    {
      recaptchaResponse: token,
      recaptchaVersion: recaptcha ? 2 : undefined,
      recaptchaType: turnstile ? 'turnstile' : undefined,
    },
    {
      headers: {
        Authorization: signature,
      },
    },
  );

export const getPointsLeaderboard = (type: 'total' | 'weekly') =>
  requester.get<PointsLeaderboard>(`/api/v1/points/leaderboard?type=${type}`).then((res) => res.data);
