import { Address } from 'viem';

import { arbitrum } from './chains/arbitrum';
import { avalanche } from './chains/avalanche';
import { base } from './chains/base';
import { bsc } from './chains/bsc';
import { bscTestnet } from './chains/bscTestnet';
import { ethereum } from './chains/ethereum';
import { linea } from './chains/linea';
import { manta } from './chains/manta';
import { optimism } from './chains/optimism';
import { polygon } from './chains/polygon';
import { polygonMumbai } from './chains/polygonMumbai';

export const NATIVE_TOKEN_ADDRESS: Address = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';

export const HIGH_SLIPPAGE_BOUND = 0.01;

export const DEFAULT_SLIPPAGE_VALUE = 0.005;

export const HIGH_PRICE_IMPACT_BOUND = 0.05;

export const USD_PRICE_IMPACT_BOUND = 10;

export const ROUNDING_THRESHOLD = 0.0001;
export const MAX_DECIMAL_ROUNDING = 18;

export const SHORT_ZEROS_BOUND = 4;

export const WETH = {
  [ethereum.id]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  [bsc.id]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  [bscTestnet.id]: '',
  [arbitrum.id]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  [polygon.id]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  [polygonMumbai.id]: '',
  [optimism.id]: '0x4200000000000000000000000000000000000006',
  [avalanche.id]: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  [base.id]: '0x4200000000000000000000000000000000000006',
  [manta.id]: '0x0dc808adce2099a9f62aa87d9670745aba741746',
  [linea.id]: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
};

export const PINNED_TOKENS = {
  [ethereum.id]: [
    {
      symbol: 'ETH',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'DAI',
      address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    },
    {
      symbol: 'USDC',
      address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    },
    {
      symbol: 'USDT',
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    },
    {
      symbol: 'WBTC',
      address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    },
    {
      symbol: 'WETH',
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    },
  ],
  [bsc.id]: [
    {
      symbol: 'BNB',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'DAI',
      address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    },
    {
      symbol: 'USDC',
      address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    {
      symbol: 'USDT',
      address: '0x55d398326f99059fF775485246999027B3197955',
    },
    {
      symbol: 'ETH',
      address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    {
      symbol: 'BTCB',
      address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    {
      symbol: 'BUSD',
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
  ],
  [bscTestnet.id]: [
    // {
    //   symbol: 'BNB',
    //   address: null,
    // },
    // {
    //   symbol: 'DAI',
    //   address: '',
    // },
    {
      symbol: 'BNB',
      address: NATIVE_TOKEN_ADDRESS,
    },
    // {
    //   symbol: 'USDC',
    //   address: '0x5c32f80319871dd2b9485322126972e5b5def2a4',
    // },
    // {
    //   symbol: 'USDT',
    //   address: '0x4469862eff2a34fc8df656886c01632877409d47',
    // },
    // {
    //   symbol: 'ETH',
    //   address: '0xac57521c0128201a28cd5e8c8226be17564dcb7a',
    // },
    // {
    //   symbol: 'BTCB',
    //   address: '0x6ef48ef768b21b6ccbea6491a5c85980b347c31b',
    // },
    // {
    //   symbol: 'BUSD',
    //   address: '0x2568d47917691c9881016dcababff10ba0ccb806',
    // },
  ],
  [arbitrum.id]: [
    {
      symbol: 'ETH',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'ARB',
      address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    },
    {
      symbol: 'DAI',
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    },
    {
      symbol: 'USDC',
      address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    },
    {
      symbol: 'USDT',
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    },
    {
      symbol: 'WBTC',
      address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    },
    {
      symbol: 'WETH',
      address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    },
  ],
  [polygon.id]: [
    {
      symbol: 'MATIC',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'WETH',
      address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    },
    {
      symbol: 'USDC',
      address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    },
    {
      symbol: 'DAI',
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    {
      symbol: 'USDT',
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    },
    {
      symbol: 'WBTC',
      address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    },
  ],
  [polygonMumbai.id]: [
    {
      symbol: 'MATIC',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'WETH',
      address: '',
    },
    {
      symbol: 'USDC',
      address: '',
    },
    {
      symbol: 'DAI',
      address: '',
    },
    {
      symbol: 'USDT',
      address: '',
    },
    {
      symbol: 'WBTC',
      address: '',
    },
  ],
  [optimism.id]: [
    {
      symbol: 'ETH',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'OP',
      address: '0x4200000000000000000000000000000000000042',
    },
    {
      symbol: 'DAI',
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    },
    {
      symbol: 'USDC',
      address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    },
    {
      symbol: 'USDT',
      address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    },
    {
      symbol: 'WBTC',
      address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    },
  ],
  [avalanche.id]: [
    {
      symbol: 'AVAX',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'DAI.e',
      address: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    },
    {
      symbol: 'USDC',
      address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    },
    {
      symbol: 'USDT',
      address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    },
    {
      symbol: 'WETH',
      address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    },
  ],
  [base.id]: [
    {
      symbol: 'ETH',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'WETH',
      address: '0x4200000000000000000000000000000000000006',
    },
    {
      symbol: 'USDC',
      address: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
    },
  ],
  [linea.id]: [
    {
      symbol: 'ETH',
      address: NATIVE_TOKEN_ADDRESS,
    },
    {
      symbol: 'WETH',
      address: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
    },
    {
      symbol: 'USDC.e',
      address: '0x176211869ca2b568f2a7d4ee941e073a821ee1ff',
    },
    {
      symbol: 'USDT',
      address: '0xa219439258ca9da29e9cc4ce5596924745e12b93',
    },
    {
      symbol: 'DAI',
      address: '0x4af15ec2a0bd43db75dd04e62faa3b8ef36b00d5',
    },
  ],
};

export const ROUTER_ADDRESS = {
  [ethereum.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [bsc.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [bscTestnet.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [arbitrum.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [polygon.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [polygonMumbai.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [optimism.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [avalanche.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [base.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
  [linea.id]: '0x6131b5fae19ea4f9d964eac0408e4408b66337b5',
};
