/* eslint-disable jsx-a11y/control-has-associated-label */
import { TooltipProps, tooltipClasses } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatSuffixNumber, useGetPointsLeaderboard } from 'entities/Points';

import { ParachuteIcon, QuestionIcon, TrophyIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { formatCurrency, shortenIfAddress } from 'shared/lib';
import { Box, Spinner, Tab, Tabs, Text, TextProps, Tooltip } from 'shared/ui';

import styles from './Leaderboard.module.scss';

enum PointsTab {
  AllTime = 'total',
  Weekly = 'weekly',
}

export const PointsLeaderboard = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(PointsTab.Weekly);

  const { data, isLoading, isError } = useGetPointsLeaderboard(tab);

  const isSmallMobile = useMediaQuery('(max-width: 425px)', { noSsr: false });

  const handleTabChange = (event: any, value: string | number | boolean) => {
    track(PredefinedEvents.Point.Tab.Click({ tab: value.toString() }));
    setTab(value.toString() as PointsTab);
  };

  return (
    <Box mt={16} gap={16}>
      <Box flexDirection="column" gap={10}>
        <Text text="app-22-medium" lineHeight="100%">
          {t('points.leaderboard.title')}
        </Text>
        <Text text="app-14-medium" opacity={0.6}>
          {t('points.leaderboard.description')}
        </Text>
      </Box>
      <Tabs style={{ width: '50%', minWidth: '260px' }} value={tab} onChange={handleTabChange}>
        <Tab label={t('points.leaderboard.tabs.weekly')} value={PointsTab.Weekly} />
        <Tab label={t('points.leaderboard.tabs.allTime')} value={PointsTab.AllTime} />
      </Tabs>
      <table className={styles['table-container']}>
        <thead>
          <tr>
            <th style={{ width: '16%' }} className={classnames(styles['table-head-cell'], styles['border-separated'])}>
              <Box flexDirection="row" alignItems="flex-end" gap="4px">
                <Text text="app-12-medium" color="gray-01">
                  {t('points.leaderboard.table.rank')}
                </Text>
              </Box>
            </th>
            <th
              style={{ width: isSmallMobile ? '35%' : '28%', paddingRight: '12px' }}
              className={classnames(styles['table-head-cell'], styles['border-separated'])}
            >
              <Box flexDirection="row" alignItems="flex-end" gap="4px">
                <Text text="app-12-medium" color="gray-01">
                  {t('points.leaderboard.table.address')}
                </Text>
              </Box>
            </th>
            <th style={{ width: '28%' }} className={classnames(styles['table-head-cell'], styles['border-separated'])}>
              <Box flexDirection="row" alignItems="flex-end" gap="4px">
                <Text text="app-12-medium" color="gray-01">
                  {t('points.leaderboard.table.earned')}
                </Text>
              </Box>
            </th>
            <th
              className={classnames(styles['table-head-cell'], styles['table-column-big'], styles['border-separated'])}
            >
              <Box flexDirection="row" alignItems="flex-end" gap="4px">
                <Tooltip
                  title={
                    tab === PointsTab.AllTime
                      ? t('points.leaderboard.table.allTimeHint')
                      : t('points.leaderboard.table.hint')
                  }
                >
                  <Box flexDirection="row" alignItems="flex-end" gap="4px">
                    <Text text="app-12-medium" color="gray-01">
                      {t('points.leaderboard.table.reward')}
                    </Text>
                    <Box width="max-content" marginBottom={-1}>
                      <QuestionIcon width={16} height={16} />
                    </Box>
                  </Box>
                </Tooltip>
              </Box>
            </th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            !isError &&
            data &&
            data.map((leaderboardItem, index) => <LeaderboardItem rank={index + 1} {...leaderboardItem} />)}
        </tbody>
      </table>
      {isLoading && (
        <Box height="25vh" flexDirection="row" alignItems="center" justifyContent="center">
          <Spinner />
        </Box>
      )}
      {data && data.length === 0 && (
        <div className={styles.noResults}>
          <Text text="app-16-medium" sx={{ opacity: 0.4 }}>
            {t('common.noResults')}
          </Text>
        </div>
      )}
    </Box>
  );
};

const MaxContentTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '100vw',
  },
});

const LeaderboardItem: React.FC<{ address: string; earned: number; rank: number; reward: number }> = ({
  address,
  earned,
  rank,
  reward,
}) => {
  const { address: connectedAddress } = useAccount();

  const isConnectedAddress = address === connectedAddress;

  return (
    <tr>
      <td style={{ paddingBottom: '12px', paddingTop: '12px' }}>
        <RankCircle rank={rank} />
      </td>
      <td>
        <Box flexDirection="column" justifyContent="center">
          <MaxContentTooltip title={address}>
            <Text color={isConnectedAddress ? 'primary-01' : 'white-01'}>{shortenIfAddress(address, 5)}</Text>
          </MaxContentTooltip>
        </Box>
      </td>
      <td>
        <Box flexDirection="row" alignItems="center" gap="4px">
          <ParachuteIcon width="16px" height="16px" />
          <ShorteningAmount amount={earned} color="primary-01" />
        </Box>
      </td>
      <td>
        <Box flexDirection="row" alignItems="center" gap="4px">
          <TrophyIcon width="16px" height="16px" />
          <ShorteningAmount amount={reward} color="secondary-03" />
        </Box>
      </td>
    </tr>
  );
};

const ShorteningAmount: React.FC<{ amount: number; color: TextProps['color'] }> = ({ amount, color }) => {
  const isSmallMobile = useMediaQuery('(max-width: 425px)', { noSsr: false });

  return (
    <>
      {isSmallMobile && (
        <Tooltip title={formatCurrency(amount || 0).replace('$', '')}>
          <Text color={color}>{formatSuffixNumber(amount || 0)}</Text>
        </Tooltip>
      )}
      {!isSmallMobile && <Text color={color}>{formatCurrency(amount || 0).replace('$', '')}</Text>}
    </>
  );
};

const RankCircle: React.FC<{ rank: number }> = ({ rank }) => (
  <Box
    style={{
      background:
        rank === 1
          ? 'linear-gradient(#FFB800, #E25100)'
          : rank === 2
          ? 'linear-gradient(#DBDBDB, #464646)'
          : rank === 3
          ? 'linear-gradient(#B89368, #902200)'
          : undefined,
    }}
    width="32px"
    height="32px"
    backgroundColor={rank > 3 ? 'secondary-03' : undefined}
    borderRadius="32px"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <Text>{rank}</Text>
  </Box>
);
