import { useCallback } from 'react';
import { Abi, Address, GetFunctionArgs, InferFunctionName, WriteContractParameters } from 'viem';
import { useFeeData, usePublicClient, useWalletClient } from 'wagmi';
import { SendTransactionResult } from 'wagmi/actions';

import { calculateGasMargin } from 'shared/lib';

export function useCallWithGasPrice() {
  const { data: walletClient } = useWalletClient();

  const { data } = useFeeData();
  const publicClient = usePublicClient();

  const callWithGasPriceWithSimulate = useCallback(
    async <
      TAbi extends Abi | unknown[],
      TFunctionName extends string = string,
      FunctionName = InferFunctionName<TAbi, TFunctionName>,
      Args = TFunctionName extends string
        ? GetFunctionArgs<TAbi, TFunctionName>['args']
        : FunctionName extends string
        ? GetFunctionArgs<TAbi, FunctionName>['args']
        : never,
    >(
      contract: { abi: TAbi; address: Address },
      methodName: InferFunctionName<TAbi, TFunctionName>,
      methodArgs?: Args extends never ? undefined : Args,
    ): Promise<SendTransactionResult> => {
      if (!walletClient) {
        throw new Error('WalletClient not found');
      }

      const gas = await publicClient.estimateContractGas({
        abi: contract.abi,
        address: contract.address,
        account: walletClient.account,
        functionName: methodName,
        args: methodArgs,
      } as unknown as any);

      const res = await walletClient.writeContract({
        abi: contract.abi,
        address: contract.address,
        account: walletClient.account,
        functionName: methodName,
        args: methodArgs,
        gasPrice: data?.gasPrice,
        gas: calculateGasMargin(gas),
      } as unknown as WriteContractParameters);

      const hash = res;

      return {
        hash,
      };
    },
    [walletClient, data, publicClient],
  );

  return { callWithGasPrice: callWithGasPriceWithSimulate };
}
