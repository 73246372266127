import { Box } from 'shared/ui';

import { PerpetualsFormAmount } from './PerpetualsFormAmount';
import { PerpetualsFormBalance } from './PerpetualsFormBalance';
import { PerpetualsFormLeverage } from './PerpetualsFormLeverage';
import { PerpetualsFormSubmitButton } from './PerpetualsFormSubmitButton';
import { PerpetualsFormSummary } from './PerpetualsFormSummary';
import { PerpetualsFormSwitch } from './PerpetualsFormSwitch';
import './index.scss';

export const PerpetualsForm = () => (
  <Box className="perpetuals-form">
    <PerpetualsFormSwitch />
    <PerpetualsFormBalance />
    <PerpetualsFormAmount />
    <PerpetualsFormLeverage />
    <PerpetualsFormSummary />
    <PerpetualsFormSubmitButton />
  </Box>
);
