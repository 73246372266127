// eslint-disable-next-line import/no-extraneous-dependencies
import { Address } from 'abitype';

import { PendingDualType } from 'entities/Dual';

import { LocalStorage } from 'shared/lib/Storage';

type Store = {
  [key: string]: PendingDualType[];
};

export const storagePendingDuals = {
  get: (address: Address, chainId: number) => {
    const duals = LocalStorage.get<Store>('pending_duals')?.[`${chainId}:${address}`];
    return duals || [];
  },
  setDual: (dual: PendingDualType) => {
    const prevDuals = storagePendingDuals.get(dual.address as Address, dual.chainId);
    LocalStorage.set('pending_duals', { [`${dual.chainId}:${dual.address}`]: [dual, ...prevDuals] });
  },
  setDuals: (address: Address, duals: PendingDualType[], chainId: number) => {
    LocalStorage.set('pending_duals', { [`${chainId}:${address}`]: duals });
  },
};
