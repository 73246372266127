import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { useState } from 'react';
import { parseUnits } from 'viem';
import { useContractRead, useContractWrite, usePrepareContractWrite, usePublicClient } from 'wagmi';
import { waitForTransaction as waitForTransactionWagmi } from 'wagmi/actions';

import { HotjarEvents, trackHotjar } from 'features/HotJar';
import { updateApproveStep } from 'features/OnboardingTour';

import { checkDoubleApproveToken, useGetDualTokenById } from 'entities/Token';
import { waitForUserAction } from 'entities/User';

import { tokenAbi } from 'shared/config/chains/abi';
import { useAccount, useCallWithGasPrice } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { extractError, checkTokenPermitSupport, waitForTransaction, calculateGasMargin } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyError } from 'shared/lib/notifications';

import { useTokenPermits } from './useTokenPermits';

type AllowanceArgs = {
  inputAmount: number | string;
  inputTicker: string;
  isDisabledPermit?: boolean;
};

export const useAllowance = ({ inputAmount = 0, inputTicker, isDisabledPermit = false }: AllowanceArgs) => {
  const { address } = useAccount();
  const { network: currentNetwork, chainId } = useNetwork();
  const inputToken = useGetDualTokenById(inputTicker);

  const publicClient = usePublicClient();
  const { permits } = useTokenPermits();

  const allowanceCheckEnabled = !!address && !!inputToken?.address;

  const {
    data: allowanceData,
    isError: isAllowanceError,
    isFetching: isAllowanceFetching,
    isLoading: isAllowanceLoading,
    isSuccess: isAllowanceSuccess,
    refetch: allowanceRefetch,
  } = useContractRead({
    abi: tokenAbi,
    address: inputToken?.address,
    args: [address!, currentNetwork?.vault?.address!],
    chainId: currentNetwork?.id,
    enabled: allowanceCheckEnabled,
    functionName: 'allowance',
    onError: (e) => {
      notifyError({ text: extractError(e), title: 'Allowance' });
    },
    scopeKey: `allowance::${currentNetwork?.id}`,
  });

  const allowanceAmount = BigInt(allowanceData || '0');

  const inputAmountToCheck =
    inputAmount && inputToken
      ? parseUnits(inputAmount as `${number}`, inputToken?.decimals || 18) // the amount user entered
      : BigInt(1); // the minimum amount to check

  const needApprove = allowanceCheckEnabled && isAllowanceSuccess && inputAmountToCheck > allowanceAmount;

  const [gasForApprove, setGasForApprove] = useState<bigint | undefined>();

  const prepareArgs = {
    abi: tokenAbi,
    address: inputToken?.address!,
    args: [currentNetwork?.vault?.address!, BigInt(2) ** BigInt(256) - BigInt(1)],
    functionName: 'approve',
    account: address!,
  } as const;

  const { callWithGasPrice } = useCallWithGasPrice();

  const { config } = usePrepareContractWrite({
    ...prepareArgs,
    chainId: currentNetwork?.id,
    enabled: needApprove,
    cacheTime: 0,
    scopeKey: `approve::${currentNetwork?.id}`,
    onSuccess: async (data) => {
      if (data.request.gas) {
        return setGasForApprove(calculateGasMargin(data.request.gas));
      }
      try {
        const gasLimit = await publicClient.estimateContractGas(prepareArgs);
        const gas = calculateGasMargin(gasLimit);
        setGasForApprove(gas);
      } catch (e) {
        setGasForApprove(undefined);
        logger.error(e);
      }
    },
  });

  const {
    isLoading: isApproveContractLoading,
    reset,
    writeAsync: requestApprove,
  } = useContractWrite(config.request ? { ...config, request: { ...config.request, gas: gasForApprove } } : config);

  const [isApproveTransactionLoading, setIsApproveTransactionLoading] = useState(false);

  const isDoubleApprove = checkDoubleApproveToken(chainId, inputTicker) && allowanceAmount !== BigInt(0);

  const requestUserApprove = async (
    trackParams: Arguments<typeof PredefinedEvents.Dual.Approve.Attempt>,
    isDoubleApprove: boolean,
    amount?: bigint,
  ) => {
    const fnApprove =
      !isDoubleApprove && requestApprove
        ? requestApprove
        : callWithGasPrice.bind(
            null,
            {
              abi: prepareArgs.abi,
              address: prepareArgs.address,
            },
            'approve',
            [currentNetwork?.vault?.address!, amount],
          );

    try {
      setIsApproveTransactionLoading(true);

      const transactionResult = await waitForUserAction(fnApprove?.());
      updateApproveStep('hide');
      track(PredefinedEvents.Dual.Approve.Attempt(trackParams));

      await waitForTransaction(waitForTransactionWagmi({ hash: transactionResult.hash }), transactionResult.hash);
      track(PredefinedEvents.Dual.Approve.Success(trackParams));
      trackHotjar(HotjarEvents.DUAL_APPROVE_SUCCESS);
      allowanceRefetch();

      if (isDoubleApprove) {
        await requestUserApprove(trackParams, false, BigInt(2) ** BigInt(256) - BigInt(1));
      }
    } catch (error: any) {
      updateApproveStep('show');
      allowanceRefetch();
      reset();
      logger.error(error);

      track(
        PredefinedEvents.Dual.Approve.Fail({
          ...trackParams,
          error: error?.message ?? 'Unknown error',
        }),
      );
    } finally {
      setIsApproveTransactionLoading(false);
    }
  };

  const approve = async (trackParams: Arguments<typeof PredefinedEvents.Dual.Approve.Attempt>) => {
    await requestUserApprove(trackParams, isDoubleApprove, BigInt(0));
  };

  const isSupportPermit = checkTokenPermitSupport(currentNetwork?.id || 0, inputToken?.address!) && !isDisabledPermit;

  const permit = permits[currentNetwork?.id || 0]?.[inputToken?.address!];

  return {
    isAllowanceLoading: allowanceCheckEnabled ? isAllowanceLoading : false,
    isApproveLoading: isApproveContractLoading || isAllowanceFetching || isApproveTransactionLoading,
    isError: isAllowanceError,
    isShowAprove: isSupportPermit && permit ? false : needApprove,
    isSupportPermit,
    needApprove,
    permit,
    requestApprove: approve,
    isDoubleApprove,
  };
};
