import { UseQueryResult } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { CFC, useMemo } from 'react';

import { PerpetualTariffsResponse } from 'entities/Perpetual/model';
import { useGetPerpetualTariffsQuery } from 'entities/Perpetual/model/hooks';

type Props = {
  isLoading: boolean;
  query: UseQueryResult<PerpetualTariffsResponse, {}>;
  tariffs: PerpetualTariffsResponse;
} & UseQueryResult<PerpetualTariffsResponse, {}>;

export const PerpetualTariffsContext = React.createContext({} as Props);

export const PerpetualTariffsProvider: CFC = observer(({ children }) => {
  const query = useGetPerpetualTariffsQuery();

  const { data, refetch, isLoading, isFetching, isRefetching } = query;

  const value = useMemo(
    () => ({
      isLoading: isLoading || isFetching || isRefetching,
      query,
      refetch,
      tariffs: data || [],
    }),
    [refetch, data, isLoading, isFetching, isRefetching],
  ) as Props;

  return <PerpetualTariffsContext.Provider value={value}>{children}</PerpetualTariffsContext.Provider>;
});
