import { DualCloseCalculateItemType } from 'entities/Dual';

/* eslint-disable @typescript-eslint/naming-convention */
export type LimitRequestArgs = {
  address: string;
  chainId: number;
};

export type CreateLimitArgs = {
  baseToken: `0x${string}`;
  initialPrice: string;
  inputAmount: string;
  inputToken: `0x${string}`;
  quoteToken: `0x${string}`;
  signature: string;
  stakingPeriod: number;
  targetPrice: string;
  yield: string;
} & Omit<LimitRequestArgs, 'address'>;

export type UpdateLimitArgs = {
  id: string;
  initialPrice: string;
  signature: string;
  targetPrice: string;
} & Pick<LimitRequestArgs, 'chainId'>;

export type LimitSettingsResponse = {
  limitMinInputAmountUSD: number;
};

export type DeleteLimitArgs = { id: string; signature: string } & LimitRequestArgs;

type DualChartItem = {
  createdAt: string;
  price: string;
};

export const enum STATUS_LIMIT {
  'CANCELED' = 'CANCELED',
  'CLOSED' = 'CLOSED',
  'OPEN' = 'OPEN',
}

export type LimitDual = {
  address: string;
  apr: string;
  baseTicker: string;
  chartData: DualChartItem[];
  closeCalculate: {
    down: DualCloseCalculateItemType;
    up: DualCloseCalculateItemType;
  };
  closedPrice: string;
  createdAt: string;
  finishedAt: null | string;
  id: string;
  initialPrice: string;
  inputAmount: string;
  inputBaseAmount: string;
  inputQuoteAmount: string;
  inputTicker: string;
  l1ChainId: number;
  l1TxHash: null | string;

  quoteTicker: string;
  reason: string;

  stakingPeriod: number;
  status: STATUS_LIMIT;
  targetPrice: string;
};

export type LimitDualsResponse = LimitDual[];
