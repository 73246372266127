import { useQuery } from '@tanstack/react-query';

import { getDualsOverview } from 'entities/Dual/api/queries';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

export const useGetDualsOverviewQuery = () => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  return useQuery(
    [`dual-overview-${chainId}-${address}`],
    () => getDualsOverview({ chainId: chainId!, address: address! }),
    {
      enabled: !!chainId && !!address,
      staleTime: 100_000,
      refetchInterval: 5 * 60 * 1000,
    },
  );
};
