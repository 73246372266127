import { styled } from '@mui/material';

import { shouldForwardProp } from 'shared/lib';
import { Box, Card } from 'shared/ui';

export const StyledCard = styled(Card, {
  shouldForwardProp,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  zIndex: 1,
}));

export const StyledInputRoot = styled(Box, {
  shouldForwardProp,
})<{ $isHide: boolean }>(({ $isHide }) => ({
  '> div': {
    marginTop: '24px',
  },
  height: $isHide ? '0' : '74px',
  minHeight: '0px',
  overflow: $isHide ? 'hidden' : 'visible',

  transition: 'all 0.2s ease',
}));
