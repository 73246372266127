export const permitByChainAndToken: {
  [chainId: number]: Record<string, number>;
} = {
  1: {
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': 2, // USDC
    '0x92d6c1e31e14520e676a687f0a93788b716beff5': 1, // DYDX
  },
  10: {
    '0x4200000000000000000000000000000000000042': 1, // OP
    '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': 2, // DAI
  },
  56: {
    '0x111111111117dc0aa78b770fa6a738034120c302': 1, // 1INCH
    '0x2dff88a56767223a5529ea5960da7a3f5f766406': 1, // ID
  },
  97: {
    '0x5c32f80319871dd2b9485322126972e5b5def2a4': 1, // USDC
  },
  42161: {
    '0x912ce59144191c1204e64559fe8253a0e49e6548': 1, // ARB
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 1, // USDC
    '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9': 1, // USDT
    '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': 2, // DAI
    '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': 1, // WETH
    '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': 1, // WBTC
  },
  43114: {
    '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e': 2, // USDC
    '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7': 1, // USDT
  },
};
