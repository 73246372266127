import { observer } from 'mobx-react-lite';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAutoReplay } from 'features/AutoReplay';

import { Button } from 'shared/ui';

type ButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  onClick: (enabled: boolean) => Promise<void>;
};

const InnerButton = forwardRef<HTMLButtonElement, ButtonProps>(({ disabled, loading, onClick }, ref) => {
  const { t } = useTranslation();
  const { enabled } = useAutoReplay();

  const handleReplay = async () => {
    await onClick(enabled);
  };

  return (
    <Button
      onClick={handleReplay}
      loading={loading}
      disabled={disabled}
      mb={16}
      flexShrink={0}
      data-id="replay-dual-now"
      ref={ref}
    >
      {t('dual.replayNow')}
    </Button>
  );
});

export const ReplayButton = observer(InnerButton);
