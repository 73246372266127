import { useQueryClient } from '@tanstack/react-query';
import { sub } from 'date-fns';
import { forwardRef, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PendingDualType, useGetDualQuery, DualChartCalculationRow, formatApr, DualType } from 'entities/Dual';
import { useGetChartRatesQuery } from 'entities/Rates';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, BoxProps, Countdown, Text, Spinner, Badge } from 'shared/ui';

import { PendingDualChart } from '../PendinDualChart';
import { PendingDualInputs } from '../PendingDualInputs';

interface DualProps extends BoxProps {
  dual: PendingDualType;
}

export const ImplDual = forwardRef<HTMLDivElement, DualProps>(({ dual, ...rest }, ref) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { chainId } = useNetwork();
  const { address } = useAccount();
  const [isLoaded, setIsLoaded] = useState(false);

  const toDate = useMemo(() => new Date().toISOString(), []);
  const fromDate = useMemo(() => sub(new Date(), { hours: 9 }).toISOString(), []);

  const { data: chartData, isLoading } = useGetChartRatesQuery({
    fromDate,
    fromTicker: dual.baseTicker,
    toDate,
    toTicker: dual.quoteTicker,
  });

  useGetDualQuery(dual.id || dual.l1TxHash, {
    refetchInterval: 2000,
    enabled: !isLoaded,
    onSuccess: (dual) => {
      setIsLoaded(true);
      queryClient.setQueryData([`opened-duals-${chainId}-${address}`], (prev: DualType[] | undefined) => {
        if (!prev) return [dual];

        return prev?.reduce(
          (acc, item) => {
            if (dual.l1TxHash && item.l1TxHash === dual.l1TxHash) return acc;
            acc.push(item);

            return acc;
          },
          [dual],
        );
      });
    },
  });

  const activeUpTicker = dual.inputTicker === dual.closeCalculate.up.outputTicker;

  return (
    <Box borderRadius={24} bg="secondary-02" p={16} pt={20} {...rest} ref={ref}>
      <Box flexDirection="row" alignItems="center" mb={18} gap={6}>
        <Spinner size={16} />
        <Text color="secondary-03" text="app-12-medium">
          {t('referralProgram.referralsPage.pending')}
        </Text>
      </Box>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" mb={19}>
        <PendingDualInputs pairBg="secondary-02" dual={dual} />
        <Box>
          <Badge>{formatApr(dual.apr)}</Badge>
        </Box>
      </Box>
      <DualChartCalculationRow row={dual.closeCalculate.up} isLoading={!activeUpTicker} active={activeUpTicker} />
      <Box mx="-16px">
        {isLoading ? (
          <Box height={140} justifyContent="center" alignItems="center">
            <Spinner />
          </Box>
        ) : (
          <PendingDualChart dual={dual} chartData={chartData || []} />
        )}
      </Box>
      <DualChartCalculationRow row={dual.closeCalculate.down} isLoading={activeUpTicker} active={!activeUpTicker} />
      <Box height="1px" mx={-16} backgroundColor="background-01" my={16} />
      <Box flexDirection="row" alignItems="center">
        <Box mr={8}>
          <Text color="secondary-03" text="app-12-medium">
            {t('dual.card.completedIn')}
          </Text>
        </Box>
        <Countdown to={Date.parse(dual.finishAt)} />
      </Box>
    </Box>
  );
});

export const PendingDual = memo(ImplDual);
