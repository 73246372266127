import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSwitchNetwork as useSwitchNetworkWagmi } from 'wagmi';

import { checkSupportedChain } from 'shared/config/chains';
import { useWidget } from 'shared/hooks';
import { useStore } from 'shared/hooks/store';
import { PathPatterns } from 'shared/lib';

export const useChainParam = () => {
  const location = useLocation();
  const { switchNetwork } = useSwitchNetworkWagmi();
  const { networkStore } = useStore();

  const isWidget = useWidget();

  const [searchParams] = useSearchParams();
  const defaultChainId = searchParams.get('chainId');

  useEffect(() => {
    if (
      location.pathname === PathPatterns.DualsDashboard ||
      location.pathname === PathPatterns.Duals ||
      !defaultChainId
    )
      return;
    const isSupportedChain = checkSupportedChain(+defaultChainId);
    if (switchNetwork && isSupportedChain && !isWidget) {
      switchNetwork(Number(defaultChainId));
    }
    if (networkStore && isSupportedChain && isWidget) {
      networkStore.selectNetwork(+defaultChainId);
    }
  }, [defaultChainId, switchNetwork, isWidget]);
};
