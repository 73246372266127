import { observer } from 'mobx-react-lite';

import { useGetClosedDualsQuery, useGetOpenedDualsQuery } from 'entities/Dual';

import { Box, Spinner } from 'shared/ui';

import { Closed } from './Closed';
import { Opened } from './Opened';

export const ActiveDuals = observer(() => {
  const openedQuery = useGetOpenedDualsQuery();
  const closedQuery = useGetClosedDualsQuery();

  const showSpinner = (openedQuery.isLoading || closedQuery.isLoading) && !openedQuery.error && !closedQuery.error;

  if (showSpinner) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Opened queryResult={openedQuery} />
      <Closed queryResult={closedQuery} mt={24} mb={{ default: 12, tablet: 0 }} />
    </>
  );
});
