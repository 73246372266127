import React, { FC, useCallback, useState } from 'react';

import { LanguageMenu } from 'widgets/Layout/Header/Menu';

import { LanguageIcon } from 'shared/assets';
import { Box, Menu } from 'shared/ui';

export const Language: FC = () => {
  const [isOpen, toggle] = useState<boolean>(false);

  const handleToggle = useCallback(() => {
    toggle((isOpen) => !isOpen);
  }, [toggle]);

  const menuTrigger = (
    <Box
      justifyContent="center"
      alignItems="center"
      minWidth={40}
      height={40}
      ml={8}
      borderRadius={40}
      bg="background-01"
      borderColor="secondary-02"
      sx={{ borderWidth: 1 }}
      onClick={handleToggle}
      color="white-01"
    >
      <LanguageIcon width={24} height={24} />
    </Box>
  );

  return (
    <Menu trigger={menuTrigger} isOpen={isOpen} toggle={toggle} blockBackgroundScroll>
      <LanguageMenu toggle={toggle} />
    </Menu>
  );
};
