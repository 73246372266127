import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';

import { NetworkMenu } from 'widgets/Layout/Header/Menu/NetworkMenu';

import { useNetwork } from 'shared/hooks/network';
import { Box, Text, Spinner, Menu } from 'shared/ui';

export const Network = observer(() => {
  const isWideMobile = useMediaQuery('(max-width: 1280px)', { noSsr: true });

  const [isOpen, toggle] = useState<boolean>(false);

  const handleToggle = useCallback(() => {
    toggle((isOpen) => !isOpen);
  }, [toggle]);

  const { network, switchingTo } = useNetwork();

  const menuTrigger = (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      minWidth={40}
      height={40}
      px={isWideMobile ? 0 : 10}
      ml={8}
      borderRadius={40}
      bg="background-01"
      borderColor="secondary-02"
      onClick={handleToggle}
      sx={{ borderWidth: 1 }}
    >
      <Box position="relative">
        {network?.renderLogo(24)}
        {!!switchingTo && (
          <Box position="absolute" top={0} right={0} bottom={0} left={0} alignItems="center" justifyContent="center">
            <Spinner size={20} />
          </Box>
        )}
      </Box>
      {!isWideMobile && (
        <Text ml={8} width="max-content">
          {network?.name}
        </Text>
      )}
    </Box>
  );

  return network ? (
    <Menu isOpen={isOpen} toggle={toggle} trigger={menuTrigger} blockBackgroundScroll>
      <NetworkMenu toggle={toggle} />
    </Menu>
  ) : null;
});
