/* eslint-disable jsx-a11y/control-has-associated-label */
import useResizeObserver from '@react-hook/resize-observer';
import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DualAssetType } from 'entities/Dual';

import { Box, Spinner, Text } from 'shared/ui';

import styles from './DualsTable.module.scss';
import { DualsTableAsset } from './DualsTableAsset';

interface Props {
  assets?: DualAssetType[];
  onSelect: (dual: DualAssetType) => void;
}

export const DualsTable: React.FC<Props> = ({ assets, onSelect }) => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useResizeObserver(document.body, (resize) => setScreenWidth(resize.borderBoxSize?.[0]?.inlineSize));

  const aprWidth = screenWidth <= 390 ? '92px' : '118px';
  const aprWidthSmall = screenWidth <= 340 ? '58px' : aprWidth;

  return (
    <>
      <table className={styles['table-container']}>
        <thead>
          <tr>
            <th className={classnames(styles['table-column-spacing'])} />
            <th
              className={classnames(styles['table-head-cell'], styles['table-column-big'], styles['border-separated'])}
            >
              <Text text="app-12-regular" color="gray-01">
                {t('main.table.pair')}
              </Text>
            </th>
            <th
              className={classnames(
                styles['table-head-cell'],
                styles['border-separated'],
                styles['table-column-disappearing'],
              )}
            >
              <Text text="app-12-regular" color="gray-01">
                {t('main.table.chain')}
              </Text>
            </th>
            <th
              style={{ width: aprWidthSmall }}
              className={classnames(styles['table-head-cell'], styles['border-separated'])}
            >
              <Box width={aprWidthSmall} alignItems="center">
                <Text text="app-12-regular" color="gray-01">
                  {t('main.table.apr')}
                </Text>
              </Box>
            </th>
            <th className={classnames(styles['table-column-spacing'])} />
          </tr>
        </thead>
        <tbody className={styles['table-body']}>
          {assets?.map((item) => (
            <DualsTableAsset
              key={`dual-asset-index-${item.chainId}-${item.baseTicker}-${item.quoteTicker}`}
              dual={item}
              className={styles['table-item']}
              onPress={() => onSelect(item)}
            />
          ))}
        </tbody>
      </table>
      {!assets && (
        <div className={styles.noResults}>
          <Spinner />
        </div>
      )}
      {assets && assets.length === 0 && (
        <div className={styles.noResults}>
          <Text text="app-16-medium" sx={{ opacity: 0.4 }}>
            {t('common.noResults')}
          </Text>
        </div>
      )}
    </>
  );
};
