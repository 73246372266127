import BigNumber from 'bignumber.js';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSwapQuote } from 'entities/Swap/lib';

import { HIGH_PRICE_IMPACT_BOUND, USD_PRICE_IMPACT_BOUND } from 'shared/config';
import { Box, Text } from 'shared/ui';

import { SwapPriceImpactWarningTooltip } from './SwapPriceImpactWarningTooltip';

export const SwapPriceImpactWarning: FC = () => {
  const { t } = useTranslation();

  const { data } = useSwapQuote();

  if (!data || !data.fromAmountUSD || !data.toAmountUSD) return;

  const impact = BigNumber(data.toAmountUSD).dividedBy(data.fromAmountUSD).minus(1);
  const impactPercentage = impact.multipliedBy(100).toFixed(2).toString();
  const isHighImpact = impact.abs().isGreaterThanOrEqualTo(HIGH_PRICE_IMPACT_BOUND);

  return (
    <>
      {isHighImpact && BigNumber(data.fromAmountUSD).gte(USD_PRICE_IMPACT_BOUND) && (
        <Box minHeight={56} border={1} borderColor="red-01" borderRadius={16} padding={16}>
          <Box flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap" rowGap={8}>
            <Box flexDirection="row" alignItems="center" gap={8}>
              <Text>{t('swap.warnings.priceImpact.title')}</Text>
              <SwapPriceImpactWarningTooltip />
            </Box>
            <Text color="red-01">{impactPercentage}%</Text>
          </Box>
        </Box>
      )}
    </>
  );
};
