import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { CFC } from 'react';

type AutoReplayContextValue = {
  defaultValue: boolean;
  enabled: boolean;
  isError: boolean;
  isLoading: boolean;
  setEnabled: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
};

export const AutoReplayContext = React.createContext({} as AutoReplayContextValue);

type ProviderProps = {
  defaultValue?: boolean;
};

export const AutoReplayProvider: CFC<ProviderProps> = observer(({ children, defaultValue = false }) => {
  const state = useLocalObservable<AutoReplayContextValue>(() => ({
    defaultValue,
    enabled: defaultValue,
    isError: false,
    isLoading: false,

    setEnabled(value: boolean) {
      this.enabled = value;
    },
    setIsError(value: boolean) {
      this.isError = value;
    },
    setIsLoading(value: boolean) {
      this.isLoading = value;
    },
  }));

  return <AutoReplayContext.Provider value={state}>{children}</AutoReplayContext.Provider>;
});
