import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DualTokenIcon, useGetDualTokensQuery } from 'entities/Token';

import { Box, Text } from 'shared/ui';

import { InfographicDirection, Infographic } from './Infographic';
import { Tag } from './Tag';

export enum CalculationDirection {
  DOWN = 'down',
  UP = 'up',
}

export interface CalculationDirectionProps {
  outputAmount: string;
  outputTicker: string;
  profit: string;
}

//

interface InfoComponentProps {
  calculationDirection: CalculationDirection;
  calculationDirectionProps: CalculationDirectionProps;
  stakingPeriod: number;
}

export const InfoComponent: FC<InfoComponentProps> = ({
  calculationDirection = CalculationDirection.UP,
  calculationDirectionProps,
  stakingPeriod,
}) => {
  const { t } = useTranslation();

  const isCalculationDirectionUp = calculationDirection === CalculationDirection.UP;

  return (
    <Box>
      <Box mb={16}>
        <Text lineHeight={30}>
          <Trans
            i18nKey={`modals.howItWorks.info_${isCalculationDirectionUp ? 'above' : 'below'}`}
            count={stakingPeriod}
          >
            If the <Tag variant="primary">Close Price</Tag> in <Tag variant="secondary">{stakingPeriod} hours</Tag> will
            <span>{isCalculationDirectionUp ? 'equal or above' : 'below'}</span> the <Tag>{t('charts.entryPrice')}</Tag>{' '}
            you receive:
          </Trans>
        </Text>
      </Box>
      <Box>
        {isCalculationDirectionUp && <InfoComponentData {...calculationDirectionProps} />}
        <Box position="relative" ml={-24} mb={isCalculationDirectionUp ? 0 : 8}>
          <Infographic
            direction={isCalculationDirectionUp ? InfographicDirection.UP : InfographicDirection.DOWN}
            period={stakingPeriod}
          />
        </Box>
        {!isCalculationDirectionUp && <InfoComponentData {...calculationDirectionProps} />}
      </Box>
    </Box>
  );
};

export const InfoComponentData: FC<CalculationDirectionProps> = ({ outputAmount, outputTicker, profit }) => {
  const { t } = useTranslation();
  const { tokens } = useGetDualTokensQuery();

  return (
    <Box flexDirection="row" justifyContent="space-between">
      <Box>
        <Text text="app-12-medium" color="secondary-03" mb={4}>
          {t('charts.totalReceive')}
        </Text>
        <Text text="app-12-medium" color="white-01">
          {outputAmount} {tokens[outputTicker]?.symbol}
        </Text>
      </Box>
      <Box flexDirection="row" alignItems="center">
        <DualTokenIcon ticker={outputTicker} size={24} />
        <Box ml={8}>
          <Text text="app-12-medium" color="secondary-03" mb={4}>
            {t('charts.profit')}
          </Text>
          <Text text="app-12-medium" color="primary-01">
            +{profit}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
