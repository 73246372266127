import { useModal } from '@rehold-io/connectkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'shared/hooks';
import { Box, Button } from 'shared/ui';

import { PerpetualsFormBalanceSelector } from './PerpetualsFormBalanceSelector';
import { PerpetualsFormModal } from './PerpetualsFormModal';
import { PerpetualsBalanceAction } from './PerpetualsFormModal/types';
import './index.scss';

export const PerpetualsFormBalance = () => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();

  const [depositOpen, setDepositOpen] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);

  const { setOpen } = useModal();

  return (
    <>
      <PerpetualsFormModal
        onOpen={() => setDepositOpen(true)}
        open={depositOpen}
        onClose={() => setDepositOpen(false)}
        type={PerpetualsBalanceAction.DEPOSIT}
      />
      <PerpetualsFormModal
        onOpen={() => setWithdrawOpen(true)}
        open={withdrawOpen}
        onClose={() => setWithdrawOpen(false)}
        type={PerpetualsBalanceAction.WITHDRAW}
      />
      <Box className="perpetuals-form-balance">
        <Box zIndex={1}>
          <PerpetualsFormBalanceSelector />
        </Box>
        <Box className="perpetuals-form-balance-buttons">
          <Button
            onClick={() => (isConnected ? setDepositOpen(true) : setOpen(true))}
            className="perpetuals-form-balance-button"
            variant="secondary"
          >
            {t('perpetuals.form.balance.deposit')}
          </Button>
          <Button
            onClick={() => (isConnected ? setWithdrawOpen(true) : setOpen(true))}
            className="perpetuals-form-balance-button"
            variant="secondary-solid"
          >
            {t('perpetuals.form.balance.withdraw')}
          </Button>
        </Box>
      </Box>
    </>
  );
};
