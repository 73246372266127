/* eslint-disable no-nested-ternary */
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { useQueryClient } from '@tanstack/react-query';
import BN from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoReplayField, AutoReplayProvider, useSetAutoReplayMutation } from 'features/AutoReplay';

import { DualType, useInputTickerLimits } from 'entities/Dual';
import { useDualTariffByChain } from 'entities/Dual/lib/hooks/useDualTariffByChain';
import { useGetDualTokensQuery } from 'entities/Token';
import { useGetDualTokenAddress } from 'entities/Token/model/useGetDualTokenAddress';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { getReason, retry } from 'shared/lib';
import { Box, Countdown, Text } from 'shared/ui';

type Props = {
  dual: DualType;
  trackedDual: Omit<DualType, 'chartData' | 'closeCalculate'>;
};

export const ClosedDualInfo: FC<Props> = observer(({ dual, trackedDual }) => {
  const { t } = useTranslation();
  const { tokens } = useGetDualTokensQuery();
  const { chainId } = useNetwork();
  const { address } = useAccount();

  const { baseTicker, quoteTicker, stakingPeriod } = dual;

  const baseToken = useGetDualTokenAddress(baseTicker, chainId)!;
  const quoteToken = useGetDualTokenAddress(quoteTicker, chainId)!;

  const { tariffs } = useDualTariffByChain({ baseToken, quoteToken, chainId });

  const tariff = tariffs.find((t) => t.stakingPeriod === stakingPeriod);

  const queryClient = useQueryClient();

  const { limit: limitDownOutput } = useInputTickerLimits(dual.closeCalculate.down.outputTicker, tariff);
  const { limit: limitUpOutput } = useInputTickerLimits(dual.closeCalculate.up.outputTicker, tariff);

  const isDisabledLimitAmountLess = dual?.closeCalculate
    ? BN(dual.closeCalculate.down.outputAmount).lt(BN(limitDownOutput.min)) ||
      BN(dual.closeCalculate.up.outputAmount).lt(BN(limitUpOutput.min))
    : false;
  const isDisabledLimitAmountMore = dual?.closeCalculate
    ? BN(dual.closeCalculate.down.outputAmount).gt(BN(limitDownOutput.max)) ||
      BN(dual.closeCalculate.up.outputAmount).gt(BN(limitUpOutput.max))
    : false;

  const error = isDisabledLimitAmountLess
    ? t('dual.autoReplay.validation.amountMore', {
        downAmount: `${limitDownOutput.min} ${tokens[dual.closeCalculate.down.outputTicker]?.symbol}`,
        upAmount: `${limitUpOutput.min} ${tokens[dual.closeCalculate.up.outputTicker]?.symbol}`,
      })
    : isDisabledLimitAmountMore
    ? t('dual.autoReplay.validation.amountLess', {
        downAmount: `${limitDownOutput.max} ${tokens[dual.closeCalculate.down.outputTicker]?.symbol}`,
        upAmount: `${limitUpOutput.max} ${tokens[dual.closeCalculate.up.outputTicker]?.symbol}`,
      })
    : undefined;

  const {
    changeAutoReplay,
    values: { isLoading: isLoadingAutoReplay },
  } = useSetAutoReplayMutation();

  const onDualClose = () => {
    queryClient.invalidateQueries({
      queryKey: [`opened-duals-${chainId}-${address}`],
    });
    queryClient.invalidateQueries({
      queryKey: [`closed-duals-${chainId}-${address}`],
    });
  };

  const handleChangeAutoReplay = useCallback(
    async (value: boolean) => {
      track(PredefinedEvents.Dual.AutoReplay.Change({ ...trackedDual, autoReplay: value }));

      const fnAutoReplay = async () => {
        const result = await changeAutoReplay(dual.id, value);
        if ('error' in result) {
          throw new Error(getReason(result.error));
        }
        return result;
      };

      try {
        await retry(fnAutoReplay, { delay: 300, maxAttempts: 5 });
        queryClient.setQueryData([`opened-duals-${chainId}-${address}`], (oldDuals: DualType[] | undefined) => {
          if (!oldDuals) return;
          const duals = oldDuals.map((d) => (d.id === dual.id ? { ...d, autoReplay: value } : d));
          return duals;
        });

        return true;
      } catch (error) {
        return false;
      }
    },
    [changeAutoReplay, dual.id, trackedDual, chainId, address, queryClient],
  );

  return (
    <>
      <Box height="1px" mx={-16} backgroundColor="background-01" my={16} />
      <Box flexDirection="row" alignItems="center">
        <Box mr={8}>
          <Text color="secondary-03" text="app-12-medium">
            {t('dual.card.completedIn')}
          </Text>
        </Box>
        <Countdown to={Date.parse(dual.finishAt)} callback={onDualClose} />
      </Box>
      <AutoReplayProvider defaultValue={dual.autoReplay}>
        <Box backgroundColor="background-01" borderRadius={16} mt={16} paddingX={16} paddingY={8}>
          <AutoReplayField
            onChange={handleChangeAutoReplay}
            inputAmount={BN(dual.inputAmount).toNumber()}
            inputTicker={dual.inputTicker}
            isLoading={isLoadingAutoReplay}
            isDisabled={isDisabledLimitAmountLess || isDisabledLimitAmountMore}
            error={error}
            isDisabledValidation
          />
        </Box>
      </AutoReplayProvider>
    </>
  );
});
