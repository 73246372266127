import { UseQueryResult } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { storagePendingDuals } from 'features/PendingDual';

import { DualRequestArgs, DualType } from 'entities/Dual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, BoxProps, Button, Text, Spinner } from 'shared/ui';

import { TypeTab } from '../../model/types';
import { Dual } from '../Dual';
import { Empty } from '../Empty';
import { PendingDuals } from '../PendingDuals';

type Props = {
  queryResult: UseQueryResult<DualType[], DualRequestArgs>;
} & BoxProps;

export const Opened: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { chainId } = useNetwork();
  const { data, isError, isFetching, isLoading, refetch } = props.queryResult;

  const hasPendingDuals = storagePendingDuals.get(address!, chainId!)?.length;

  if (!isError && !isLoading && !data?.length && !hasPendingDuals) {
    return (
      <Box {...props}>
        <Empty type={TypeTab.ACTIVE} />
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Box mb={16} flexDirection="row">
        <Text text="app-18-medium">{t('dual.active')}</Text>
        {!!data?.length && isFetching && (
          <Box ml={8}>
            <Spinner size={24} />
          </Box>
        )}
      </Box>

      {(isLoading || isError) && (
        <Box height={150} justifyContent="center" alignItems="center">
          {isLoading && <Spinner />}
          {isError && (
            <Button onClick={() => refetch()} data-id="refetch-opened-dual">
              {t('common.refetch')}
            </Button>
          )}
        </Box>
      )}

      <PendingDuals data={data} />
      {!isError && data?.map((dual, index) => <Dual key={dual.id} dual={dual} mt={index === 0 ? 0 : 24} />)}
    </Box>
  );
});

Opened.displayName = 'Opened_Duals';
