import { Address } from 'wagmi';

export interface AddressArgs {
  address: Address;
}

export interface ListNotificationsArgs {
  address: string;
  language: string;
}

export type NotificationsResponse = NotificationItem[];

export type NotificationItem = {
  chainId: number;
  content: string;
  createdAt: string;
  id: number;
  referenceId: string;
  title: string;
};

export interface CountNotificationResponse {
  unread: 0;
}
export interface CountNotificationArgs {
  address: Address;
  timestamp?: number;
}

export interface SignupArgs {
  address: Address;
  signature: string;
}

type ClientSettings = {
  notificationLastSeenAt: number;
};

export interface UpdateUserArgs {
  clientSettings?: ClientSettings;
  discordUrl?: string;
  email?: string;
  language?: string;
  signature: string;
  telegramUrl?: string;
  twitterUrl?: string;
}

export interface AuthResponse {
  address: string;
  clientSettings: ClientSettings;
  discordUrl: null | string;
  email: null | string;
  inviter: string;
  language: string;
  telegramUrl: null | string;
  twitterUrl: null | string;
}

export interface NomisArgs {
  address: Address;
  chainId: number;
}

export interface NomisResponse {
  score: string;
}

export interface CreateWithdrawalArgs {
  amount: string;
  chainId: number;
  signature: string;
  ticker: string;
}

export interface CreateWithdrawalResponse {
  transactionId: 'string';
  txHash: 'string';
}

export enum TransferStatus {
  CONFIRMED = 'CONFIRMED',
  DROPPED = 'DROPPED',
  PENDING = 'PENDING',
  REVERTED = 'REVERTED',
}

export interface GetTransferArgs {
  chainId: number;
  logIndex: number;
  txHash: string;
}

export interface GetTransferResponse {
  amount: string;
  chainId: number;
  createdAt: string;
  fromAddress: string;
  logIndex: number;
  status: TransferStatus;
  ticker: string;
  toAddress: string;
  txHash: string;
  type: 'DEPOSIT' | 'WITHDRAWAL';
}

export interface GetTransfersArgs {
  address: string;
  status: TransferStatus;
}

export type GetTransfersResponse = GetTransferResponse[];
