import { track, PredefinedEvents } from '@rehold-io/data-layer-client';
import { formatAmount } from '@rehold-io/formatters';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAddTokenToWallet } from 'features/AddToken';
import { HappyMomentsType } from 'features/HappyMoments/model/types';

import { DualTokenIcon, useGetDualTokensQuery } from 'entities/Token';

import { HappyIcon, LongArrowIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { availableWallets } from 'shared/lib/rehold-wallets';
import { Box, Button, Text, Modal } from 'shared/ui';

type Props = {
  amount?: string;
  isOpen: boolean;
  onClose: () => void;
  onCloseCallback: () => void;
  ticker?: string;
  type?: HappyMomentsType;
};

const tKey = {
  'dual-claim': 'dual',
  'referral-claim': 'referral',
} as const;

export const PaidModal: FC<Props> = observer(({ amount = '', isOpen, onClose, onCloseCallback, ticker = '', type }) => {
  const { connector } = useAccount();
  const { tokens } = useGetDualTokensQuery();
  const { t } = useTranslation();

  const { addToken } = useAddTokenToWallet();

  const iconPath = availableWallets.find(({ connector: c }) => c.name === connector?.name)?.iconPath;
  const navigate = useNavigate();

  const handleGoToMainPage = () => {
    if (type === 'referral-claim') track(PredefinedEvents.Referral.HappyMoment.CTA());
    if (type === 'dual-claim') track(PredefinedEvents.Dual.HappyMoment.Claim.CTA());
    navigate(PathPatterns.DualsDashboard);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeIcon closeCallback={onCloseCallback} maxWidth={390}>
      <Box alignItems="center" mb="40px" mt="10px">
        <Box mb="32px" gap="4px" alignItems="center" color="primary-01">
          <HappyIcon width={32} height={32} />
          {type && (
            <Text text="app-14-medium" mt="4px">
              {t(`happyMoments.${tKey[type]}.title`)}
            </Text>
          )}
          <Text text="app-18-medium">{t('happyMoments.paid')}</Text>
        </Box>
        <Box alignItems="center" width="100%">
          <DualTokenIcon size={40} ticker={ticker} />
          <Text text="app-22-medium" color="primary-01" mt="16px">
            {`${formatAmount({
              symbol: ticker,
              value: amount,
            })} ${tokens[ticker]?.symbol}`}
          </Text>
          <Text text="app-16-medium" opacity={0.4} mt="8px" mb="16px">
            {t('happyMoments.sendToWallet', { value: connector?.name })}
          </Text>
          <LongArrowIcon width={12} height={48} />

          <Box borderColor="primary-01" sx={{ borderWidth: 1 }} borderRadius="100%" padding="8px" mt="16px">
            <Box borderColor="primary-01" sx={{ borderWidth: 1 }} borderRadius="100%" padding="8px">
              <Box backgroundColor="secondary-02" width="64px" height="64px" borderRadius="100%" padding="16px">
                <img src={iconPath} alt="wallet" />
              </Box>
            </Box>
          </Box>

          <Box padding="16px" mt="16px" gap="16px" borderRadius={24} alignItems="center">
            <Text text="app-12-medium" textAlign="center">
              {t('addToken')}
            </Text>
            <Button width="100%" onClick={() => addToken(ticker)} size="tiny" variant="primary-solid" maxWidth="200px">
              {t('addToWallet')}
            </Button>
          </Box>

          <Button width="100%" maxWidth="342px" mt="24px" onClick={handleGoToMainPage}>
            {t('happyMoments.useInDual')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});
