import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Address } from 'viem';

import { useSwapTokenByAddress } from 'entities/Swap/lib/hooks';

import { SwapAssetSelectButton } from './SwapAssetSelectButton';
import { SwapAssetSelectModal } from './SwapAssetSelectModal';

interface Props {
  onSelect: (value: Address | null) => void;
  value: Address | null;
}

export const SwapAssetSelect: FC<Props> = memo(({ value, onSelect }) => {
  const [isOpen, setOpen] = useState(false);

  const { data } = useSwapTokenByAddress(value);

  useEffect(() => {
    if (value && data && !data.symbol) onSelect(null);
  }, [value, data]);

  const handleClick = useCallback(() => {
    setOpen((open) => !open);
    track(PredefinedEvents.Swap.Tokens.Click());
  }, [setOpen]);

  const handleSelect = useCallback(
    (address: Address) => {
      onSelect(address);
      setOpen(false);
    },
    [onSelect, setOpen],
  );

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <SwapAssetSelectButton onClick={handleClick} selected={value} />
      <SwapAssetSelectModal selected={value} isOpen={isOpen} onClose={handleClose} onSelect={handleSelect} />
    </>
  );
});
