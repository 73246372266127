import { usePublicClient } from 'wagmi';
import { writeContract } from 'wagmi/actions';

import { useSignAuthMessage, storageSignature } from 'features/Auth';

import { DualType, useClaimDualMutation } from 'entities/Dual';
import { waitForUserAction } from 'entities/User';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { calculateGasMargin } from 'shared/lib';
import { logger } from 'shared/lib/logger';

export const useClaimDual = (dual: DualType) => {
  const { mutateAsync: claimDual } = useClaimDualMutation();
  const { network } = useNetwork();
  const { address } = useAccount();
  const publicClient = usePublicClient();

  const abi = network?.router?.abi!;

  const { signAuthMessage, validateSign } = useSignAuthMessage();

  const writeAsync = async (receiver: string) => {
    const isSigned = await validateSign();

    if (!isSigned) {
      try {
        const isSuccess = await waitForUserAction(signAuthMessage(), 'message');

        if (!isSuccess) return;
      } catch {
        return;
      }
    }

    const userSignature = storageSignature.get(address!);
    if (!userSignature) {
      return Promise.reject(new Error('not signature'));
    }
    const result = await claimDual({ address: address!, chainId: network?.id!, id: dual.id, signature: userSignature });

    if ('error' in result) {
      return Promise.reject(result.error);
    }

    const { outputAmount, outputToken, signature, txHash, user } = result.data;

    let gasLimit: bigint | undefined;

    const args = {
      abi,
      address: network?.router?.address!,
      args: [user, (receiver as `0x${string}`) || user, outputToken, BigInt(outputAmount), txHash, signature],
      functionName: 'claim',
      account: user,
    } as const;
    try {
      gasLimit = await publicClient.estimateContractGas(args);
    } catch (e) {
      logger.error(e);
    }
    const gas = gasLimit ? calculateGasMargin(gasLimit) : undefined;

    return waitForUserAction(
      writeContract({
        ...args,
        gas,
      }),
    );
  };

  return { writeAsync };
};
