import { PropsWithChildren, FC } from 'react';

import { Box, BoxProps, Text, TextProps } from 'shared/ui';

export interface TagVariantProps {
  props: BoxProps;
  textProps: TextProps;
}

export interface TagVariant {
  default: TagVariantProps;
  primary: TagVariantProps;
  secondary: TagVariantProps;
}

export const TAG_VARIANT: TagVariant = {
  default: {
    props: {
      bg: 'white-01',
    },
    textProps: {
      color: 'black-01',
    },
  },
  primary: {
    props: {
      bg: 'primary-01',
    },
    textProps: {
      color: 'black-01',
    },
  },
  secondary: {
    props: {
      bg: 'secondary-01',
    },
    textProps: {
      color: 'white-01',
    },
  },
};

export interface TagProps {
  variant?: keyof TagVariant;
}

export const Tag: FC<PropsWithChildren<TagProps>> = ({ children, variant = 'default' }) => {
  const variantProps = TAG_VARIANT[variant];

  return (
    <Box alignItems="center" display="inline-flex" borderRadius={20} px={6} py={0.5} {...variantProps.props}>
      <Text text="app-12-medium" {...variantProps.textProps}>
        {children}
      </Text>
    </Box>
  );
};
