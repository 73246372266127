import { useEffect } from 'react';

export const useHideLiveChat = () => {
  useEffect(() => {
    window.LiveChatWidget?.init();
    window.LiveChatWidget?.once('ready', () => {
      const widget = document.getElementById('chat-widget-container');
      if (widget) widget.style.display = 'none';
    });
  }, []);
};
