import { useMemo } from 'react';

import { useGetDualTariffQuery } from 'entities/Dual/model/hooks/useGetDualTariffQuery';
import { mapToTariffs } from 'entities/Dual/model/mapResponseTariffsToTariffs';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';

import { CHAIN_INFO } from 'shared/config/chains';

import { formatTariff } from '../providers';

type Args = {
  baseToken: string;
  chainId: number;
  quoteToken: string;
};

export const useDualTariffByChain = ({ baseToken, quoteToken, chainId }: Args) => {
  const { tokens } = useGetDualTokensAllQuery();

  const { data, error, isFetching, isLoading, refetch } = useGetDualTariffQuery({ baseToken, quoteToken, chainId });

  const network = CHAIN_INFO[chainId];

  const tariffs = useMemo(() => {
    const tariffs = mapToTariffs(data || [], tokens[chainId]);

    const tariffsWithNativeCurrency = tariffs.reduce<typeof tariffs>((acc, tariff) => {
      const newTarrif = formatTariff(tariff, network);

      if (newTarrif) {
        acc.push(newTarrif);
      }

      acc.push(tariff);
      return acc;
    }, []);

    return tariffsWithNativeCurrency;
  }, [data, network, tokens]);

  return useMemo(
    () => ({
      error,
      isFetching,
      isLoading,
      refetch,
      response: data,
      tariffs,
    }),
    [tariffs, refetch, isLoading, error, isFetching, data],
  );
};
