import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from 'viem';

import { useSwapTokenByAddress } from 'entities/Swap/lib/hooks';
import { SwapTokenIcon } from 'entities/Token';

import { ExpandIcon } from 'shared/assets';
import theme from 'shared/theme';
import { Box, Button, Spinner, Text } from 'shared/ui';

import styles from './SwapAssetSelect.module.scss';

interface SwapAssetSelectButtonProps {
  onClick: () => void;
  selected: Address | null;
}

export const SwapAssetSelectButton: React.FC<SwapAssetSelectButtonProps> = observer(
  ({ onClick = (): void => {}, selected }) => {
    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    return (
      <>
        {selected ? (
          <SwapAssetSelectedButton address={selected} onClick={onClick} />
        ) : (
          <Button
            onClick={onClick}
            width="fit-content"
            size="small"
            variant="secondary"
            borderRadius={24}
            text="app-14-medium"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <Box flexDirection="row" gap={8} alignItems="center">
              <Text whiteSpace="nowrap">{t('swap.button.selectToken')}</Text>
              <ExpandIcon
                strokeWidth={3}
                width={16}
                height={16}
                color={hovered ? theme.colors['secondary-01'] : theme.colors['secondary-03']}
              />
            </Box>
          </Button>
        )}
      </>
    );
  },
);

interface SelectedProps {
  address: Address;
  onClick: () => void;
}

const SwapAssetSelectedButton: FC<SelectedProps> = observer(({ address, onClick }) => {
  const { data, isLoading, isError } = useSwapTokenByAddress(address);

  return (
    <Box className={styles.select} onClick={onClick}>
      {isLoading && <Spinner size={24} />}
      {!isLoading && !isError && data && <SwapTokenIcon address={address} url={data.logoURI} size={24} />}
      {!isLoading && !isError && data && <div className={styles.label}>{data.symbol}</div>}
      <div className={styles.icon}>
        <ExpandIcon strokeWidth={3} width={16} height={16} />
      </div>
    </Box>
  );
});
