import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDualModal } from 'entities/Dual';
import { waitForUserAction } from 'entities/User';
import { NotificationItem as NotificationItemType } from 'entities/User/model/types';

import { CHAIN_INFO } from 'shared/config/chains';
import { useNetwork, useSwitchNetwork } from 'shared/hooks/network';
import { Box, Button, Text } from 'shared/ui';

export const NotificationItem: FC<NotificationItemType> = observer(
  ({ chainId: dualChainId, content, referenceId, title }) => {
    const { t } = useTranslation();
    const { setDualParams } = useDualModal();
    const { switchNetwork } = useSwitchNetwork();
    const { chainId } = useNetwork();
    const onOpen = async (enabledAutoReplay: boolean) => {
      if (dualChainId !== chainId) {
        const result = await waitForUserAction(switchNetwork(dualChainId), {
          description: t('modals.switchNetwork.message', { chain: CHAIN_INFO[dualChainId].name }),
          title: t('modals.switchNetwork.title'),
        });
        if (!result) return;
      }
      setDualParams({ enabledAutoReplay, id: referenceId, type: 'replay' });
    };

    return (
      <Box padding="16px 20px" gap={12} borderBottom={(theme) => `1px solid ${theme.colors['secondary-02']}`}>
        <Text text="app-14-medium">{title}</Text>
        <Text text="app-12-regular" opacity={0.6}>
          {content}
        </Text>
        <Box flexDirection="row" gap="12px">
          <Button onClick={() => onOpen(true)} size="tiny" variant="primary" width="max-content">
            {t('dual.autoReplay.name')}
          </Button>
          <Button onClick={() => onOpen(false)} size="tiny" variant="secondary" width="max-content">
            {t('dual.replay.name')}
          </Button>
        </Box>
      </Box>
    );
  },
);
