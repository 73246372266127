import { Address } from 'wagmi';

import { PINNED_TOKENS } from 'shared/config';
import { useNetwork } from 'shared/hooks/network';

export const usePinnedTokens = () => {
  const { selectedChainId: chainId } = useNetwork();

  const pinnedTokens = PINNED_TOKENS[chainId];

  if (pinnedTokens)
    return pinnedTokens.map((token) => ({ ...token, address: token.address?.toLocaleLowerCase() as Address }));

  return [];
};
