import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Shepherd from 'shepherd.js';

import { useGetCountDualsQuery } from 'entities/Dual/model/hooks';

import { TOUR_CLASSES } from '../lib';
import { STEPS_CLASSES } from '../lib/classes';
import { getItems } from '../lib/shepherd/helpers';

import { useStorageOnboarding } from './useStorageOnboarding';
import { useTour } from './useTour';

export const useInitOnboarding = () => {
  const { get, set } = useStorageOnboarding();
  const { tour } = useTour();
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = tour?.isActive() || false;

  const { data } = useGetCountDualsQuery();

  useEffect(() => {
    if (!isActive) return;

    const targetNode = document.getElementById('root')!;
    const config = { attributes: false, childList: true, subtree: true };

    const callback = (mutationList: any) => {
      const items = getItems(STEPS_CLASSES);
      const stepId = Shepherd.activeTour?.getCurrentStep()?.id;
      const stepApprove = stepId === `step-${TOUR_CLASSES.APROVE.id}`;
      const stepStart = stepId === `step-${TOUR_CLASSES.START.id}`;
      const stepInf = stepId === `step-${TOUR_CLASSES.INSUFFICIENT_FUNDS.id}`;
      const stepSwitch = stepId === `step-${TOUR_CLASSES.SWITCH_CHAIN.id}`;
      const stepUnavailable = stepId === `step-${TOUR_CLASSES.UNAVAILABLE_CREATION.id}`;
      const lastSteps = stepApprove || stepStart || stepInf || stepUnavailable || stepSwitch;

      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          for (const target of mutation.removedNodes) {
            if (!lastSteps) continue;

            const isSwitchButton =
              target.classList?.contains(TOUR_CLASSES.SWITCH_CHAIN.button) ||
              target.classList?.contains(TOUR_CLASSES.SWITCH_CHAIN.class);

            const isInsButton =
              target.classList?.contains(TOUR_CLASSES.INSUFFICIENT_FUNDS.button) ||
              target.classList?.contains(TOUR_CLASSES.INSUFFICIENT_FUNDS.class);

            const isUnavailableButton =
              target.classList?.contains(TOUR_CLASSES.UNAVAILABLE_CREATION.button) ||
              target.classList?.contains(TOUR_CLASSES.UNAVAILABLE_CREATION.class);

            const isAproveButton =
              target.classList?.contains(TOUR_CLASSES.APROVE.button) ||
              target.classList?.contains(TOUR_CLASSES.APROVE.class);

            const isStartButton =
              target.classList?.contains(TOUR_CLASSES.START.button) ||
              target.classList?.contains(TOUR_CLASSES.START.button);

            if (isSwitchButton || isInsButton || isAproveButton || isStartButton || isUnavailableButton) {
              Shepherd.activeTour?.show(`step-${items[items.length - 1]}`);
            }
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };
  }, [isActive]);

  useEffect(() => {
    const isConnected = localStorage.getItem('REHOLD_CHAIN_ID');
    const isShown = get();

    if (!location.pathname.includes('/dual/')) return;

    if (tour?.isActive()) {
      const step = tour.getCurrentStep();
      tour?.show(step?.id);
      return;
    }

    if (isConnected && data === undefined) return;

    if (isShown || (isConnected && data && data.total)) return;

    tour?.start();

    if (isConnected) tour?.next();

    set();

    return () => {
      if (tour?.isActive() && !location.pathname.includes('/dual/')) {
        tour?.hide();
      }
    };
  }, [data, t]);

  useEffect(
    () => () => {
      if (tour?.isActive()) {
        tour?.hide();
      }
    },
    [],
  );

  useEffect(() => {
    if (!location.pathname.includes('/dual/') && tour?.isActive()) tour.hide();
  }, [location.pathname]);
};
