import { useMediaQuery } from '@mui/material';
import { ConnectKitButton } from '@rehold-io/connectkit';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { memo, useDeferredValue, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TransactionReceipt, UserRejectedRequestError } from 'viem';

import { useInsufficientFunds } from 'widgets/Swap/lib/useInsufficientFunds';
import { useSubmit } from 'widgets/Swap/lib/useSubmit';
import { useTrackParamsLazy } from 'widgets/Swap/lib/useTrackParamsLazy';

import { DoubleApproveModal } from 'features/DoubleApprove';
import { HotjarEvents, trackHotjar } from 'features/HotJar';
import { useAllowance } from 'features/SwapTokens';

import { SwapDataResponse, SwapFormValues } from 'entities/Swap';
import { useSwapForm, useSwapTokenByAddress, useSwapTokens } from 'entities/Swap/lib/hooks';
import { SwapStateModal } from 'entities/Swap/ui/SwapStateModal';

import { useAccount } from 'shared/hooks';
import { DEFAULT_ERROR, didUserReject, mapErrorToUserReadableMessage, waitForTransaction } from 'shared/lib';
import { notifyAttention, notifyError } from 'shared/lib/notifications';
import { i18n } from 'shared/locales';
import { ApproveTooltip, Button } from 'shared/ui';

import styles from './SubmitButton.module.scss';

type SwapState = {
  confirm: boolean;
  data?: SwapDataResponse;
  error?: any;
  fail: boolean;
  hash?: string;
  id?: string;
  submitted: boolean;
  success: boolean;
  waitTx?: Promise<TransactionReceipt>;
};

export const SwapSubmitButton: React.FC = memo((props) => {
  const {
    formValues: { fromAmount, ...rest },
  } = useSwapForm();

  const { refetchBalances } = useSwapTokens();

  const defferedInputAmount = useDeferredValue(fromAmount);

  const [swapState, setSwapState] = useState<SwapState>({
    fail: false,
    confirm: false,
    submitted: false,
    success: false,
  });

  const [resetForm, setResetForm] = useState(false);

  const onSwapStart = (id: string, data: SwapDataResponse) => {
    setSwapState({
      data,
      hash: undefined,
      id,
      fail: false,
      confirm: true,
      submitted: false,
      success: false,
    });
  };
  const onSwapFail = (id: string, hash: string | undefined, error: any | undefined) => {
    setSwapState((prev) => {
      if (id === prev.id)
        return {
          data: prev.data,
          hash,
          id: prev.id,
          fail: true,
          confirm: false,
          submitted: false,
          success: false,
          error,
        };

      if (!hash) {
        if (error instanceof UserRejectedRequestError || didUserReject(error)) {
          notifyAttention({ text: i18n.t('common.userRejectedRequest') });
        } else {
          notifyError({
            text: mapErrorToUserReadableMessage(error) || DEFAULT_ERROR,
          });
        }
      }

      return prev;
    });

    refetchBalances();
  };
  const onSwapSubmit = (id: string, hash: string, waitTx: Promise<TransactionReceipt>) => {
    setSwapState((prev) => {
      if (prev.id === id)
        return {
          data: prev.data,
          hash,
          id: prev.id,
          fail: false,
          confirm: false,
          submitted: true,
          success: false,
          waitTx,
        };

      waitForTransaction(waitTx, hash);
      return prev;
    });
  };
  const onSwapSuccess = (id: string, hash: string) => {
    setSwapState((prev) => {
      if (prev.id === id)
        return {
          data: prev.data,
          hash,
          id: prev.id,
          fail: false,
          confirm: false,
          submitted: false,
          success: true,
        };
      return prev;
    });

    refetchBalances();
  };

  return (
    <>
      <SwapStateModal
        data={swapState.data}
        hash={swapState.hash}
        error={swapState.error}
        fail={swapState.fail}
        confirm={swapState.confirm}
        submitted={swapState.submitted}
        success={swapState.success}
        onClose={() => {
          if (swapState.waitTx) waitForTransaction(swapState.waitTx, swapState.hash);
          setSwapState({
            data: undefined,
            hash: undefined,
            id: undefined,
            fail: false,
            confirm: false,
            submitted: false,
            success: false,
          });
          setResetForm(true);
        }}
      />
      <SwapInnerSubmitButton
        {...props}
        {...(rest as SwapFormValues)}
        fromAmount={defferedInputAmount as string}
        resetState={resetForm ? () => setResetForm(false) : undefined}
        onSwapStart={onSwapStart}
        onSwapFail={onSwapFail}
        onSwapSubmit={onSwapSubmit}
        onSwapSuccess={onSwapSuccess}
      />
    </>
  );
});

interface Props {
  onSwapFail: (id: string, hash?: string, error?: any) => void;
  onSwapStart: (id: string, data: SwapDataResponse) => void;
  onSwapSubmit: (id: string, hash: string, waitTx: Promise<TransactionReceipt>) => void;
  onSwapSuccess: (id: string, hash: string) => void;
  resetState?: () => void;
}

export const SwapInnerSubmitButton: React.FC<SwapFormValues & Props> = observer((values) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { isConnected } = useAccount();
  const [isOpenDoubleApproveModal, setIsOpenDoubleApproveModal] = useState(false);

  const { fromAmount, fromToken, toToken, resetState, onSwapFail, onSwapStart, onSwapSubmit, onSwapSuccess } = values;

  const getTrackParams = useTrackParamsLazy(values);

  const { data: inputToken } = useSwapTokenByAddress(fromToken!);

  const { isInsufficientFunds, isLoading: isLoadingBalances } = useInsufficientFunds(fromToken!, fromAmount);

  const {
    formState: { isSubmitting },
    reset: resetForm,
    handleSubmit,
  } = useFormContext<SwapFormValues>();

  const { formValues } = useSwapForm();

  const { isAllowanceLoading, isApproveLoading, isShowAprove, requestApprove, isDoubleApprove } = useAllowance({
    amount: fromAmount,
    address: fromToken!,
  });

  const { isDisabled, isSubmitPreparingError, isSubmitPreparing, onSubmit } = useSubmit(values, {
    onSwapStart,
    onSwapFail,
    onSwapSubmit,
    onSwapSuccess,
  });

  useEffect(() => {
    if (resetState) {
      resetForm(formValues, { keepValues: true, keepDefaultValues: true, keepTouched: true, keepIsSubmitted: false });
      resetState();
    }
  }, [formValues, resetState, resetForm]);

  const onApprovePress = () => {
    const trackParams = getTrackParams(formValues);

    setIsOpenDoubleApproveModal(false);
    track(PredefinedEvents.Swap.Approve.Click(trackParams));
    requestApprove(trackParams);
  };

  const onSubmitPress = () => {
    handleSubmit(onSubmit)();
  };

  const openDoubleApproveModal = () => {
    setIsOpenDoubleApproveModal(true);
  };

  const isSmallMobile = useMediaQuery('(max-height: 715px) and (max-width: 767px)');

  const button = useMemo(
    () => (
      <>
        <div className={classNames(styles.container)}>
          {!isConnected ? (
            <ConnectKitButton.Custom>
              {({ show }) => {
                const onPress = () => {
                  trackHotjar(HotjarEvents.WALLET_CONNECT_CLICK);
                  track(PredefinedEvents.Wallet.Connect.Click({ from: pathname }));
                  show?.();
                };

                return (
                  <Button mt={3} variant="primary" onClick={onPress} data-id="connect-from-swap">
                    {t('modals.connectWallet.title')}
                  </Button>
                );
              }}
            </ConnectKitButton.Custom>
          ) : !fromToken || !toToken ? (
            <Button mt={3} disabled variant="secondary">
              {t('swap.button.submit.select')}
            </Button>
          ) : !fromAmount || !BigNumber(fromAmount).gt(0) ? (
            <Button mt={3} disabled loading={false} variant="secondary" data-id="swap-zero-amount">
              {t('swap.button.submit.amount')}
            </Button>
          ) : isInsufficientFunds ? (
            <Button mt={3} disabled loading={false} variant="secondary" data-id="swap-insufficient-funds">
              {t('common.errors.insufficientFunds')}
            </Button>
          ) : isShowAprove ? (
            <Button
              mt={3}
              variant="secondary"
              onClick={isDoubleApprove ? openDoubleApproveModal : onApprovePress}
              disabled={!fromAmount}
              loading={isApproveLoading}
              data-id="approve-swap"
            >
              {t('common.allowToUse')} {inputToken?.symbol}
              <ApproveTooltip
                content={t('common.allowToUseTooltip', { ticker: inputToken?.symbol })}
                isPermit={false}
              />
            </Button>
          ) : (
            <Button
              mt={3}
              className={classNames({
                [styles['pulse-light']]: !isDisabled && fromAmount && !isSubmitPreparing && !isSubmitPreparingError,
              })}
              variant="primary"
              onClick={onSubmitPress}
              disabled={isDisabled || !fromAmount || isSubmitPreparing || isSubmitPreparingError}
              loading={isSubmitting || isAllowanceLoading || isSubmitPreparing}
              data-id="start-swap"
            >
              {t('swap.button.submit.swap')}
            </Button>
          )}
        </div>
        <DoubleApproveModal
          isOpen={isOpenDoubleApproveModal}
          setIsOpen={setIsOpenDoubleApproveModal}
          onApprove={onApprovePress}
        />
      </>
    ),
    [
      isConnected,
      fromToken,
      toToken,
      isInsufficientFunds,
      isShowAprove,
      isDoubleApprove,
      fromAmount,
      isApproveLoading,
      inputToken,
      isDisabled,
      isSubmitPreparing,
      isSubmitPreparingError,
      isSubmitting,
      isAllowanceLoading,
      isLoadingBalances,
      openDoubleApproveModal,
      onApprovePress,
      onSubmitPress,
    ],
  );

  if (isSmallMobile) return ReactDOM.createPortal(button, document.body);

  return button;
});
