import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

import { authUser } from '../api/queries';

import { AuthResponse } from './types';

export const useAuthQuery = ({ enabled = true, ...options }: UseQueryOptions<AuthResponse, AxiosError>) => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  return useQuery<AuthResponse, AxiosError>([`auth-user-${address}`], () => authUser(address!), {
    enabled: !!address && !!chainId && enabled,
    retry: false,
    ...options,
  });
};
