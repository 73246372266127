import { environment } from '../common';

import { arbitrum } from './arbitrum';
import { avalanche } from './avalanche';
import { base } from './base';
import { bsc } from './bsc';
import { bscTestnet } from './bscTestnet';
import { ethereum } from './ethereum';
import { linea } from './linea';
import { manta } from './manta';
import { optimism } from './optimism';
import { polygon } from './polygon';
import { polygonMumbai } from './polygonMumbai';
import { rootstock } from './rootstock';
import { AppChain } from './types';

const DEBUG = environment !== 'production' || localStorage.getItem('REHOLD_DEBUG') === 'true';

export const supportedChains = [
  ethereum,
  bsc,
  bscTestnet,
  arbitrum,
  polygonMumbai,
  polygon,
  optimism,
  avalanche,
  base,
  linea,
  manta,
  rootstock,
].filter(({ testnet }) => !testnet || DEBUG);

export const CHAIN_INFO = supportedChains.reduce((acc, chain) => {
  acc[chain.id] = chain;
  return acc;
}, {} as Record<number, AppChain<any>>);

export const checkSupportedChain = (chainId: number): boolean => !!CHAIN_INFO[chainId];
