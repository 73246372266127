export class SaveLoadAdapter {
  charts: TradingView.ChartData[] | undefined;
  setTvCharts: (a: TradingView.ChartData[]) => void;
  onSelectToken: (token: string) => void;

  constructor(
    charts: TradingView.ChartData[] | undefined,
    setTvCharts: (a: TradingView.ChartData[]) => void,
    onSelectToken: (token: string) => void,
  ) {
    this.charts = charts;
    this.setTvCharts = setTvCharts;
    this.onSelectToken = onSelectToken;
  }

  getAllCharts() {
    return Promise.resolve(this.charts || []);
  }

  removeChart(id: string) {
    if (!this.charts) return Promise.reject();
    for (let i = 0; i < this.charts.length; ++i) {
      if (this.charts[i].id === id) {
        this.charts.splice(i, 1);
        this.setTvCharts(this.charts);
        return Promise.resolve();
      }
    }

    return Promise.reject();
  }

  saveChart(chartData: any) {
    if (!chartData.id) {
      chartData.id = Math.random().toString();
    } else {
      this.removeChart(chartData.id);
    }

    const currentTimestamp = Math.floor(Date.now() / 1000);
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetSeconds = offsetMinutes * 60;
    const adjustedTimestamp = currentTimestamp - offsetSeconds;

    chartData.timestamp = adjustedTimestamp;
    if (this.charts) {
      this.charts.push(chartData);
      this.setTvCharts(this.charts);
    }

    return Promise.resolve(chartData.id);
  }

  getChartContent(id: string) {
    if (!this.charts) return Promise.reject();
    for (let i = 0; i < this.charts.length; ++i) {
      if (this.charts[i].id === id) {
        const { content, symbol } = this.charts[i];
        this.onSelectToken(symbol);
        return Promise.resolve(content);
      }
    }
    return Promise.reject();
  }
}
