import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
};

export const OnboardingStartTooltip: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="shepherd-content shepherd-has-cancel-icon shepherd-has-title shepherd-enabled">
      <header className={styles.header}>
        <h3 className="shepherd-title">{t('onboarding.step5.title')}</h3>
        <button aria-label="Close Tour" className="shepherd-cancel-icon" type="button" onClick={onClick}>
          <span aria-hidden="true">{t('onboarding.skip')}</span>
        </button>
      </header>
      <div className="shepherd-text" id="step-5-description">
        <p>{t('onboarding.step5.description')}</p>
      </div>
    </div>
  );
};
