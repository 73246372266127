import BigNumber from 'bignumber.js';
import React, { FC, useState } from 'react';
import { formatUnits } from 'viem';

import { useRate } from 'entities/Rates';
import { useSwapForm, useSwapQuote, useSwapTokenByAddress } from 'entities/Swap/lib';
import { useIsWETH } from 'entities/Swap/lib/hooks/useIsWETH';

import { NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { formatCurrency, formatZeroDecimals, roundToNearest } from 'shared/lib';
import { Box, Text } from 'shared/ui';

export const SwapCollapsibleRates = () => {
  const [toggled, setToggled] = useState(true);

  const { input, output } = useSwapForm();
  const {
    data: quote,
    isError: isQuoteError,
    isFetching: isQuoteFetching,
    isInitialLoading: isQuoteInit,
  } = useSwapQuote();

  const { data: inputToken } = useSwapTokenByAddress(input.address);
  const { data: outputToken } = useSwapTokenByAddress(output.address);

  const isWETH = useIsWETH(input.address, output.address);

  const { data: nativeToken } = useSwapTokenByAddress(NATIVE_TOKEN_ADDRESS);
  const { price } = useRate({ from: nativeToken?.symbol, to: 'usd' });

  if (!inputToken || !outputToken) return;

  const inputSymbol = toggled ? inputToken.symbol : outputToken.symbol;
  const outputSymbol = toggled ? outputToken.symbol : inputToken.symbol;

  if (isWETH)
    return (
      <Component
        onClick={() => setToggled((prev) => !prev)}
        input={1}
        inputSymbol={inputSymbol}
        output={1}
        outputSymbol={outputSymbol}
        currency={price}
      />
    );

  const isError = isQuoteError || quote === null;
  const isLoading = isQuoteFetching && isQuoteInit;

  if (!quote || isError || isLoading) return;

  const amounts = {
    inputWei: quote.fromAmount,
    inputEth: formatUnits(BigInt(quote.fromAmount), inputToken.decimals),
    outputWei: quote.toAmount,
    outputEth: formatUnits(BigInt(quote.toAmount), outputToken.decimals),
  };

  const defaultRate = BigNumber(amounts.outputEth).div(amounts.inputEth).toNumber();
  const invertedRate = BigNumber(amounts.inputEth).div(amounts.outputEth).toNumber();

  const rate = toggled ? defaultRate : invertedRate;

  const defaultRateUSD = BigNumber(quote.fromAmountUSD).div(amounts.inputEth).toNumber();
  const invertedRateUSD = BigNumber(quote.toAmountUSD).div(amounts.outputEth).toNumber();

  const rateUSD = toggled ? defaultRateUSD : invertedRateUSD;

  return (
    <Component
      onClick={() => setToggled((prev) => !prev)}
      input={1}
      inputSymbol={inputSymbol}
      output={1 * rate}
      outputSymbol={outputSymbol}
      currency={1 * rateUSD}
    />
  );
};

interface Props {
  currency?: number;
  input: number;
  inputSymbol: string;
  onClick: () => void;
  output: number;
  outputSymbol: string;
}

const Component: FC<Props> = ({ onClick, input, inputSymbol, output, outputSymbol, currency }) => {
  const roundedOutput = roundToNearest(BigNumber(output)).toFixed(20).replace(/0*$/, '');

  return (
    <Box maxWidth="100%" flexDirection="row" alignItems="center" gap={8} flexWrap="wrap" rowGap={8} onClick={onClick}>
      <Text text="app-14-medium">{`${input} ${inputSymbol} = ${
        output !== 1 ? formatZeroDecimals(roundedOutput) : 1
      } ${outputSymbol}`}</Text>
      {currency && (
        <Text style={{ opacity: 0.4 }} text="app-14-medium" color="white-01">
          {`(${formatCurrency(currency)})`}
        </Text>
      )}
    </Box>
  );
};
