import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import React, { useCallback, FC, useState } from 'react';

import { formatApr, type DualAssetType } from 'entities/Dual';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';

import { CHAIN_INFO } from 'shared/config/chains';
import { formatPercent } from 'shared/lib';
import { Box, Text, CurrencyPair, Badge, BoxProps } from 'shared/ui';

import { DualRate } from '../DualRate';

import styles from './DualsTable.module.scss';

type DualsTableAssetProps = {
  className?: string;
  dual: DualAssetType;
  onPress?: (item: DualAssetType) => void;
};

export const DualsTableAsset: FC<DualsTableAssetProps> = ({ className, dual, onPress }) => {
  const { tokens } = useGetDualTokensAllQuery();
  const { apr } = dual;
  const baseTicker = tokens[dual.chainId!]?.[dual.baseTicker]?.symbol!;
  const quoteTicker = tokens[dual.chainId!]?.[dual.quoteTicker]?.symbol!;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useResizeObserver(document.body, (resize) => setScreenWidth(resize.borderBoxSize?.[0]?.inlineSize));

  const handlePress = useCallback(() => {
    if (onPress) onPress(dual);
  }, [dual, onPress]);

  return (
    <tr className={className} onClick={handlePress} data-id="dual-asset">
      <td className={classNames(styles['table-column-spacing'])} />
      <td>
        <Box className={styles['table-item-hoverable']} flexDirection="row" alignItems="center" paddingY="10px">
          <CurrencyPair
            backgroundColor="background-01"
            borderColor={'inherit' as BoxProps['borderColor']}
            renderAsTokens
            major={baseTicker.toLowerCase()}
            minor={quoteTicker.toLowerCase()}
          />
          <Box ml={8}>
            <Text color="white-01">
              {baseTicker}&#183;{quoteTicker}
            </Text>

            <DualRate from={baseTicker} to={quoteTicker} />
          </Box>
        </Box>
      </td>
      <td className={styles['table-column-disappearing']}>
        <Box width="100%" alignItems="center">
          {CHAIN_INFO[dual.chainId || -1]?.renderLogo(32)}
        </Box>
      </td>
      <td>
        <Box width="100%" alignItems="end">
          <Badge>
            <Text color="black-01" whiteSpace="nowrap">
              {screenWidth <= 390
                ? `${formatPercent({ decimals: 0, value: apr })}%${screenWidth > 340 ? ' APR' : ''}`
                : formatApr(apr)}
            </Text>
          </Badge>
        </Box>
      </td>
      <td className={classNames(styles['table-column-spacing'])} />
    </tr>
  );
};
