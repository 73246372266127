/* eslint-disable no-nested-ternary */
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCalculateOutput } from 'widgets/Dual/lib/useCalculateOutput';
import { DashboardFormValues } from 'widgets/Dual/model/form';

import { AutoReplayField } from 'features/AutoReplay';

import { useInputTickerLimits } from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

export const DualAutoReplayField: FC = observer(() => {
  const { t } = useTranslation();
  const { tokens } = useGetDualTokensQuery();
  const { control } = useFormContext<DashboardFormValues>();
  const [inputAmount, inputTicker, tariff] = useWatch({
    control,
    name: ['inputAmount', 'inputTicker', 'tariff'],
  });

  const { output } = useCalculateOutput();

  const { limit: limitDownOutput } = useInputTickerLimits(output?.down?.outputTicker || '', tariff);
  const { limit: limitUpOutput } = useInputTickerLimits(output?.up?.outputTicker || '', tariff);

  const isDisabledLimitAmountLess = output
    ? BigNumber(output.down.outputAmount).lt(BigNumber(limitDownOutput.min)) ||
      BigNumber(output.up.outputAmount).lt(BigNumber(limitUpOutput.min))
    : false;
  const isDisabledLimitAmountMore = output
    ? BigNumber(output.down.outputAmount).gt(BigNumber(limitDownOutput.max)) ||
      BigNumber(output.up.outputAmount).gt(BigNumber(limitUpOutput.max))
    : false;

  const error = isDisabledLimitAmountLess
    ? t('dual.autoReplay.validation.amountMore', {
        downAmount: `${limitDownOutput.min} ${tokens[output?.down?.outputTicker || '']?.symbol}`,
        upAmount: `${limitUpOutput.min} ${tokens[output?.up?.outputTicker || '']?.symbol}`,
      })
    : isDisabledLimitAmountMore
    ? t('dual.autoReplay.validation.amountLess', {
        downAmount: `${limitDownOutput.max} ${tokens[output?.down?.outputTicker || '']?.symbol}`,
        upAmount: `${limitUpOutput.max} ${tokens[output?.up?.outputTicker || '']?.symbol}`,
      })
    : undefined;

  return (
    <AutoReplayField error={error} inputAmount={Number(inputAmount)} inputTicker={inputTicker} isDisabledValidation />
  );
});
