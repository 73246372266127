import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useState } from 'react';

import { ClaimDualButton } from 'features/ClaimDual';
import { useHappyMoments } from 'features/HappyMoments';
import { ReplayDual } from 'features/ReplayDual';

import { DualChartWithProfit, DualInputs, DualType, formatApr, useDualModal } from 'entities/Dual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, PageContent, Badge } from 'shared/ui';

import { Details } from '../Details';

type Props = {
  dual: DualType;
  onClose: () => void;
};

export const ActiveDual: FC<Props> = observer(({ dual, onClose }) => {
  const queryClient = useQueryClient();
  const { chainId } = useNetwork();
  const { address } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const { setValues } = useHappyMoments();

  const { setDualParams } = useDualModal();

  const onSuccessClaim = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: [`claimed-duals-${chainId}-${address}`],
    });
    queryClient.invalidateQueries({
      queryKey: [`closed-duals-${chainId}-${address}`],
    });
    queryClient.invalidateQueries({
      queryKey: [`count-duals-${chainId}-${address}`],
    });

    onClose();
    setValues({ amount: dual.outputAmount, ticker: dual.outputTicker, type: 'dual-claim' });
  }, [dual, setValues, onClose, chainId, address, queryClient]);

  const onStart = useCallback(() => {
    setIsLoading(true);
  }, []);
  const onError = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onOpen = () => {
    onClose();

    setDualParams({ id: dual.id, type: 'replay' });
  };

  return (
    <PageContent className="hide-horizontal-scroll" px={24} pb={24} mt={20}>
      <Box flexDirection="row" justifyContent="space-between" mb={19} alignItems="center">
        <DualInputs dual={dual} pairBg="background-01" />
        <Box>
          <Badge>{formatApr(dual.apr)}</Badge>
        </Box>
      </Box>
      <DualChartWithProfit dual={dual} />
      <Details dual={dual} />
      {!!dual.closedPrice && (
        <Box mt={24} zIndex={1}>
          <ReplayDual dual={dual} onOpen={onOpen} isDisabled={isLoading} type="button" />
          <ClaimDualButton dual={dual} onSuccess={onSuccessClaim} onStart={onStart} onError={onError} />
        </Box>
      )}
    </PageContent>
  );
});
