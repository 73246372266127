import { useCallback } from 'react';

import { storageSignature } from 'features/Auth/lib';

import { useAutoReplayMutation } from 'entities/Dual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

export const useSetAutoReplayMutation = () => {
  const { mutateAsync: setAutoReplay, ...values } = useAutoReplayMutation();
  const { address } = useAccount();
  const { chainId } = useNetwork();

  const changeAutoReplay = useCallback(
    async (id: string, enable = true) => {
      const signature = storageSignature.get(address!);
      if (signature && chainId) {
        return setAutoReplay({ autoReplay: enable, chainId, id, signature });
      }
      return Promise.reject(new Error('not signature'));
    },
    [setAutoReplay, address, chainId],
  );

  return { changeAutoReplay, values };
};
