import { observer } from 'mobx-react-lite';
import React from 'react';

import { useAccount, useIsMobile } from 'shared/hooks';
import { Box, Spinner } from 'shared/ui';

import { useReferral, useReferralCodeLinkLoader, useReferralData } from './lib';
import {
  ConnectAccountButton,
  ReferralProgramReferralsTotalEarned,
  ReferralProgramInviteFriends,
  ReferralProgramInviteFriendsText,
  ReferralProgramHowItWorks,
  ReferralProgramReferralsClaims,
  ReferralProgramReferralsAccumulated,
} from './ui';

export const FeatureReferralProgram: React.FC = observer(() => {
  const { isConnected } = useAccount();

  const { balanceLoading, revShareFeeLoading } = useReferral();
  const { isLoading: codeLoading } = useReferralCodeLinkLoader();
  const { isLoading: isLoadingReferrals, referrals } = useReferralData();

  const isLoading = isConnected
    ? (revShareFeeLoading && balanceLoading && codeLoading) || isLoadingReferrals
    : revShareFeeLoading;

  const isMobile = useIsMobile();

  if (isLoading) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  return referrals ? (
    <>
      <ReferralProgramReferralsTotalEarned />
      <ReferralProgramReferralsAccumulated />
      <ReferralProgramInviteFriendsText />
      <ReferralProgramInviteFriends />
      <Box mt={24} mb={isMobile ? 82 : 0}>
        <ReferralProgramReferralsClaims />
      </Box>
    </>
  ) : (
    <>
      <ReferralProgramInviteFriendsText />
      {isConnected && <ReferralProgramInviteFriends />}
      {!isConnected && <ConnectAccountButton />}
      <Box mt={16} mb={isMobile ? 82 : 0}>
        <ReferralProgramHowItWorks />
      </Box>
    </>
  );
});
