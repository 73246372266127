import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useGetDualsOverviewQuery } from 'entities/Dual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, BoxProps, Text, Spinner } from 'shared/ui';

import { formatCurrency } from '../../../../shared/lib/formats/format';

export const Overview: React.FC<BoxProps> = observer((props) => {
  const { t } = useTranslation();
  const { chainId } = useNetwork();
  const { address } = useAccount();

  const { data, isLoading } = useGetDualsOverviewQuery();

  if (!address || !chainId) {
    return null;
  }

  return (
    <Box flexDirection="row" justifyContent="space-between" alignItems="center" {...props}>
      <Box flexBasis={0} flexGrow={1}>
        <Text opacity={0.6} width="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {t('dual.totalProfit')}
        </Text>
        <Box flexDirection="row" alignItems="center" mt={8}>
          <Box>
            <Text text="app-22-medium">{formatCurrency(Number(data?.profit || 0), 2)}</Text>
          </Box>
          {isLoading && (
            <Box ml={8}>
              <Spinner size={24} />
            </Box>
          )}
        </Box>
      </Box>
      <Box flexBasis={0} flexGrow={1}>
        <Text opacity={0.6} textAlign="end" width="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {t('dual.currentProfit')}
        </Text>
        <Box flexDirection="row" alignItems="center" justifyContent="flex-end" mt={8}>
          <Box>
            <Text text="app-22-medium" color={BigNumber(data?.currentProfit || 0).gt(0) ? 'primary-01' : 'white-01'}>
              {formatCurrency(Number(data?.currentProfit || 0), 2)}
            </Text>
          </Box>
          {isLoading && (
            <Box ml={8}>
              <Spinner size={24} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
});
