import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { arbitrum } from 'viem/chains';
import { useDisconnect } from 'wagmi';

import { useGetDomain } from 'entities/Domain';
import { useNomisScore } from 'entities/User';

import { WalletIcon, ForwardIcon, DisconnectIcon, CopyIcon, NomisImage } from 'shared/assets';
import { linea } from 'shared/config/chains/linea';
import { useBlockExplorerLink, useCopy, useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { shortenIfAddress } from 'shared/lib';
import { availableWallets } from 'shared/lib/rehold-wallets';
import theme from 'shared/theme';
import { Box, BoxProps, Text, Tooltip, IconByUrl } from 'shared/ui';

export const EXPLORER_LINKS = {
  56: 'https://bscscan.com/address',
  97: 'https://testnet.bscscan.com/address',
} as { [key: number]: string };

export const NOMIS_LINK_LINEA = 'https://nomis.cc/linea-voyage';
export const NOMIS_LINK_ARBITRUM = 'https://nomis.cc/layerzero-verax';
export const DEFI_ANTIVIRUS_LINK = 'https://de.fi/scanner';

export const getExplorerLink = (chainId: number, address: string) => `${EXPLORER_LINKS[chainId]}/${address}`;

export const WalletMenu: FC = () => (
  <>
    <WalletMenuDisconnect />
    <WalletMenuConnectedWallet mb={-8} />
  </>
);

export const WalletMenuDisconnect: FC<BoxProps> = (props) => {
  const { t } = useTranslation();
  const { disconnectAsync } = useDisconnect();
  const { address, connector } = useAccount();
  const { pathname: from } = useLocation();

  const handlePressDisconnect = useCallback(async () => {
    await disconnectAsync();

    if (address && connector) {
      track(PredefinedEvents.Wallet.Disconnect.Success({ address, from, provider: connector.name }));
    }
  }, [address, connector, disconnectAsync, from]);

  return (
    <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={48} px={24} {...props}>
      <Box flexDirection="row" alignItems="center">
        <Box width={24} height={24} mr={8} color="white-01">
          <WalletIcon width="100%" height="100%" />
        </Box>
        <Text>{t('menu.wallet.wallet')}</Text>
      </Box>
      <Box
        flexDirection="row"
        alignItems="center"
        height={32}
        ml={8}
        onClick={handlePressDisconnect}
        data-id="wallet-disconnect"
      >
        <Text text="app-12-medium" color="secondary-03">
          {t('menu.wallet.disconnect')}
        </Text>
        <Box width={16} height={16} ml={4} color="secondary-03">
          <DisconnectIcon width="100%" height="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export const WalletMenuConnectedWallet: FC<BoxProps> = observer((props) => {
  const { t } = useTranslation();
  const { address, connector, isConnected } = useAccount();
  const { network } = useNetwork();

  const copy = useCopy(address);

  const handlePressCopyAddress = useCallback(() => {
    if (address) {
      track(PredefinedEvents.Wallet.Address.Copy({ address }));
      copy();
    }
  }, [address, copy]);

  const { name: domain } = useGetDomain();

  const link = useBlockExplorerLink('address', address);

  const currentWallet = availableWallets.find((w) => w.connector.id === connector?.id);

  const onLinkClick = () => {
    track(PredefinedEvents.Wallet.Address.ViewExplorer({ address: address! }));
  };

  if (!isConnected) {
    return null;
  }

  const nomisLink = useMemo(
    () => (network?.network === linea.network ? NOMIS_LINK_LINEA : NOMIS_LINK_ARBITRUM),
    [network?.network],
  );

  const nomisScoreData = useNomisScore({});
  const nomisScore = BigNumber(nomisScoreData.data?.score || 0)
    .toFixed(2)
    .replace(/0*$/, '');
  const formattedNomisScore = !nomisScore.split('.')[1] ? nomisScore.split('.')[0] : nomisScore;

  const slice = domain ? 20 : 4;
  const viewAddress = domain || address || '';

  return (
    <Box bg="secondary-02" px={24} pt={24} pb={16} mx={16} mt={8} mb={8} borderRadius={16} {...props}>
      <Text text="app-12-regular" mb={10}>
        {connector?.name}
      </Text>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" mb={4}>
        <Box flexDirection="row" alignItems="center" flex="1 1 auto">
          {currentWallet && (
            <Box width={24} height={24} mr={8}>
              <IconByUrl url={currentWallet.iconPath} size={24} />
            </Box>
          )}
          <Tooltip
            title={viewAddress}
            PopperProps={{
              disablePortal: true,
            }}
            disabled={!domain || viewAddress?.length <= slice}
          >
            <Text mr={8} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
              {shortenIfAddress(viewAddress, slice)}
            </Text>
          </Tooltip>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          width={24}
          height={24}
          onClick={handlePressCopyAddress}
          color="secondary-03"
        >
          <CopyIcon width="100%" height="100%" />
        </Box>
      </Box>
      {/* {(network?.network === linea.network || network?.network === arbitrum.network) && nomisScoreData.data && (
        <Box flexDirection="row" alignItems="center" gap={8} mb={8}>
          <NomisImage width={24} height={24} />
          <a href={nomisLink} target="_blank" rel="noreferrer">
            <Box flexDirection="row" alignItems="center" gap={8}>
              {formattedNomisScore === '0' ? (
                <Text color="secondary-03">{t('nomis.notAttested')}</Text>
              ) : (
                <Text color="secondary-03">{t('nomis.score', { score: formattedNomisScore })}</Text>
              )}
              <ForwardIcon color={theme.colors['secondary-03']} width={16} height={16} />
            </Box>
          </a>
        </Box>
      )} */}
      <Box flexDirection="row" justifyContent="space-between" alignItems="center">
        <a href={link?.url} target="_blank" rel="noreferrer" onClick={onLinkClick}>
          <Box flexDirection="row" alignItems="center" height={32}>
            <Text text="app-12-regular" color="secondary-03">
              {t('common.viewOnExplorer')}
            </Text>
            <Box width={16} height={16} ml={4} color="secondary-03">
              <ForwardIcon width="100%" height="100%" />
            </Box>
          </Box>
        </a>
      </Box>
    </Box>
  );
});
