import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LinkIcon, UserCheckIcon, GiftIcon } from 'shared/assets';
import { Box, Text } from 'shared/ui';

import { useReferral } from '../../lib';

const referralProgramHowItWorksListItems = [
  {
    Icon: LinkIcon,
    description: 'referralProgram.mainPage.howItWorks.invite.description',
    title: 'referralProgram.mainPage.howItWorks.invite.title',
  },
  {
    Icon: UserCheckIcon,
    description: 'referralProgram.mainPage.howItWorks.wait.description',
    title: 'referralProgram.mainPage.howItWorks.wait.title',
  },
  {
    Icon: GiftIcon,
    description: 'referralProgram.mainPage.howItWorks.earn.description',
    title: 'referralProgram.mainPage.howItWorks.earn.title',
  },
];

export const ReferralProgramHowItWorks: FC = observer(() => {
  const { revShareFee, revShareFeeError, revShareFeeLoading } = useReferral();
  const { t } = useTranslation();

  if (revShareFeeError || revShareFeeLoading) {
    return null;
  }

  return (
    <Box>
      <Box mb={24}>
        <Text text="app-22-medium">{t('referralProgram.mainPage.howItWorks.title')}</Text>
      </Box>
      <Box>
        {referralProgramHowItWorksListItems.map((item, index, list) => (
          <ReferralProgramHowItWorksItem
            {...item}
            // eslint-disable-next-line react/no-array-index-key
            key={`list-item-${index}`}
            revShareFee={revShareFee}
            last={index === list.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
});

export interface ReferralProgramHowItWorksItemProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  description: string;
  last: boolean;
  revShareFee?: string;
  title: string;
}

export const ReferralProgramHowItWorksItem: React.FC<ReferralProgramHowItWorksItemProps> = ({
  Icon,
  description,
  last,
  revShareFee,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="row" minHeight={64}>
      <Box alignItems="center" mr={16}>
        <Box
          justifyContent="center"
          alignItems="center"
          width={56}
          height={56}
          borderRadius={32}
          bg="secondary-04"
          color="primary-01"
        >
          <Icon width={24} height={24} />
        </Box>
        {!last && <Box flexGrow={1} width={2} bg="secondary-04" />}
      </Box>
      <Box flex={1}>
        <Text text="app-18-medium" color="primary-01" mb={8}>
          {t(title as any)}
        </Text>
        <Text color="white-02" mb={last ? 0 : 32}>
          {t(description as any, { revShareFee })}
        </Text>
      </Box>
    </Box>
  );
};
