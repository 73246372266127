import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getPerpetualTariffs } from 'entities/Perpetual/api';

import { PerpetualTariffsResponse } from '../data';

export const useGetPerpetualTariffsQuery = (options?: UseQueryOptions<PerpetualTariffsResponse, {}>) =>
  useQuery<PerpetualTariffsResponse, {}>([`perpetual-tariffs`], getPerpetualTariffs, {
    ...options,
    enabled: true,
    cacheTime: 1000,
  });
