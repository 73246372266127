import { useMemo } from 'react';
import { Address, parseUnits } from 'viem';

import { useSwapTokens } from 'entities/Swap/lib';
import { getTokenByAddress } from 'entities/Token';
import { UserToken } from 'entities/Token/model/types';

export const useInsufficientFunds = (address: string, amount: string) => {
  const { data: tokens, isLoading } = useSwapTokens();

  const token = useMemo(
    () => (tokens ? getTokenByAddress<UserToken>(tokens, address as Address) : undefined),
    [address, tokens],
  );

  return {
    isInsufficientFunds:
      token && token.balance && amount
        ? BigInt(token.balance) < parseUnits(amount as `${number}`, token.decimals)
        : false,
    isLoading,
  };
};
