import { useAccount } from 'shared/hooks';

import { useGetPerpetualOpenedPositionsQuery } from '../../model/hooks/useGetPerpetualOpenedPositionsQuery';

export const usePerpetualOpenedPositions = () => {
  const { address } = useAccount();

  const query = useGetPerpetualOpenedPositionsQuery({ address: address || '' }, { enabled: !!address });

  return query;
};
