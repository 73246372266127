import { useMediaQuery } from '@mui/material';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { DEFAULT_PRICE_DECIMALS, PRICE_DECIMALS } from '@rehold-io/formatters';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues, NumericFormat, OnValueChange } from 'react-number-format';

import { DownIcon, UpIcon } from 'shared/assets';
import { MAX_INPUT_VALUE } from 'shared/lib';
import { Box } from 'shared/ui';
import { StyledText } from 'shared/ui/Error/styled';

import { useLimitDualInfo } from '../lib/useLimitDualInfo';

import { StyledContainer, StyledCard } from './styles';

type Props = {
  asset: string;
};

export const LimitsDual: FC<Props> = observer(({ asset }) => {
  const { t } = useTranslation();
  const { changePerc, changeTargetPrice, errorText, perc, targetPrice } = useLimitDualInfo();

  const handlePriceChange: OnValueChange = (values, sourceInfo) => {
    if (sourceInfo.source === 'event') changeTargetPrice(values.value ?? '0');
  };

  const handleDistanceChange: OnValueChange = (values, sourceInfo) => {
    if (sourceInfo.source === 'event') changePerc(values.value ?? '0');
  };

  const checkPercDistance = ({ floatValue }: NumberFormatValues) => (floatValue ?? 0) >= -100;

  const onClickUpIcon = () => {
    changePerc(Number(perc) > 100 ? '-100' : `${-perc}`);
    track(PredefinedEvents.LimitDual.Reverse.Click({ direction: 'up' }));
  };

  const onClickDownIcon = () => {
    changePerc(`${-perc}`);
    track(PredefinedEvents.LimitDual.Reverse.Click({ direction: 'down' }));
  };

  const isSmallMobile = useMediaQuery('(max-width: 534px)', { noSsr: false });

  return (
    <StyledContainer>
      <StyledCard variant="secondary" label={t('dual.targetPrice')} isOutlined>
        <NumericFormat
          className="dual-limit-input"
          displayType="input"
          type="text"
          inputMode="decimal"
          onValueChange={handlePriceChange}
          value={targetPrice}
          allowLeadingZeros={false}
          allowNegative={false}
          allowedDecimalSeparators={['.', ',']}
          defaultValue={0}
          valueIsNumericString
          decimalScale={PRICE_DECIMALS[asset] || DEFAULT_PRICE_DECIMALS}
        />
      </StyledCard>
      {!!errorText && (
        <StyledText
          $align="left"
          className="triangle-up"
          position="absolute"
          bottom={0}
          zIndex={10}
          borderRadius="8px"
          px={8}
          py={4}
          bgColor="red-01"
          text="app-12-regular"
          left={0}
          right="unset"
          maxWidth={isSmallMobile ? '100%' : '534px'}
        >
          {errorText}
        </StyledText>
      )}
      <StyledCard variant="secondary" label={t('dual.distance')} isOutlined>
        <NumericFormat
          className={classNames('dual-limit-input', { 'dual-down': Number(perc) < 0, 'dual-up': Number(perc) > 0 })}
          displayType="input"
          type="text"
          inputMode="decimal"
          onValueChange={handleDistanceChange}
          isAllowed={checkPercDistance}
          value={perc}
          allowNegative
          allowLeadingZeros={false}
          max={MAX_INPUT_VALUE}
          decimalScale={2}
          allowedDecimalSeparators={['.', ',']}
          defaultValue={0}
          valueIsNumericString
          min={-100}
          suffix="%"
        />
        <Box justifyContent="center">
          {Number(perc) > 0 && <UpIcon width={32} height={32} role="button" onClick={onClickUpIcon} />}
          {Number(perc) < 0 && <DownIcon width={32} height={32} role="button" onClick={onClickDownIcon} />}
        </Box>
      </StyledCard>
    </StyledContainer>
  );
});
