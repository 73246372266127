import { useMediaQuery } from '@mui/material';
import { ConnectKitButton } from '@rehold-io/connectkit';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { memo, useDeferredValue, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { handleOnboarding } from 'widgets/Dual/lib/handleOnboarding';
import { useInsufficientFunds } from 'widgets/Dual/lib/useInsufficientFunds';
import { useSubmit } from 'widgets/Dual/lib/useSubmit';
import { DashboardFormValues } from 'widgets/Dual/model/form';

import { useSetAutoReplayMutation } from 'features/AutoReplay';
import { useAllowance, usePermit } from 'features/CreateDual';
import { DoubleApproveModal } from 'features/DoubleApprove';
import { HelpStartTooltip } from 'features/HelpStart';
import { HotjarEvents, trackHotjar } from 'features/HotJar';
import { useLimitDualInfo } from 'features/LimitsDual';
import { useTour, TOUR_CLASSES } from 'features/OnboardingTour';

import { useGetSettingsDualsQuery } from 'entities/Dual';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';
import { waitForUserAction } from 'entities/User';

import { CHAIN_INFO } from 'shared/config/chains';
import { useAccount } from 'shared/hooks';
import { useNetwork, useSwitchNetwork } from 'shared/hooks/network';
import { logger } from 'shared/lib/logger';
import { ApproveTooltip, Button } from 'shared/ui';

import { useTrackParams } from '../../lib/useTrackParams';

import styles from './SubmitButton.module.scss';

export const SubmitButton: React.FC = memo(() => {
  const { control } = useFormContext<DashboardFormValues>();
  const values = useWatch({ control });
  const { inputAmount, ...rest } = values;

  const defferedInputAmount = useDeferredValue(inputAmount);

  return <InnerSubmitButton {...(rest as DashboardFormValues)} inputAmount={defferedInputAmount as string} />;
});

export const InnerSubmitButton: React.FC<DashboardFormValues> = observer((values) => {
  const location = useLocation();
  const { isConnected } = useAccount();
  const { chainId: connectedChainId } = useNetwork();
  const isSmallMobile = useMediaQuery('(max-height: 820px) and (max-width: 767px)');

  const { switchNetwork } = useSwitchNetwork();

  const { t } = useTranslation();
  const { tour } = useTour();

  const [isOpenDoubleApproveModal, setIsOpenDoubleApproveModal] = useState(false);

  const { isLoading: isLoadingSettings } = useGetSettingsDualsQuery(values.chainId);
  const { tokens } = useGetDualTokensAllQuery();

  const { enabled: enabledLimitDual } = useLimitDualInfo();
  const { inputAmount, inputTicker } = values;

  const isSwitchRequired = connectedChainId !== values.chainId;
  const [isSwitching, setSwitching] = useState(false);

  const { isInsufficientFunds, isLoading: isLoadingBalances } = useInsufficientFunds(
    inputTicker,
    inputAmount,
    values.chainId,
  );

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useFormContext<DashboardFormValues>();

  const { isAllowanceLoading, isApproveLoading, isShowAprove, isSupportPermit, requestApprove, isDoubleApprove } =
    useAllowance({
      inputAmount,
      inputTicker,
      isDisabledPermit: enabledLimitDual,
    });

  const trackParams = useTrackParams(values as DashboardFormValues);
  const {
    isDisabled,
    isError: isErrorPrepare,
    isLoading: isLoadingSubmit,
    onSubmit,
  } = useSubmit(values as DashboardFormValues);

  const {
    values: { isLoading: isLoadingAutoReplay },
  } = useSetAutoReplayMutation();

  const { requestPermit } = usePermit(tokens[values.chainId]?.[values.inputTicker]?.address!);

  const onApprovePress = () => {
    setIsOpenDoubleApproveModal(false);
    if (isSupportPermit) {
      track(PredefinedEvents.Dual.Permit.Click(trackParams));
      requestPermit(trackParams);
    } else {
      track(PredefinedEvents.Dual.Approve.Click(trackParams));
      trackHotjar(HotjarEvents.DUAL_APPROVE_CLICK);
      requestApprove?.(trackParams);
    }
  };

  const onSubmitPress = () => {
    handleSubmit(onSubmit)();
  };

  const openDoubleApproveModal = () => {
    setIsOpenDoubleApproveModal(true);
  };

  const onSwitchNetworkPress = async () => {
    setSwitching(true);
    try {
      await waitForUserAction(switchNetwork(values.chainId), {
        title: t('modals.switchNetwork.title'),
        description: t('modals.switchNetwork.message', { chain: CHAIN_INFO[values.chainId].name }),
      });
    } catch (e: any) {
      logger.error(e);
    }
    setSwitching(false);
  };

  const isUnavailableCreation =
    !!(isErrorPrepare as any)?.message?.match('Dual: Creating new duals is unavailable now') ||
    !!(isErrorPrepare as any)?.message?.match('Pausable: paused');

  useEffect(() => {
    handleOnboarding({
      tour,
      isApprove: isShowAprove,
      isConnected,
      isInsufficientFunds,
      isSwitch: isSwitchRequired,
      isUnavalable: isUnavailableCreation,
    });
  }, [isUnavailableCreation, isSwitchRequired, isInsufficientFunds, isShowAprove]);

  let button = (
    <>
      <div
        className={classNames(
          styles.container,
          TOUR_CLASSES.START.class,
          TOUR_CLASSES.APROVE.class,
          TOUR_CLASSES.SWITCH_CHAIN.class,
          TOUR_CLASSES.INSUFFICIENT_FUNDS.class,
        )}
      >
        {isUnavailableCreation ? (
          <Button
            mt={3}
            className={TOUR_CLASSES.UNAVAILABLE_CREATION.button}
            key={TOUR_CLASSES.UNAVAILABLE_CREATION.button}
            disabled
            variant="secondary"
          >
            {t('common.errors.unavailableCreation')}
          </Button>
        ) : isSwitchRequired ? (
          <Button
            mt={3}
            className={TOUR_CLASSES.SWITCH_CHAIN.button}
            key={TOUR_CLASSES.SWITCH_CHAIN.button}
            loading={isSwitching}
            variant="secondary"
            onClick={onSwitchNetworkPress}
            data-id="switch-network"
          >
            {t('common.errors.switchNetwork')}
          </Button>
        ) : isInsufficientFunds ? (
          <Button
            mt={3}
            className={TOUR_CLASSES.INSUFFICIENT_FUNDS.button}
            key={TOUR_CLASSES.INSUFFICIENT_FUNDS.button}
            disabled
            loading={isLoadingBalances}
            variant="secondary"
            data-id="insufficient-funds"
          >
            {t('common.errors.insufficientFunds')}
          </Button>
        ) : isShowAprove ? (
          <Button
            mt={3}
            className={TOUR_CLASSES.APROVE.button}
            key={TOUR_CLASSES.APROVE.button}
            variant="secondary"
            onClick={isDoubleApprove ? openDoubleApproveModal : onApprovePress}
            disabled={!inputAmount}
            loading={isApproveLoading || isLoadingSettings || isLoadingAutoReplay}
            data-id="approve-create"
          >
            {t('common.allowToUse')} {tokens[values.chainId]?.[values.inputTicker]?.symbol}
            <ApproveTooltip
              content={t('common.allowToUseTooltip', {
                ticker: tokens[values.chainId]?.[values.inputTicker]?.symbol,
              })}
              isPermit={isSupportPermit}
            />
          </Button>
        ) : (
          <HelpStartTooltip
            disabled={isLoadingSubmit || isSubmitting || isAllowanceLoading || isLoadingSettings || !!tour?.isActive()}
            className={TOUR_CLASSES.START.button}
          >
            <Button
              mt={3}
              key={TOUR_CLASSES.START.button}
              className={classNames(TOUR_CLASSES.START.button, { [styles['pulse-light']]: !isDisabled && inputAmount })}
              variant="primary"
              onClick={onSubmitPress}
              disabled={isDisabled || !inputAmount}
              loading={isSubmitting || isAllowanceLoading || isLoadingSettings || isLoadingSubmit}
              data-id="start-create"
            >
              {t(enabledLimitDual ? 'dual.createLimitDual' : 'dual.submit')}
            </Button>
          </HelpStartTooltip>
        )}
      </div>
      <DoubleApproveModal
        isOpen={isOpenDoubleApproveModal}
        setIsOpen={setIsOpenDoubleApproveModal}
        onApprove={onApprovePress}
      />
    </>
  );

  if (!isConnected) {
    button = (
      <div className={classNames(styles.container, TOUR_CLASSES.FORM_CONNECT.class)}>
        <ConnectKitButton.Custom>
          {({ show }) => {
            const onPress = () => {
              trackHotjar(HotjarEvents.WALLET_CONNECT_CLICK);
              track(PredefinedEvents.Wallet.Connect.Click({ from: location.pathname }));
              show?.();
            };

            return (
              <Button mt={3} variant="primary" onClick={onPress} data-id="connect-from-create">
                {t('modals.connectWallet.title')}
              </Button>
            );
          }}
        </ConnectKitButton.Custom>
      </div>
    );
  }

  if (isSmallMobile) {
    // needs to be rendered in body because of iOS overflow scrolling bug
    // Issue: https://rehold.youtrack.cloud/issue/DEV-196/Forma-sozdaniya-duala-perekryvaet-knopku-Start-Now-na-Iphone-SE
    // Resources:
    // - https://gist.github.com/nicolaskopp/637aa4e20c66fe41a6ea2a0773935f6e
    // - https://www.bram.us/2021/07/08/the-large-small-and-dynamic-viewports/
    // - https://stackoverflow.com/questions/55272266/webkit-fill-available-is-not-working-as-expected-top-and-bottom-of-divs-verti
    // - https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
    // Some of elements in layout don't fit correctly into 100vh.
    return ReactDOM.createPortal(button, document.body);
  }

  return button;
});
