import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import BigNumber from 'bignumber.js';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getMaxAmountForSwap } from 'widgets/Swap/lib/getMaxAmountForSwap';

import { SwapAssetField } from 'entities/Swap';
import { useSwapForm, useSwapQuote, useSwapTokenByAddress } from 'entities/Swap/lib';

import { useNetwork } from 'shared/hooks/network';

import { NATIVE_TOKEN_ADDRESS } from '../../../../shared/config/swap';

export const SwapInputField = () => {
  const { t } = useTranslation();
  const { network } = useNetwork();

  const { input, output, update, reverse } = useSwapForm();

  const { isFetching, isInitialLoading, error } = useSwapQuote();

  const { data: inputToken } = useSwapTokenByAddress(input.address);

  return (
    <SwapAssetField
      title={t('common.youPay')}
      value={input}
      isFetching={isFetching && isInitialLoading}
      error={error}
      onChange={(value) => {
        if (value.address === output.address) {
          reverse();
          return;
        }
        update({
          input: {
            ...value,
            amountUSD: BigNumber(value.amount).isEqualTo(0) || value.amount === '' ? null : value.amountUSD,
          },
          output: {
            ...output,
            amount: BigNumber(value.amount).isEqualTo(0) || value.amount === '' ? '' : output.amount,
            amountUSD: BigNumber(value.amount).isEqualTo(0) || value.amount === '' ? '' : output.amountUSD,
          },
        });
      }}
      onBalanceClick={(balance: string) => {
        const inputAmount =
          input.address === NATIVE_TOKEN_ADDRESS ? getMaxAmountForSwap(network?.network, balance) : balance;

        update({
          input: {
            amount: inputAmount,
            amountUSD: BigNumber(inputAmount).eq(0) ? null : input.amountUSD,
          },
          output: {
            amount: BigNumber(inputAmount).eq(0) ? '' : output.amount,
          },
        });
        if (inputToken) track(PredefinedEvents.Swap.Balance.Click({ ticker: inputToken.symbol }));
      }}
    />
  );
};
