import { UseQueryResult } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DualRequestArgs, DualType } from 'entities/Dual';

import { Box, BoxProps, Button, Text, Spinner } from 'shared/ui';

import { Dual } from '../Dual';

type Props = {
  queryResult: UseQueryResult<DualType[], DualRequestArgs>;
} & BoxProps;

export const Closed: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { data, isError, isFetching, isLoading, refetch } = props.queryResult;

  if (!isError && !isLoading && !data?.length) {
    return null;
  }

  return (
    <Box {...props}>
      <Box mb={16} flexDirection="row">
        <Text text="app-18-medium">{t('dual.completed')}</Text>
        {data?.length && isFetching && (
          <Box ml={8}>
            <Spinner size={24} />
          </Box>
        )}
      </Box>

      {(isLoading || isError) && (
        <Box height={150} justifyContent="center" alignItems="center">
          {isLoading && <Spinner />}
          {isError && (
            <Button onClick={() => refetch()} data-id="refetch-closed-dual">
              {t('common.refetch')}
            </Button>
          )}
        </Box>
      )}

      {!isError && data?.map((dual, index) => <Dual key={dual.id} dual={dual} mt={index === 0 ? 0 : 24} />)}
    </Box>
  );
});

Closed.displayName = 'Closed_Duals';
