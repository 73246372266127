import BigNumber from 'bignumber.js';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { CFC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetSettingsDualsQuery } from 'entities/Dual';

type Props = {
  calcPerc: (value: string) => string;
  calcPrice: (value: string) => string;
  changePerc: (value: string) => void;
  changeTargetPrice: (value: string) => void;
  enabled: boolean;
  errorText: string;
  initialPrice: number;
  isError: boolean;
  isLoading: boolean;
  perc: string;
  price: null | string;
  setEnabled: (enabled: boolean) => void;
  setError: (error: string) => void;
  setIsLoading: (loading: boolean) => void;
  targetPrice: string;
};

export const LimitDualInfoContext = React.createContext({} as Props);

type ProviderProps = {
  chainId?: number;
  defaultEnabled?: boolean;
  defaultTargetPrice?: string;
  entryPrice?: string;
  recalculate?: boolean | string;
};

const DEFAULT_PERC = '5';

export const LimitDualInfoProvider: CFC<ProviderProps> = observer(
  ({ children, defaultTargetPrice, defaultEnabled = false, entryPrice: entryPriceOuter, recalculate, chainId }) => {
    const { t } = useTranslation();

    const defaultPerc =
      entryPriceOuter && defaultTargetPrice
        ? BigNumber(defaultTargetPrice || 0)
            .minus(BigNumber(entryPriceOuter))
            .div(BigNumber(entryPriceOuter))
            .multipliedBy(100)
            .toFixed()
        : DEFAULT_PERC;

    const state = useLocalObservable<Props>(() => ({
      errorText: '',
      isLoading: false,
      enabled: defaultEnabled,
      perc: defaultPerc,
      price: null,
      initialPrice: 0,

      get targetPrice(): string {
        return this.price ?? '0';
      },

      get isError(): boolean {
        return !!this.errorText || Number(this.targetPrice) === this.initialPrice;
      },

      setError(error: string) {
        if (this.price === null) return;
        this.errorText = error;
      },
      setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
      },
      setEnabled(enabled: boolean) {
        if (enabled) {
          this.perc = DEFAULT_PERC;
          const diff = BigNumber(entryPrice.current).multipliedBy(DEFAULT_PERC).div(100);
          this.price = diff.plus(entryPrice.current).toFixed();
          this.initialPrice = entryPrice.current;
        }

        this.enabled = enabled;
      },
      calcPerc(price: string) {
        return BigNumber(Number(price) || 0)
          .minus(BigNumber(entryPrice.current))
          .div(BigNumber(entryPrice.current))
          .multipliedBy(100)
          .toFixed();
      },
      calcPrice(perc: string) {
        const kf = (Number(perc) || 0) / 100;
        const diff = BigNumber(entryPrice.current!).multipliedBy(kf);

        return diff.plus(entryPrice.current).toFixed();
      },
      changePerc(value: string) {
        this.price = this.calcPrice(value);
        this.perc = value;
        this.initialPrice = entryPrice.current;
      },
      changeTargetPrice(value: string) {
        this.perc = this.calcPerc(value);
        this.price = value;
        this.initialPrice = entryPrice.current;
      },
    }));

    const { data } = useGetSettingsDualsQuery(chainId);

    const maxDistance =
      data?.limitMaxPricesDistance && entryPriceOuter
        ? BigNumber(entryPriceOuter).multipliedBy(data.limitMaxPricesDistance + 1)
        : null;

    const minDistance =
      data?.limitMaxPricesDistance && entryPriceOuter
        ? BigNumber(entryPriceOuter).div(data.limitMaxPricesDistance + 1)
        : null;

    let errorMessage = '';

    if (state.targetPrice && maxDistance && BigNumber(state.targetPrice).gt(maxDistance)) {
      errorMessage = t('dual.validation.priceLess', { less: `${maxDistance.toFixed()}` });
    }
    if (state.targetPrice && minDistance && BigNumber(state.targetPrice).lt(minDistance)) {
      errorMessage = t('dual.validation.priceMore', { more: `${minDistance.toFixed()}` });
    }

    const entryPrice = useRef(0);

    useEffect(() => {
      if (entryPriceOuter) {
        entryPrice.current = BigNumber(entryPriceOuter).toNumber();
      }
    }, [entryPriceOuter]);

    useEffect(() => {
      state.changePerc(state.perc);
    }, [recalculate, state]);

    useEffect(() => {
      state.setError(errorMessage);
    }, [errorMessage, state]);

    return <LimitDualInfoContext.Provider value={state}>{children}</LimitDualInfoContext.Provider>;
  },
);
