import { useEffect, useState } from 'react';

import { useAuthCheckQuery, useAuthQuery, useUserStore } from 'entities/User';

import { useSignAuthMessage } from './useSignAuthMessage';

export const useSignup = () => {
  const { signAuthMessage } = useSignAuthMessage();
  const { setUser, setIsLoading } = useUserStore();

  const [hasIP, setHasIP] = useState(false);

  useAuthCheckQuery({
    onSuccess: () => {
      setHasIP(true);
    },
  });

  //TODO Delete storage after delete V1
  const { refetch, isLoading, isFetching, isRefetching } = useAuthQuery({
    enabled: hasIP,
    onError: (e) => {
      if (e.response?.status === 404) {
        auth();
        setUser(null);
      }
    },
    onSuccess(data) {
      setUser(data);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setIsLoading(isLoading || isFetching || isRefetching);
  }, [isLoading, isFetching, isRefetching]);

  const auth = async () => {
    const isSuccess = await signAuthMessage();
    if (isSuccess) {
      refetch();
    }
  };
};
