import { useGetReferralClaimsQuery, useGetReferralOverviewQuery } from 'entities/Referral';

export const useReferralData = () => {
  const {
    data: referralOverview,
    error: referralOverviewIsError,
    isInitialLoading: referralOverviewLoading,
  } = useGetReferralOverviewQuery();
  const { error: referralClaimsIsError, isInitialLoading: referralClaimsLoading } = useGetReferralClaimsQuery();

  const isLoading = referralOverviewLoading || referralClaimsLoading;

  const isReady = !isLoading;

  const isError = !!referralOverviewIsError || !!referralClaimsIsError;

  return {
    duals: referralOverview?.duals ?? 0,
    swaps: referralOverview?.swaps ?? 0,
    isError,
    isLoading,
    isReady,
    referrals: referralOverview?.referrals ?? 0,
  };
};
