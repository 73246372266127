import orderBy from 'lodash.orderby';
import { useMemo } from 'react';

import { mapResponseAssetsToAssets, useGetDualAssetsQuery } from 'entities/Dual/model';
import { tokenOrder } from 'entities/Token';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';

type Props = {
  chainId?: number;
};

export const useDualAssets = (props?: Props) => {
  const { data: assetsV2 } = useGetDualAssetsQuery();
  const { tokens } = useGetDualTokensAllQuery();

  const assets = useMemo(
    () =>
      assetsV2 && assetsV2.length !== 0 && tokens && Object.keys(tokens).length !== 0
        ? orderBy(
            mapResponseAssetsToAssets({ assets: assetsV2, tokens }),
            ['apr', (o) => tokenOrder.get(o.baseTicker), (o) => tokenOrder.get(o.quoteTicker)],
            ['desc', 'desc', 'desc'],
          )
        : undefined,
    [assetsV2, tokens],
  );

  const filteredAssets = useMemo(
    () => (props?.chainId ? assets?.filter((asset) => asset.chainId === props.chainId) : assets),
    [assets, props?.chainId],
  );

  return filteredAssets;
};
