import { useTheme } from '@mui/material';
import { useModal } from '@rehold-io/connectkit';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DashboardFormValues } from 'widgets/Dual/model/form';

import { useAutoReplay } from 'features/AutoReplay';
import { useLimitDualInfo } from 'features/LimitsDual/lib';
import { LimitsDual } from 'features/LimitsDual/ui';

import { getWrappedNativeToken } from 'entities/Token';
import { useGetDualTokenByTickerAndChain } from 'entities/Token/model/useGetDualTokenByTickerAndChain';

import { HintIcon, LimitOrder } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { Box, Text, Tooltip, FormControlSwitch, ButtonIcon } from 'shared/ui';

import { StyledInputs } from './styled';

export const DualLimitField = observer(() => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigate = useNavigate();

  const { address } = useAccount();
  const { setOpen } = useModal();
  const { setEnabled: setEnableAutoReplay, enabled: enabledAutoReplay } = useAutoReplay();
  const { enabled, setEnabled } = useLimitDualInfo();
  const { control } = useFormContext<DashboardFormValues>();
  const [inputTicker, assetId, chainId] = useWatch({
    control,
    name: ['inputTicker', 'assetId', 'chainId'],
  });

  const [overflow, setOverflow] = useState('hidden');

  const wrappedToken = getWrappedNativeToken(chainId);
  const token = useGetDualTokenByTickerAndChain(inputTicker, chainId);

  const isNativeToken = !token?.address;

  useEffect(() => {
    if (inputTicker && isNativeToken) {
      setEnabled(false);
    }
  }, [inputTicker, setEnabled, isNativeToken, enabledAutoReplay]);

  useEffect(() => {
    if (enabledAutoReplay) {
      setEnabled(false);
    }
  }, [enabledAutoReplay, setEnabled]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (enabled) {
      timeout = setTimeout(() => setOverflow('visible'), 200);
    } else {
      setOverflow('hidden');
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [enabled]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!address) {
      return setOpen(true);
    }
    if (e.target.checked) setEnableAutoReplay(false);
    setEnabled(e.target.checked);
  };

  const updateAsset = () => {
    const [baseTicker, quoteTicker] = assetId.split('/');

    const wBaseTicker = baseTicker === inputTicker ? wrappedToken : baseTicker;
    const wQuoteTicker = quoteTicker === inputTicker ? wrappedToken : quoteTicker;

    navigate(`/dual/${chainId}/${wBaseTicker}/${wQuoteTicker}`);
  };

  const errorMessage = isNativeToken ? (
    <Trans
      t={t}
      i18nKey="dual.notSupportedNativeToken"
      components={{
        Link: <Text color="primary-01" textDecoration="underline" text="app-12-medium" onClick={updateAsset} />,
      }}
      values={{
        token: token?.symbol,
        wToken: wrappedToken.toUpperCase(),
      }}
    />
  ) : undefined;
  const isDisabled = isNativeToken;

  return (
    <>
      <Box paddingY={8} display="flex" justifyContent="center" overflow="hidden">
        <FormControlSwitch
          disabled={isDisabled}
          checked={enabled}
          tooltip={errorMessage}
          onChange={handleChange}
          label={
            <Box gap="8px" alignItems="center" flexDirection="row">
              <LimitOrder width={24} height={20} color={enabled ? colors['primary-01'] : colors['secondary-03']} />
              <Text text="app-14-medium" color={enabled ? 'primary-01' : 'secondary-03'}>
                {t('dual.limitDual')}
              </Text>
              <Tooltip title={t('dual.limitTooltip')}>
                <Box>
                  <ButtonIcon isOutlined sx={{ padding: '2px' }}>
                    <HintIcon width={16} height={16} />
                  </ButtonIcon>
                </Box>
              </Tooltip>
            </Box>
          }
          justifyContent="space-between"
        />
      </Box>
      <StyledInputs overflow={overflow} $isHide={!enabled}>
        <LimitsDual asset={assetId} />
      </StyledInputs>
    </>
  );
});
