import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { OnrampProviderType } from 'entities/Onramp';

import { CloseIcon } from 'shared/assets';
import { Box, Logo, Text } from 'shared/ui';

import { OnrampConnecting } from './OnrampConnecting';

const providerBackground = { [OnrampProviderType.MOONPAY]: '#1c1c1e' };

export const OnrampWidget: React.FC<{ onClose: () => void; url: string | null }> = observer(({ url, onClose }) => {
  const urlObject = url ? new URL(url) : null;

  const [loading, setLoading] = useState(true);

  const isWidgetSizeScreen = useMediaQuery('(max-width: 568px)');
  const isMobileScreen = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    const basicLayoutElement = document.getElementById('basic-layout-content');

    if (!basicLayoutElement) return;

    if ((url && !loading) || isMobileScreen) basicLayoutElement.style.borderWidth = '0';

    return () => {
      if (!isMobileScreen) basicLayoutElement.style.borderWidth = '1px';
    };
  }, [loading, url, isMobileScreen]);

  return (
    <>
      <Box
        height="calc(100vh - 65px - 80px)"
        maxHeight="580px"
        minWidth={isWidgetSizeScreen ? 0 : 568}
        maxWidth={568}
        marginX={isWidgetSizeScreen ? 0 : 'auto'}
      >
        <Box height="100%" width="calc(100%)">
          {!loading && url && (
            <>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                padding={12}
                style={{
                  backgroundColor: providerBackground[OnrampProviderType.MOONPAY],
                  borderRadius: isWidgetSizeScreen ? 0 : '24px 24px 0 0',
                }}
              >
                <Logo size={28} />
                <Text>{`${urlObject?.hostname}${urlObject?.pathname.replace('/', '')}`}</Text>
                <Box style={{ cursor: 'pointer' }}>
                  <CloseIcon width={22} height={22} onClick={onClose} />
                </Box>
              </Box>
              <Box style={{ height: 1 }} backgroundColor="layout-01" />{' '}
            </>
          )}
          {loading && <OnrampConnecting />}
          {url && (
            <iframe
              title="onramp-result"
              height="100%"
              style={{ borderRadius: isWidgetSizeScreen ? 0 : '0 0 24px 24px' }}
              src={url}
              allow="accelerometer; autoplay; camera; gyroscope; payment"
              hidden={loading}
              onLoad={() => setLoading(false)}
            />
          )}
        </Box>
      </Box>
    </>
  );
});
