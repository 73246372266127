import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Swap as SwapWidget } from 'widgets/Swap';

import { PUBLIC_URL } from 'shared/config';
import { PathPatterns } from 'shared/lib';
import { PageContent } from 'shared/ui';

export const Swap = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('swap.metaTitle')}>
        <link rel="canonical" href={`${PUBLIC_URL}${PathPatterns.Swap}`} />
      </Helmet>

      <PageContent px={0} style={{ overflow: 'visible' }}>
        <SwapWidget />
      </PageContent>
    </>
  );
};
