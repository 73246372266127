import BigNumber from 'bignumber.js';
import { Address, formatUnits } from 'viem';
import { useContractWrite } from 'wagmi';

import { useSwapTokenByAddress } from 'entities/Swap';
import { useIsWETH } from 'entities/Swap/lib/hooks/useIsWETH';

import { useAllowance } from './useAllowance';
import { useWETHContractPrepare } from './useWETHContractPrepare';

type WETHContractArgs = {
  address: Address;
  fromAmount: string;
  fromToken: Address;
  toToken: Address;
};

export const useWETHContract = ({ address: user, fromAmount, fromToken, toToken }: WETHContractArgs) => {
  const { data: fromTokenData } = useSwapTokenByAddress(fromToken);

  const isWETH = useIsWETH(fromToken, toToken);

  const { needApprove, isError: isAllowanceError } = useAllowance({
    amount: fromTokenData ? formatUnits(BigInt(fromAmount), fromTokenData.decimals).toString() : '',
    address: fromToken,
  });

  const prepare = useWETHContractPrepare({
    fromAmount,
    fromToken,
    toToken,
    enabled: !needApprove,
  });

  const write = useContractWrite(prepare.config);

  if (!user || !fromAmount || !BigNumber(fromAmount).gt(0) || isAllowanceError || prepare.error || prepare.isError) {
    return {
      isWETH,
      isPreparing: false,
      isError: true,
      write: null,
    };
  }

  if (needApprove) {
    return {
      isWETH,
      isPreparing: true,
      isError: false,
      write: null,
    };
  }

  return {
    isWETH,
    isPreparing: false,
    isError: false,
    write,
  };
};
