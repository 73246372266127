export const POPULAR_CODES = ['btc', 'wbtc', 'eth', 'eth_arbitrum', 'eth_polygon', 'matic_polygon'];

export const CHAIN_NAME_MAP: Record<string, string> = {
  avalanche_c_chain: 'Avalanche C-Chain',
  bnb_chain: 'BNB Chain',
  zksync: 'zkSync',
};

export const CHAIN_IMAGE_MAP: Record<string, string> = {
  avalanche_c_chain: 'avalanche',
  binance_smart_chain: 'bsc',
  bnb_chain: 'bnb',
};
