import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useRate } from 'entities/Rates';

import { GasIcon, HintIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { Box, ButtonIcon, Text, Tooltip } from 'shared/ui';

type GasPriceProps = {
  estimateGas: () => Promise<string>;
};

export const GasPrice: FC<GasPriceProps> = observer(({ estimateGas }) => {
  const { t } = useTranslation();
  const { network, chainId } = useNetwork();
  const { data: gas, isLoading } = useQuery([`estimate-gas-${chainId}`], () => estimateGas(), {
    cacheTime: 0,
    refetchInterval: 3000,
    staleTime: 0,
  });

  const rateObj = useRate({ from: network?.nativeCurrency?.symbol?.toLowerCase(), to: 'usd' });

  const price = rateObj?.price;
  const gasInUSD = BigNumber(price || 0)
    .multipliedBy(gas || '0')
    .shiftedBy(-18);

  return (
    <Box flexDirection="row" gap={8} alignItems="center" ml={16}>
      <GasIcon width={24} height={24} />
      {isLoading ? (
        <Skeleton
          width={60}
          height={18}
          sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'none' }}
          animation="wave"
        />
      ) : (
        <Box flexDirection="row" gap={6} alignItems="center">
          <Text text="app-14-medium">${gasInUSD.toFixed(2)}</Text>
          <Tooltip title={t('gasPrice.tooltip')}>
            <Box flexDirection="row">
              <ButtonIcon isOutlined sx={{ padding: '2px' }}>
                <HintIcon width={16} height={16} />
              </ButtonIcon>
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
});
