import React, { memo } from 'react';

import { Notifications } from 'features/Notifications';

import { useAccount } from 'shared/hooks';
import { useIsMobile } from 'shared/hooks/useResponsive';
import { Box } from 'shared/ui';

import { Account } from './Account';
import { Language } from './Language';
import { Network } from './Network';
import { Points } from './Points';

export const NavContent = memo(() => {
  const { address } = useAccount();

  const isMobile = useIsMobile();

  return (
    <Box flexDirection="row" alignItems="center">
      <Points />
      {(!isMobile || !address) && <Network />}
      {(!isMobile || !address) && <Language />}

      {address && <Notifications />}
      <Account />
    </Box>
  );
});
