import React, { FC } from 'react';

import { AttentionIcon } from 'shared/assets';
import theme from 'shared/theme';
import { Box, BoxProps, Text } from 'shared/ui';

interface SwapInlineWarningProps extends BoxProps {
  content: string;
  show: boolean;
}

export const SwapInlineWarning: FC<SwapInlineWarningProps> = ({ content, show }) => (
  <>
    {show && (
      <Box flexDirection="row" alignItems="center" gap={8}>
        <AttentionIcon style={{ minWidth: 20 }} color={theme.colors['orange-01']} height={20} width={20} />
        <Text text="app-12-medium" color="orange-01">
          {content}
        </Text>
      </Box>
    )}
  </>
);
