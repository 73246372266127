import { FC } from 'react';

import { WalletMenuDisconnect, WalletMenuConnectedWallet } from '../WalletMenu';

export const MainMenuAccount: FC = () => (
  <>
    <WalletMenuDisconnect />
    <WalletMenuConnectedWallet />
  </>
);
