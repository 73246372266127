import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { CalculateOutput } from '@rehold-io/dual-calculator';
import { formatAmount } from '@rehold-io/formatters';
import BN from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatApr } from 'entities/Dual';
import { useRate } from 'entities/Rates';
import { useGetDualTokensQuery } from 'entities/Token';

import { useIsSmallMobile } from 'shared/hooks/useResponsive';
import { CurrencyPair, PageContent, PageHeader, Box, Text, Modal, Badge } from 'shared/ui';

import { CalculationDirection, InfoComponent } from './InfoComponent';

export interface HowItWorksProps {
  apr: number;
  baseTicker: string;
  calculation: CalculateOutput;
  inputAmount: number;
  inputTicker: string;
  isOpenHowItWorks: boolean;
  quoteTicker: string;
  setIsOpenHowItWorks: (value: boolean) => void;
  stakingPeriod: number;
}

export const HowItWorks: FC<HowItWorksProps> = observer(
  ({
    apr,
    baseTicker,
    calculation,
    inputAmount,
    inputTicker,
    isOpenHowItWorks,
    quoteTicker,
    setIsOpenHowItWorks,
    stakingPeriod,
  }) => {
    const { t } = useTranslation();

    const { tokens } = useGetDualTokensQuery();

    const eqTicker = inputTicker === baseTicker ? quoteTicker : baseTicker;

    const rateObj = useRate({ from: inputTicker, to: eqTicker, inputTicker });
    const price = rateObj?.price;
    const eqValue = formatAmount({ symbol: eqTicker, value: BN(price || 0).multipliedBy(inputAmount || 0) });
    const inputValue = formatAmount({ symbol: inputTicker, value: inputAmount });

    const isMobile = useIsSmallMobile();

    const onCloseModal = () => {
      setIsOpenHowItWorks(false);
      track(PredefinedEvents.Dual.HowItWorks.Close());
    };

    return (
      <Modal
        isOpen={isOpenHowItWorks}
        onClose={onCloseModal}
        closeIcon
        header={<PageHeader showLeftButton={false}>{t('modals.howItWorks.title')}</PageHeader>}
      >
        <PageContent maxHeight="100%" height="100%" px={isMobile ? 12 : 24} pb={16} mt={20}>
          <Text>{t('modals.howItWorks.youInvest')}</Text>
          <Box flexDirection="row" justifyContent="space-between" alignItems="center" minHeight={72}>
            <Box flexDirection="row" alignItems="center">
              <CurrencyPair size={32} major={baseTicker} minor={quoteTicker} renderAsTokens />
              <Box ml={8}>
                <Text mb={2}>
                  {inputValue} {tokens[inputTicker]?.symbol}
                </Text>
                <Text text="app-12-regular" opacity={0.4}>
                  {eqValue} {tokens[eqTicker]?.symbol}
                </Text>
              </Box>
            </Box>
            <Badge>{formatApr(apr)}</Badge>
          </Box>
          <Box mt={-8}>
            <Box height="1px" bg="secondary-02" my={16} />
            <InfoComponent
              stakingPeriod={stakingPeriod}
              calculationDirection={CalculationDirection.UP}
              calculationDirectionProps={calculation[CalculationDirection.UP]}
            />

            <Box height="1px" bg="secondary-02" my={16} />
            <InfoComponent
              stakingPeriod={stakingPeriod}
              calculationDirection={CalculationDirection.DOWN}
              calculationDirectionProps={calculation[CalculationDirection.DOWN]}
            />
          </Box>
        </PageContent>
      </Modal>
    );
  },
);
