import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getClaimedDuals } from 'entities/Dual/api/queries';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

import { DualRequestArgs, DualType } from '../dual';

export const useGetClaimedDualsQuery = (options?: UseQueryOptions<DualType[], DualRequestArgs>) => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  return useQuery<DualType[], DualRequestArgs>(
    [`claimed-duals-${chainId}-${address}`],
    () => getClaimedDuals({ chainId: chainId!, address: address! }),
    {
      ...options,
      enabled: !!address && !!chainId,
      refetchInterval: 5 * 60 * 1000,
    },
  );
};
