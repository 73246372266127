import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { HotjarEvents, trackHotjar } from 'features/HotJar';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

export const useWalletAnalytics = () => {
  const location = useLocation();

  const { address, connector, isConnected } = useAccount();
  const { lastConnectorId } = useNetwork();
  const connectorId = connector?.id;

  useEffect(() => {
    // Это состояние возможно, только если пользователь сам подключился
    // Если это авто-коннект, то будет lastConnectorId - условие не выполнится
    // Если пользователь подключается сам (через кнопку), то lastConnectorId здесь еще не задан (мы трекнем), но в следующий тик будет установлен (уже не будем трекать)
    if (isConnected && !lastConnectorId) {
      const isWalletConnect = connectorId === 'walletConnectLegacy' || connectorId === 'walletConnect';
      const walletConnectInfo = localStorage.getItem('walletconnect');
      const walletConnectProviderName = walletConnectInfo ? JSON.parse(walletConnectInfo)?.peerMeta?.name : null;
      const walletConnectProvider = walletConnectProviderName ? `walletConnect:${walletConnectProviderName}` : null;
      const provider = isWalletConnect ? walletConnectProvider || connectorId : connectorId;

      trackHotjar(HotjarEvents.WALLET_CONNECT_SUCCESS);
      track(
        PredefinedEvents.Wallet.Connect.Success({
          address: address!,
          from: location.pathname,
          provider: provider!,
        }),
      );
    }
  }, [isConnected, location, lastConnectorId, address, connectorId]);
};
