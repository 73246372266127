import React, { CFC, useEffect } from 'react';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';

import { useAccount } from 'shared/hooks';

import { TOUR_CLASSES } from '../../lib';
import { STEPS_CLASSES } from '../../lib/classes';
import { ShepherdTour } from '../../lib/shepherd';
import { getItems } from '../../lib/shepherd/helpers';

export const OnboardingTour: CFC = ({ children }) => (
  <ShepherdTour>
    <TourInitialized>{children}</TourInitialized>
  </ShepherdTour>
);

const TourInitialized: CFC = ({ children }) => {
  const { address } = useAccount();

  useEffect(() => {
    if (!window.location.href.includes('/dual/')) return;

    if (!address || !Shepherd.activeTour?.isActive()) return;

    const stepId = Shepherd.activeTour.getCurrentStep()?.id;

    if (stepId === `step-${TOUR_CLASSES.HEADER_CONNECT.id}`) {
      Shepherd.activeTour.next();
    }

    if (stepId === `step-${TOUR_CLASSES.FORM_CONNECT.id}`) {
      const items = getItems(STEPS_CLASSES);

      Shepherd.activeTour.show(`step-${items[items.length - 1]}`);
    }
  }, [address]);

  return <>{children}</>;
};
