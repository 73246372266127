import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { supportedChains } from 'shared/config/chains';
import { useNetwork, useSwitchNetwork } from 'shared/hooks/network';
import { Box } from 'shared/ui';

import { MenuHeader } from '../Header';
import { MenuProps } from '../types';

import { NetworkListItem } from './NetWorkListItem';

export const NetworkMenu: FC<MenuProps> = observer(({ onPressBack, toggle }) => {
  const { switchNetwork } = useSwitchNetwork();
  const { chainId: currentNetworkId, switchingTo } = useNetwork();
  const { t } = useTranslation();

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  return (
    <Box height={isWideMobile ? 'calc(100dvh - 115px)' : undefined}>
      {onPressBack && <MenuHeader onPressBack={onPressBack}>{t('common.network')}</MenuHeader>}
      <Box>
        {supportedChains.map((network) => {
          const { id } = network;
          return (
            <NetworkListItem
              key={id}
              network={network}
              onNetworkChange={switchNetwork}
              selected={id === currentNetworkId}
              switching={id === switchingTo}
              toggle={toggle}
            />
          );
        })}
      </Box>
    </Box>
  );
});
