import { useTranslation } from 'react-i18next';

import { HintIcon } from 'shared/assets';
import { Box, ButtonIcon, Tooltip } from 'shared/ui';

export const SwapSettingsSlippageTooltip = () => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('swap.settings.maxSlippage.tooltip')}>
      <Box>
        <ButtonIcon
          isOutlined
          sx={{ padding: '2px' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <HintIcon width={16} height={16} />
        </ButtonIcon>
      </Box>
    </Tooltip>
  );
};
