import { parseISO, format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DualType, useDualModal, useGetClaimedDualsQuery } from 'entities/Dual';

import { dateFnsLocales } from 'shared/locales';
import { Box, BoxProps, Button, Text, Spinner } from 'shared/ui';

import { TypeTab } from '../../model/types';
import { Empty } from '../Empty';

import { ClaimedDual } from './ClaimedDual';

export const Claimed: React.FC<BoxProps> = observer((props) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const locale = dateFnsLocales[language] || dateFnsLocales.en;
  const { data, isError, isFetching, isLoading, refetch } = useGetClaimedDualsQuery();
  const { setDualParams } = useDualModal();

  const openClaimedDual = useCallback(
    (id: string) => {
      setDualParams({ id });
    },
    [setDualParams],
  );

  const duals = useMemo(
    () =>
      data?.reduce((acc, dual) => {
        const date = parseISO(dual.finishAt);
        const key = format(date, 'd MMM yyyy', { locale });
        if (acc[key]) {
          acc[key].push(dual);
        } else {
          acc[key] = [dual];
        }

        return acc;
      }, {} as Record<string, DualType[]>) || {},
    [data, locale],
  );

  if (!isError && !isLoading && !data?.length) {
    return <Empty type={TypeTab.HISTORY} />;
  }

  return (
    <Box {...props}>
      <Box mb={16} flexDirection="row">
        <Text text="app-18-medium" color="white-01">
          {t('dual.history')}
        </Text>
        {data?.length && isFetching && (
          <Box ml={8}>
            <Spinner size={24} />
          </Box>
        )}
      </Box>

      {(isLoading || isError) && (
        <Box height={150} justifyContent="center" alignItems="center">
          {isLoading && <Spinner />}
          {isError && (
            <Button onClick={() => refetch()} data-id="refetch-claimed-dual">
              {t('common.refetch')}
            </Button>
          )}
        </Box>
      )}

      {Object.entries(duals).map(([date, values]) => (
        <ClaimedDualGroup key={date} date={date} values={values} openClaimedDual={openClaimedDual} />
      ))}
    </Box>
  );
});

type PropsGroup = {
  date: string;
  openClaimedDual: (id: string) => void;
  values: DualType[];
};

const ClaimedDualGroup: React.FC<PropsGroup> = observer(({ date, openClaimedDual, values }) => (
  <Box id="test">
    <Text color="secondary-03" text="app-14-medium" mb={14}>
      {date}
    </Text>
    {values.map((dual) => (
      <ClaimedDual key={dual.id} dual={dual} pb={14} openClaimedDual={openClaimedDual} />
    ))}
  </Box>
));
