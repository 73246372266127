export function mapOffsetToTimezone(offsetInMinutes: string): TradingView.CustomTimezones {
  const offsetToTimezone: { [key: string]: TradingView.CustomTimezones } = {
    '-660': 'Pacific/Honolulu', // UTC-11:00
    '-600': 'Pacific/Fakaofo', // UTC-10:00
    '-540': 'America/Juneau', // UTC-9:00
    '-480': 'America/Los_Angeles', // UTC-8:00
    '-420': 'America/Phoenix', // UTC-7:00
    '-360': 'America/Chicago', // UTC-6:00
    '-300': 'America/New_York', // UTC-5:00
    '-270': 'America/Caracas', // UTC-4:30
    '-240': 'America/Santiago', // UTC-4:00
    '-210': 'America/Sao_Paulo', // UTC-3:30
    '-180': 'America/Sao_Paulo', // UTC-3:00
    '-120': 'Atlantic/Reykjavik', // UTC-2:00
    '-60': 'Atlantic/Reykjavik', // UTC-1:00
    '0': 'Europe/London', // UTC+0:00
    '60': 'Europe/Berlin', // UTC+1:00
    '120': 'Europe/Athens', // UTC+2:00
    '180': 'Asia/Riyadh', // UTC+3:00
    '210': 'Asia/Tehran', // UTC+3:30
    '240': 'Asia/Dubai', // UTC+4:00
    '270': 'Asia/Dubai', // UTC+4:30
    '300': 'Asia/Karachi', // UTC+5:00
    '330': 'Asia/Kolkata', // UTC+5:30
    '345': 'Asia/Kathmandu', // UTC+5:45
    '360': 'Asia/Almaty', // UTC+6:00
    '390': 'Asia/Almaty', // UTC+6:30
    '420': 'Asia/Bangkok', // UTC+7:00
    '480': 'Asia/Singapore', // UTC+8:00
    '525': 'Asia/Singapore', // UTC+8:45
    '540': 'Asia/Tokyo', // UTC+9:00
    '570': 'Asia/Tokyo', // UTC+9:30
    '600': 'Australia/Sydney', // UTC+10:00
    '630': 'Australia/Sydney', // UTC+10:30
    '660': 'Pacific/Norfolk', // UTC+11:00
    '720': 'Pacific/Auckland', // UTC+12:00
    '765': 'Pacific/Chatham', // UTC+12:45
    '780': 'Pacific/Chatham', // UTC+13:00
    '840': 'Pacific/Chatham', // UTC+14:00
  };

  return offsetToTimezone[offsetInMinutes] || offsetToTimezone['0'];
}

export function parseResolution(resolution: string): number {
  if (resolution === '1D') {
    return 86400;
  }
  const minutes = +resolution;

  if (Number.isNaN(minutes)) throw new Error(`Failed to parse Trading View resolution: ${resolution}`);

  return minutes * 60;
}

export function getNextBarTime(barTime: number, resolution: string): number {
  const date = new Date(barTime);
  const resolutionSeconds = parseResolution(resolution);

  if (resolution === '1D') {
    date.setDate(date.getDate() + 1);
  } else {
    const totalSeconds = Math.floor(date.getTime() / 1000) + resolutionSeconds;
    const nextInterval = Math.floor(totalSeconds / resolutionSeconds) * resolutionSeconds;
    date.setTime(nextInterval * 1000);
  }

  return date.getTime();
}
