import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageIcon, ForwardIcon } from 'shared/assets';
import { SUPPORTED_LANGUAGES } from 'shared/locales';
import { Box } from 'shared/ui/Box';
import { Text } from 'shared/ui/Text';

export interface MainMenuLanguageProps {
  onPress: () => void;
}

export const MainMenuLanguage: FC<MainMenuLanguageProps> = ({ onPress }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={48} p={24} onClick={onPress}>
      <Box flexDirection="row" alignItems="center">
        <Box width={24} height={24} mr={8}>
          <LanguageIcon width="100%" height="100%" />
        </Box>
        <Text>{t('common.language')}</Text>
      </Box>
      <Box flexDirection="row" alignItems="center" height={32} ml={8} onClick={onPress}>
        <Text text="app-12-regular" color="secondary-03">
          {SUPPORTED_LANGUAGES[language]}
        </Text>
        <Box width={16} height={16} ml={4} color="secondary-03">
          <ForwardIcon width="100%" height="100%" />
        </Box>
      </Box>
    </Box>
  );
};
