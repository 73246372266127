import type { Network } from '../model';

type ChainGas = { dual: number; swap: number; ticker: string; weth: number };

export const GAS_FOR_CHAINS: { [key in Network]: ChainGas } = {
  ethereum: { dual: 0.0036, swap: 0.0036, weth: 0.0012, ticker: 'eth' },
  arbitrum: { dual: 0.0001, swap: 0.0001, weth: 0.000034, ticker: 'eth' },
  avalanche: { dual: 0.009, swap: 0.009, weth: 0.003, ticker: 'avax' },
  bsc: { dual: 0.0014, swap: 0.0014, weth: 0.0005, ticker: 'bnb' },
  'bsc-testnet': { dual: 0.0026, swap: 0.0026, weth: 0.0009, ticker: 'bnb' },
  optimism: { dual: 0.00014, swap: 0.00014, weth: 0.00005, ticker: 'eth' },
  polygon: { dual: 0.06, swap: 0.06, weth: 0.02, ticker: 'matic' },
  'polygon-mumbai': { dual: 0.0005, swap: 0.0005, weth: 0.0002, ticker: 'matic' },
  base: { dual: 0.00004, swap: 0.00004, weth: 0.00002, ticker: 'eth' },
  manta: { dual: 0.00028, swap: 0.00028, weth: 0.0001, ticker: 'eth' },
  linea: { dual: 0.00028, swap: 0.00028, weth: 0.0001, ticker: 'eth' },
  rootstock: { dual: 0.0000075, swap: 0.0000075, weth: 0.0000035, ticker: 'rbtc' },
} as Record<Network, ChainGas>;
