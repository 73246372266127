import { Skeleton } from '@mui/material';
import BigNumber from 'bignumber.js';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, formatUnits } from 'viem';

import { getFormattedEthers } from 'widgets/Swap/lib/getFormattedBalance';

import { useRate } from 'entities/Rates';
import { useSwapTokenByAddress, useWETHAddress } from 'entities/Swap/lib/hooks';
import { SwapAssetFieldValue } from 'entities/Swap/model';
import { SwapInlineWarning } from 'entities/Swap/ui/SwapInlineWarning';

import { NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { useAccount } from 'shared/hooks';
import { formatCurrency } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import { SwapAssetInput } from '../SwapAssetInput';
import { SwapAssetSelect } from '../SwapAssetSelect';

interface SwapAssetFieldProps {
  disabled?: boolean;
  error?: any;
  fiatHidden?: boolean;
  isFetching?: boolean;
  onBalanceClick?: (balance: string) => void;
  onChange: (value: SwapAssetFieldValue) => void;
  title: string;
  value: SwapAssetFieldValue;
}

export const SwapAssetField: FC<SwapAssetFieldProps> = ({
  title,
  disabled = false,
  fiatHidden = false,
  isFetching = false,
  value,
  error,
  onChange,
  onBalanceClick,
}) => {
  const { t } = useTranslation();

  const { address: user } = useAccount();

  const { data: tokenData } = useSwapTokenByAddress(value.address!);

  const { data: nativeToken } = useSwapTokenByAddress(NATIVE_TOKEN_ADDRESS);
  const { price } = useRate({ from: nativeToken?.symbol, to: 'usd' });

  const weth = useWETHAddress();
  const isNativeOrWrapped = value.address === NATIVE_TOKEN_ADDRESS || value.address === weth;

  const handleAmountChange = (amount: string) => {
    onChange({
      ...value,
      amount,
    });
  };
  const handleAddressChange = (address: Address | null) => {
    onChange({
      ...value,
      address,
    });
  };

  const showInput = !(isFetching && disabled);
  const showUsdValue = value.amountUSD && !isFetching && !error;
  const showInlineWarning = error && !isFetching;
  const showTokenBalance = tokenData && tokenData.symbol && user && value.address && tokenData.balance;

  // const [amountUsdValueChange] = useState(null);

  return (
    <Box border={1} borderColor="secondary-02" borderRadius={16} padding={16} gap={2}>
      <Text text="app-12-medium" style={{ opacity: 0.4 }}>
        {title}
      </Text>
      <Box flexDirection="row" alignItems="center">
        {!showInput && (
          <Skeleton
            animation="wave"
            sx={{ bgcolor: `rgba(134, 77, 247, 0.4)`, borderRadius: 4, transform: 'scale(1, 0.90)' }}
            width="100%"
            height={40}
            style={{ marginRight: '30%' }}
          />
        )}
        {showInput && (
          <SwapAssetInput
            value={value.amount}
            disabled={disabled}
            handleChange={({ value }) => handleAmountChange(value)}
            ticker={tokenData?.symbol.toLowerCase()}
          />
        )}
        <SwapAssetSelect value={value.address} onSelect={handleAddressChange} />
      </Box>
      <Box minHeight={18} flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box flexDirection="row" gap={4} width="40%">
          {!fiatHidden && value.amount && value.address && (
            <>
              <SwapInlineWarning content={t('swap.warnings.priceUnknown')} show={showInlineWarning} />
              {showUsdValue && (
                <Text text="app-12-medium" style={{ opacity: 0.4 }}>
                  {formatCurrency(parseFloat(value.amountUSD!))}
                </Text>
              )}
              {!value.amountUSD &&
                !isFetching &&
                !error &&
                isNativeOrWrapped &&
                price !== 1 &&
                BigNumber(value.amount).isFinite() && (
                  <Text text="app-12-medium" style={{ opacity: 0.4 }}>
                    {formatCurrency(BigNumber(value.amount).multipliedBy(price).toNumber())}
                  </Text>
                )}
              {isFetching && (
                <Skeleton
                  animation="wave"
                  sx={{ bgcolor: `rgba(134, 77, 247, 0.4)`, borderRadius: 4, transform: 'scale(1, 0.90)' }}
                  width="100%"
                  height={18}
                />
              )}
              {/* {amountUsdValueChange && <Text style={{ fontSize: 12, opacity: 0.3 }}>({amountUsdValueChange})</Text>} */}
            </>
          )}
        </Box>{' '}
        <Box flexDirection="row" gap={6}>
          {showTokenBalance && (
            <>
              <Text text="app-12-medium" style={{ opacity: 0.4 }}>
                {t('common.balance')}:
              </Text>
              <Text
                text="app-12-medium"
                style={{ opacity: onBalanceClick ? 1 : 0.4 }}
                color={onBalanceClick ? 'secondary-03' : 'white-01'}
                role={onBalanceClick ? 'button' : 'textbox'}
                onClick={() => onBalanceClick?.(formatUnits(BigInt(tokenData.balance || 0), tokenData.decimals))}
              >
                {getFormattedEthers(tokenData)}
              </Text>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
