/* eslint-disable guard-for-in */
import { useCallback, useContext, useMemo } from 'react';
import { formatUnits } from 'viem';

import { DualTariff } from 'entities/Dual/model';
import { useGetDualTokenAddress } from 'entities/Token/model/useGetDualTokenAddress';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';

import { CHAIN_INFO } from 'shared/config/chains';
import { useNetwork } from 'shared/hooks/network';

import { getTariffUniqueKey } from '../helpers';
import { DualTariffsContext } from '../providers';

import { useDualTariffByChain } from './useDualTariffByChain';

export interface Limit {
  max: number;
  min: number;
}

export type Limits = Record<string, Limit>;

export const useInputTickerLimits = (_inputTicker: string, tariff?: DualTariff | null, chainId?: number) => {
  const inputTicker = _inputTicker.toLowerCase();
  const { tokens } = useGetDualTokensAllQuery();

  const { network: connectedNetwork } = useNetwork();
  const network = chainId ? CHAIN_INFO[chainId] : connectedNetwork;

  const baseToken = useGetDualTokenAddress(tariff?.baseTicker?.toLowerCase()!, network?.id) || '';
  const quoteToken = useGetDualTokenAddress(tariff?.quoteTicker?.toLowerCase()!, network?.id) || '';

  const { response: tariffs } = useContext(DualTariffsContext);
  const { response: dualTariffs } = useDualTariffByChain({ baseToken, quoteToken, chainId: network?.id! });

  const createLimit = useMemo(
    () => (tokenAddress: string, min: bigint, max: bigint, tariff?: Omit<DualTariff, 'apr'>) => {
      const address = tokenAddress?.toLowerCase();
      const token = tokens?.[network?.id]?.[address];

      if (!token || !network) return {};

      const tokenSymbol = token.symbol?.toLowerCase();

      const key = `${tokenSymbol}-${getTariffUniqueKey(tariff)}`;
      const decimals = token?.decimals;

      const limits = {} as Record<string, Limit>;
      const limit = {
        max: Number(formatUnits(max, decimals)),
        min: Number(formatUnits(min, decimals)),
      };

      const wToken = `w${network.nativeCurrency.symbol}`?.toLowerCase();

      if (wToken === tokenSymbol) {
        const nativeSymbol = network.nativeCurrency.symbol?.toLowerCase();
        limits[`${nativeSymbol}-${getTariffUniqueKey(tariff)}`] = limit;
      }

      limits[key] = limit;

      return limits;
    },
    [tokens, network],
  );

  const mapTariffsToLimits = useCallback(() => {
    if (!tariffs && !dualTariffs) return {};

    return (tariffs || dualTariffs).reduce<Limits>(
      (
        acc,
        {
          baseToken,
          id,
          maxBaseAmount,
          maxQuoteAmount,
          minBaseAmount,
          minQuoteAmount,
          quoteToken,
          stakingPeriod,
          yield: yieldTarriff,
        },
      ) => {
        const baseTicker = tokens?.[network?.id]?.[baseToken?.toLowerCase()]?.symbol?.toLowerCase() || '';
        const quoteTicker = tokens?.[network?.id]?.[quoteToken?.toLowerCase()]?.symbol?.toLowerCase() || '';

        const dualTariff = {
          baseTicker,
          id: id.toString(),
          quoteTicker,
          stakingPeriod: Number(stakingPeriod),
          yield: yieldTarriff,
        };
        const limitInput = createLimit(baseToken, BigInt(minBaseAmount), BigInt(maxBaseAmount), dualTariff);
        const limitQuote = createLimit(quoteToken, BigInt(minQuoteAmount), BigInt(maxQuoteAmount), dualTariff);

        for (const inputProperty in limitInput) {
          acc[inputProperty] = limitInput[inputProperty];
        }
        for (const quoteProperty in limitQuote) {
          acc[quoteProperty] = limitQuote[quoteProperty];
        }

        return acc;
      },
      {},
    );
  }, [tariffs, tariff, createLimit, tokens]);

  const limits = useMemo(() => mapTariffsToLimits(), [mapTariffsToLimits]);

  const limit = useMemo(() => {
    const nativeToken = `${network?.nativeCurrency?.symbol}`?.toLowerCase();
    const wToken = `w${network?.nativeCurrency?.symbol}`?.toLowerCase();

    const wTariff = tariff
      ? {
          baseTicker: tariff.baseTicker === nativeToken ? wToken : tariff.baseTicker,
          quoteTicker: tariff.quoteTicker === nativeToken ? wToken : tariff.quoteTicker,
          stakingPeriod: tariff.stakingPeriod,
          yield: tariff.yield,
        }
      : undefined;
    const key = `${inputTicker}-${getTariffUniqueKey(wTariff)}`;
    return limits[key] ?? { max: 0, min: 0 };
  }, [limits, inputTicker, tariff, network]);

  return { limit };
};
