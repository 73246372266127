import { wrapCreateBrowserRouter } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { createBrowserRouter, Navigate, Outlet, ScrollRestoration } from 'react-router-dom';

import { Dual } from 'pages/Dual';
import { Duals } from 'pages/Duals';
import { Onramp } from 'pages/Onramp';
import { PageNotFound } from 'pages/PageNotFound';
import { Perpetuals } from 'pages/Perpetuals';
import { Points } from 'pages/Points';
import { ReferralProgram } from 'pages/ReferralProgram';
import { Swap } from 'pages/Swap';

import { DualWidget } from 'widgets/Dual';
import { DualModal } from 'widgets/DualModal';
import { DualCreateFeature } from 'widgets/DualsDashboard';
import { BasicLayout } from 'widgets/Layout';

import { useSignup } from 'features/Auth';
import { TokenPermitsProvider } from 'features/CreateDual';
import { HappyMomentsProvider } from 'features/HappyMoments';
import { useHotjarStateChanges } from 'features/HotJar';
import { useHideLiveChat, useLiveChatSettings } from 'features/LiveChat';
import { useDataLayer } from 'features/MixPanel';
import { useChainParam, useSyncNetwork, useWalletAnalytics } from 'features/Network';
import { OnboardingTour } from 'features/OnboardingTour';

import { DualModalProvider } from 'entities/Dual';
import { usePrefetchTokens } from 'entities/Token';
import { useQueryConnectWallet, WelcomeBonusModal } from 'entities/User';
import { useHandlePendingTransfers } from 'entities/User/model/useHandleTransfers';

import { useSaveHistoryApp, useSentryScope } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { BoxProps } from 'shared/ui';

const Root: React.FC<React.PropsWithChildren & BoxProps> = observer(({ children, ...layoutProps }) => {
  usePrefetchTokens();
  useSentryScope();
  useDataLayer();
  useSignup();
  useSaveHistoryApp();
  useChainParam();
  useSyncNetwork();
  useWalletAnalytics();
  useHandlePendingTransfers();
  // useIntercomInit();
  useHotjarStateChanges();
  useLiveChatSettings();
  useQueryConnectWallet();

  return (
    <DualModalProvider>
      <TokenPermitsProvider>
        <OnboardingTour>
          <HappyMomentsProvider>
            <BasicLayout {...layoutProps}>
              <ScrollRestoration />
              <Outlet />
            </BasicLayout>
            <WelcomeBonusModal />
            <DualModal />
          </HappyMomentsProvider>
        </OnboardingTour>
      </TokenPermitsProvider>
    </DualModalProvider>
  );
});

const WidgetsRoot = React.memo(() => {
  useDataLayer();
  useHideLiveChat();

  return <Outlet />;
});

export const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    index: true,
    element: <Navigate to={PathPatterns.Perpetuals} replace />,
    path: '/',
  },
  {
    children: [
      {
        element: <Perpetuals />,
        path: PathPatterns.Perpetuals,
      },
      {
        element: <Duals />,
        path: PathPatterns.DualsDashboard,
      },
      {
        path: PathPatterns.Duals,
        children: [
          {
            index: true,
            element: <Duals />,
          },
          {
            path: ':type',
            element: <Duals />,
          },
          {
            path: ':type/:id',
            element: <Duals />,
          },
        ],
      },
    ],
    element: <Root display="contents" />,
    path: '/',
  },
  {
    children: [
      {
        element: <Dual />,
        path: PathPatterns.Dual,
      },
      {
        element: <Swap />,
        path: PathPatterns.Swap,
      },
      {
        element: <Onramp />,
        path: PathPatterns.Buy,
      },
      {
        element: <ReferralProgram />,
        path: PathPatterns.ReferralProgramMain,
      },
      {
        element: <Points />,
        path: PathPatterns.Points,
      },
      {
        element: <PageNotFound />,
        path: PathPatterns.PageNotFound,
      },
    ],
    element: <Root />,
    path: '/',
  },
  {
    children: [
      { element: <DualCreateFeature />, path: '/widgets/dual' },
      { element: <DualWidget />, path: '/widgets/dual/:chainId/:baseTicker/:quoteTicker' },
      { element: <Swap />, path: '/widgets/swap' },
      { element: <Onramp />, path: '/widgets/buy' },
    ],
    element: <WidgetsRoot />,
    path: '/widgets',
  },
]);
