import React, { FC } from 'react';
import { NumberFormatValues, NumericFormat, OnValueChange } from 'react-number-format';

import { DEFAULT_SLIPPAGE_VALUE } from 'shared/config';
import { Box, Text } from 'shared/ui';

import styles from './SwapSettings.module.scss';

interface Props {
  disabled: boolean;
  handleChange: OnValueChange;
  value: string;
}

export const SwapSettingsSlippageInput: FC<Props> = ({ disabled, value, handleChange }) => {
  const withValueLimit = ({ floatValue }: NumberFormatValues) => (floatValue ?? 0) <= 50 && (floatValue ?? 0) >= 0;

  return (
    <Box border={1} borderColor="secondary-02" className={styles.cardContent}>
      <NumericFormat
        className={styles.input}
        placeholder={(DEFAULT_SLIPPAGE_VALUE * 100).toString()}
        disabled={disabled}
        displayType="input"
        type="text"
        inputMode="decimal"
        onValueChange={handleChange}
        value={value}
        isAllowed={withValueLimit}
        allowNegative={false}
        allowLeadingZeros={false}
        decimalScale={2}
        allowedDecimalSeparators={['.', ',']}
        defaultValue={0}
        valueIsNumericString
      />
      <Text text="app-16-medium" flexBasis={0} flexGrow={1}>
        %
      </Text>
    </Box>
  );
};
