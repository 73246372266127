import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PathPatterns } from 'shared/lib';

import { SwapTab } from './ui/SwapTab';

export const Swap = observer(() => {
  const navigate = useNavigate();

  return <SwapTab tab={PathPatterns.Swap} onTabChange={(tab) => navigate(tab)} />;
});
