import { LOCAL_STORAGE_HIDDEN_TRANSFERS, LOCAL_STORAGE_PENDING_TRANSFERS, LocalStorage } from 'shared/lib';

export type UserTransfer = {
  address: string;
  amount: string;
  chainId: number;
  ticker: string;
  timeout?: number;
  txHash: string;
  type: 'DEPOSIT' | 'WITHDRAWAL';
};

export const storageTransfers = {
  getPendingTransfers: () => {
    let localStoragePendingTransfers = LocalStorage.get<{ transfers: UserTransfer[] }>(LOCAL_STORAGE_PENDING_TRANSFERS);

    if (!localStoragePendingTransfers) {
      LocalStorage.set(LOCAL_STORAGE_PENDING_TRANSFERS, { transfers: [] });
      localStoragePendingTransfers = LocalStorage.get(LOCAL_STORAGE_PENDING_TRANSFERS);
    }

    return localStoragePendingTransfers?.transfers ?? [];
  },
  getHiddenTransfers: () => {
    let localStorageHiddenTransfers = LocalStorage.get<{ transfers: string[] }>(LOCAL_STORAGE_HIDDEN_TRANSFERS);

    if (!localStorageHiddenTransfers) {
      LocalStorage.set(LOCAL_STORAGE_HIDDEN_TRANSFERS, { transfers: [] });
      localStorageHiddenTransfers = LocalStorage.get(LOCAL_STORAGE_HIDDEN_TRANSFERS);
    }

    return localStorageHiddenTransfers?.transfers ?? [];
  },
  addPendingTransfer: (value: UserTransfer) => {
    const transfers = storageTransfers.getPendingTransfers();

    const isPending = transfers.find((transfer) => transfer.txHash === value.txHash);

    if (!isPending)
      LocalStorage.set(LOCAL_STORAGE_PENDING_TRANSFERS, {
        transfers: [...transfers, value],
      });
  },
  addHiddenTransfer: (value: UserTransfer) => {
    const transfers = storageTransfers.getHiddenTransfers();

    const isHidden = transfers.find((transfer) => transfer === value.txHash);

    if (!isHidden)
      LocalStorage.set(LOCAL_STORAGE_HIDDEN_TRANSFERS, {
        transfers: [...transfers, value.txHash],
      });
  },
  removePendingTransfer: (value: UserTransfer) => {
    const transfers = storageTransfers.getPendingTransfers();

    LocalStorage.set(LOCAL_STORAGE_PENDING_TRANSFERS, {
      transfers: transfers.filter((transfer) => transfer.txHash !== value.txHash),
    });
  },
  removeHiddenTransfer: (value: UserTransfer) => {
    const transfers = storageTransfers.getHiddenTransfers();

    LocalStorage.set(LOCAL_STORAGE_HIDDEN_TRANSFERS, {
      transfers: transfers.filter((transfer) => transfer !== value.txHash),
    });
  },
};
