import { useQuery } from '@tanstack/react-query';

import { getSettingsDuals } from 'entities/Dual/api/queries';

import { useNetwork } from 'shared/hooks/network';

export const useGetSettingsDualsQuery = (chainId?: number) => {
  const { chainId: connectedChainId } = useNetwork();

  return useQuery(
    [`dual-settings-${chainId || connectedChainId}`],
    () => getSettingsDuals({ chainId: chainId || connectedChainId }),
    {
      enabled: !!(chainId || connectedChainId),
      refetchInterval: 5 * 60 * 1000,
    },
  );
};
