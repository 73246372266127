import { styled } from '@mui/material';

import { shouldForwardProp } from 'shared/lib';
import { Box, Card } from 'shared/ui';

export const StyledCard = styled(Card, {
  shouldForwardProp,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  zIndex: 1,
}));

export const StyledInputs = styled(Box, {
  shouldForwardProp,
})<{ $isHide: boolean }>(({ $isHide }) => ({
  zIndex: '1',
  height: $isHide ? '0' : '72px',
  minHeight: '0px',

  transition: 'all 0.2s ease',
}));
