import { useMutation } from '@tanstack/react-query';

import { claimPoints } from '../api/queries';

export const useClaimPointsMutation = () =>
  useMutation(['claim-points'], (input: { recaptchaV2?: string; signature: string; turnstile?: string }) =>
    claimPoints({
      signature: input.signature,
      token: input.turnstile || input.recaptchaV2 || '',
      recaptcha: !!input.recaptchaV2,
      turnstile: !!input.turnstile,
    }),
  );
