import React, { memo } from 'react';

import { Box } from 'shared/ui';

import { OpenedLimitDuals } from './Opened';

export const LimitDuals = memo(() => (
  <Box display="flex" flexDirection="column" gap="24px">
    <OpenedLimitDuals />
    {/* <ClosedLimitDuals /> */}
  </Box>
));
