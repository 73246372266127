import { useModal, useIsMounted } from '@rehold-io/connectkit';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAccount } from 'shared/hooks';
import { removeQueryParam } from 'shared/lib';

export const useQueryConnectWallet = () => {
  const { isConnected } = useAccount();
  const [searchParams] = useSearchParams();
  const { open, setOpen } = useModal();

  const isMounted = useIsMounted();

  const connectWalletParam = searchParams.get('showConnectWallet');

  useEffect(() => {
    if (!isMounted || open) return;

    if (!isConnected && connectWalletParam && connectWalletParam === 'true') {
      setOpen(true);
      removeQueryParam('showConnectWallet');
    }
  }, [isConnected, isMounted, connectWalletParam]);
};
