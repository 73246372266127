import { styled } from '@mui/material';

import { shouldForwardProp } from 'shared/lib';
import { Card } from 'shared/ui';

export const StyledContainer = styled('div', {
  shouldForwardProp,
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  zIndex: 1,
  marginBottom: '4px',
  paddingTop: '8px',

  '.dual-limit-input': {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.colors['white-01'],
    display: 'inline-flex',
    marginRight: '16px',
    outline: 'none',
    fontSize: 'var(--typography-22-medium-font-size)',
    flexGrow: '1',
    minWidth: '40px',
  },

  '.dual-down': {
    color: theme.colors['red-01'],
  },
  '.dual-up': {
    color: theme.colors['primary-01'],
  },
}));

export const StyledCard = styled(Card, {
  shouldForwardProp,
})(() => ({
  height: '56px',
  paddingLeft: '16px',
  paddingRight: '8px',
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  label: {
    transform: 'translateY(-50%) translateX(-8px)',
  },
}));
