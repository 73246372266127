import { Theme, styled } from '@mui/material';
import React, { useCallback, FC } from 'react';
import { Address } from 'viem';
import { useBalance } from 'wagmi';

import { getFormattedEthers } from 'widgets/Swap/lib/getFormattedBalance';

import { useSwapTokenAdd, useSwapTokenUnsafe, useSwapTokens } from 'entities/Swap/lib';
import { SwapTokenIcon } from 'entities/Token';
import { UserToken } from 'entities/Token/model/types';

import { AttentionIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { shouldForwardProp } from 'shared/lib';
import { Box, Text } from 'shared/ui';

type Props = {
  $borderColor: keyof Theme['colors'];
};

export const StyledBox = styled(Box, { shouldForwardProp })<Props>(({ $borderColor, theme }) => ({
  borderColor: `var(--currency-pair-color, ${theme.colors[$borderColor]})`,
  transition: 'all 0.2s ease',
}));

type SwapAssetProps = {
  address: Address;
  className?: string;
  onPress: (selected: Address) => void;
  selected?: boolean;
  token: UserToken;
};

export const SwapAsset: FC<SwapAssetProps> = ({ className, address, token, selected = false, onPress }) => {
  const { address: user } = useAccount();
  const { selectedChainId: chainId } = useNetwork();

  const { refetch } = useSwapTokens();
  const addTokenToStorage = useSwapTokenAdd({ cache: false });

  const isUnsafe = useSwapTokenUnsafe(address);

  const { data: balanceData } = useBalance({
    address: user,
    token: token.address,
    chainId,
    enabled: !!user && !!chainId && token && !!token.address && !token.balance,
  });

  const handlePress = useCallback(() => {
    if (isUnsafe && addTokenToStorage) {
      addTokenToStorage(token);
      refetch();
    }
    onPress(address);
  }, [address, onPress, addTokenToStorage?.toString()]);

  return (
    <>
      <li className={className} onClick={handlePress} style={{ opacity: selected ? 0.5 : 1 }}>
        <Box flexDirection="row" alignItems="center" width="60%">
          <Box flexDirection="row">
            <StyledBox
              position="relative"
              zIndex={1}
              borderRadius={32}
              sx={{ borderWidth: 3 }}
              $borderColor="background-01"
            >
              <SwapTokenIcon address={address!} url={token.logoURI} size={32} />
            </StyledBox>
          </Box>{' '}
          <Box ml={8} flexBasis={0} flexGrow={1}>
            {isUnsafe ? (
              <Box flexDirection="row" alignItems="center" gap={8}>
                <Text color="white-01" textOverflow="ellipsis" whiteSpace="nowrap">
                  {token.name}
                </Text>
                <AttentionIcon width={14} height={14} style={{ opacity: 0.4 }} />
              </Box>
            ) : (
              <Text color="white-01" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {token.name}
              </Text>
            )}
            <Text color="gray-01">{token.symbol}</Text>
          </Box>
        </Box>
        <Text minWidth="max-content">
          {token && token.balance
            ? getFormattedEthers(token)
            : balanceData
            ? getFormattedEthers({ ...token, balance: balanceData.value.toString() })
            : ''}
        </Text>
      </li>
    </>
  );
};
