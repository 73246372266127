import { useQuery } from 'wagmi';

import { getChartRates } from '../api';

import { ChartRatesArgs, ChartRatesResponse } from './types';

export const useGetChartRatesQuery = (args: ChartRatesArgs) =>
  useQuery([`rates-${args.fromDate}-${args.toDate}-${args.fromTicker}-${args.toTicker}`], () => getChartRates(args), {
    select(data: ChartRatesResponse) {
      return data.map(({ createdAt, price }) => ({ createdAt: Date.parse(`${createdAt}`), price: Number(price) }));
    },
  });
