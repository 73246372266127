import { useQuery } from '@tanstack/react-query';

import { getCountDuals } from 'entities/Dual/api/queries';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

export const useGetCountDualsQuery = () => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  return useQuery(
    [`count-duals-${chainId}-${address}`],
    () => getCountDuals({ chainId: chainId!, address: address! }),
    {
      enabled: !!chainId && !!address,
      staleTime: 60_000,
      refetchInterval: 5 * 60 * 1000,
    },
  );
};
