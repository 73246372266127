import React, { CFC, memo } from 'react';

import { Tooltip } from 'shared/ui';

import { useShowHelpTooltip } from '../../hook';
import { OnboardingStartTooltip } from '../OnboardingStartTooltip';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  disabled: boolean;
};

export const HelpStartTooltip: CFC<Props> = memo(({ children, className, disabled }) => {
  const { isShow, onClose } = useShowHelpTooltip();

  return (
    <>
      <Tooltip
        title={<OnboardingStartTooltip onClick={onClose} />}
        disabled={!isShow || disabled}
        open={isShow}
        classes={{ tooltip: styles.tooltip }}
        enterDelay={1000}
        enterNextDelay={1000}
        PopperProps={{
          disablePortal: true,
        }}
        className={className}
      >
        {children as React.ReactElement}
      </Tooltip>
    </>
  );
});
