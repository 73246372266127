import { parseUnits } from 'viem';
import { useBalance } from 'wagmi';

import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';
import { useBalances } from 'entities/User';

import { useAccount } from 'shared/hooks';

export const useInsufficientFunds = (token: string, amount: string, chainId: number) => {
  const { tokens } = useGetDualTokensAllQuery();
  const { address } = useAccount();

  const tokenAddress = tokens?.[chainId]?.[token]?.address as `0x${string}`;

  const {
    balances,
    data: balancesResponse,
    isLoading,
  } = useBalances([
    {
      chainId: chainId!,
      token: tokenAddress,
      user: address!,
    },
  ]);

  const { data, isLoading: isLoadingNative } = useBalance({
    address,
    chainId: chainId!,
    enabled: !tokenAddress,
  });

  const tokenBalance = balances?.[`${chainId}:${tokenAddress}`] || 0;

  if (!tokenAddress) {
    return {
      isInsufficientFunds:
        data === undefined
          ? false
          : data.value < parseUnits(amount as `${number}`, tokens?.[chainId]?.[token]?.decimals || 18),
      isLoading: isLoadingNative,
    };
  }

  return {
    isInsufficientFunds:
      balancesResponse === undefined
        ? false
        : tokenBalance < parseUnits(amount as `${number}`, tokens?.[chainId]?.[token]?.decimals || 18),
    isLoading,
  };
};
