import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { DashboardFormValues } from 'widgets/Dual/model/form';

import { LimitDualInfoProvider } from 'features/LimitsDual';

import { useRate } from 'entities/Rates';

type Props = {
  children: React.ReactNode;
};

export const DualLimitRoot: FC<Props> = observer(({ children }) => {
  const { control } = useFormContext<DashboardFormValues>();
  const [tariff, inputTicker, chainId] = useWatch({
    control,
    name: ['tariff', 'inputTicker', 'chainId'],
  });

  const baseTicker = tariff?.baseTicker || '';
  const quoteTicker = tariff?.quoteTicker || '';

  const rateObj = useRate({ from: baseTicker, to: quoteTicker, inputTicker });
  return (
    <LimitDualInfoProvider
      entryPrice={rateObj?.formatted?.price}
      recalculate={`${baseTicker}-${quoteTicker}-${inputTicker}`}
      chainId={chainId}
    >
      {children}
    </LimitDualInfoProvider>
  );
});
