import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useSignAuthMessage } from 'features/Auth';

import { useUserMutation } from 'entities/User';

import { useAccount } from 'shared/hooks';
import { DEFAULT_ERROR } from 'shared/lib';
import { notifyError } from 'shared/lib/notifications';
import { Box, Button, Input, Modal, PageContent, Text } from 'shared/ui';
import { StyledText } from 'shared/ui/Error/styled';

import { Spinner } from './Spinner';
import { ReactComponent as SuccessIcon } from './assets/success.svg';
import { ReactComponent as XIcon } from './assets/x_icon.svg';
import styles from './index.module.scss';

const REHOLD_X_URL = 'https://twitter.com/intent/follow?screen_name=rehold_io';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function buildFullUrl(url: string) {
  try {
    return new URL(url);
  } catch (_: any) {
    return null;
  }
}

function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return (url.hostname === 'x.com' || url.hostname === 'twitter.com') && url.pathname !== '/';
}

export const DailyQuestTwitterModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const [url, setUrl] = useState('');

  const { getSignature } = useSignAuthMessage();
  const { data, mutateAsync: updateUser, isLoading, isSuccess: isUpdateUserSuccess } = useUserMutation();

  const usernameRegex = /^[a-z0-9_]*$/i;
  const urlObject = buildFullUrl(url) || buildFullUrl(`https://${url}`);

  const isValidLink = isValidHttpUrl(url) || isValidHttpUrl(`https://${url}`);
  const isValidUsername = urlObject && usernameRegex.test(urlObject.pathname.split('/')[1]);
  const isValid = !!url && isValidLink && isValidUsername;

  const isSuccess = isUpdateUserSuccess && !!data?.data?.twitterUrl;
  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  const handleTwitterCheck = async () => {
    try {
      if (!address) return;

      const signature = await getSignature();

      if (!signature) return;

      if (!urlObject || !isValid) {
        notifyError({ text: t('modals.dailyQuest.error.invalidLink') });
        return;
      }

      await updateUser({ signature, twitterUrl: `${urlObject.origin}${urlObject.pathname}` });
    } catch (e: any) {
      const message = e.response?.data?.twitterUrl?.message || e.response?.data?._error?.message || DEFAULT_ERROR;

      notifyError({ text: message });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth={isWideMobile ? '100%' : 500} closeIcon>
      <PageContent height="100%" maxHeight="100%" flexShrink={2} px={22} pb={22}>
        <Box flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          {!isLoading && !isSuccess && <XIcon width={80} height={80} />}
          {isLoading && !isSuccess && <Spinner />}
          {isSuccess && <SuccessIcon width={80} height={80} />}

          {!isLoading && !isSuccess && (
            <Text className={styles['text-container']} mt={12} textAlign="center" text="app-22-medium">
              <Trans
                i18nKey="modals.dailyQuest.text.subscribe.twitter"
                components={[<Link to={REHOLD_X_URL} target="_blank" rel="nofollow noreferrer" />]}
              />
            </Text>
          )}
          {isLoading && !isSuccess && (
            <Text mt={12} textAlign="center" text="app-22-medium">
              {t('modals.dailyQuest.text.verifying')}
            </Text>
          )}
          {isSuccess && (
            <Text mt={12} textAlign="center" text="app-22-medium">
              {t('modals.dailyQuest.text.verified')}
            </Text>
          )}

          {!isLoading && !isSuccess && (
            <Button variant="primary" size="large" mt={24} onClick={() => window.open(REHOLD_X_URL, '_blank')}>
              {t('modals.dailyQuest.button.subscribe')}
            </Button>
          )}
          {isSuccess && (
            <Button variant="primary" size="large" mt={24} onClick={onClose}>
              {t('modals.dailyQuest.button.gotIt')}
            </Button>
          )}

          {!isSuccess && (
            <Box
              mt={20}
              width="100%"
              borderRadius={12}
              backgroundColor="secondary-02"
              padding={16}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={16}
            >
              <Text text="app-14-medium"> {t('modals.dailyQuest.linkToAccount')}</Text>
              <Box
                width="100%"
                borderRadius={16}
                backgroundColor="background-01"
                padding={8}
                flexDirection="row"
                alignItems="center"
              >
                <Input placeholder="x.com/user" value={url} onChange={(e) => setUrl(e.target.value)} />
                <Button
                  width={96}
                  variant="secondary"
                  size="medium"
                  disabled={!isValid || isLoading || !address}
                  onClick={handleTwitterCheck}
                >
                  {t('modals.dailyQuest.button.check')}
                </Button>
                {!!url && !isValid && (
                  <StyledText
                    $align="left"
                    className="triangle-up"
                    position="absolute"
                    bottom={0}
                    zIndex={10000000000}
                    borderRadius="8px"
                    px={8}
                    py={4}
                    bgColor="red-01"
                    text="app-12-regular"
                    left={12}
                    right="unset"
                    maxWidth="100%"
                  >
                    {t('modals.dailyQuest.error.invalidLink')}
                  </StyledText>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </PageContent>
    </Modal>
  );
};
