import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NotificationDualsCard } from 'features/Notifications';

import { useGetCountDualsQuery, useGetDualsOverviewQuery } from 'entities/Dual';
import { useUserStore } from 'entities/User';

import { useIsMobile } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { Box, Text, Tabs, Tab, PageContent, Spinner } from 'shared/ui';

import { TypeTab } from './model';
import { ActiveDuals } from './ui/ActiveDuals';
import { HistoryDuals } from './ui/HistoryDuals';
import { LimitDuals } from './ui/LimitDuals';
import { Overview } from './ui/Overview';

export const DualsUserWidget = observer(() => {
  const { t } = useTranslation();

  const { isLoading: isLoadingUser } = useUserStore();
  const { isLoading: isLoadingCount, data: countData } = useGetCountDualsQuery();
  const { isLoading: isLoadingOverview } = useGetDualsOverviewQuery();

  const isLoading = isLoadingCount || isLoadingUser || isLoadingOverview;

  const router = useNavigate();
  const { type } = useParams();
  const [tab, setTab] = useState(type || TypeTab.ACTIVE);

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: boolean | number | string) => {
      setTab(newValue as string);
      if (!window.location.pathname.endsWith(PathPatterns.Duals))
        window.history.replaceState(
          window.history.state,
          '',
          window.location.pathname.replace(/\/[^/]*$/, `/${newValue}${window.location.search}`),
        );
      else
        window.history.replaceState(
          window.history.state,
          '',
          `${window.location.pathname}/${newValue}${window.location.search}`,
        );
      track(PredefinedEvents.Dual.Tabs.Click({ tab: newValue as string }));
    },
    [router],
  );

  const countActive = countData?.active;
  const countLimit = countData?.limit;

  const isMobile = useIsMobile();

  return (
    <>
      <PageContent px={{ default: 12, desktop: 16, tablet: 16 }} py={{ desktop: 20, default: 8, tablet: 16 }}>
        {!isLoading && (
          <>
            <Overview mb={24} />
            <NotificationDualsCard mb={24} />

            <Tabs onChange={handleChange} value={tab} selectionFollowsFocus mb={24}>
              <Tab label={t('dual.active')} value={TypeTab.ACTIVE}>
                {!!countActive && !isLoadingCount && (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    minWidth={20}
                    height={20}
                    bg="primary-01"
                    borderRadius={16}
                    px={4}
                  >
                    <Text color="black-01">{countActive}</Text>
                  </Box>
                )}
              </Tab>
              <Tab label={t('dual.limit')} value={TypeTab.LIMIT}>
                {!!countLimit && !isLoadingCount && (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    minWidth={20}
                    height={20}
                    bg="primary-01"
                    borderRadius={16}
                    px={4}
                  >
                    <Text color="black-01">{countLimit}</Text>
                  </Box>
                )}
              </Tab>
              <Tab label={t('dual.history')} value={TypeTab.HISTORY} />
            </Tabs>
            <Box paddingBottom={isMobile ? 80 : 0}>
              {tab === TypeTab.ACTIVE && <ActiveDuals />}
              {tab === TypeTab.LIMIT && <LimitDuals />}
              {tab === TypeTab.HISTORY && <HistoryDuals />}
            </Box>
          </>
        )}
        {isLoading && (
          <Box flexDirection="row" alignItems="center" justifyContent="center" height={561}>
            <Spinner />
          </Box>
        )}
      </PageContent>
    </>
  );
});
