import { useQuery } from '@tanstack/react-query';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

import { getReferralOverview } from '../api/queries';

export const useGetReferralOverviewQuery = () => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  return useQuery([`referral-overview-${address}`], () => getReferralOverview(address!), {
    enabled: !!address && !!chainId,
    staleTime: 30000,
  });
};
