import { useMemo } from 'react';

import { useGetDualTokenAddress } from 'entities/Token/model/useGetDualTokenAddress';

import { useDualTariffByChain } from './useDualTariffByChain';

type Props = {
  assetId: string;
  chainId?: number;
};

export const useStakingPlans = ({ assetId, chainId }: Props) => {
  const [baseTicker = '', quoteTicker = ''] = assetId.split('/');
  const baseToken = useGetDualTokenAddress(baseTicker, chainId!);
  const quoteToken = useGetDualTokenAddress(quoteTicker, chainId!);

  const { tariffs } = useDualTariffByChain({
    baseToken: (baseToken as string) || '',
    quoteToken: (quoteToken as string) || '',
    chainId: chainId!,
  });

  return useMemo(() => {
    if (!assetId) {
      return [];
    }

    return tariffs
      .filter((tariff) => tariff.baseTicker === baseTicker && tariff.quoteTicker === quoteTicker)
      .sort((a, b) => a.stakingPeriod - b.stakingPeriod);
  }, [tariffs, baseTicker, quoteTicker, assetId]);
};
