import BN from 'bignumber.js';

import { GAS_FOR_CHAINS, Network } from 'entities/Chain';

export const getMaxAmountForSwap = (network: Network | null | undefined, balance: string) => {
  if (!network) return BN(0).toFixed(6);
  if (!balance) return BN(balance).toFixed(6);

  const gas = BN(GAS_FOR_CHAINS[network]?.swap || 0).times(BN(3));

  if (BN(balance).gt(gas)) {
    return BN(balance).minus(gas).toFixed(6);
  }

  return BN(0).toFixed(6);
};
