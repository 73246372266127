import { getAmountPrecisionBySymbol } from '@rehold-io/formatters';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues, NumericFormat, OnValueChange } from 'react-number-format';

import { Card } from 'shared/ui/Card';

import styles from './styles.module.scss';

interface AmountHandlerProps {
  amount: string;
  className?: string;
  maxAmount: number;
  onAmountChange: (amount: string, ticker: string) => void;
  ticker: string;
}

export const PerpetualsFormAmountField: FC<AmountHandlerProps> = ({
  amount,
  maxAmount,
  className,
  onAmountChange,
  ticker,
}) => {
  const { t } = useTranslation();

  const handleAmountChange: OnValueChange = (values) => {
    onAmountChange(values.value, ticker);
  };

  const withValueLimit = ({ floatValue }: NumberFormatValues) => (floatValue ?? 0) <= maxAmount;

  return (
    <div className={className}>
      <Card variant="secondary" label={t('modals.perpetuals.amount')} isOutlined className={className}>
        <div className={styles.cardContent}>
          <NumericFormat
            data-id="input-amount"
            className={styles.input}
            displayType="input"
            type="text"
            inputMode="decimal"
            onValueChange={handleAmountChange}
            value={amount}
            isAllowed={withValueLimit}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={getAmountPrecisionBySymbol(ticker)}
            allowedDecimalSeparators={['.', ',']}
            defaultValue={0}
            placeholder="0"
            valueIsNumericString
          />
        </div>
      </Card>
    </div>
  );
};
