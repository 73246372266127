import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, FC, useMemo } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { DashboardFormValues } from 'widgets/Dual/model/form';

import { AutoReplayProvider } from 'features/AutoReplay';
import { useInitOnboarding, TOUR_CLASSES, useTour } from 'features/OnboardingTour';

import { useStakingPlans } from 'entities/Dual';

import { useAccount, useWidget } from 'shared/hooks';
import { Box } from 'shared/ui';

import { AmountHandlerField } from '../DualAmountField';
import { DualAutoReplayField } from '../DualAutoReplayField';
import { DualChartPreview } from '../DualChartPreview';
import { DualHeader } from '../DualHeader';
import { DualLimitField } from '../DualLimitField';
import { DualLimitRoot } from '../DualLimitField/DualLimitRoot';
import { StakingPlanSelectField } from '../StackingPlanSelectField';
import { SubmitButton } from '../SubmitButton';

import styles from './DualCreateForm.module.scss';

export const DualCreateForm = observer(() => {
  const isWidget = useWidget();
  const { tour } = useTour();
  const { isConnected } = useAccount();

  const { chainId, baseTicker, quoteTicker } = useParams();

  const assetId = baseTicker && quoteTicker ? `${baseTicker}/${quoteTicker}` : null;

  const plans = useStakingPlans({ assetId: assetId!, chainId: Number(chainId)! });

  const form = useForm<DashboardFormValues>({
    defaultValues: {
      assetId: assetId || '',
      inputAmount: '0',
      inputTicker: '',
      tariff: plans[plans.length - 1],
      chainId: Number(chainId),
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    form.reset({
      assetId: assetId || '',
      inputAmount: '0',
      inputTicker: '',
      tariff: plans[plans.length - 1],
      chainId: Number(chainId),
    });
  }, [chainId, baseTicker, quoteTicker]);

  useEffect(() => {
    if (isConnected && tour?.isActive() && tour.getCurrentStep()?.id === 'step-0') {
      tour.hide();
      tour.next();
    }
  }, [isConnected]);

  return (
    <FormProvider {...form}>
      <DualLimitRoot>
        <AutoReplayProvider>
          <Box
            pt={4}
            mb={8}
            className={classNames(styles.root, 'hide-scroll', {
              [styles['hide-submit-button']]: isWidget,
            })}
          >
            <DualHeader />
            <Box height={88} className={TOUR_CLASSES.PERIOD.class}>
              <StakingPlanSelectField name="tariffId" />
            </Box>
            <AmountHandlerField className={TOUR_CLASSES.AMOUNT.class} />

            {!isWidget && <DualAutoReplayField />}
            {!isWidget && <DualLimitField />}
            <DualChartPreview />
          </Box>

          {!isWidget && <SubmitButton />}

          <Updated />
        </AutoReplayProvider>
      </DualLimitRoot>
    </FormProvider>
  );
});

const Updated: FC = observer(() => {
  useInitOnboarding();

  const { control, setValue } = useFormContext<DashboardFormValues>();

  const [inputTicker, currentTariff, assetId, chainId] = useWatch({
    control,
    name: ['inputTicker', 'tariff', 'assetId', 'chainId'],
  });
  const plans = useStakingPlans({ assetId, chainId });

  useEffect(() => {
    const currentPlan = plans?.find((tariff) => tariff.id === currentTariff?.id);

    if (currentPlan && currentPlan.apr === currentTariff?.apr) return;

    const nextTariff = currentPlan || plans[plans.length - 1];

    setValue('tariff', nextTariff);
  }, [plans, currentTariff, setValue]);

  const amountTickers = useMemo(
    () => (currentTariff ? [currentTariff.baseTicker, currentTariff.quoteTicker] : []),
    [currentTariff],
  );

  useEffect(() => {
    if (amountTickers.includes(inputTicker)) return;

    const nextInputTicker = amountTickers[0] || '';

    if (nextInputTicker === inputTicker) return;

    setValue('inputTicker', nextInputTicker);
  }, [setValue, amountTickers, inputTicker]);

  return null;
});
