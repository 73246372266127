import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CloseIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { mapErrorToUserReadableMessage } from 'shared/lib';
import theme from 'shared/theme';
import { Box, Modal, PageContent, Text } from 'shared/ui';

import styles from './SwapStateModal.module.scss';

interface Props {
  error?: any;
  hash?: string;
  onClose: () => void;
  open: boolean;
}

export const SwapStateModalFail: FC<Props> = ({ error, hash, open, onClose }) => {
  const { t } = useTranslation();
  const { network } = useNetwork();

  return (
    <Modal className={styles.modal} isOpen={open} onClose={onClose} closeIcon>
      <PageContent height="100%" maxHeight="100%" flexShrink={2} px={22} pb={56} pt={34}>
        <Box flexDirection="column" alignItems="center" justifyContent="center" gap={16}>
          <Box
            height={60}
            width={60}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            backgroundColor="red-01"
            borderRadius={60}
          >
            <CloseIcon color={theme.colors['secondary-02']} width={40} height={40} />
          </Box>
          <Text text="app-22-medium">{t('modals.swapState.fail.title')}</Text>
          {error && (
            <Text text="app-14-medium" width="70%" textAlign="center">
              {`${mapErrorToUserReadableMessage(error)}`}
            </Text>
          )}
          {network && network.blockExplorers && network.blockExplorers.default && hash && (
            <Link to={`${network.blockExplorers.default.url}/tx/${hash}`} target="_blank">
              <Text text="app-14-medium" color="secondary-03">
                {t('common.viewOnExplorer')}
              </Text>
            </Link>
          )}
        </Box>
      </PageContent>
    </Modal>
  );
};
