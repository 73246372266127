import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getPerpetualBalance } from 'entities/Perpetual/api';

import { PerpetualBalanceRequestArgs, PerpetualBalanceResponse } from '../data';

export const useGetPerpetualBalanceQuery = (
  args: PerpetualBalanceRequestArgs,
  options?: UseQueryOptions<PerpetualBalanceResponse, {}>,
) =>
  useQuery<PerpetualBalanceResponse, {}>(
    [`perpetual-balance-${args.address}`],
    () => (!options || options.enabled === undefined || options.enabled ? getPerpetualBalance(args) : []),
    {
      ...options,
      enabled: options?.enabled !== undefined ? options.enabled : true,
      cacheTime: 2000,
      refetchInterval: 5000,
    },
  );
