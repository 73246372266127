import { useAccount } from 'shared/hooks';

import { useGetPerpetualClosedPositionsQuery } from '../../model/hooks/useGetPerpetualClosedPositionsQuery';

export const usePerpetualClosedPositions = () => {
  const { address, isConnected } = useAccount();

  const query = useGetPerpetualClosedPositionsQuery({ address: address || '' }, { enabled: isConnected });

  return query;
};
