import { offset, shift } from '@floating-ui/dom';
import { ThemeProvider } from '@mui/system';
import { PredefinedEvents, track } from '@rehold-io/data-layer-client';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import Shepherd from 'shepherd.js';
import Tour from 'shepherd.js/src/types/tour';

import { useIsMobile } from 'shared/hooks/useResponsive';
import { i18n } from 'shared/locales';
import theme from 'shared/theme';
import { Pagination } from 'shared/ui/Pagination';

import { STEPS_CLASSES, TOUR_CLASSES } from '../classes';

import styles from './Shepherd.module.scss';
import { getItems, addSteps } from './helpers';
import { ShepherdOptionsWithType } from './types';

interface ShepherdProps {
  children: React.ReactNode;
}

const ShepherdTourContext = React.createContext<Tour | null>(null);
const ShepherdTourContextConsumer = ShepherdTourContext.Consumer;

const elements: Record<number, ReactDOM.Root> = {};

const params = {
  buttons: [
    {
      text: 'Next',
    },
  ],
  when: {
    cancel() {
      track(PredefinedEvents.Onboarding.Skip());
    },
    show() {
      const currentStep = Shepherd.activeTour?.getCurrentStep()!;
      const vElement = currentStep.getElement();

      // https://github.com/shipshapecode/shepherd/issues/1143
      if (vElement) {
        vElement.focus = () => {};
      }
      const number = Shepherd.activeTour?.steps.indexOf(currentStep)!;

      track(PredefinedEvents.Onboarding.Change({ step: number + 1 }));

      const stepElement = document.querySelector(`[data-shepherd-step-id="step-${number}"]`)!;

      const element = stepElement.querySelector('.shepherd-cancel-icon')!.children[0];

      if (element) {
        element.textContent = i18n.t('onboarding.skip');
      }

      elements[number] = ReactDOM.createRoot(stepElement.querySelector('.shepherd-footer')!);

      const items = getItems(STEPS_CLASSES);

      const fallbackIndexItem = items.findIndex((index) => number <= index);
      const fallbackPage = items[fallbackIndexItem];

      elements[number].render(
        <ThemeProvider theme={theme}>
          <Pagination
            page={fallbackPage}
            totalPages={items.length}
            setPage={(id: number) => Shepherd.activeTour?.show(`step-${id}`)}
            items={items}
          />
        </ThemeProvider>,
      );
    },
  },
};

export const ShepherdTour: FC<ShepherdProps> = ({ children }) => {
  const [options, setOptions] = useState<Shepherd.Tour.TourOptions>();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  useEffect(() => {
    setOptions({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true,
        },
        floatingUIOptions: {
          middleware: [
            offset(10),
            shift({
              padding: 12,
            }),
          ],
        },
        ...params,
        scrollTo: !isMobile,
      },
      exitOnEsc: true,
      modalContainer: document.getElementById('page')!,
      stepsContainer: document.getElementById('page')!,
    });
  }, [isMobile]);

  const tourObject = useMemo(() => {
    if (!options) return null;
    const steps: Array<ShepherdOptionsWithType> = [
      {
        attachTo: { element: `.${TOUR_CLASSES.HEADER_CONNECT.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.HEADER_CONNECT.id}`,
        text: () => `<p>${t('onboarding.step1.description')}</p>`,
        title: () => t('onboarding.step1.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.PERIOD.class}`, on: 'bottom' },
        id: `step-${TOUR_CLASSES.PERIOD.id}`,
        text: () => `<p>${t('onboarding.step2.description')}</p>`,
        title: () => t('onboarding.step2.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.AMOUNT.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.AMOUNT.id}`,
        text: () => `<p>${t('onboarding.step3.description')}</p>`,
        title: () => t('onboarding.step3.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.FORM_CONNECT.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.FORM_CONNECT.id}`,
        text: () => `<p>${t('onboarding.step1.description')}</p>`,
        title: () => t('onboarding.step1.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.APROVE.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.APROVE.id}`,
        text: () => `<p>${t('onboarding.step4.description')}</p>`,
        title: () => t('onboarding.step4.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.START.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.START.id}`,
        text: () => `<p>${t('onboarding.step5.description')}</p>`,
        title: () => t('onboarding.step5.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.SWITCH_CHAIN.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.SWITCH_CHAIN.id}`,
        text: () => `<p>${t('onboarding.step7.description')}</p>`,
        title: () => t('onboarding.step7.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.INSUFFICIENT_FUNDS.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.INSUFFICIENT_FUNDS.id}`,
        text: () => `<p>${t('onboarding.step6.description')}</p>`,
        title: () => t('onboarding.step6.title'),
        classes: styles['onboarding-class'],
      },
      {
        attachTo: { element: `.${TOUR_CLASSES.UNAVAILABLE_CREATION.class}`, on: 'top' },
        id: `step-${TOUR_CLASSES.UNAVAILABLE_CREATION.id}`,
        text: () => `<p>${t('onboarding.step6.description')}</p>`,
        title: () => t('onboarding.step6.title'),
        classes: styles['onboarding-class'],
      },
    ];

    const ShepherdInstance = new Shepherd.Tour(options);

    addSteps(steps, ShepherdInstance);

    return ShepherdInstance;
  }, [options]);

  return <ShepherdTourContext.Provider value={tourObject}>{children}</ShepherdTourContext.Provider>;
};

export { ShepherdTourContext, ShepherdTourContextConsumer as TourMethods };
