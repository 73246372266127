import { BigNumber } from 'bignumber.js';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { etherUnits, formatUnits, parseUnits } from 'viem';

import { useEstimateWETHGas } from 'features/SwapTokens/lib/useEstimateWETHGas';

import { useRate } from 'entities/Rates';
import { useSwapDebouncedAmount, useSwapForm, useSwapQuote, useSwapTokenByAddress } from 'entities/Swap/lib/hooks';
import { useIsWETH } from 'entities/Swap/lib/hooks/useIsWETH';

import { GasIcon } from 'shared/assets';
import { NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { formatCurrency } from 'shared/lib';
import theme from 'shared/theme';
import { Box, Spinner, Text } from 'shared/ui';

import { SwapCollapsibleRates } from './SwapCollapsibleRates';

export const SwapCollapsible: FC = () => {
  const { t } = useTranslation();

  const { input, output } = useSwapForm();
  const {
    data: quote,
    isError: isQuoteError,
    isFetching: isQuoteFetching,
    isInitialLoading: isQuoteInit,
  } = useSwapQuote();

  const { data: inputToken } = useSwapTokenByAddress(input.address);

  const debouncedAmount = useSwapDebouncedAmount();

  const { data: nativeToken } = useSwapTokenByAddress(NATIVE_TOKEN_ADDRESS);
  const { price } = useRate({ from: nativeToken?.symbol, to: 'usd' });

  const isWETH = useIsWETH(input.address, output.address);

  const { data: gas, isLoading: isGasLoading } = useEstimateWETHGas({
    fromAmount: inputToken ? parseUnits(debouncedAmount as `${number}`, inputToken.decimals).toString() : '',
    fromToken: input.address,
    toToken: output.address,
    enabled: isWETH && price !== 1,
  });

  const formattedGas =
    isWETH && price !== 1 && gas && !isGasLoading
      ? formatCurrency(
          parseFloat(
            BigNumber(formatUnits(gas, etherUnits.gwei).toString())
              .multipliedBy(price)
              .toFixed(2, BigNumber.ROUND_CEIL),
          ),
          2,
        )
      : null;

  const [isOpen] = useState(false);

  const isError = (isQuoteError || quote === null) && !isWETH;
  const isLoading = isQuoteFetching && isQuoteInit && !isWETH;

  if (!input.address || !output.address || !debouncedAmount || !BigNumber(debouncedAmount).gt(0) || isError) return;

  return (
    <>
      <Box minHeight={56} border={1} borderColor="secondary-02" borderRadius={16} padding={16}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          columnGap={12}
          rowGap={8}
        >
          <Box flexDirection="row" alignItems="center" gap={16} maxWidth="100%">
            {(isLoading || (isWETH && isGasLoading)) && <Spinner size={20} />}
            {isLoading && <Text style={{ opacity: 0.4 }}>{t('swap.fetchingBestPrice')}</Text>}
            <SwapCollapsibleRates />
          </Box>
          <Box height={24} flexDirection="row" alignItems="center" gap={16} ml={-4}>
            {!isOpen && ((!isLoading && quote) || (!isGasLoading && formattedGas)) && (
              <Box height={24} flexDirection="row" alignItems="center" gap={8}>
                <GasIcon width={24} height={24} color={theme.colors['secondary-03']} />
                <Text text="app-14-medium" color="secondary-03">
                  {quote
                    ? formatCurrency(parseFloat(BigNumber(quote.gasAmountUSD).toFixed(2, BigNumber.ROUND_CEIL)), 2)
                    : formattedGas}
                </Text>
              </Box>
            )}
            {/* <ExpandIcon
            width={24}
            height={24}
            color={theme.colors['secondary-03']}
            style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
            onClick={() => setIsOpen((prev) => !prev)}
          /> */}
          </Box>
        </Box>
        {/* {isOpen && (
        <>
          <Box style={{ height: 1 }} my={isSmallMobile ? 8 : 16} backgroundColor="secondary-02" />
          <Box flexDirection="column" gap={isSmallMobile ? 8 : 16}>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <Text style={{ opacity: 0.4 }}>Network fee</Text>
              <Text>{currencyFormat(4.47)}</Text>
            </Box>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <Text style={{ opacity: 0.4 }}>Price impact</Text>
              <Text>{formatPercent({ decimals: 3, value: -0.0447 })}%</Text>
            </Box>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <Text style={{ opacity: 0.4 }}>Minimum input</Text>
              {inputToken && (
                <Text>
                  {formatAmount({
                    roundingMode: BN.ROUND_DOWN,
                    symbol: inputToken.symbol,
                    value: 605,
                  })}{' '}
                  {inputToken.symbol}
                </Text>
              )}
            </Box>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <Text style={{ opacity: 0.4 }}>Expected output</Text>
              {outputToken && (
                <Text>
                  {formatAmount({
                    roundingMode: BN.ROUND_DOWN,
                    symbol: outputToken.symbol,
                    value: 256,
                  })}{' '}
                  {outputToken.symbol}
                </Text>
              )}
            </Box>
          </Box>
        </>
      )} */}
      </Box>
    </>
  );
};
