import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useSignAuthMessage } from 'features/Auth';

import { useUserMutation } from 'entities/User';

import { useAccount } from 'shared/hooks';
import { DEFAULT_ERROR } from 'shared/lib';
import { notifyError } from 'shared/lib/notifications';
import { Box, Button, Input, Modal, PageContent, Text } from 'shared/ui';
import { StyledText } from 'shared/ui/Error/styled';

import { Spinner } from './Spinner';
import { ReactComponent as SocialIcon } from './assets/discord_icon.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';
import styles from './index.module.scss';

const REHOLD_DISCORD_URL = 'https://discord.gg/bDzYBhpyyJ';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function validateAndFormatDiscordUsername(input: string): string | null {
  const normalizedInput = input.trim().startsWith('@') ? input.trim().slice(1) : input.trim();
  const usernameRegex = /^(?!.*?\.{2,})[A-Za-z0-9_.]{2,32}$/gm;

  if (usernameRegex.test(normalizedInput)) {
    return normalizedInput;
  }

  return null;
}

export const DailyQuestDiscordModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const [username, setUsername] = useState('');

  const { getSignature } = useSignAuthMessage();
  const { data, mutateAsync: updateUser, isLoading, isSuccess: isUpdateUserSuccess } = useUserMutation();

  const isValid = !!username && !!validateAndFormatDiscordUsername(username);

  const isSuccess = isUpdateUserSuccess && !!data?.data?.discordUrl;

  const handleCheck = async () => {
    try {
      if (!address) return;

      const signature = await getSignature();

      if (!signature) return;

      const discordUrl = validateAndFormatDiscordUsername(username);

      if (!isValid || !discordUrl) {
        notifyError({ text: t('modals.dailyQuest.error.invalidUsername') });
        return;
      }

      await updateUser({ signature, discordUrl: discordUrl.toLowerCase() });
    } catch (e: any) {
      const message = e.response?.data?.discordUrl?.message || e.response?.data?._error?.message || DEFAULT_ERROR;
      notifyError({ text: message });
    }
  };

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth={isWideMobile ? '100%' : 500} closeIcon>
      <PageContent height="100%" maxHeight="100%" flexShrink={2} px={22} pb={22}>
        <Box flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          {!isLoading && !isSuccess && <SocialIcon width={80} height={80} />}
          {isLoading && !isSuccess && <Spinner />}
          {isSuccess && <SuccessIcon width={80} height={80} />}

          {!isLoading && !isSuccess && (
            <Text className={styles['text-container']} mt={12} textAlign="center" text="app-22-medium">
              <Trans
                i18nKey="modals.dailyQuest.text.subscribe.discord"
                components={[<Link to={REHOLD_DISCORD_URL} target="_blank" rel="nofollow noreferrer" />]}
              />
            </Text>
          )}
          {isLoading && !isSuccess && (
            <Text mt={12} textAlign="center" text="app-22-medium">
              {t('modals.dailyQuest.text.verifying')}
            </Text>
          )}
          {isSuccess && (
            <Text mt={12} textAlign="center" text="app-22-medium">
              {t('modals.dailyQuest.text.verified')}
            </Text>
          )}

          {!isLoading && !isSuccess && (
            <Button variant="primary" size="large" mt={24} onClick={() => window.open(REHOLD_DISCORD_URL, '_blank')}>
              {t('modals.dailyQuest.button.subscribe')}
            </Button>
          )}
          {isSuccess && (
            <Button variant="primary" size="large" mt={24} onClick={onClose}>
              {t('modals.dailyQuest.button.gotIt')}
            </Button>
          )}

          {!isSuccess && (
            <Box
              mt={20}
              width="100%"
              borderRadius={12}
              backgroundColor="secondary-02"
              padding={16}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={16}
            >
              <Text text="app-14-medium"> {t('modals.dailyQuest.linkToAccount')}</Text>
              <Box
                width="100%"
                borderRadius={16}
                backgroundColor="background-01"
                padding={8}
                flexDirection="row"
                alignItems="center"
              >
                <Input placeholder="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <Button
                  width={96}
                  variant="secondary"
                  size="medium"
                  disabled={!isValid || isLoading || !address}
                  onClick={handleCheck}
                >
                  {t('modals.dailyQuest.button.check')}
                </Button>
                {!!username && !isValid && (
                  <StyledText
                    $align="left"
                    className="triangle-up"
                    position="absolute"
                    bottom={0}
                    zIndex={10000000000}
                    borderRadius="8px"
                    px={8}
                    py={4}
                    bgColor="red-01"
                    text="app-12-regular"
                    left={12}
                    right="unset"
                    maxWidth="100%"
                  >
                    {t('modals.dailyQuest.error.invalidUsername')}
                  </StyledText>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </PageContent>
    </Modal>
  );
};
