import { useCallback } from 'react';

import { LocalStorage } from 'shared/lib/Storage';

export const useStorageHelpStart = () => {
  const get = useCallback(() => {
    const isShown = LocalStorage.get<Record<string, string>>('rehold_start_tooltip_shown')?.isShown;
    return !!isShown;
  }, []);
  const set = useCallback(() => LocalStorage.set('rehold_start_tooltip_shown', { isShown: 'yes' }), []);

  return { get, set };
};
