import BigNumber from 'bignumber.js';

import { FLEX_TARIFF_RELEASED_AT } from 'shared/config/common';

import { LimitDualsResponse } from '../model/types';

export const selectLimitDual = (data: LimitDualsResponse) =>
  data.map((item) => ({
    ...item,
    apr: FLEX_TARIFF_RELEASED_AT > item.createdAt ? BigNumber(item.apr).toFixed(6) : BigNumber(item.apr).toFixed(4),
  }));
