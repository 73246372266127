import { identify, init, page, PredefinedEvents, track, setUser } from '@rehold-io/data-layer-client';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { environment } from 'shared/config';
import { useAccount } from 'shared/hooks';
import { logger } from 'shared/lib/logger';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const IS_DEBUG = environment !== 'production';

export const useDataLayer = () => {
  const [isReady, setReady] = useState(false);
  const { pathname: from } = useLocation();
  const { address, connector } = useAccount();
  const {
    i18n: { language },
  } = useTranslation();

  useLayoutEffect(() => {
    if (isReady && from) {
      page();
    }
  }, [from, isReady]);

  useEffect(() => {
    if (!MIXPANEL_TOKEN) {
      return logger.error(new Error('Provide REACT_APP_MIXPANEL_TOKEN in .env to make MixPanel working.'));
    }

    try {
      init(MIXPANEL_TOKEN, {
        debug: IS_DEBUG,
        ignore_dnt: true,
      });

      setReady(true);
    } catch (error) {
      logger.error(error);
    }
  }, []);

  useEffect(() => {
    if (address && isReady) {
      try {
        identify(address.toLowerCase());
        setUser({ address: address.toLowerCase() });
      } catch (error) {
        logger.error(error);
      }
    }
  }, [address, isReady]);

  useEffect(() => {
    if (address && isReady) {
      setUser({ language });
    }
  }, [address, isReady, language]);

  useEffect(() => {
    if (connector && address && isReady) {
      connector.on('disconnect', () => {
        track(PredefinedEvents.Wallet.Disconnect.Success({ address, from, provider: connector.name }));
      });

      return () => {
        connector.removeAllListeners('disconnect');
      };
    }
  }, [address, connector, from, isReady]);
};
