import { toast } from 'react-hot-toast';
import { WaitForTransactionResult } from 'wagmi/dist/actions';

import { TransactionToast } from 'shared/ui/Toasts/TransactionConfirmation/TransactionToast';

export const waitForTransaction = (promise: Promise<WaitForTransactionResult>, txHash?: string) => {
  const toastId = toast.custom((t) => <TransactionToast toast={t} txHash={txHash} />, {
    duration: Infinity,
  });

  const timeoutPromise = new Promise<typeof promise>((_, reject) => {
    setTimeout(() => {
      reject(new Error('Transaction timeout'));
    }, 5 * 60 * 1000);
  });

  return Promise.race([promise, timeoutPromise])
    .then((r) => {
      if (!r.status) {
        throw new Error('Transaction failed');
      }

      toast.custom(
        (t) => (
          <TransactionToast
            toast={t}
            status="success"
            txHash={r.transactionHash}
            onClose={() => {
              toast.remove(t.id);
            }}
          />
        ),
        {
          duration: 3000,
          id: toastId,
        },
      );
      return r;
    })
    .catch((e) => {
      toast.custom(
        (t) => (
          <TransactionToast
            toast={t}
            status="error"
            txHash={txHash}
            onClose={() => {
              toast.remove(t.id);
            }}
          />
        ),
        {
          duration: 10000,
          id: toastId,
        },
      );

      throw e;
    });
};
