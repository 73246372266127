import { useState } from 'react';

import { Box } from 'shared/ui';

import { PerpetualsListsHistory } from './PerpetualsListsHistory';
import { PerpetualsListsPositions } from './PerpetualsListsPositions';
import { PerpetualsListTab, PerpetualsListsTabs } from './PerpetualsListsTabs';
import './index.scss';

export const PerpetualsLists = () => {
  const [tab, setTab] = useState<PerpetualsListTab>(PerpetualsListTab.POSITIONS);

  const handleTabChange = (tab: PerpetualsListTab) => {
    setTab(tab);
  };

  return (
    <Box className="perpetuals-lists">
      <Box className="perpetuals-lists-header">
        <PerpetualsListsTabs tab={tab} onTabChange={handleTabChange} />
      </Box>
      <Box className="perpetuals-lists-content">
        {tab === PerpetualsListTab.POSITIONS && <PerpetualsListsPositions />}
        {tab === PerpetualsListTab.HISTORY && <PerpetualsListsHistory />}
      </Box>
    </Box>
  );
};
