import { RefAttributes, forwardRef, useMemo } from 'react';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';

import { CheckIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { Box, Text } from 'shared/ui';

import './index.scss';

type Props = {
  onSelect: (chain: number, ticker: string) => void;
  selected: number;
};

export const PerpetualsTokenSelectModal: React.ForwardRefExoticComponent<Props & RefAttributes<unknown>> = forwardRef(
  ({ onSelect, selected }) => {
    const { state } = usePerpetualForm();

    const { networks: allNetworks } = useNetwork();

    const networks = useMemo(() => allNetworks.filter((net) => !!net.tokens), [allNetworks]);

    const tokens = useMemo(
      () =>
        networks
          .flatMap((network) =>
            Object.keys(network.tokens || {}).map((ticker) => ({ ...network.tokens?.[ticker], ticker, network })),
          )
          .sort((a, b) => b.ticker.localeCompare(a.ticker)),
      [networks],
    );

    return (
      <Box className="perpetuals-modal-token-select">
        {tokens.map((token) => (
          <>
            <Box
              onClick={() => {
                onSelect(token.network.id, token.ticker);
              }}
              className="perpetuals-modal-token-select-token"
            >
              <Box className="perpetuals-modal-token-select-token-info">
                <Box className="perpetuals-modal-token-select-token-image">
                  <img alt={`${token.ticker} token`} src={`/static/tickers/${token.ticker}.svg`} />
                  {token.network.renderLogo(16)}
                </Box>
                <Text
                  text="app-16-medium"
                  color={
                    token.network.id === selected && token.ticker === state.inputTicker ? 'primary-01' : 'white-01'
                  }
                >
                  {token.ticker.toUpperCase()}
                </Text>
                <Box className="perpetuals-modal-token-select-token-badge">
                  <Text text="app-12-medium">{token.network.tokenStandard}</Text>
                </Box>
                {token.network.testnet && (
                  <Box className="perpetuals-modal-token-select-token-badge">
                    <Text text="app-12-medium">Testnet</Text>
                  </Box>
                )}
              </Box>
              {token.network.id === selected && token.ticker === state.inputTicker && (
                <CheckIcon width={16} height={16} color="var(--primary-01)" />
              )}
            </Box>
          </>
        ))}
      </Box>
    );
  },
);
