import hj from '@hotjar/browser';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAccount } from 'shared/hooks';

export const useHotjarStateChanges = () => {
  const { pathname } = useLocation();
  const { address } = useAccount();
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    hj.init(Number(process.env.REACT_APP_HOTJAR_ID), Number(process.env.REACT_APP_HOTJAR_VERSION));
  }, []);

  useEffect(() => {
    if (hj.isReady() && !!window.hj?.settings) hj.identify(address as string | null, { language });
  }, [address, language]);

  useEffect(() => {
    if (hj.isReady() && !!window.hj?.settings) hj.stateChange(pathname);
  }, [pathname]);
};
