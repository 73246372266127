import { Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { PropsWithChildren } from 'react';

import { StyledButton } from './styled';
import { ButtonProps } from './types';

export const ButtonIcon = React.forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      children,
      className,
      disabled,
      isActive,
      isOutlined,
      isRounded,
      onClick,
      size = 'md',
      sx,
      variant = 'secondary',
      withIcon,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();

    const VariantProps = {
      dark: {
        background: theme.colors['background-01'],
        border: `1x solid ${theme.colors['secondary-02']}`,
        borderRadius: '40px',
        color: theme.colors['primary-01'],
        fontSize: theme.text['app-14-medium'].fontSize,
      },
      primary: {
        background: isOutlined ? 'transparent' : theme.colors['primary-01'],
        borderColor: theme.colors['primary-01'],
        color: isOutlined ? theme.colors['primary-01'] : theme.colors['background-01'],
      },
      secondary: {
        background: theme.colors['secondary-02'],
        borderColor: theme.colors['secondary-02'],
        color: theme.colors['secondary-03'],
        ...(isRounded &&
          isActive &&
          !isOutlined && {
            background: theme.colors['secondary-03'],
            borderColor: theme.colors['secondary-03'],
            color: theme.colors['white-01'],
          }),
        ...(isRounded &&
          !isActive &&
          isOutlined && {
            background: 'transparent',
          }),
      },
    };

    const sxProps: SxProps<Theme> = {
      ...VariantProps[variant],
      ...(size === 'lg' && { fontSize: theme.text['app-18-medium'].fontSize, height: '56px', px: '32px' }),
      ...(size === 'sm' && { height: '40px', px: '16px' }),
      ...(isRounded && { borderRadius: '32px' }),
      ...(withIcon && { height: '40px', padding: 0, width: '40px' }),
      ...(disabled && { cursor: 'not-allowed' }),
      ...sx,
    };

    return (
      <StyledButton
        ref={ref}
        type="button"
        className={className}
        onClick={onClick}
        disabled={disabled}
        $size={size}
        $variant={variant}
        sx={sxProps}
        {...props}
      >
        {children}
      </StyledButton>
    );
  },
);
