import { useEffect, useMemo, useRef, useState } from 'react';

import { getRateBySymbol } from '../lib/getRateBySymbol';

import { ratesStore } from './ratesStore';
import { RateProduct } from './types';

type RateArgs = {
  from: string | undefined;
  inputTicker?: string;
  interval?: number;
  product?: RateProduct;
  skip?: boolean;
  to: string | undefined;
};

export const useRateInterval = ({ from, to, inputTicker, product, skip = false, interval = 1000 }: RateArgs) => {
  const symbol = from && to ? `${from.toLowerCase()}/${to.toLowerCase()}` : '';
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const [rateObj, setRateObj] = useState({
    symbol,
    rate: ratesStore.rates[product || RateProduct.DEFAULT]?.[symbol],
  });

  useEffect(() => {
    if (!symbol || skip) {
      return;
    }

    ratesStore.rateSubscribe(symbol, product);

    return () => {
      ratesStore.rateUnsubscribe(symbol, product);
    };
  }, [symbol, skip]);

  useEffect(() => {
    if (!symbol || skip) {
      return;
    }

    const updateRate = () => {
      setRateObj({ symbol, rate: ratesStore.rates[product || RateProduct.DEFAULT]?.[symbol] });
    };

    updateRate();

    intervalId.current = setInterval(updateRate, interval);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [symbol, skip, interval]);

  const formattedRate = useMemo(
    () => getRateBySymbol(rateObj.rate!, rateObj.symbol, inputTicker),
    [rateObj, symbol, inputTicker],
  );

  return formattedRate;
};
