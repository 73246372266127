import { useQuery } from '@tanstack/react-query';

import { useNetwork } from 'shared/hooks/network';

import { getReferralSettings } from '../api/queries';

export const useGetReferralSettingsQuery = () => {
  const { chainId } = useNetwork();

  return useQuery([`referral-settings-${chainId}`], () => getReferralSettings(chainId!), {
    enabled: !!chainId,
    staleTime: 30000,
  });
};
