import { formatAmount } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBalance } from 'wagmi';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';

import { ExpandIcon, NextIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { useNetwork, useSwitchNetwork } from 'shared/hooks/network';
import { formatWithCommas } from 'shared/lib/formats/format';
import { availableWallets } from 'shared/lib/rehold-wallets';
import { Box, Button, Text } from 'shared/ui';

import { PerpetualsFormAmountField } from '../PerpetualsFormAmountField';
import { PerpetualsBalanceAction } from '../types';
import { useBalanceDeposit } from '../useBalanceDeposit';
import { useBalanceWithdraw } from '../useBalanceWithdraw';

import './index.scss';

type Props = {
  onClose: () => void;
  onSelectionOpen: () => void;
  onTokenChainUpdate: (chainId: number) => void;
  tokenChain: number;
  type: PerpetualsBalanceAction;
};

export const PerpetualsActionContent: React.FC<Props> = ({
  tokenChain,
  type,
  onTokenChainUpdate,
  onSelectionOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState('');

  const { switchNetwork } = useSwitchNetwork();
  const { state } = usePerpetualForm();

  const { networks, chainId } = useNetwork();
  const { address, connector } = useAccount();

  const currentWallet = useMemo(() => availableWallets.find((w) => w.connector.id === connector?.id), [connector]);
  const network = useMemo(() => networks.find((network) => network.id === tokenChain), [tokenChain, networks]);

  const tokenAddress = network?.tokens?.[state.inputTicker]?.address;
  const tokenAbi = network?.tokens?.[state.inputTicker]?.abi;

  useEffect(() => {
    if (network && (!network.tokens || !network.tokens?.[state.inputTicker])) {
      onTokenChainUpdate(networks.find((network) => !!network.tokens && network?.tokens?.[state.inputTicker])?.id || 1);
    }
  }, [network, state.inputTicker]);

  const { data: tokenBalance } = useBalance({
    address,
    token: tokenAddress,
    chainId: tokenChain,
    enabled: !!tokenAddress,
  });

  const {
    deposit,
    isPreLoading: isDepositPreLoading,
    isSufficientFunds: isDepositSufficientFunds,
  } = useBalanceDeposit({
    amountEth: amount,
    token: tokenAddress,
    chainId: tokenChain,
    ticker: state.inputTicker,
    abi: tokenAbi,
  });

  const {
    withdraw,
    isPreLoading: isWithdrawPreLoading,
    isSufficientFunds: isWithdrawSufficientFunds,
  } = useBalanceWithdraw({
    amountEth: amount,
    ticker: state.inputTicker,
    chainId: tokenChain,
    token: tokenAddress,
  });

  const handleClose = () => {
    if (type === PerpetualsBalanceAction.WITHDRAW) withdraw();
    if (type === PerpetualsBalanceAction.DEPOSIT) deposit();
    onClose();
    setTimeout(() => setAmount(''), 200);
  };

  return (
    <Box className="perpetuals-form-modal-content">
      <Box onClick={onSelectionOpen} className="perpetuals-form-modal-token">
        <Box className="perpetuals-form-modal-token-info">
          <Box className="perpetuals-form-modal-token-image">
            <img alt={`${state.inputTicker} token`} src={`/static/tickers/${state.inputTicker}.svg`} />
            {network?.renderLogo(16)}
          </Box>
          <Text text="app-16-medium">{state.inputTicker.toUpperCase()}</Text>
          <Box className="perpetuals-form-modal-token-badge">
            <Text text="app-12-medium">{network?.tokenStandard}</Text>
          </Box>
          {network?.testnet && (
            <Box className="perpetuals-modal-token-select-token-badge">
              <Text text="app-12-medium">Testnet</Text>
            </Box>
          )}
        </Box>
        <ExpandIcon width={16} height={16} />
      </Box>
      <PerpetualsFormAmountField
        className="perpetuals-form-modal-input"
        ticker={state.inputTicker}
        amount={amount}
        maxAmount={
          type === PerpetualsBalanceAction.DEPOSIT ? +(tokenBalance?.formatted || 0) : +(state.balance?.amount || 0)
        }
        onAmountChange={setAmount}
      />
      <Box className="perpetuals-form-modal-balance-container">
        <Box className="perpetuals-form-modal-balance-top">
          {type === PerpetualsBalanceAction.DEPOSIT && (
            <PerpetualsBalanceBreakdown
              source={tokenBalance?.formatted}
              amount={amount}
              imageSrc={currentWallet?.iconPath || '/static/wallets/injected.svg'}
              imageAlt={connector?.name || t('modals.perpetuals.wallet')}
              title={connector?.name || t('modals.perpetuals.wallet')}
              type="sub"
            />
          )}
          {type === PerpetualsBalanceAction.WITHDRAW && (
            <PerpetualsBalanceBreakdown
              source={state.balance?.amount}
              amount={amount}
              imageSrc={`/static/tickers/${state.inputTicker}.svg`}
              imageAlt={state.inputTicker}
              title={t('modals.perpetuals.tradingBalance')}
              type="sub"
            />
          )}
          <Button
            onClick={() => {
              setAmount(
                formatAmount({
                  value:
                    (type === PerpetualsBalanceAction.DEPOSIT ? tokenBalance?.formatted : state.balance?.amount) || 0,
                  symbol: state.inputTicker,
                  roundingMode: BigNumber.ROUND_DOWN,
                }),
              );
            }}
            variant="primary-solid"
          >
            {t('modals.perpetuals.max')}
          </Button>
          <Box className="perpetuals-form-modal-balance-down">
            <NextIcon width={16} height={16} />
          </Box>
        </Box>
        <Box className="perpetuals-form-modal-balance-bottom">
          {type === PerpetualsBalanceAction.WITHDRAW && (
            <PerpetualsBalanceBreakdown
              source={tokenBalance?.formatted}
              amount={amount}
              imageSrc={currentWallet?.iconPath || '/static/wallets/injected.svg'}
              imageAlt={connector?.name || t('modals.perpetuals.wallet')}
              title={connector?.name || t('modals.perpetuals.wallet')}
              type="sum"
            />
          )}
          {type === PerpetualsBalanceAction.DEPOSIT && (
            <PerpetualsBalanceBreakdown
              source={state.balance?.amount}
              amount={amount}
              imageSrc={`/static/tickers/${state.inputTicker}.svg`}
              imageAlt={state.inputTicker}
              title={t('modals.perpetuals.tradingBalance')}
              type="sum"
            />
          )}
        </Box>
      </Box>
      {type === PerpetualsBalanceAction.DEPOSIT &&
        (chainId !== tokenChain ? (
          <Button onClick={() => switchNetwork(tokenChain)}>{t('common.errors.switchNetwork')}</Button>
        ) : !isDepositPreLoading && !isDepositSufficientFunds ? (
          <Button disabled>{t('common.errors.insufficientFunds')}</Button>
        ) : (
          <Button
            onClick={handleClose}
            disabled={isDepositPreLoading || !amount || BigNumber(amount).eq(0)}
            loading={isDepositPreLoading}
          >
            {t(`modals.perpetuals.deposit.button`)}
          </Button>
        ))}
      {type === PerpetualsBalanceAction.WITHDRAW &&
        (!isWithdrawPreLoading && !isWithdrawSufficientFunds ? (
          <Button disabled>{t('common.errors.insufficientFunds')}</Button>
        ) : (
          <Button
            onClick={handleClose}
            disabled={isWithdrawPreLoading || !amount || BigNumber(amount).eq(0)}
            loading={isWithdrawPreLoading}
          >
            {t('modals.perpetuals.withdraw.button')}
          </Button>
        ))}
    </Box>
  );
};

const PerpetualsBalanceBreakdown: React.FC<{
  amount: string | undefined;
  imageAlt: string;
  imageSrc: string;
  source: string | undefined;
  title: string;
  type: 'sum' | 'sub';
}> = ({ title, imageSrc, imageAlt, source, amount, type }) => {
  const { state } = usePerpetualForm();

  const balanceBefore = BigNumber(source || 0);
  const balanceAfter = type === 'sum' ? balanceBefore.plus(amount || 0) : balanceBefore.minus(amount || 0);

  const balanceBeforeFormatted = formatWithCommas(
    formatAmount({
      value: balanceBefore.toNumber(),
      symbol: state.inputTicker,
      roundingMode: BigNumber.ROUND_DOWN,
    }),
  );
  const balanceAfterFormatted = formatWithCommas(
    formatAmount({
      value: balanceAfter.toNumber(),
      symbol: state.inputTicker,
      roundingMode: BigNumber.ROUND_DOWN,
    }),
  );

  return (
    <Box className="perpetuals-form-modal-balance-info">
      <img alt={imageAlt} src={imageSrc} className="perpetuals-form-modal-balance-image" />
      <Box className="perpetuals-form-modal-balance-texts">
        <Text text="app-12-medium" color="gray-01">
          {title}
        </Text>
        <Box className="perpetuals-form-modal-balance-text">
          <Text text="app-14-medium" color="white-01" title={balanceBeforeFormatted}>
            {balanceBeforeFormatted}
          </Text>
          <Box>
            <NextIcon />
          </Box>
          <Text text="app-14-medium" color="white-01" title={balanceAfterFormatted}>
            {balanceAfterFormatted}
          </Text>
          <Text minWidth="fit-content" ml={2} text="app-14-medium" color="gray-01">
            {state.inputTicker.toUpperCase()}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
