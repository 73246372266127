import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getPerpetualOpenedPositions } from 'entities/Perpetual/api';

import { PerpetualOpenedPositionsRequestArgs, PerpetualOpenedPositionsResponse } from '../data';

export const useGetPerpetualOpenedPositionsQuery = (
  args: PerpetualOpenedPositionsRequestArgs,
  options?: UseQueryOptions<PerpetualOpenedPositionsResponse | null, {}>,
) =>
  useQuery<PerpetualOpenedPositionsResponse | null, {}>(
    [`perpetual-opened-positions-${args.address}`],
    () => (!options || options.enabled === undefined || options.enabled ? getPerpetualOpenedPositions(args) : null),
    {
      ...options,
      enabled: options?.enabled !== undefined ? options.enabled : true,
      cacheTime: 1000 * 60,
      refetchInterval: 1000 * 60,
    },
  );
