import React from 'react';

import { DualTokenIcon } from 'entities/Token/ui/DualTokenIcon';

import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { Box, BoxProps } from '../Box';

import { StyledBox } from './styled';

interface CurrencyPairProps {
  backgroundColor?: BoxProps['borderColor'];
  borderColor?: BoxProps['borderColor'];
  borderWidth?: number;
  major: string;
  minor: string;
  renderAsTokens?: boolean;
  size?: number;
}

export const CurrencyPair: React.FC<CurrencyPairProps> = ({
  backgroundColor,
  borderColor = 'background-01',
  borderWidth = 3,
  major,
  minor,
  renderAsTokens = false,
  size = 32,
}) => {
  const IconComponent = renderAsTokens ? DualTokenIcon : CurrencyIcon;

  return (
    <Box flexDirection="row" m={-borderWidth} style={{ borderColor: 'inherit', backgroundColor: 'inherit' }}>
      <StyledBox
        position="relative"
        zIndex={1}
        marginRight={`-${size * 0.4 + borderWidth}px`}
        borderRadius={size}
        sx={{ borderWidth }}
        $borderColor={borderColor}
        bg={backgroundColor || borderColor}
      >
        <IconComponent ticker={major} size={size} />
      </StyledBox>
      <StyledBox position="relative" borderRadius={size} sx={{ borderWidth }} $borderColor={borderColor}>
        <IconComponent ticker={minor} size={size} />
      </StyledBox>
    </Box>
  );
};
